<template>
  <div class="box">
    <div class="content">
      <img src="@/assets/image/error1.png" alt="" />
      <div class="tips">{{tips}}</div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            tips: '该应用不存在或已下架'
        }
    },
    created() {
        this.tips = this.$route.query.tips
    }
}
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
      width: 220px;
      height: 160px;
  }
}
.tips {
  margin-top: 23px;
  text-align: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  color: #999999;
}
</style>