<template>
  <div>
    <div class="box">
      <div class="title1">隐私声明</div>
      <div class="title2">
        <div>一、&nbsp;&nbsp;采集个人（公司）信息和数据</div>
        <div>
          1.
          鉴于悟空在线（以下简称本平台）所提供服务的特性，应国家法律法规之要求，
          本平台在为您提供服务前及服务过程中可能需要您提供一些个人信息，以确保本平台服务的安全性，同时也能够使本平台更好地了解您的需求来为您服务。您承诺提供真实的个人信息并同意本平台有权采取措施验证您提供的个人信息的真实性。同时，根据国家法律法规，如您需要使用本平台的开发者功能，或注册本平台账户，在提供服务前，本平台需要对您进行实名认证，您应提供您合法、真实、准确、有效的实名认证资料等。经您同意，在您注册本平台账户我们可能获取您提交的公司名、职位、公司规模、QQ
          号等可选信息。<br />
          如果您想成为注册用户并使用本平台提供的服务，您必须阅读并接受本服务协议。只要您注册成为本平台的用户（无论是通过本平台还是通过其他第三方媒介，下同）就视为您接受了本服务协议。如果您接受本服务协议，则表示您有能力受其约束，或者，如果您代表了一个公司或实体，您有权使该实体受其约束。<br />

          2.
          服务过程中，为确保您使用服务的安全性，提升您的用户体验，我们可能会收集您的如下信息：<br />
          2.1.根据国家法律法规要求，为维护网络安全，本平台会收集您的首次登录 IP
          及时间、最近一次登录 IP
          及时间；在您充值时，本平台会记录您本次充值的微信账户、充值金额、充值时间、微信流水单号等充值记录。<br />
          2.2.当您访问本平台或其服务时，本平台可能会以 "Cookie"
          的形式将您用户权限 Token、来源网站等信息存入您的计算机。"Cookie"
          是网页服务器放置在您的计算机上的一个小的文本文件，"Cookie"不能用于运行程序，也不会将病毒传播到您的计算机上。使用
          "Cookie"
          的目的是通过记录登录信息的方式避免每次访问网站都需要重复进行登录操作进而为您提供一项节省时间的简便功能。您可以选择接受或拒绝
          "Cookie"。<br />
          2.3.本平台将有可能从商业伙伴处或通过其他合法途径获取您已经上载的个人信息，但本平台在获取前述信息前将向您明示并取得您的同意。<br />
          2.4.
          本平台承诺，非经法定原因或用户事先许可，本平台不会向任何第三方违法披露用户信息。<br />2.5在以下情况下，本平台可能对您的个人信息进行必要的披露：<br />

          2.51经您同意向您本人或您许可的其他第三方进行披露；<br />
          2.52
          根据法律、法规等相关规定，或行政机构要求，向行政、司法机构或其他法律规定的第三方披露；<br />
          2.53其它本平台根据法律、法规等相关规定进行的披露。
        </div>
        <br />
        <div>二、&nbsp;&nbsp;个人信息的保护</div>
        <div>
          您的个人信息于您而言非常重要，本平台将对其给予充分的重视，我们采用数据访问加密机制、cookie
          加密、数据库脱敏处理等技术保护您的个人信息。同时，希望您在使用本平台的过程中对您个人信息的保护给予充分的关注，如果您在使用本平台的过程中主动披露，或在通过本平台下载、使用应用程序的过程中主动披露您的个人信息，您应对前述披露的后果自行负责。
        </div>
        <br />
        <div>三、&nbsp;&nbsp;特别声明</div>
        <div>
          1.在您通过本平台下载或使用应用程序的过程中，本平台不会要求您提供任何个人信息，但我们无法控制亦无法保证应用程序开发者不会要求您提供个人信息。无论依据国家法律、法规的规定，以及《用户协议》的要求，应用程序开发者收集、使用您的个人信息应当遵循合法、正当、必要的原则，公开收集规则，向您明示收集、使用信息的目的、方式和范围，并且必须经过您的同意。应用程序开发者不得收集与其提供服务无关的个人信息，不得违反法律、法规规定或与您之间的约定收集、使用您的个人信息。<br />
          2.
          如您在通过本平台下载或使用应用程序的过程中被要求提供个人信息，请您审慎考虑并自行决定是否提供。如您发现应用程序对您个人信息的收集和使用不符合法律、法规的规定或不符合本平台既有规则要求的，请联系我们进行投诉。<br />
          3.在您通过本平台下载或使用应用程序过程中，如遇未经您同意擅自开启收集地理位置、读取通讯录、使用摄像
          头、启用录音等功能，或开启与服务无关的功能，或捆绑安装无关应用程序的情形，请联系我们对该应用程序进行投诉。<br />
          4. 投诉应当以书面形式进行，您可以通过下列方式发送您的书面投诉：
          邮箱：1515835306qq.com<br />
          4.1 投诉内容应包括：<br />
          4.1.1. 权利人的姓名（名称）、身份证号、手机号；<br />
          4.1.2.要求我们采取相应措施的应用被举报的原因；<br />
          4.1.3.前述内容违反法律或侵犯您合法权益的初步证明材料。<br />
          4.2.我们提请用户注意：<br />
          4.2.1.包含前述各项内容的投诉方为有效投诉。我们的工作人员将在15日内进行回复您的有效投诉。<br />
          4.2.2.您应当对前述投诉内容的真实性负责，如果您恶意投诉不实信息，将有可能承担相应的法律责任。
        </div>
        <br />
        <div>四、&nbsp;&nbsp;针对开发者的特别声明</div>
        <div>
          1.
          您通过本平台发布的应用程序如需收集、使用任何他人个人信息，您在收集、使用他人个人信息时应当遵循合法、正当、必要的原则，公开收集规则，向被收集者明示收集、使用信息的目的、方式和范围。该等信息应为您提供服务所必须收集的信息，否则不得收集，且不得违反法律、法规规定及与被收集者的约定进行收集、使用。收集、使用该等信息前必须经过被收集者同意。<br />
          2.
          您不得泄露、篡改、毁损您收集的个人信息；未经被收集者同意，不得向他人提供该等个人信息。<br />
          3.您通过本平台发布的应用程序不得在未经未成年人法定代理人同意的情况下收集或发布任何未成年人的身份信息和地理位置、指纹、声纹、面部识别、使用偏好等隐私信息。
        </div>
        <br />
        <div>五、&nbsp;&nbsp;其他条文约定</div>
        <div>
          1.本服务协议、任何适用的隐私政策、补充和更新条款以及您同意的任何隐私政策，构成悟空在线服务及其应用程序的完整的服务协议。<br />
          2.
          本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  margin: 15px;
  padding: 20px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  .title1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    margin-bottom: 10px;
    color: #333333;
  }
  .title2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #666666;
  }
  .font_indent {
    text-indent: 2em;
  }
}
</style>
