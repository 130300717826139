<template>
	<div>
		<div class="content_right">
			<div class="header">
				<div class="title">{{ appleList.remark2 }}</div>
				<div class="header_btn">
					<el-button size="medium" type="primary" v-loading="loading" plain @click="systemClick(4)">一键自查</el-button>
					<el-button size="medium"  :type="appleList.status == 3 ? 'info' : 'danger'" plain @click="systemClick(3)" :disabled="appleList.status == 3">失效</el-button>
					<el-button v-if="appleList.status == 2" size="medium" :type="appleList.status == 3 ? 'info' : 'success'" plain @click="systemClick(1)" :disabled="appleList.status == 3">启用</el-button>
					<el-button v-else size="medium"  :type="appleList.status == 3 ? 'info' : 'warning'"  @click="systemClick(2)" :disabled="appleList.status == 3">暂停</el-button>
					<el-button size="medium" type="primary" @click="editClick">编辑</el-button>
				</div>
			</div>
			<div class="zfstatus">
				<div class="titlestatus">证书状态</div>
				<div v-if="appleList.status != 3">
					<el-tag size="mini" type="success" style="margin-right: 5px;">有效</el-tag>
					<el-tag size="mini" type="info" v-if="appleList.status == 2" style="margin-right: 5px;">未启用</el-tag>
					<el-tag size="mini" type="primary" v-else>已启用</el-tag>
				</div>
				<div v-else><el-tag size="mini" type="danger">失效</el-tag></div>
			</div>
			<div class="box">
				<div class="box_header">
					<div class="img_box">
						<img src="@/assets/image/iPhone.png" />
						<div class="tips_box">
							<div class="title">{{ (100 - appleList.iosphoneudidNum) > 0?(100 - appleList.iosphoneudidNum):0 }}</div>
							<div class="tips">剩余可添加手机设备数</div>
						</div>
					</div>
					<div class="img_box">
						<img src="@/assets/image/ipad.png" />
						<div class="tips_box">
							<div class="title">{{ (100 - appleList.ipadudidNum) > 0?(100 - appleList.ipadudidNum):0 }}</div>
							<div class="tips">剩余可添加iPad设备数</div>
						</div>
					</div>
					<div class="img_box">
						<img src="@/assets/image/Mac.png" />
						<div class="tips_box">
							<div class="title">{{ (100 - appleList.macNum) > 0?(100 - appleList.macNum):0 }}</div>
							<div class="tips">剩余可添加Mac设备数</div>
						</div>
					</div>
				</div>
				<div class="content_box">
					<div class="box_item">
						<div class="content_item">
							<div class="title">Apple ID</div>
							<div class="tips">{{ appleList.appleaccount }}</div>
						</div>
						<div class="content_item">
							<div class="title">团队 ID</div>
							<div class="tips">{{ appleList.teamid }}</div>
						</div>
					</div>
					<div class="box_item">
						<div class="content_item">
							<div class="title">Issure ID</div>
							<div class="tips">{{ appleList.iss }}</div>
						</div>
						<div class="content_item">
							<div class="title">秘钥ID</div>
							<div class="tips">{{ appleList.keyid }}</div>
						</div>
					</div>
				</div>
				<div class="tips2">添加时间：{{ transformTimestamp(appleList.crdate) }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { accountOperation } from '@/api/api';
export default {
	props: {
		appleList: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			loading: false
		};
	},
	created() {
		console.log(this.appleList);
	},
	methods: {
		editClick() {
			this.$emit('editShow', 3);
			this.$emit('idChange', this.appleList.id);
		},
		systemClick(val) {
			let message = '';
			if (val == 1) {
				message = '启动';
				this.buttonPrompt(val, message);
			} else if (val == 2) {
				message = '暂停';
				this.buttonPrompt(val, message);
			} else if (val == 3) {
				message = '失效';
				this.buttonPrompt(val, message);
			} else {
				this.loading = true
				this.buttonAction(val);
			}
		},
		buttonPrompt(val, message) {
			this.$confirm('是否确定' + message, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.buttonAction(val);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消' + message
					});
				});
		},
		buttonAction(val) {
			accountOperation({ id: this.appleList.id, useState: val }).then(res => {
				this.loading = false
				if (res.code == 0) {
					this.$emit('statusId', this.appleList.id)
					if (val == 1) {
						this.$message.success('启用成功');
					} else if (val == 2) {
						this.$message.success('暂停成功');
					} else if (val == 3) {
						this.$message.success('失效成功');
					} else {
						if(res.data.status == 1){
							this.$message.success('账号正常');
						}else if(res.data.status == 2){
							this.$message.success('暂时无效');
						}else if(res.data.status == 3){
							this.$message.error('已被失效');
						}else{
							this.$message.error("苹果接口异常");
						}
						
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//时间转换
		transformTimestamp(timestamp) {
			let a = new Date(timestamp).getTime();
			const date = new Date(a);
			const Y = date.getFullYear() + '-';
			const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
			const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
			const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
			// const s = date.getSeconds(); // 秒
			const dateString = Y + M + D + h + m;
			// console.log('dateString', dateString); // > dateString 2021-07-06 14:23
			return dateString;
		}
	}
};
</script>

<style lang="scss" scoped>
.tips2 {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	margin: 20px 40px;
	color: #666666;
}
.content_item {
	display: flex;
	.title {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		color: #333333;
		margin-right: 20px;
		margin-bottom: 20px;
	}
	.tips {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;

		color: #666666;
	}
}
.content_box {
	display: flex;
	width: 840px;
	height: 104px;
	background: #f1f6f9;
	border-radius: 10px;
	.box_item {
		width: 420px;
		padding: 20px 40px;
	}
}
.tips_box {
	// width: 101px;
	text-align: center;
	margin: 10px 10px;
	.title {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 50px;
		text-align: center;
		color: #333333;
	}
	.tips {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #666666;
	}
}
.box_header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
}
.img_box {
	display: flex;
	justify-content: space-between;
    align-items: center;
	width: 270px;
	height: 110px;
	background: #f1f6f9;
	border-radius: 10px;
	padding: 5px 0;
	img {
		display: inline-block;
		width: 80px;
		height: 80px;
		margin-left: 20px;
	}
}
.zfstatus {
	display: flex;
	margin-bottom: 24px;
	.titlestatus {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: #666666;
		margin-right: 5px;
	}
}
.content_right {
	width: 920px;
	overflow: hidden;
	background: #ffffff;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 30px 40px;
	.header {
		display: flex;
		justify-content: space-between;
		.title {
			font-family: PingFang SC;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 31px;
			color: #333333;
		}
	}
}
</style>
