<template>
  <div>
    <div class="content_footer2">
      <div class="w">
        <div class="footer_left">
          <div class="list_left">
            <div
              class="font_style"
              style="cursor: pointer;"
              @click="contractClick(1)"
            >
              关于我们
            </div>
            <div class="contract">
              <div class="contract_left">
                <div @click="contractClick(2)" style="cursor: pointer;">
                  联系我们
                </div>
                <div @click="contractClick(3)" style="cursor: pointer;">
                  服务协议
                </div>
                <div @click="contractClick(4)" style="cursor: pointer;">
                  隐私申明
                </div>
                <div @click="contractClick(5)" style="cursor: pointer;">
                  免责申明
                </div>
              </div>
              <div class="contract_right">
                <div @click="contractClick(6)" style="cursor: pointer;">
                  审核规则
                </div>
                <div @click="contractClick(7)" style="cursor: pointer;">
                  合作伙伴
                </div>
                <div @click="contractClick(8)" style="cursor: pointer;">
                  外包业务
                </div>
              </div>
            </div>
          </div>
          <div class="list_left" style="margin-left: 60px;">
            <div class="font_style">产品服务</div>
            <div class="contract">
              <div class="contract_left">
                <div style="cursor: pointer;" @click="contractClick(9)">
                  应用管理
                </div>
                <div style="cursor: pointer;" @click="contractClick(10)">
                  WebClip
                </div>
                <div style="cursor: pointer;" @click="contractClick(11)">
                  悟空助手
                </div>
              </div>
              <div class="contract_right">
                <div style="cursor: pointer;" @click="contractClick(12)">
                  工具箱
                </div>
                <div style="cursor: pointer;" @click="contractClick(13)">
                  博客
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../../assets/image/heng.png"
          style="width: 1px;height: 100px;margin-top: 80px"
          alt=""
        />
        <div class="footer_right">
          <div style="margin-right: 76px">
            <div class="font_style" style="text-align: center">关注我们</div>
            <img src="../../assets/image/gongzhonghao.png" alt="" />
            <div class="footer_title">微信公众号</div>
          </div>
          <div style="margin-right: 76px">
            <div class="font_style" style="text-align: center">微信小程序</div>
            <img src="../../assets/image/weixin.png" alt="" />
            <div class="footer_title">微信扫一扫</div>
          </div>
		  <div >
		    <div class="font_style" style="text-align: center">微信客服</div>
		    <img src="../../assets/image/wxsys.png" alt="" />
		    <div class="footer_title">微信扫一扫</div>
		  </div>
        </div>
      </div>
      <footer-bottom></footer-bottom>
    </div>
  </div>
</template>
<script>
import footerBottom from "@/components/common/footer";
export default {
  name: "footer2",
  components: {
    footerBottom,
  },
  methods: {
    contractClick(val) {
      this.$store.commit('navActiveChange','')
      // window.localStorage.setItem("activeIndex", "");
      // if (val < 7) {
      //   this.$router.push({
      //     path: "/contract",
      //     query: {
      //       status: val,
      //     },
      //   });
      // }
      if(val == 1) {
        this.$router.push('/contract/aboutUs')
      }
      if(val == 2) {
        this.$router.push('/contract/contractUs')
      }
      if(val == 3) {
        this.$router.push('/contract/serviceGgreement')
      }
      if(val == 4) {
        this.$router.push('/contract/privacyStatement')
      }
      if(val == 5) {
        this.$router.push('/contract/disclaimers')
      }   
      if(val == 6) {
        this.$router.push('/contract/auditPolicy')
      }                              
      if(val == 7) {
        this.$router.push('/cooperative')
      }
      if(val == 8) {
        this.$router.push('/wukong/outsourcing')
      }
      if(val == 9) {
        this.$router.push('/appManagement')
      } 
      if(val == 10) {
        this.$router.push('/webClip')
      }
      if(val == 11) {
        this.$router.push('/installAssistant')
      }
      if(val == 12) {
        this.$router.push('/kit')
      }
      if(val == 13) {
        window.open('http://blog.wkzx.store/')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list_left {
  float: left;
}
.font_style {
  font-size: 18px;
  color: #fff;
  margin-bottom: 20px;
}
.content_footer2 {
  width: 100%;
  height: 393px;
  background: #0e0a33;
  position: absolute;
  // bottom: 0;
  .footer_left {
    width: 599.5px;
    float: left;
    margin-top: 35px;
    .contract {
      font-size: 14px;
      color: #c8c8c8;
      .contract_left {
        float: left;
        div {
          padding: 10px 0;
          margin-right: 60px;
        }
      }
      .contract_right {
        float: left;
        div {
          padding: 10px 0;
          margin-right: 60px;
        }
      }
    }
  }
  .footer_right {
    display: flex;
    justify-content: center;
    width: 599.5px;
    float: right;
    margin-top: 35px;
    img {
      width: 100px;
      height: 100px;
    }
    .footer_title {
      text-align: center;
      margin-top: 10px;
      color: #c8c8c8;
    }
  }
}
</style>
