<template>
  <div class="box">
    <div class="box_w">
      <div style="font-size: 20px; text-align: center">未受信任的企业及开发者“的解决办法</div>
      <div style="line-height: 35px">
        因苹果公司的政策原因，在iOS系统中首次
        安装企业版应用时会出现”未受信任的企业 级开发者“提示
      </div>
      <img style="width: 100%;height: 206px" src="../../assets/image/template_1.png" />
      <div style="font-size: 20px; text-align: center">解决办法如下 ( 以iOS 11系统为例 )：</div>
      <div style="line-height: 35px">1、在手机中打开设置功能，选择“通用”</div>
      <img style="width: 100%;height: 206px" src="../../assets/image/template_2.png" />
      <div style="width: 100%;height: 206px">2、在通用中，选择“描述文件与设备管理”功能</div>
      <img style="width: 100%;height: 206px" src="../../assets/image/template_3.png" />
      <div style="line-height: 35px;">
        3、在描述文件与设备管理中的企业级应用分类中，
        选择要安装的企业应用的文件名称与打开时的提示 一致），点击进入
      </div>
      <img style="width: 100%;height: 206px" src="../../assets/image/template_4.png" />
      <div style="line-height: 35px;">
        4、进入内测分发页面后，确认内测分发中的公司名
        称与应用名称后，点击信任“内测分发公司名称
      </div>
      <img style="width: 100%;height: 206px" src="../../assets/image/template_5.png" />
      <img style="width: 100%;height: 206px" src="../../assets/image/template_6.png" />
      <div style="line-height: 35px;">5、回到桌面，重新打开应用即可使用</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  height: 100vh;
  background: #fff;
  .box_w {
    margin: 0 1rem;
    height: 100%;
    background-color: #e5e5e5;
  }
}
</style>
