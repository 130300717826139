<template>
  <div>
    <div class="w">
      <div class="nav">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/appManagement' }"
            >应用管理</el-breadcrumb-item
          >
          <el-breadcrumb-item @click="goBack">超级签名</el-breadcrumb-item>
          <el-breadcrumb-item>{{title}}有池设备</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="header">
          <div class="title_box">
            <div class="title">{{title}}有池设备使用详情</div>
            <div class="title_footer">台数设备</div>
          </div>
          <div class="header_btn">
            <el-button type="primary" @click="exportClick">批量导出</el-button>
            <el-button :type="allBtnStyle" @click="allClick">全部</el-button>
            <el-button :type="onDayStyle" @click="onDayClick">一天</el-button>
            <el-button :type="onWeekStyle" @click="onWeekClick">一周</el-button>
          </div>
        </div>
        <div class="table">
          <el-table
            id="table"
            :data="tableData"
            border
            max-height="500"
            v-loading="loading"
            :header-cell-style="{ background: '#E5F1FA' }"
          >
            <el-table-column
              width="55"
              label="序号"
              align="center"
              type="index"
            ></el-table-column>
            <el-table-column
              prop="udid"
              label="设备UDID"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="firstDate"
              label="使用时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="softName"
              label="应用名称"
              align="center"
            ></el-table-column>
          </el-table>
          <paging-template :currentPage="currentPage" :pageSize="pageSize" :total="total" @pageSizeChange="pageSizeChange" @currentChange="currentChange"></paging-template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagingTemplate from '@/components/common/pagingTemplate'
import XLXS from "xlsx";
import FileSaver from "file-saver";
import { useUdidDetail,exportUdidDetail } from '@/api/api'
export default {
    components: {
      pagingTemplate
    },
    data() {
        return {
          currentPage: 1,
          pageSize: 20,
          total: null,
            tableData: [],
            title: '',
            loading: false,
            allBtnStyle: 'primary',
            onDayStyle: 'default',
            onWeekStyle: 'default',
            pattern: '',  // 公有池私有池下载 0.公有 1.私有
            date: 0  // 时间选择 0.全部 1.今天 2.一周
        }
    },
    created() {
        this.pattern = this.$route.query.pattern
        if(this.pattern == 0) {
            this.title = '公'
        } else {
            this.title = '私'
        }
        this.getuseUdid()
    },
    methods: {
      // 批量导出
      exportClick() {
        exportUdidDetail({
          pattern: this.pattern
        }).then((res) => {
          console.log(res)
          if (res.code == 1) {
            this.$message.error(res.msg);
            // this.fullscreenLoading = false;
          } else if (res.code == 0) {
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = res.data.url;
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$message.success("导出成功!");
            // this.fullscreenLoading = false;
          }
        });
      },
      pageSizeChange(val) {
        this.pageSize = val
      },
      currentChange(val) {
        this.currentPage = val
        this.getuseUdid()
      },
      // 返回vip页面
      goBack() {
        this.$router.push({
          path: '/appManagement',
          query: {
            vipShow: true
          }
        })
      },
        allClick() {
            this.allBtnStyle = 'primary'
            this.onDayStyle = 'default'
            this.onWeekStyle = 'default'
            this.date = 0
            this.getuseUdid()
        },
        onDayClick() {
            this.allBtnStyle = 'default'
            this.onDayStyle = 'primary'
            this.onWeekStyle = 'default'
            this.date = 1
            this.getuseUdid()
        },
        onWeekClick() {
            this.allBtnStyle = 'default'
            this.onDayStyle = 'default'
            this.onWeekStyle = 'primary'
            this.date = 2
            this.getuseUdid()
        },
        getuseUdid() {
            this.loading = true
            useUdidDetail({pattern: this.pattern,pageNum: this.currentPage,date: this.date}).then(res => {
                this.loading = false
                if(res.code == 0) {
                    console.log(res)
                    this.pageSize = res.data.useUdid.pageSize
                    this.currentPage = res.data.useUdid.pageNum
                    this.total = res.data.useUdid.total
                    this.tableData = res.data.useUdid.list
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.nav {
  margin: 20px 0;
}

.content {
  // height: 644px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .title_box {
    display: flex;
    .title {
      color: #333333;
      font-weight: bold;
      font-size: 22px;
      margin-right: 10px;
    }
    .title_footer {
      font-size: 14px;
      line-height: 40px;
      color: #666666;
    }
  }
}
</style>
