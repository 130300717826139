<template>
  <div class="content">
    <el-dialog
      title="举报"
      center
      v-model="dialogVisible"
      :close-on-click-modal="false"
      width="630px"
      :before-close="handleClose"
    >
      <div class="dialog_box">
        <div class="dialog_header">
          <span
            >作为一个第三方内测分发平台，我们一直致力于打击违规应用，保护知识产权。非常感谢您的举报，我们会在第一时间安排专人处理您举报的问题，并在处理之后向您反馈处理结果。</span
          >
          <br />
          <span style="margin-top: 20px;display:block;font-size:14px"
            >感谢您对我们的支持，如果有问题欢迎拨打我们的热线电话：0371-86550361</span
          >
        </div>
        <hr style="margin: 20px 0" />
        <el-form ref="formData" :model="formData" label-width="90px">
          <el-form-item label="您的手机号">
            <el-input v-model="formData.phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input v-model="formData.code" style="width: 280px"></el-input>
            <el-button
              type="primary"
              style="float:right;width: 144px"
              @click="getCode"
              :disabled="!show"
              >获取验证码<span v-show="!show" class="count"
                >({{ count }}s)</span
              ></el-button
            >
          </el-form-item>
          <div style="height: 32px">
            <my-slider-captcha
              @isSuccess="isSuccess"
              @codeData="codeData"
              :scene="scene"
            ></my-slider-captcha>
          </div>
          <el-form-item label="举报原因">
            <el-radio-group v-model="formData.report">
              <el-radio :label="1">盗版</el-radio>
              <el-radio :label="2">色情</el-radio>
              <el-radio :label="3">欺诈</el-radio>
              <el-radio :label="4">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="举报内容">
            <el-input
              v-model="formData.contentSupplement"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-button type="text">上传附件</el-button> -->
        <el-upload
          class="upload-demo"
          :headers="myHeaders"
          :action="src + 'software/upload'"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :before-upload="beforeUpload"
          multiple
          :limit="2"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button type="primary">上传附件</el-button>
        </el-upload>
        <br />
        <span
          >为了能够更快处理您的举报信息，建议上传截图或其他相关文件等证据</span
        >
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveData">举报</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="nextDialogVisible" :show-close="false" width="80%">
      <div style="height: 400px;position: relative">
        <div style="height: 230px;background: #3ABDF8;width: 100%">
          <div style="width: 175px;margin: auto">
            <el-carousel trigger="click">
              <el-carousel-item
                v-for="(item, index) in bannerList"
                :key="index"
              >
                <img
                  class="banner_img"
                  :src="item.imgUrl"
                  alt=""
                  style="width: 175px;height: 230px"
                />
                <p>{{ item.title1 }}</p>
                <h4 style="height: 45px;">{{ item.title2 }}</h4>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div
          style="position: absolute; bottom: 10px;left: 50%;transform: translate(-50%, 0);"
        >
          <el-button
            type="primary"
            round
            style="width: 250px"
            @click="nextInstall"
            >继续安装</el-button
          >
        </div>
      </div>
    </el-dialog>

    <div class="logo">
      <img :src="form.iconUrl" />
    </div>
    <div class="title">
      {{ form.label }}
    </div>
    <div class="verison" v-show="languageToggle == 1">
      版本： {{ form.version }}
    </div>
    <div class="verison" v-show="languageToggle == 2">
      versions： {{ form.version }}
    </div>
    <div class="font_style" v-show="languageToggle == 2">
      time:{{ form.remarks2 }}
    </div>
    <div
      class="down_class"
      v-show="languageToggle == 1"
      @click="downClick"
      v-if="downFlag"
    >
      立即下载
    </div>
    <div
      class="down_class"
      v-show="languageToggle == 2"
      @click="downClick"
      v-if="downFlag"
    >
      Download
    </div>
    <div class="down_class" v-if="!downFlag" v-show="languageToggle == 1">
      暂不支持此系统
    </div>
    <div class="down_class" v-if="!downFlag" v-show="languageToggle == 2">
      This system is not supported temporarily
    </div>
    <!-- <div v-if="homeKey" class="homeKey">正在安装,请返回桌面查看</div> -->
    <div class="erwei">
      <div id="qrCode" ref="qrCodeDiv"></div>
    </div>
    <div
      style="font-size: 14px;margin-bottom: 5px;"
      v-show="languageToggle == 1"
    >
      扫描二维码下载
    </div>
    <div
      style="font-size: 14px;margin-bottom: 5px;"
      v-show="languageToggle == 2"
    >
      Scan the QR code to download
    </div>
    <div style="font-size: 14px;margin-bottom: 10px;" v-show="languageToggle == 1">
      或使用浏览器打开 :{{ form.remarks1 }}
    </div>
    <div style="font-size: 14px;margin-bottom: 10px;" v-show="languageToggle == 2">
      Or use your mobile browser :{{ form.remarks1 }}
    </div>
    <footer-bottom :softInfo="form" style="margin-top: 10px;" @languageChange="languageChange"></footer-bottom>
     <el-dialog v-model="centerDialogVisible" title="提示"  width="70%" top="38vh" :show-close="false"  center>
            <span class="pop-context">该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。</span>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="centerDialogVisible = false">关闭</el-button>
              </span>
            </template>
      </el-dialog>
  </div>
</template>
<script>
import BASE_URL from "@/utils/request.js";
import {
  getOssToken,
  reportCode,
  reportCodeVerify,
  reportApp,
  webClipTakeOut,
} from "@/api/api";
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord } from "@/common/js/common.js";
import QRCode from "qrcodejs2";
import footerBottom from "@/views/appDown/footerBottom";
import mixin from "@/mixin"; // 引入mixin文件
import MySliderCaptcha from "../../../components/common/MySliderCaptcha.vue";
export default {
  name: "templatePhone",
  mixins: [mixin],
  components: {
    footerBottom,
    MySliderCaptcha
  },
  props: {
    form: {},
    shortUrl: {
      //短链接
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scene: "report",
      checkData: null,
      checkSuccess: false,
      show: true,
      count: "",
      dialogVisible: false,
      nextDialogVisible: false,
      softId: "",
      src: BASE_URL,
      formData: {
        phone: "",
        code: "",
        report: 1,
        contentSupplement: "",
        softId: "",
        files: [],
      },
      bannerList: [
        {
          id: 1,
          imgUrl: require("@/assets/image/vip_1.png"),
          title1: "第一步",
          title2: "点击“允许”进行下一步操作。",
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/vip_2.png"),
          title1: "第二步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/vip_3.png"),
          title1: "第三步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 4,
          imgUrl: require("@/assets/image/vip_4.png"),
          title1: "第四步",
          title2: "点击右上角“安装”，然后进行下一步操作。",
        },
        {
          id: 5,
          imgUrl: require("@/assets/image/vip_5.png"),
          title1: "第五步",
          title2: "点击“安装”即可。",
        },
      ],
      fileList: [],
      token: "",
      myHeaders: null,
      timer: null,
      languageToggle: 1,
      downFlag: true,
      homeKey: false,
      centerDialogVisible: false,
    };
  },
  created() {
    this.softId = this.$route.query.id;
    this.formData.softId = this.softId;
    this.getAppShow();
    this.token = window.localStorage.getItem("token");
    this.myHeaders = { Authorization: this.token };
  },
  methods: {
    codeData(val) {
      this.checkData = val;
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
     // 切换中英文
    languageChange(val) {
      this.languageToggle = val;
    },
    // 判断是否是ios17
    isIOSNew() {
      var ua = navigator.userAgent.toLowerCase();
      if(ua.indexOf("like mac os x") > 0){
            var regStr_saf = /os [\d._]*/gi ;
            var verinfo = ua.match(regStr_saf) ;
            var version = (verinfo+"").replace(/[^0-9|_.]/ig,"").replace(/_/ig,".");
            var arr=version.split(".");
            if(arr[0]>=17) return true;
        }
    },
    //点击下载
    downClick() {
       if(!this.getVipUserAgent()){
              this.centerDialogVisible = true;
          }else{
            webClipTakeOut({ userId: this.form.userId, id: this.form.id }).then(
        (res) => {
          if (res.code == 0) {
            if (res.data.result != 1) {
              this.$message.error("下载流量已用完,请购买下载流量包");
            } else {
              let ua = navigator.userAgent.toLocaleLowerCase();
              if (
                /Safari/.test(navigator.userAgent) &&
                !/Chrome/.test(navigator.userAgent)
              ) {
                if (ua.indexOf(" mac os x") > 0) {
                  var regStr_saf = /os [\d._]*/gi;
                  var verinfo = ua.match(regStr_saf);
                  var version = (verinfo + "")
                    .replace(/[^0-9|_.]/gi, "")
                    .replace(/_/gi, ".");
                  var arr = version.split(".");
                  if ((arr[0] > 11 && arr[0] < 17) && arr[1] >= 0) {
                    this.nextDialogVisible = true;
                  } else {
                    this.homeKey = true;
                    window.location.href = this.form.mobileconfigUrl;
                  }
                }
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        }
      );
       }
    },
    // 判断是否是ios17
    isIOSNew() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("like mac os x") > 0) {
        var regStr_saf = /os [\d._]*/gi;
        var verinfo = ua.match(regStr_saf);
        var version = (verinfo + "")
          .replace(/[^0-9|_.]/gi, "")
          .replace(/_/gi, ".");
        var arr = version.split(".");
        if (arr[0] >= 17) return true;
      }
    },
    //继续安装
    nextInstall() {
      window.location.href = this.form.mobileconfigUrl;
      if(!this.isIOSNew()) {
        setTimeout(function() {
          window.location.href = "https://p.udid.store/embedded.mobileprovision";
        }, 2 * 1000); //延
      }
     
    },
    // 选择中英文
    languageClick(val) {
      this.languageToggle = val;
    },
    // 免责声明
    exemptionClick() {
      this.$router.push({
        path: "/contract",
        query: {
          status: 5,
        },
      });
    },
    // 保存
    saveData() {
      this.codeJiaoyan();
      reportApp(this.formData).then((res) => {
        if (res.code == 0) {
          this.$message.success("举报成功");
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取验证码
    getCode() {
      if (!this.formData.phone) return this.$message.warning("请输入手机号");
      if (this.checkSuccess) {
        reportCode({ phone: this.formData.phone,sessionId:this.checkData.sessionId, sig: this.checkData.sig, sessiontoken: this.checkData.token, scene: this.scene }).then((res) => {
        if (res.code == "1") {
          this.$message({
            type: "warning",
            duration: 2000,
            message: res.msg,
          });
        } else {
          this.timerChange();
        }
      });
      } else {
        this.$message.warning("请先通过滑块验证");
      }
      
    },
    codeJiaoyan() {
      if (this.formData.phone) {
        reportCodeVerify({
          phone: this.formData.phone,
          code: this.formData.code,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
          } else {
            return this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号",
        });
      }
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 举报
    reportClick() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    getAppShow() {
      setTimeout(() => {
        this.bindQRCode(this.form.remarks1);
      }, 500);
    },
    // 删除文件
    handleRemove(file) {
      console.log(file);
    },
    //上传限制数
    handleExceed(files) {
      this.$message.warning(
        `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件`
      );
      //共选择了 ${files.length + fileList.length }个文件
    },
    //删除弹出框
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //上传文件调用
    beforeUpload(files) {
      //获取后缀名
      var fileName = files.name;
      var index = fileName.lastIndexOf(".");
      var suffix = fileName.substring(index, fileName.length); //后缀名
      fileName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(fileName, files)
            .then((res) => {
              if (res.url) {
                this.formData.files.push(res.url);
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {
              console.log(err)
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height:326px;
}
::v-deep .el-dialog {
  border-radius: 10px!important;
  .pop-title{
    font-size: 3vh;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .pop-context{
    text-align: center;
    display: block;
    height: auto;
  }
}
::v-deep .el-form-item__label {
  text-align: left;
}
.dialog_box {
  color: #888;
  padding: 25px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 20px 0;
  background: rgba(229, 229, 229, 1);
  .w {
    display: flex;
    justify-content: space-between;
    span {
      color: #0098dd;
    }
  }
}
#qrCode {
  border-radius: 16px;
  margin-bottom: 20px;
}
.content {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo img {
    width: 100px;
    height: 100px;
    border-radius: 16%;
    margin-top: 80px;
  }
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    margin: 20px 0 10px 0;
    /* identical to box height */

    text-align: center;

    color: #000000;
  }
  .verison {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #666666;
  }
  .font_style {
    margin-bottom: 40px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
.down_class {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  width: 9rem;
  padding: 0 1rem;
  height: 44px;
  text-align: center;
  background: #0098dd;
  border-radius: 22px;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin-top: 1.125rem;
  margin-bottom: 42px;

  font-family: PingFang SC;
  font-style: normal;
  // font-weight: bold;
  font-size: 14px;
}
.homeKey {
  margin-top: -18px;
  margin-bottom: 10px;
}
</style>