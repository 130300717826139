<template>
	<div>
		<div class="content">
			<div class="box1">
				<div class="header">
					<div class="title">流量包</div>
					<div class="tips">常见问题及扣除规则</div>
				</div>
				<div class="no_page" v-if="packageList.length < 1">
					暂无可购买方案
				</div>
				<div class="list_item" v-for="item in packageList" :key="item.id" @click="selectPrice(item,1)">
					<div class="hr"></div>
					<div class="item">
						<div class="item1">
							<div class="font1">{{ item.downnum }}</div>
							<div class="font2">累计下载流量</div>
						</div>
						<div class="item2">
							<span class="price1">¥</span>
							<span class="price2">{{ item.totalPrice }}</span>
						</div>
					</div>
				</div>
				<!-- <div class="tips_box">优先消耗免费流量，流量包不受时间限制，大陆地区每100兆（私有池每50兆）扣一点，海外以及港澳台地区每20兆（私有池每10兆）扣一点。</div> -->
				<div class="tips_box"> 优先消耗赠送流量，流量包点数不受时间限制，根据上传文件大小扣除，大陆地区分发每60M多扣一点，海外以及港澳台地区每20M多扣一点。 （超级签由于技术的特殊性，每20M多抠一点）</div>
			</div>
			<div class="box1">
				<div class="header">
					<div class="title">购买设备数量</div>
					<div class="tips">常见问题及扣除规则</div>
				</div>
				<div class="tips1">公有池设备 · 按台数购买</div>
				<div class="tips2">设备掉签会消耗新的设备数。</div>
				<div class="no_page" v-if="publicList.length < 1">
					暂无可购买方案
				</div>
				<div class="list_item1" v-for="item in publicList" :key="item.id" @click="selectPrice(item,2)">
					<div class="hr"></div>
					<div class="item">
						<div class="item1">
							<div class="font1">{{ item.deviceNum ? item.deviceNum : 0 }} 台</div>
							<div class="font2">设备数量</div>
						</div>
						<div class="item2">
							<span class="price1">¥</span>
							<span class="price2">{{ item.totalPrice }}</span>
						</div>
					</div>
				</div>

				<div class="header" style="margin-top: 15px"><div class="tips1">公有池设备 · 按时间购买</div></div>
				<div class="tips2">有效期内同一台设备掉签免费补签</div>
				<div class="no_page" v-if="yearList.length < 1">
					暂无可购买方案
				</div>
				<div class="list_item2" v-for="item in yearList" :key="item.id" @click="selectPrice(item,3)">
					<div class="hr"></div>
					<div class="item">
						<div class="item1">
							<div class="font1">
								<span v-if="item.remark3 == 1">一年/台</span>
								<span v-if="item.remark3 == 2">6个月/台</span>
								<span v-if="item.remark3 == 3">3个月/台</span>
								<span v-if="item.remark3 == 4">一个月/台</span>
							</div>
							<div class="font2">设备数量</div>
						</div>
						<div class="item2">
							<span class="price1">¥</span>
							<span class="price2">{{ item.totalPrice }}</span>
						</div>
					</div>
				</div>

				<div class="header" style="margin-top: 15px"><div class="tips1">私有池设备</div></div>
				<div class="tips2">用户自己提供开发者账号，如果您没有苹果开发者账号，请勿购买！申请苹果开发者账号</div>
				<div class="no_page" v-if="privateList.length < 1">
					暂无可购买方案
				</div>
				<div class="list_item3" v-for="item in privateList" :key="item.id" @click="selectPrice(item,4)">
					<div class="hr"></div>
					<div class="item">
						<div class="item1">
							<div class="font1">{{ item.deviceNum }} 台</div>
							<div class="font2">设备数量</div>
						</div>
						<div class="item2">
							<span class="price1">¥</span>
							<span class="price2">{{ item.totalPrice }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div>企业客户需要批量购买方案，私有定制方案</div>
				<div>
					请联系微信：
					<span style="color: #0092FF;">wkzx2020</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getPrice } from '@/api/api';
export default {
	data() {
		return {
			packageList: [],
			publicList: [],
			privateList: [],
			yearList: []
		};
	},
	mounted() {
		this.getPriceList();
	},
	methods: {
		// 购买
		selectPrice(val,index) {
			this.$message('请在小程序中操作')
			// let downnum = ''
			// if(index == 1) {
			// 	downnum = val.downnum
			// }
			// if(index == 2) {
			// 	downnum = val.deviceNum
			// }
			// if(index == 3) {
			// 	downnum = val.remark3
			// }
			// if(index == 4) {
			// 	downnum = val.deviceNum
			// }
			// this.$router.push({
			// 	path: '/mobilePrice',
			// 	query: {
			// 		downnum: downnum,
			// 		totalPrice: val.totalPrice,
			// 		productType: val.producttype,
			// 		priceType: index,
			// 		orderPriceId: val.id
			// 	}
			// })
		},
		getPriceList() {
			getPrice().then(res => {
				if (res.code == 0) {
					this.packageList = res.data.packageList;
					this.privateList = res.data.privateList;
					this.publicList = res.data.publicList;
					this.yearList = res.data.yearList;
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.no_page {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #666666;
}
.footer {
	margin: 15px 0;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #666666;
}
.list_item3 {
	height: 80px;
	background: #eed9ff;
	border: 1px solid #ac40ff;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 10px;
	overflow: hidden;
	.hr {
		height: 10px;
		background: #ac40ff;
	}
}
.list_item2 {
	height: 80px;
	background: #fff5cc;
	border: 1px solid #ffcc00;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 10px;
	overflow: hidden;
	.hr {
		height: 10px;
		background: #ffcc00;
	}
}
.list_item1 {
	height: 80px;
	background: #ccecdf;
	border: 1px solid #009e61;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 10px;
	overflow: hidden;
	.hr {
		height: 10px;
		background: #009e61;
	}
}
.tips1 {
	margin: 8px 0;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #333333;
}
.tips2 {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #ffb450;
	margin-bottom: 10px;
}
.tips_box {
	margin-top: 15px;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 17px;
	color: #999999;
}
.item {
	display: flex;
	justify-content: space-between;
}
.item1 {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.font1 {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 31px;
		text-align: center;
		color: #333333;
	}
	.font2 {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 17px;
		text-align: center;
		color: #666666;
	}
}
.item2 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10.875rem;
	height: 70px;
	background: #fff;
	.price1 {
		font-family: Arial;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: #ff4a4a;
	}
	.price2 {
		margin-left: 4px;
		font-family: Arial;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		text-align: center;
		color: #ff4a4a;
	}
}
.list_item {
	height: 80px;
	background: #cceaf8;
	border: 1px solid #0098dd;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 10px;
	overflow: hidden;
	.hr {
		height: 10px;
		background: #0098dd;
	}
}
.tips {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 25px;
	color: #ff4a4a;
}
.title {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;
	color: #333333;
}
.content {
	margin: 10px;
}
.header {
	display: flex;
	justify-content: space-between;
}
.box1 {
	padding: 20px 15px;
	// height: 379px;
	background: #ffffff;
	border-radius: 10px;
	margin-bottom: 10px;
}
</style>
