<template>
  <div>
    <div v-if="!sellFlag">
      <div class="content" v-if="qqAndWxStatus == 0">
        <div class="logo">
          <img :src="software.iconUrl" />
        </div>
        <div class="title">
          {{ software.softName }}
        </div>
        <div class="verison" v-show="languageToggle == 1">
          版本： {{ publish.publishName }}
        </div>
        <div class="verison" v-show="languageToggle == 2">
          versions： {{ publish.publishName  }}
        </div>
        <div class="font_style" v-show="languageToggle == 1">
          大小：{{ publish.softSize }}
        </div>
        <div class="font_style" v-show="languageToggle == 2">
          size: {{ publish.softSize }}
        </div>
        <div
          class="down_class"
          v-show="languageToggle == 1"
          @click="downClick"
          v-if="downFlag == 0"
        >
          立即下载
        </div>
        <div
          class="down_class"
          v-show="languageToggle == 2"
          @click="downClick"
          v-if="downFlag == 0"
        >
          Download
        </div>
        <div
          class="down_class"
          v-if="downFlag == 1"
          v-show="languageToggle == 1"
        >
          暂不支持此系统
        </div>
        <div
          class="down_class"
          v-if="downFlag == 1"
          v-show="languageToggle == 2"
        >
          This system is not supported temporarily
        </div>
        <div style="margin-bottom:30px;"></div>
           <footer-bottom
            :softId="software.softId"
            :bannerImg="bannerImg"
            :link="link"
            @languageChange="languageChange"
          ></footer-bottom>
      </div>
      <brower-template
        v-if="qqAndWxStatus == 1 || qqAndWxStatus == 2"
        :qqAndWxStatus="qqAndWxStatus"
        :icon="software.iconUrl"
        :softname="software.softName"
      ></brower-template>
    </div> 
  </div>
</template>
<script>
import browerTemplate from "@/views/appDown/template/browerTemplate";
import footerBottom from "@/views/appDown/footerBottom";
import {
  softConformInstallIf,
  getSuperInfo,
  getMMPPhoneAdvertising
} from "@/api/api";
import mixin from "@/mixin"; // 引入mixin文件
export default {
  mixins: [mixin],
  components: {
    browerTemplate,
    footerBottom,
  },
  data() {
    return {
      software:{},
      item: '',
      publish:{},
      sellFlag: false,
      DowndialogVisible: false,
      qqAndWxStatus: 0,
      dialogVisible: false,
      downFlag: 0,
      languageToggle: 1,
      userId: "",
      shortUrl: '',//短链接
      bannerImg:'',//广告图
      link:'',//连接
      
    };
  },
  mounted(){
    this.getMMPPhoneAdvertising();
      let short = window.location.href;
      this.shortUrl = short.substring(short.lastIndexOf("/")+1,short.length);
    if(this.shortUrl){
      this.getSuperInfo();
    }
    this.andriodAndApple();
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      this.qqAndWxStatus = 1;
    } else {
      if(ua.match(/iphone/i) == "iphone" && ua.match(/qqtheme/i) == "qqtheme"){
          this.qqAndWxStatus = 2;
       }
       if(ua.match(/android/i) == "android" && ua.match(/qqtheme/i) == "qqtheme"){
          this.qqAndWxStatus = 2;
      }
    }
  },
  methods: {
   async getSuperInfo(){
       await getSuperInfo(this.shortUrl).then((res) => {
        if(res.code == 0){
           this.item = res.data.item; 
           this.publish = res.data.publish; 
           this.software = res.data.software; 
           if(this.qqAndWxStatus == 1 || this.qqAndWxStatus == 2){
             if(this.software.softName){
                document.title = this.software.softName;
              }else{
                document.title = '';
            }
           }
        }else{
          this.$message.error(res.msg);
        }
       });
      
   },
    // 判断系统所属安卓还是苹果
   async andriodAndApple() {
        if (
          !(
            this.getBrower().ios ||
            this.getBrower().iPhone ||
            this.getBrower().iPad
          )
        ) {
          //非苹果端
          this.downFlag = 1;
        } else {
          this.downFlag = 0;
      }
    },
    languageClick(val) {
      this.languageToggle = val;
    },
    // 跳转到免责声明
    exemptionClick() {
      this.$router.push("/disclaimer");
    },
    // 举报
    reportClick() {
      this.$router.push({
        path: "/report",
        query: {
          softId: this.form.softId,
        },
      });
    },
    // 下载
    downClick() {
      //校验应用是否符合下载标准
      softConformInstallIf({softId: this.software.softId}).then((res) => {
         if(res.code != 0){
           this.$message.error(res.msg);
           return;
         }
      });
      console.log(this.item);
       window.location.href = this.item;
    },
     // 切换中英文
    languageChange(val) {
      this.languageToggle = val;
    },
    getMMPPhoneAdvertising() {//获取手机端
      getMMPPhoneAdvertising().then(res => {
        if(res.code == 0) {
          this.bannerImg = res.data.advertising.imgUrl
          this.link = res.data.advertising.link
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px;
}
.modal-body {
  padding: 30px;
  .modal_item {
    display: flex;
    justify-content: space-between;
    .img_dialog {
      width: 20px;
      height: 20px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
::v-deep .el-dialog__header {
  display: none;
}
.cxt {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(0, 152, 221, 1);
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 0.5rem;
  background: rgba(229, 229, 229, 1);
  .footer_header {
    display: flex;
    justify-content: space-between;
    span {
      color: #0098dd;
    }
  }
}
.sell_class {
  width: auto;
  padding: 0 1.5rem;
  height: 3rem;
  text-align: center;
  background: #ba812f;
  border-radius: 1.5rem;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin: 20px 0 30px 0;
}
.down_class {
  width: 9rem;
  padding: 0 1rem;
  height: 3rem;
  text-align: center;
  background: #0098dd;
  border-radius: 1.5rem;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin: 20px 0 30px 0;
}
.mobile_box {
  background: #fff;
  height: 100vh;
}

#qrCode {
  border: 10px solid #eee;
  padding: 10px;
  border-radius: 16px;
  margin-bottom: 20px;
}
.content {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo img {
    width: 8rem;
    height: 8rem;
    border-radius: 16%;
    margin-top: 80px;
  }
  .title {
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
  }
  .verison {
    font-size: 15px;
    margin-top: 18px;
    margin-bottom: 16px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
  }
  .font_style {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}

h3 {
  margin-top: 12px;
  font-size: 15px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
  text-align: center;
}
h4 {
  font-size: 12px;
  font-family: PingFang-SC-Regular;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 18px;
  text-align: left;
}
</style>