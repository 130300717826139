<template>
  <div>
    <div class="mobile" v-if="this.is_mobiState">
		<mobile-login></mobile-login>
	</div>
	<div class="pc" v-if="!this.is_mobiState">
		<div class="content">
		  <div class="content_box">
		    <div class="content_logo">
		      <img src="../../assets/image/logo.png" alt="" />
		    </div>
		    <div class="content_login">
		      <div class="content_tab">
		        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
		          <el-tab-pane label="密码登录" name="first">
		            <el-form
		              ref="form"
		              :model="form"
		              :rules="rules"
		              style="margin-top: 30px;"
		            >
		              <el-form-item prop="userName">
		                <el-input
		                  v-model="form.userName"
		                  placeholder="手机号/邮箱"
		                ></el-input>
		              </el-form-item>
		              <el-form-item prop="password">
		                <el-input
		                  type="password"
		                  v-model="form.password"
		                  placeholder="密码"
		                ></el-input>
		              </el-form-item>
		              <el-form-item>
		                <el-button @click="onSubmit" type="primary">登录</el-button>
		              </el-form-item>
		            </el-form>
		          </el-tab-pane>
		          <el-tab-pane label="免密登录" name="second">
		            <mianmi-login></mianmi-login>
		          </el-tab-pane>
		        </el-tabs>
		        <div class="ce_box">
		          <el-button type="text" @click="registerClick" v-show="showRegister">账号注册</el-button>
		          <el-button type="text" @click="forgetClick">忘记密码</el-button>
		        </div>
		      </div>
		    </div>
		    <div class="content_goback">
		      <div class="goback">
		        <img src="../../assets/image/home.png" alt="" />
		        <span @click="goback">回到首页</span>
		      </div>
		    </div>
		  </div>
		  <footer-bottom></footer-bottom>
		</div>
	</div>
  </div>
</template>

<script>
import { userLogin } from "@/api/api";
import mobileLogin from '@/views/mobilePage/mobileLogin.vue'
import footerBottom from "@/components/common/footer";
import mianmiLogin from '@/components/login/mianmiLogin'
export default {
  name: "login",
  components: {
    footerBottom,
    mianmiLogin,
	mobileLogin
  },
  data() {
    return {
      activeName: "first",
	  is_mobi: false,
      rules: {
        userName: [
          { required: true, message: "请输入手机号/邮箱", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      form: {
        userName: "",
        password: "",
        loginState: 0,
        accountType: 1,
      },
    };
  },
  computed: {
  	 is_mobiState() {
  		 return this.$store.state.is_mobi
  	 } 
  },
  created() {
	this.getPcAndMobile()  
  },
  methods: {
	  // 获取是否是pc
	  getPcAndMobile() {
	  		  this.is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
	  		  this.$store.commit('is_mobiChange',this.is_mobi)
	  },
    // 忘记密码
    forgetClick() {
      this.$router.push("/forgetPassword");
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.form.userName = "";
      this.form.password = "";
    },
    // 注册账号
    registerClick() {
      this.$router.push("/register");
    },
    goback() {
      this.$router.push("/index");
    },
    onSubmit() {
      const params = new URLSearchParams();
      params.append("userName", this.form.userName);
      params.append("loginState", this.form.loginState);
      params.append("password", this.form.password);
      this.$refs.form.validate((valid) => {
        if (valid) {
          userLogin(params).then((res) => {
            if (res.code == 0) {
              console.log(res)
							console.log(22222222222222222222222)
              this.$store.commit('tokenChange',res.data.token)
              this.$store.commit('authenticationChange',res.data.authentication)
              window.localStorage.setItem("token", res.data.token);
              let obj = {};
              obj.userName = res.data.userName;
              obj.userId = res.data.userId;
              window.localStorage.setItem("userInfo", JSON.stringify([obj]));
              if(res.data.authentication == 0 || res.data.authentication == 1){
                  return this.$router.push("/personalData?status=3");
              }
              // if(res.data.authentication == 2) {
              //   return this.$router.push('/lock/noexamine')
              // }
              window.localStorage.setItem("authentication", res.data.authentication);

              if(res.data.authentication == 4) {
                return this.$router.push('/lock/lockUser')
              }
              this.$router.push("/appManagement");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  position: relative;
}
.content_box {
  position: absolute;
  width: 422px;
  overflow: hidden;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content_logo {
    width: 100%;
    height: 56.6px;
    display: flex;
    align-item: center;
    margin-bottom: 50px;
    img {
      width: 200px;
      height: 56.6px;
      margin: auto;
    }
  }
  .content_login {
    width: 100%;
    height: 354px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    .content_tab {
      width: 362px;
      overflow: hidden;
      margin: auto;
      .ce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .el-tabs .el-form-item .el-button {
        width: 100%;
      }
    }
  }
  .content_goback {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background: #fff;
    color: #999999;
    margin: auto;
    .goback {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
