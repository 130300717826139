<template>
	<div class="content">
		<div class="pasword">
			<img style="width: 100px;height: 100px" :src="passwordObj.iconUrl" />
			<div class="title">{{ passwordObj.softName }}</div>
			<div class="title_1">
				版本：{{ passwordObj.version }}
				<!-- <span v-if="controlState == 0">请输入密码</span>
		    <span v-else>请输入邀请码</span> -->
			</div>
			<el-input v-model="password" :placeholder="tips"></el-input>
			<el-button v-if="passwordObj.actCode == 1" type="primary"
				style="width: 148px;margin-top: 1.875rem;-webkit-tap-highlight-color: rgba(0,0,0,0);" round
				@click="actSave">确认</el-button>
			<el-button v-else type="primary"
				style="width: 148px;margin-top: 1.875rem;-webkit-tap-highlight-color: rgba(0,0,0,0);" round
				@click="save">确认</el-button>
			<div class="banner" id="banner" v-if="bannerImg != ''" v-show="bannerFlag">
				<img :src="bannerImg" style="width: 100%; height: 100%" @click="bannerClick" />
				<img class="btn" @click="closeBtn" src="@/assets/image/close-btn.png" style="width: 20px; height: 20px" />
			</div>
		</div>
	</div>
</template>

<script>
import { MobilecheckInviteCode, MobilecheckActCode } from "@/api/api";
export default {
	props: {
		passwordObj: {
			type: Object,
			default: {},
		},
		controlState: {
			type: String,
			default: ""
		},
		bannerImg: {
			type: String,
			default: ''
		},
		link: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			adkey: '', // 用于校验是否是密码下载
			password: "",
			tips: '',
			bannerFlag: true,
			hideshow: true,
			docmHeight: 0,
			showHeight: 0,
		};
	},
	watch: {
		showHeight: function () {
			this.inputType();//安卓键盘顶起
		}
	},
	created() {
		console.log(this.passwordObj.iconUrl)
		console.log(this.controlState)
		//  document.title = this.passwordObj.softName
		if (this.controlState == 0) {
			this.tips = '请输入密码'
		}
		if (this.controlState == 1) {
			this.tips = '请输入邀请码'
		}
		if (this.passwordObj.actCode == 1) {
			this.tips = '请输入激活码'
		}
		// if(this.passwordObj.actCode== 0){
		// 	this.tips = '请输入密码'
		// }
	},
	methods: {
		inputType() {
			let height = this.docmHeight * 0.2;
			if (this.docmHeight - this.showHeight < height) {
				document.getElementById('banner').style.position = 'fixed';
			} else {
				document.getElementById('banner').style.position = 'relative';
			}
		},
		bannerClick() {
			window.location.href = this.link;
		},
		// 关闭
		closeBtn() {
			this.bannerFlag = false;
		},
		save() {
			let type = ''
			if (this.controlState) {
				type = this.controlState
			} else {
				type = 0
			}
			MobilecheckInviteCode({
				softId: this.passwordObj.softId,
				inviteCode: this.password,
				type: type,
			}).then((res) => {
				if (res.code == 0) {
					this.adkey = res.msg
					if (type == 0) {
						this.$router.push({
							path: '/templateMerge',
							query: {
								softId: this.passwordObj.softId,
								softUrl: this.passwordObj.softUrl,
								adkey: this.adkey,
							}
						});
					} else {
						this.$router.push({
							path: '/invitation/' + this.password,
						});
					}

				} else {
					this.$message.error(res.msg);
				}
			});
		},
		actSave() {
			MobilecheckActCode({
				softId: this.passwordObj.softId,
				actCode: this.password
			}).then((res) => {
				console.log(res)
				if (res.code == 200) {
					this.$router.push({
						path: '/active/' + this.password,
					});
				} else {
					this.$message.error(res.msg);
				}
			})
		},
	},
	mounted() {
		this.docmHeight = window.innerHeight;
		window.onresize = () => {
			return (() => {
				this.showHeight = window.innerHeight;
			})()
		}
	},
};
</script>

<style lang="scss" scoped>
.content {
	height: 100vh;
	background: #fff;
}

.banner {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 90px;
	overflow-y: scroll;

	.btn {
		position: absolute;
		display: block;
		z-index: 999;
		top: 35px;
		right: 10px;
		cursor: pointer;
	}
}

.title {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	text-align: center;
	margin: 1.25rem 0 0.625rem 0;
	color: #000000;
}

.title_1 {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	text-align: center;
	color: #666666;
	margin-bottom: 1.25rem;
}

.pasword {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10rem 3rem 0 3rem;
}
</style>
