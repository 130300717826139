<template>
  <div class="udid_body">
    <div class="udid_header">
      <div class="w1">
        <img
          src="../../assets/image/UDID.png"
          style="margin-top: 50px;margin-left: 20px;"
        />
      </div>
    </div>
    <div class="w1 udid_box" style="margin-top: 120px;">
      <div class="udid_left">
        <div class="font_1">
          请使用 iOS 设备的 Safari 浏览器打开 {{udidUrl}}
          或扫描右侧的二维码
        </div>
        <div class="font_2">该功能仅适配iOS 设备的 Safari 浏览器</div>
        <div class="font_3">
          UDID 是一种 iOS 设备的特殊识别码。除序号之外，每台 iOS
          装置都另有一组独一无二的号码，我们就称之为识别码（ Unique Device
          Identifier, UDID ）。就像我们的身份证一样。开发者需要知道你的
          UDID，才可以让你的手机安装访问测试中的应用，就像需要你的身份证才可以让你登机一样
          :)
        </div>
      </div>
      <div class="udid_right">
          <div class="erwei">
          <div id="qrCode" ref="qrCodeDiv"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  name: 'udid',
  data() {
    return {
      udidUrl: BASE_GDOMAIN+'udid'
    }
  },
  created() {
    this.getAppShow()
  },
  methods: {
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 280,
        height: 280,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    getAppShow() {
      setTimeout(() => {
        this.bindQRCode(this.udidUrl);
      }, 500);
    },
  }
}
</script>
<style lang="scss" scoped>
.w1 {
    width: 1170px;
    margin: auto;
}
.font_1 {
  font-size: 23px;
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  color: rgba(136, 136, 136, 1);
  line-height: 40px;
}
.font_2 {
  margin-top: 40px;
  font-size: 18px;
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  color: rgba(255, 0, 0, 1);
}
.font_3 {
  margin-top: 40px;
  line-height: 27px;
  font-size: 18px;
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  color: rgba(136, 136, 136, 1);
  margin-bottom: 100px;
}
.udid_body {
  background: #fff;
}
.udid_header {
  height: 200px;
  background: rgba(0, 183, 238, 1);
}
.udid_left {
  width: 682.5px;
}
.udid_box {
  display: flex;
  justify-content: space-between;
}
.udid_right {
    width: 280px;
}
</style>
