<template>
  <div v-if="!isScanCode" class="box">
      <div class="udid_box">
        <img src="@/assets/image/UDID_1.png" />
      </div>
      <div class="udid-mid">UDID</div>
      <div class="udid-item" v-if="!UDID">
        <el-button
          type="primary"
          @click="udidClick"
          style="margin: 40px 0 30px 0;width: 100%"
          >获取UDID</el-button
        >
        <div class="udid-txt">
          UDID 是一种 iOS 设备的特殊识别码。除序号之外，每台 iOS
          装置都另有一组独一无二的号码，我们就称之为识别码（ Unique Device
          Identifier, UDID ）。就像我们的身份证一样。开发者需要知道你的
          UDID，才可以让你的手机安装访问测试中的应用，就像需要你的身份证才可以让你登机一样
          :)
        </div>
      </div>
      <div class="udid-has" v-if="UDID">
        <div class="udid" id="copyBody">{{ UDID }}</div>
        <el-button
          class="copy_obj"
          type="primary"
          style="margin: 20px 0 30px 0"
          data-clipboard-action="copy"
          data-clipboard-target="#copyBody"
          @click="copy"
          >复制UDID</el-button
        >
        <div class="email_box">
          <div class="email_header">
            通过邮箱接收UDID？
          </div>
          <div class="e1">
            <el-input
              style="margin-bottom: 40px"
              v-model="email"
              placeholder="请输入接收UDID邮箱地址"
            ></el-input>
            <el-button type="primary">发送邮件给开发者</el-button>
          </div>
        </div>
      </div>
   </div>
   <div v-else class="scan_code">
     <img src="@/assets/image/iosMask.gif"/>
   </div>
</template>
<script>
import { udidReceive } from "@/api/api";
import Clipboard from "clipboard";
export default {
  name: "udidMobile",
  data() {
    return {
      UDID: "",
      iphone: "",
      email: '',
      isScanCode:false,
    };
  },
  created(){
    if (window.location.href.includes("?")) {
      this.UDID = this.$route.query.UDID;
      this.iphone = this.$route.query.iphone;
    }
      var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      this.isScanCode = true;
    } else {
      if(ua.match(/iphone/i) == "iphone" && ua.match(/qqtheme/i) == "qqtheme"){
          this.isScanCode = true;
       }
       if(ua.match(/android/i) == "android" && ua.match(/qqtheme/i) == "qqtheme"){
          this.isScanCode = true;
      }
    }
  },
  methods: {
    // 复制
    copy() {
      let clipboard = new Clipboard(".copy_obj");
      clipboard.on("success", (e) => {
        clipboard.destroy();
        return this.$message.success("复制成功");
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        clipboard.destroy();
        return this.$message.error("该浏览器不支持复制");
      });
    },
    // 获取udid
    udidClick() {
      var ua = navigator.userAgent.toLowerCase();
      if(ua.indexOf("like mac os x") > 0){
          var regStr_saf = /os [\d._]*/gi ;
          var verinfo = ua.match(regStr_saf) ;
          var version = (verinfo+"").replace(/[^0-9|_.]/ig,"").replace(/_/ig,".");
          var arr=version.split(".");
          if (arr[0]>11&&arr[1]>=0) {//12.2以后的版本
            window.location.href="https://p.udid.store/20211021wkzxsigned.mobileconfig";
            setTimeout( function(){
              window.location.href="https://p.udid.store/embedded.mobileprovision";
              }, 2 * 1000 );//延
          }else{//12.2以前的版本
            window.location.href="https://p.udid.store/20211021wkzxsigned.mobileconfig";
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100vh;
  background: #fff;
}
.scan_code{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}
.scan_code img{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.udid-has {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  .udid {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
    color: #fff;
    border-radius: 4px;
    background: #0098dd;
    margin: 20px 0;
  }
  .email_box {
    width: 100%;
    border-radius: 8px;
    height: 300px;
    border: 1px solid #888;
    .email_header {
      height: 50px;
      line-height: 50px;
      color: #888;
      font-size: 18px;
      padding: 0 20px;
      background: #ccc;
    }
    .e1 {
      padding: 60px 30px 0 30px;
    }
  }
}
.udid-item {
  padding: 0 20px;
}
.udid-txt {
  font-size: 17px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(136, 136, 136, 1);
  line-height: 27px;
  margin-top: 30px;
}
.udid_box {
  height: 200px;
  background: #0098dd;
  img {
    display: inline-block;
    margin: 50px 0 0 30px;
    width: 189px;
    height: 115px;
  }
}
.udid-mid {
  margin-top: 50px;
  margin-left: 30px;
  font-size: 30px;
  font-family: FZDHTJW--GB1-0;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  margin-bottom: 10px;
}
</style>
