<template>
  <div v-show="mbShow">
    <mbcontract-top :info="form" v-if="form.wx != '' || form.infoqq != '' "></mbcontract-top>
    <div v-if="!sellFlag">
      <div class="box" v-if="qqAndWxStatus == 0">
        <el-dialog
          v-model="DowndialogVisible"
          :close-on-click-modal="false"
          v-if="isShow"
          :show-close="false"
          width="80%"
        >
          <div class="modal-body">
            <div class="modal_item">
              <span class="china">配置文件中</span>
              <!-- <span class="usa">In profile</span> -->
              <img
                ref="a"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
                style="display: none;"
              /><img
                ref="a1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
              /><img
                ref="a2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
                style="display: none;"
              />
            </div>
            <br />
            <div class="modal_item">
              <span class="china">校验中</span>
              <!-- <span class="usa">Check in</span> -->
              <img
                ref="b"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
                style="display: none;"
              /><img
                ref="b1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
                style="display: none;"
              /><img
                ref="b2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
              />
            </div>

            <br />
            <div class="modal_item">
              <span class="china">正在下载</span>
              <!-- <span class="usa">Downloading</span> -->
              <img
                ref="c"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
              /><img
                ref="c1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
                style="display: none;"
              /><img
                ref="c2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
                style="display: none;"
              />
            </div>

            <br />
            <h4 style="color: #0098dd;text-align: center;display: none;">
              <span class="china">请不要离开此页面并保持屏幕常亮</span>
              <!-- <span class="usa"
              >Please do not leave this page and keep the screen on</span
            > -->
            </h4>
          </div>
        </el-dialog>
        <el-dialog v-model="dialogVisible" :show-close="false" :close-on-click-modal="false" width="80%">
          <div style="height: 400px;position: relative">
            <div style="height: 230px;background: #3ABDF8;width: 100%">
              <div style="width: 175px;margin: auto">
                <el-carousel trigger="click">
                  <el-carousel-item
                    v-for="(item, index) in bannerList"
                    :key="index"
                  >
                    <img
                      class="banner_img"
                      :src="item.imgUrl"
                      alt=""
                      style="width: 175px;height: 230px"
                    />
                    <h3>{{ item.title1 }}</h3>
                    <h4 style="height: 45px;">{{ item.title2 }}</h4>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div
              style="position: absolute; bottom: 20px;left: 50%;transform: translate(-50%, 0);"
            >
              <el-button
                type="primary"
                round
                style="width: 250px"
                @click="nextInstall"
                >继续安装</el-button
              >
            </div>
          </div>
        </el-dialog>
        <div class="box_1">
          <div class="box_header">
            <div class="header_img">
              <img
                :src="form.iconurl"
                style="width: 100px;height: 100px;border-radius: 20px"
              />
            </div>
            <div class="header_content">
              <div class="app_title">
                <span class="iconfont icon-pingguo icstyle" v-show="form.fittype == 'IOS'"></span>
                <span class="iconfont icon-android-fill icstyle" v-show="form.fittype == 'Android'"></span>
                <span>&nbsp;{{ form.softname }}</span>
              </div>
              <el-button
                v-show="languageToggle == 1"
                v-if="downFlag == 0"
                type="primary"
                style="width: 90px;height: 32px"
                round
                size="mini"
                @click="downClick"
                >立即下载</el-button
              >
              <el-button
                v-show="languageToggle == 2"
                v-if="downFlag == 0"
                type="primary"
                style="width: 90px;height: 32px"
                round
                size="mini"
                @click="downClick"
                >Download</el-button
              >
              <div
                class="sell_class"
                v-show="languageToggle == 1"
                @click="sellClick"
                v-if="downFlag == 3"
              >
                购买应用
              </div>
              <div
                class="sell_class"
                v-show="languageToggle == 2"
                @click="sellClick"
                v-if="downFlag == 3"
              >
                Buy apps
              </div>
              <el-button
                v-show="languageToggle == 1"
                v-if="downFlag == 1"
                type="primary"
                round
                size="middle"
                >暂不支持此系统</el-button
              >
              <el-button
                v-show="languageToggle == 2"
                style="width: 100px"
                v-if="downFlag == 1"
                type="primary"
                round
                size="middle"
                >This system is not supported temporarily</el-button
              >
            </div>
            <span
              v-if="(form.fittype == 'IOS' && !superShow) || (form.fittype == 'IOS' && superShow && isHide)"
              v-show="languageToggle == 1"
              class="install_style"
              @click="installClick"
              >安装教程</span
            >
            <span
              v-if="(form.fittype == 'IOS' && !superShow) || (form.fittype == 'IOS' && superShow && isHide)"
              v-show="languageToggle == 2"
              class="install_style"
              @click="installClick"
              >teacher</span
            >
          </div>
          <div class="box_tabs">
            <el-tabs
              v-model="activeName"
              stretch
              type="card"
              @tab-click="handleClick"
            >
              <el-tab-pane label="截图" name="1" v-if="languageToggle == 1">
                <div class="image_box">
                  <img
                    :src="item.applUrl"
                    v-for="item in imageList"
                    :key="item.applId"
                  />
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="Screenshot"
                name="1"
                v-if="languageToggle == 2"
              >
                <div class="image_box">
                  <img
                    :src="item.applUrl"
                    v-for="item in imageList"
                    :key="item.applId"
                  />
                </div>
              </el-tab-pane>
              <el-tab-pane label="详情" name="2" v-if="languageToggle == 1">
                <div class="tabs_detail">
                  <div>软件大小</div>
                  <div>{{ form.fileSize }}</div>
                </div>
                <div class="tabs_detail">
                  <div>更新时间</div>
                  <div>{{ form.chDate }}</div>
                </div>
                <div class="tabs_detail">
                  <div>版本号</div>
                  <div>{{ form.publishName }}</div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Details" name="2" v-if="languageToggle == 2">
                <div class="tabs_detail">
                  <div>Software size</div>
                  <div>{{ form.fileSize }}</div>
                </div>
                <div class="tabs_detail">
                  <div>Update time</div>
                  <div>{{ form.chDate }}</div>
                </div>
                <div class="tabs_detail">
                  <div>Version</div>
                  <div>{{ form.publishName }}</div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="说明" name="3" v-if="languageToggle == 1">
                <div class="descrice tabs_detail">
                  <div>软件描述语：</div>
                  <div>{{ form.softDescribe }}</div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Explain" name="3" v-if="languageToggle == 2">
                <div class="descrice tabs_detail">
                  <div>Software descriptors :</div>
                  <div>{{ form.softDescribe }}</div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
		<div style="margin-bottom: 40px"></div>
        <footer-bottom
          :softId="form.softId"
          :softInfo="form"
          @languageChange="languageChange"
          :bannerImg="bannerImg" 
          :link="link"
          :closeAD="closeAD"
        ></footer-bottom>
        <vip-dialog :dialogFlag="dialogFlag"></vip-dialog>
      </div>
      <brower-template
        v-if="qqAndWxStatus == 1 || qqAndWxStatus == 2"
        :qqAndWxStatus="qqAndWxStatus"
      ></brower-template>
    </div>
    <sell-template
      v-if="sellFlag"
      :UDID="UDID"
      :userId="form.userId"
      :shortUrl="form.softUrlSmall"
    ></sell-template>
    <el-dialog  v-model="centerDialogVisible" title="提示" top="38vh" width="70%"  center>
      <div class="pop-title">提示</div>
      <span class="pop-context">该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <app-DownDoc v-if="isShowDoc" :language="languageToggle"></app-DownDoc>
    <vip-Doc v-if="isShowVipDoc"></vip-Doc>
    <div class="modal" v-if="isShowDoc || isShowVipDoc"></div>
  </div>
</template>

<script>
import sellTemplate from "@/views/appDown/template/sellTemplate";
import browerTemplate from "@/views/appDown/template/browerTemplate";
import appDownDoc from "@/views/appDown/template/appDownDoc";
import vipDoc from "@/views/appDown/template/vipDoc";


import {
  downConsumptionFlow,
  checkEquipmentNum,
  signatureUDID,
  checkStatus,
  verifyPersonUDIDPay,
  softConformInstallIf,
  saveVisitMess,
} from "@/api/api";
import footerBottom from "@/views/appDown/footerBottom";
import mixin from "@/mixin";
import MbcontractTop from "../../../components/common/mbcontractTop.vue";
import { isEmpty } from "@/common/js/common.js";
import MobileDetect from "@/common/js/mobile-detect.js"

export default {
  mixins: [mixin],
  components: {
    footerBottom,
    browerTemplate,
    sellTemplate,
    MbcontractTop,
    appDownDoc,
    vipDoc
},
  props: {
    bannerImg: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    imageList: {
      type: Array,
    },
    form: {
      type: Object,
    },
    vip: {
      type: Boolean,
      default: false,
    },
    UDID: {
      type: String,
      default: "",
    },
    closeAD: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mbShow: false,
      isShow: true,
      sellFlag: false,
      DowndialogVisible: false,
      qqAndWxStatus: 0,
      bannerList: [
        {
          id: 1,
          imgUrl: require("../../../assets/image/vip_1.png"),
          title1: "第一步",
          title2: "点击“允许”进行下一步操作。",
        },
        {
          id: 2,
          imgUrl: require("../../../assets/image/vip_2.png"),
          title1: "第二步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 3,
          imgUrl: require("../../../assets/image/vip_3.png"),
          title1: "第三步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 4,
          imgUrl: require("../../../assets/image/vip_4.png"),
          title1: "第四步",
          title2: "点击右上角“安装”，然后进行下一步操作。",
        },
        {
          id: 5,
          imgUrl: require("../../../assets/image/vip_5.png"),
          title1: "第五步",
          title2: "点击“安装”即可。",
        },
      ],
      dialogVisible: false,
      activeName: "1",
      dialogFlag: false,
      languageToggle: 1,
      downFlag: 0,
      stopCheckState: "", //停止轮询变量
      centerDialogVisible:false,
      actCode:'',//激活码
      isShowDoc:false,
      isHide:false,
      superShow:false,
      isShowVipDoc:false,
    };
  },
  created() {
    this.showVip();
    if(isEmpty(this.form.wx)) {
      this.form.wx = '';
    }
    if(isEmpty(this.form.infoqq)){
      this.form.infoqq = '';
    }
    this.actCode = window.location.href.substring(
      window.location.href.length - 8
    );
    this.andriodAndApple();
    this.vipId = this.$route.query.vipId;
    if (this.vipId) {
      this.DowndialogVisible = true;
      this.vipDownCheck();
    }
    if(this.form.softname) {
      document.title = this.form.softname
    } else {
      document.title = ''
    }
    this.saveVisitMess();
  },
  methods: {
    showVip(){
      let device_type = navigator.userAgent;
      // console.log(device_type)
      let md = new MobileDetect(device_type);
      let os = md.os();
      // console.log(os)
      if(os == "iOS"){
        os = md.version("iPhone");
        if(os.toString().indexOf('.') > -1) {
          let index = os.toString().indexOf('.')
          os= os.toString().substring(0, index)
        }
        if(Number(os) < 16) {
          this.isHide = false;
        }else{
          this.isHide = true;
        }
      }
      // console.log(md.version)
      if(this.form.automaticallyRefresh == 1) {
        this.superShow = true;
      }
    },
    sellClick() {
      this.sellFlag = true;
    },
    saveVisitMess(){//浏览保存
      saveVisitMess({ softId: this.form.softId }).then(() => {}); //保存下载量
    },
    vipDownCheck() {
      //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
      var udid = localStorage.getItem("udidcache");
      if (udid != "undefined" && udid != null  && this.UDID != null && this.UDID !="") {
        if (this.form.openPay == 1) {
          verifyPersonUDIDPay({
            userId: this.form.userId,
            udid: this.UDID,
          }).then((res) => {
            if (res.code != 0) {
              this.$message.error("此应用已开启付费下载，请购买后安装");
              this.downFlag = 3;
              this.isShow = false;
            } else {
              this.getCheckEquipmentNum();
            }
          });
        } else {
          this.getCheckEquipmentNum();
        }
      }
    },
    //校验设备
    getCheckEquipmentNum() {
      // if (this.form.remarks4 == 1) {
      //校验设备
      checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
        (res) => {
          if (res.code == 0) {
            //签名
            signatureUDID({
              softId: this.form.softId,
              UDID: this.UDID,
              softUrl: this.form.softUrlSmall,
            }).then((res) => {
              if (res.code != 0) {
                this.dialogVisible = false;
                this.$message.error(res.msg);
              } else {
                if (res.data.data.result == "downing") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.$message.error("有用户正在下载该应用，请稍后重试");
                } else if(res.data.data.result == "fail"){
                        clearInterval(this.stopCheckState); //清除定时轮询
                        this.DowndialogVisible = false;
                        this.$message.error(res.data.data.msg);
                } else {
                  this.dialogVisible = false;
                  // this.$message.success(res.msg);
                }
              }
            });
            this.stopCheckState = setInterval(() => {
              this.checkStatus();
            }, 3000); //轮询查看vip安装进度
            localStorage.setItem("udidcache", this.UDID); //缓存浏览器
          } else {
            this.DowndialogVisible = false;
            this.$message.error(res.msg);
          }
        }
      );
      //查看状态
      // } else {
      // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
      // }
    },
    checkStatus() {
      //查看vip安装进度
      checkStatus({ softId: this.form.softId, UDID: this.UDID }).then((res) => {
        var status = res.data.result;
        if (status == "1") {
          this.$refs.a2.style.display = "none";
          this.$refs.a1.style.display = "block";
          this.$refs.b.style.display = "none";
          this.$refs.b2.style.display = "block";
        }
        if (status == "2") {
          this.$refs.b2.style.display = "none";
          this.$refs.b1.style.display = "block";
          this.$refs.c2.style.display = "block";
          this.$refs.c.style.display = "none";
        }
        if (status == "3") {
          this.$refs.c2.style.display = "none";
          //获取存放在隐藏域的itemservice链接
          var itemservice = res.data.itemservice;
          if (isEmpty(itemservice)) {
            return;
          }
          clearInterval(this.stopCheckState); //清除定时轮询
          this.DowndialogVisible = false;
          window.location.href = itemservice;
        }
      });
    },
    // 判断是否是ios17
    isIOSNew() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("like mac os x") > 0) {
        var regStr_saf = /os [\d._]*/gi;
        var verinfo = ua.match(regStr_saf);
        var version = (verinfo + "")
          .replace(/[^0-9|_.]/gi, "")
          .replace(/_/gi, ".");
        var arr = version.split(".");
        if (arr[0] >= 17) return true;
      }
    },
    // 继续安装
    nextInstall() {
      window.location.href = this.form.mobileconfigUrl;
      if (!this.isIOSNew()) {
        setTimeout(function () {
          window.location.href =
            "https://p.udid.store/embedded.mobileprovision";
        }, 2 * 1000); //延
      }
    },
    // 下载
    downClick() {
      if (this.form.automaticallyRefresh == 1) {
          if(!this.getVipUserAgent()){
              this.centerDialogVisible = true;
              return;
          }
      }else{
         if((this.getBrower().iPhone || this.getBrower().iPad) && (this.getBrower().presto || this.getBrower().Quark)){
         this.centerDialogVisible = true;
          // alert("该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。");
          return;
         }
      }
      //校验应用是否符合下载标准
      softConformInstallIf({ softId: this.form.softId }).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg);
          return;
        }
      });
      if (this.form.automaticallyRefresh == 1) {
        var ua = navigator.userAgent.toLowerCase();
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          if (ua.indexOf(" mac os x") > 0) {
            if (!navigator.cookieEnabled) {
              this.$message.error("你的浏览器已禁用cookie");
              return;
            }
          }
        } else {
          this.$message.error("请在Safari浏览器中打开本页");
          return;
        }
        //判断浏览器udid
        //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
        var udid = localStorage.getItem("udidcache");
        if (udid != "undefined" && udid != null  && this.UDID != null && this.UDID !="") {
          // if (this.form.remarks4 == 1) {
          //校验设备
          checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
            (res) => {
              if (res.code == 0) {
                 this.DowndialogVisible = true;
                //签名
                signatureUDID({
                  softId: this.form.softId,
                  UDID: this.UDID,
                  softUrl: this.form.softUrlSmall,
                }).then((res) => {
                  if (res.code != 0) {
                    this.dialogVisible = false;
                    this.$message.error(res.msg);
                  } else {
                    if (res.data.data.result == "downing") {
                      clearInterval(this.stopCheckState); //清除定时轮询
                      this.$message.error("有用户正在下载该应用，请稍后重试");
                    } else if(res.data.data.result == "fail"){
                        clearInterval(this.stopCheckState); //清除定时轮询
                        this.DowndialogVisible = false;
                        this.$message.error(res.data.data.msg);
                    } else {
                      this.dialogVisible = false;
                      // this.$message.success(res.msg);
                    }
                  }
                });
                this.stopCheckState = setInterval(() => {
                  this.checkStatus();
                }, 3000); //轮询查看vip安装进度
                localStorage.setItem("udidcache", this.UDID); //缓存浏览器
              } else {
                this.DowndialogVisible = false;
                this.$message.error(res.msg);
              }
            }
          );
          //查看状态
          // } else {
          // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
          // }
        } else {
          this.dialogVisible = true;
        }
      } else {
        let param = {
          softId: this.form.softId,
          userId: this.form.userId,
          adkey: this.$route.query.adkey ? this.$route.query.adkey : "",
        }
        if(this.form.actcode == 1){
          param.actCode = this.actCode
        }
        downConsumptionFlow(param).then((res) => {
          if (res.code == 0) {
            // window.location.href = this.publishList[0].downUrl;
            window.location.href = res.data.downUrl;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 判断系统所属安卓还是苹果
    andriodAndApple() {
      this.mbShow = true;
      if (this.form.fittype == "IOS") {
        if (
          !(
            this.getBrower().ios ||
            this.getBrower().iPhone ||
            this.getBrower().iPad
          )
        ) {
          //非苹果端
          this.downFlag = 1;
          if (this.form.fittype == "IOS" && this.form.thirdurl) {
            return window.location.href = 'http://' + this.form.thirdurl;
          } else {
            this.mbShow = true;
          }
        }
      } else {
        //安卓
        if (
          this.getBrower().ios ||
          this.getBrower().iPhone ||
          this.getBrower().Pad
        ) {
          this.downFlag = 1;
          if (this.form.fittype == "Android" && this.form.thirdurl) {
            return window.location.href = 'http://' + this.form.thirdurl;
          } else {
            this.mbShow = true;
          }
        }
      }
    },
    // 切换中英文
    languageChange(val) {
      this.languageToggle = val;
    },
    // 安装教程
    installClick() {
      if(!this.superShow) {
        this.isShowDoc = true;
      }else{
        this.isShowVipDoc = true;
      }
      // this.$router.push("/trustTeach");
      // this.$router.push("/blog/untrustedEnterprise"); //跳转到未受信任的安装教程
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  mounted(){
    if(this.form.chEn == 0 ){
      if ((navigator.language || navigator.browserLanguage).toLowerCase().includes('zh-cn')) {
        this.languageToggle = 1;
      } else {
        this.languageToggle = 2;
      }
    }else{
      this.languageToggle = this.form.chEn;
    }
    // console.log(this.languageToggle)
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height:326px;
}
::v-deep .el-dialog {
  border-radius: 10px!important;
  .pop-title{
    font-size: 3vh;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .pop-context{
    text-align: center;
    display: block;
    height: auto;
  }
}
.modal-body {
  padding: 30px;
  .modal_item {
    display: flex;
    justify-content: space-between;
    .img_dialog {
      width: 20px;
      height: 20px;
    }
  }
}
.install_style {
  position: absolute;
  right: 0;
  bottom: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;

  color: #666666;
}
.app_title {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 37px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
::v-deep .el-dialog__header {
  display: none;
}
.image_box {
  white-space: nowrap; /*文本不会换行，文本会在在同一行上继续*/
  overflow-y: auto; /*可滑动*/
  img {
    width: 220px;
    height: 340px;
    border-radius:10px;
    margin-right:20px;
  }
}
.descrice {
  display: block !important;
  height: 20rem !important;
  border: 0px !important;
}
.tabs_detail {
  height: 3rem;
  line-height: 3rem;
  color: #888;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.box_1 {
  padding: 30px 20px;
  height: 70vh;
}
.box {
  background: #fff;
}
.box_header {
  display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  .header_img {
    margin-right: 20px;
  }
}
.header_content {
  // margin: 0 2rem 0 1rem;
}

h3 {
  margin-top: 12px;
  font-size: 15px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
  text-align: center;
}
h4 {
  font-size: 12px;
  font-family: PingFang-SC-Regular;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 18px;
  text-align: left;
}
.sell_class {
  width: auto;
  padding: 0 0.5rem;
  height: 2rem;
  text-align: center;
  background: #ba812f;
  border-radius: 1.5rem;
  line-height: 2rem;
  color: #fff;
  cursor: pointer;
  padding: 0.2rem 0 0.2rem 0;
}
.icstyle {
  font-size: 30px;
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
}
</style>
