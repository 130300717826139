<template>
  <div>
    <div class="box">
      <div class="title1">服务协议</div>
      <div class="title2">
        <div>
          悟空在线（除非特别说明，本服务协议所提及的悟空在线同时包括悟空在线网站）系由河南悟空在线软件科技有限公司（以下简称“本平台”或“我们”）搭建并运营的为使用主体（以下简称“用户”或“您”）提供测试应用极速发布等一系列极速开发测试功能的服务平台，我们将通过本平台为您提供内测托管、应用合并、命令行工具、权限控制、开放
          API、Web Hooks
          等服务（以下简称“悟空在线服务”或“服务”），服务内容将完全按照悟空在线不时发布或修订的用户协议、隐私政策等规则（以下统称“悟空在线服务规则”）严格执行。
        </div>
        <div class="font_indent">
          本协议是用户与本公司就本平台服务所达成的协议，本公司对服务发布的单项条款、活动规则及公告、声明等，均为本协议不可分割的组成部分。如您拒绝接受本协议条款，将无法访问或使用悟空在线。如您点击接受本协议或访问、使用本平台的全部或部分功能（无论该等访问是通过本平台还是通过其他第三方媒介包括但不限于微信、QQ、GitHub
          等渠道，下同），即表示您同意并接受本协议的所有条件和条款。如您点击接受本协议或访问、使用
          本平台的全部或部分功能时是代表某一法人、非法人组织等实体，请确保该实体同意接受本协议的所有条件和条款且您已取得该实体的有效授权。
        </div>

        <div class="font_indent">
          我们将不时对本协议进行修订或重新发布，本协议之修订稿或更新版本一经公布即有效并替代原有协议。用户可随时查阅最新协议，如您不同意相关变更，应立即停止使用本平台的各项服务。
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  margin: 15px;
  padding: 20px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  .title1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    margin-bottom: 10px;
    color: #333333;
  }
  .title2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #666666;
  }
  .font_indent {
      text-indent:2em;
  }
}
</style>
