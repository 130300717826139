<template>
  <div>
    <div class="box">
      <div class="title1">免责声明</div>
      <div class="title2">
        <div>
          一 .
          悟空在线（除非特别说明，本服务协议所提及的悟空在线同时包括悟空在线网站）系由河南悟空在线软件科技有限公司（以下简称“本平台”或“我们”）搭建并运营的为使用主体（以下简称“用户”或“您”）提供测试应用极速发布等一系列极速开发测试功能的服务平台，我们将通过本平台为您提供内测托管、应用合并、命令行工具、权限控制、开放
          API、Web Hooks 等服务（以下简称“悟空在线服务”或“服务”）
        </div>
        <br />
        <div>
          二 .
          本平台不为您使用本平台服务发布的任何应用的准确性、安全性、兼容性、功能或性能作任何声明或保证。
        </div>
        <br />
        <div>
          三 .
          您使用本平台提供的服务，即表明您同意遵循所有适用法律。我们不会改变您提供的应用，亦不会从您发布的应用中直接获取经济利益，如您提供的应用不符合《悟空在线应用审核规则》的规定或者第三方平台的规定，您的应用将被下架。
        </div>
        <br />
        <div>
          四 .
          本平台对悟空在线服务不做任何明确或隐含的声明或担保，包括但不限于可销性和商业效用担保，以及适合某一特殊目的和不侵害第三方的权益的担保。您在本平台上发布应用所涉及的法律及政策风险将由您自行承担，本平台不承担任何责任。
        </div>
        <br />
        <div>
          五 .
          您在平台上发布的应用所服务的用户通过您发布的应用进行投资所产生的风险将由您或通过您发布的应用进行投资的用户自行承担，本平台不承担任何责任；如果您在平台上发布的应用所服务最终给本平台造成了损失，亦承担向本平台的赔偿责任。
        </div>
        <br />
        <div>
          六 .
          互联网传输可能会受到干扰，中断、延迟或数据错误，本平台对于非本平台能控制的通讯设施故障可能引致的数据及交易之准确性或及时性不负任何责任。
        </div>
        <br />
        <div>
          七 .
          您上传本平台的应用为您独立研发完成，不存在任何侵犯第三方合法权益的情形，您拥有该应用的完整权利，包括但不限于版权、再次授权等。保证该应用的内容符合国家法律法规。您上传的应用因内容或版权等问题侵犯了任何第三方合法权益的情形，您愿意承担所有法律责任，并赔偿本平台因此遭受的一切损失。
        </div>
        <br />
        <div>
          八 .
          您上传本平台的应用中与该应用用户之间单独的协议、相关业务规则等均为您与上述用户之间的单独约定，上述协议、规则等约定与本平台无关，本平台不承担由上述约定引起的任何责任。
        </div>
        <br />
        <div>
          九 .
          本平台不对使用您上传本平台的应用相关服务的用户相关数据的删除或存储失败负责。
        </div>
        <br />
        <div>
          十 . 本平台没有义务为使用您上传本平台的应用的用户提供客户支持服务。
        </div>
        <br />
        <div>
          十一 . 本平台不保证您上传本平台的应用显示在本平台页面的某个特定位置。
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  margin: 15px;
  padding: 20px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  .title1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    margin-bottom: 10px;
    color: #333333;
  }
  .title2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #666666;
  }
  .font_indent {
    text-indent: 2em;
  }
}
</style>
