<template>
  <div>
    <div class="contract_right" v-if="!flag">
      <div class="header">
        <div class="title">订单列表</div>
      </div>
      <div class="tishi">
        <div>提示:</div>
        <div class="tishi_content">
          <div>1.订单额度累计超过 200 元可以开具发票。</div>
          <div>
            2.请仔细核对并确认您的发票信息正确无误，发票开具完成后，如因信息错误不可重开。
          </div>
          <div>
            3.如需开具增值税专用发票，请确认每条订单的购买方式均为对公转账。
          </div>
        </div>
      </div>
      <el-table
        :data="tableData"
        max-height="300"
        border
        v-loading="loading"
        @selection-change="setSelectRows"
        :header-cell-style="{ background: '#E5F1FA' }"
      >
        <el-table-column
          prop="orderId"
          label="订单号"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="购买时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="购买金额"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="shopType" label="类别" align="center">
          <template #default="scope">
            <div v-if="scope.row.shopType == 0">下载次数</div>
            <div v-if="scope.row.shopType == 1">vip设备</div>
            <div v-if="scope.row.shopType == 2">私有池设备</div>
            <div v-if="scope.row.shopType == 3">按时间购买</div>
            <div v-if="scope.row.shopType == 4">webclip时长</div>
            <div v-if="scope.row.shopType == 5">按时间购买</div>
            <div v-if="scope.row.shopType == 6">按时间购买</div>
            <div v-if="scope.row.shopType == 7">按时间购买</div>
          </template>
        </el-table-column>
        <el-table-column prop="shopNum" label="套餐" align="center">
          <template #default="scope">
             <!-- {{ scope.row.shopNum ? scope.row.shopNum : 0}}(台)  -->
             <div v-if="scope.row.shopType == 0">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(点)</div>
             <div v-if="scope.row.shopType == 1">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(台)</div>
             <div v-if="scope.row.shopType == 2">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(台)</div>
            <div v-if="scope.row.shopType == 3">年包</div>
            <div v-if="scope.row.shopType == 4">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(天)</div>
            <div v-if="scope.row.shopType == 5">半年包</div>
            <div v-if="scope.row.shopType == 6">季包</div>
            <div v-if="scope.row.shopType == 7">月包</div>
             </template>
        </el-table-column>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
      </el-table>
      <div class="footer">
        <div class="font">您已选择{{totalObj.num}}条订单，累计金额{{totalObj.price}}元</div>
        <div class="btn">
          <el-button @click="reset">取消</el-button>
          <el-button type="primary" @click="nextStep">下一步</el-button>
        </div>
      </div>
    </div>
    <invoice-application v-if="flag" :orderId="totalObj.orderId" :price="totalObj.price" @reset="reset"></invoice-application>
  </div>
</template>
<script>
import { getOrderList } from "@/api/api";
import invoiceApplication from "@/components/personalData/controlOverInvoicesC/invoiceApplication";
export default {
  components: {
    invoiceApplication,
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      flag: false,
      loading: false,
      selectList: [],
      orderArray: [],
      totalObj: {
        num: 0,
        price: 0,
        orderId: ''
      }
    };
  },
  created() {
    this.orderList();
  },

  methods: {
    setSelectRows(val) {
      this.multipleSelection = JSON.parse(JSON.stringify(val))
      this.selectList = []
      this.orderArray = []
      this.multipleSelection.forEach(v => {
        this.selectList.push({
          orderId: v.orderId,
          createDate: v.createDate,
          money: v.money,
          shopType: v.shopType,
          shopNum: v.shopNum
        })
      })
      let arr = JSON.parse(JSON.stringify(this.selectList))
      this.orderArray = arr
      if(arr.length > 0) {
        this.totalObj.num = 0
        this.totalObj.price = 0
        this.totalObj.num = arr.length
        let orderIdList = []
        arr.forEach(item => {
          this.totalObj.price += parseInt(item.money)
          orderIdList.push(item.orderId)
        })
        this.totalObj.orderId = orderIdList.join(',')
      } else {
        this.totalObj.num = 0
        this.totalObj.price = 0
      }
    },
    orderList() {
      this.loading = true
      getOrderList({ pageNum: 1,invoiceStatus:1 }).then((res) => {
        this.loading = false
        this.tableData = res.data.pageInfo.list;
        this.tableData.forEach(item => {
          item.money = item.money / 100
        })
      });
    },
    reset() {
      this.$emit('showAndNo',false)
    },
    nextStep() {
      if(this.orderArray.length > 0) {
        if(this.totalObj.price > 199) {
          this.flag = true;
        } else {
          this.$message.error('满200才可开具发票')
        }
        
      } else {
        this.$message.error('请至少选择一条数据')
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 20px;
  width: 840px;
  display: flex;
  justify-content: space-between;
  .font {
    line-height: 40px;
  }
}
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  position: relative;
  width: 920px;
  height: 689px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      font-size: 22px;
      font-weight: 700;
    }
  }
  .tishi {
    display: flex;
    height: 93px;
    box-sizing: border-box;
    padding: 15px;
    background: #fff7dc;
    border: 1px solid #ffe17a;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    .tishi_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #9f8c6d;
      margin-left: 10px;
    }
  }
}
</style>
