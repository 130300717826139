<template>
  <div>
    <div class="mobile" v-if="this.is_mobiState">
		<mobile-forget></mobile-forget>
	</div>
	<div class="pc" v-if="!this.is_mobiState">
		<div class="content">
		  <div class="content_box">
		    <div class="content_logo">
		      <img src="../../assets/image/logo.png" alt="" />
		    </div>
		    <div class="content_login">
		      <div class="content_tab">
		        <el-tabs v-model="activeName" stretch @tab-click="handleClick">
		          <el-tab-pane label="邮箱找回" name="first">
		            <forget-email v-if="activeName === 'first'"></forget-email>
		          </el-tab-pane>
		          <el-tab-pane label="手机找回" name="second">
		            <forget-tel v-if="activeName === 'second'"></forget-tel>
		          </el-tab-pane>
		        </el-tabs>
		        <div style="margin-top: -20px;">
		          <span>已有账号, </span>
		          <el-button type="text" @click="goLogin">马上登录</el-button>
		        </div>
		      </div>
		    </div>
		    <div class="content_goback">
		      <div class="goback">
		        <img src="../../assets/image/home.png" alt="" />
		        <span @click="goback">回到首页</span>
		      </div>
		    </div>
		  </div>
		  <footer-bottom></footer-bottom>
		</div>
	</div>
  </div>
</template>

<script>
import mobileForget from '@/views/mobilePage/loginCommon/mobileForget.vue'
import footerBottom from "@/components/common/footer";
import forgetEmail from "@/components/forgetPassword/forgetEmail";
import forgetTel from "@/components/forgetPassword/forgetTel";
export default {
  name: "forgetPassword",
  components: {
    footerBottom,
    forgetEmail,
    forgetTel,
	mobileForget
  },
  data() {
    return {
      activeName: "first",
    };
  },
  computed: {
  	 is_mobiState() {
  		 return this.$store.state.is_mobi
  	 } 
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    goback() {
      this.$router.push('/home')
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  position: relative;
}
.content_box {
  position: absolute;
  width: 422px;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content_logo {
    width: 100%;
    height: 56.5px;
    display: flex;
    align-item: center;
    margin-bottom: 50px;
    img {
      width: 200px;
      height: 56.5px;
      margin: auto;
    }
  }
  .content_login {
    width: 100%;
    height: 354px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    .content_tab {
      width: 362px;
      overflow: hidden;
      margin: auto;
      .ce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .el-tabs .el-form-item .el-button {
        width: 100%;
      }
    }
  }
  .content_goback {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background: #fff;
    color: #999999;
    margin: auto;
    .goback {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
