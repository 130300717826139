<template>
  <div>
    <div v-if="!closeAD"><!-- v-if="!closeAD" -->
      <div class="banner" v-if="bannerImg" v-show="bannerFlag">
        <img v-if="isUC" src="@/assets/image/phone_advertising.png" style="width: 100%;height: 90px"
          @click="bannerClick">
        <img v-else :src="bannerImg" style="width: 100%;height: 90px" @click="bannerClick">
        <img class="btn" @click="closeBtn" src="@/assets/image/close-btn.png" style="width: 20px;height: 20px">
      </div>
      <div class="banner" v-show="!bannerImg && showbannerImg2">
        <img v-if="isUC" src="@/assets/image/phone_advertising.png" style="width: 100%;height: 90px"
          @click="bannerClick">
        <img v-else :src="bannerImg2" style="width: 100%;height: 90px" @click="bannerClick2">
        <img class="btn" @click="closeBtn" src="@/assets/image/close-btn.png" style="width: 20px;height: 20px">
      </div>
    </div>
    <div class="footer">
      <div class="cnAm_toggle">
        <img src="@/assets/image/cnAm.png" style="width: 12px;height: 12px;margin-right: 4px" />
        <span @click="languageClick" style="cursor: pointer;">{{ countryTitle }}</span>
      </div>
      <div class="cnAm_middle">
        <span style="margin-left: 5px;cursor: pointer" v-show="languageToggle == 1" @click="docClick(1)">服务协议</span>
        <span style="margin-left: 5px;cursor: pointer" v-show="languageToggle == 2"
          @click="docClick(1)">Agreement</span>
        <span style="color:#DDDDDD">|</span>
        <span v-show="languageToggle == 1" style="cursor: pointer" @click="docClick(2)">隐私申明</span>
        <span v-show="languageToggle == 2" style="cursor: pointer" @click="docClick(2)">Privacy</span>
        <span style="color:#DDDDDD">|</span>
        <span v-show="languageToggle == 1" style="cursor: pointer" @click="docClick(3)">免责申明</span>
        <span v-show="languageToggle == 2" style="cursor: pointer" @click="docClick(3)">Disclaimers</span>
        <span style="color:#DDDDDD">|</span>
        <span v-show="languageToggle == 1" style="margin-right: 5px;cursor: pointer" @click="docClick(4)">违规举报</span>
        <span v-show="languageToggle == 2" style="margin-right: 5px;cursor: pointer"
          @click="docClick(4)">Complaints</span>
      </div>
      <div class="cnAm_footer" v-show="languageToggle == 1">
        提示：该应用来源于第三方产品，内容不受悟空在线控制，请您仔细甄别，审慎安装。悟空在线仅为信息服务平台，与第三方应用之间不存在任何关联关系，不提供任何担保或承诺。悟空在线不为因此产生的任何投资、购买行为承担任何责任。
        <div v-show="softInfo.icp" style="text-align: center">APP备案:{{ softInfo.icp }}</div>
      </div>

      <div class="cnAm_footer" v-show="languageToggle == 2">
        Tip: this application comes from a third-party product, and its content is not controlled by Wukong online.
        Please carefully identify and carefully install it. Wukong online is only an information service platform,
        and has no relationship with third-party applications, and does not provide any guarantee or commitment.
        Wukong online will not bear any responsibility for any investment and purchase behavior arising therefrom!
      </div>
    </div>
  </div>
</template>

<script>
import { getMMPPhoneAdvertising } from '@/api/api'
import { isEmpty } from "@/common/js/common.js";
import mixin from "@/mixin"; // 引入mixin文件
export default {
  mixins: [mixin], // 判断是否安卓公共方法
  props: {
    softId: {
      type: String,
      default: "",
    },
    softInfo: {
      type: Object,
      default: {}
    },
    bannerImg: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    closeAD: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bannerFlag: true,
      languageToggle: 1,
      countryTitle: "中文",
      bannerImg2: '',
      link2: '',
      isUC: false,
      showbannerImg2: true,
    };
  },
  created() {
    if (this.getBrower().UC || this.getBrower().Quark) {
      this.isUC = true;
    }
    if (isEmpty(this.bannerImg)) {
      console.log(this.bannerImg)
      this.getPhoneAdvertisingTl();
    }
    console.log(navigator.language)
  },
  methods: {

    // 获取广告栏数据
    getPhoneAdvertisingTl() {
      getMMPPhoneAdvertising().then(res => {
        if (res.code == 0) {
          console.log(res)
          if (!isEmpty(res.data.advertising)) {
            this.bannerImg2 = res.data.advertising.imgUrl
            this.link2 = res.data.advertising.link
          } else {
            this.closeAD = true;
          }
        } else {
          this.showbannerImg2 = false;
        }
      })
    },
    bannerClick() {
      window.location.href = this.link
    },
    bannerClick2() {
      window.location.href = this.link2
    },
    // 关闭
    closeBtn() {
      this.bannerFlag = false
      this.showbannerImg2 = false
    },
    docClick(val) {
      if (val == 1) {
        this.$router.push("/mobileDocAgreement");
      }
      if (val == 2) {
        this.$router.push("/mobileDocprivacy");
      }
      if (val == 3) {
        this.$router.push("/mobileDocdisclaimers");
      }
      if (val == 4) {
        this.$router.push({
          path: "/report",
          query: {
            softId: this.softId,
          },
        });
      }
    },
    languageClick() {
      if (this.countryTitle == "中文") {
        this.countryTitle = "English";
        this.languageToggle = 2;
      } else {
        this.countryTitle = "中文";
        this.languageToggle = 1;
      }
      this.$emit("languageChange", this.languageToggle);
    },
    // 跳转到免责声明
    exemptionClick() {
      this.$router.push("/disclaimer");
    },
    // 举报
    reportClick() {
      this.$router.push({
        path: "/report",
        query: {
          softId: this.softId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  width: 100%;
  height: 90px;

  .btn {
    position: absolute;
    display: block;
    z-index: 999;
    top: 35px;
    right: 10px;
    cursor: pointer;
  }
}

.footer {
  padding: 15px 20px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background: #f1f6f9;

  .cnAm_toggle {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 25px;
    background: #cce9ff;
    border-radius: 4px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    color: #0092ff;
  }

  .cnAm_middle {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #0092ff;
  }

  .cnAm_footer {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: center;

    color: #666666;
  }
}
</style>
