import {
	createRouter,
	createWebHistory
} from "vue-router";
import store from "@/store";
//导入
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Login from "@/views/Login/login";
import Index from "@/views/Index/index";
import Register from "@/views/register/register";
import Home from "@/views/Home/home";
import Contract from "@/views/contract/contract";
import Password from "@/views/password/password";
import ForgetPassword from "@/views/forgetPassword/forgetPassword";
import aggregateCode from "@/views/aggregateCode/aggregateCode";
import appManagement from "@/views/appManagement/appManagement";
import durationUdidList from "@/views/appManagement/durationUdidList";
import useUdidList from "@/views/appManagement/useUdidList";
import editApp from "@/views/appManagement/editApp";
import installAssistant from "@/views/installAssistant/installAssistant";
import price from "@/views/price/price";
import webClip from "@/views/webClip/webClip";
import submitWebClip from "@/views/webClip/submitWebClip";
import updateWebClip from "@/views/webClip/updateWebClip";
import webClipPCPreview from "@/views/webClip/webClipPCPreview";
import applySoftness from "@/views/applySoftness/applySoftness";
import icpServices from "@/views/icpServices/icpServices";


import installDoc from "@/views/installDoc/installDoc";
import personalData from "@/views/personalData/personalData";
import messageCore from "@/views/personalData/messageCore";
import aliPayAuthen from "@/views/personalData/realNameAuthentication/aliPayAuthen";
import weXinAuthen from "@/views/personalData/realNameAuthentication/weXinAuthen";
import enterpriseCertification from "@/views/personalData/realNameAuthentication/enterpriseCertification";
import seeApp from "@/views/appManagement/seeApp";
import channelCodeDetail from "@/views/appManagement/channelCodeDetail"; 
import activationCodeDetail from "@/views/appManagement/activationCodeDetail"; 
import payDownDetails from "@/views/appManagement/payDownDetails";
import inviteCodeDetail from "@/views/appManagement/inviteCodeDetail";
import appDown from "@/views/appDown/appDown";
import report from "@/views/appDown/report";
import trustTeach from "@/views/appDown/trustTeach";
import disclaimer from "@/views/appDown/disclaimer";
import templateMerge from "@/views/appDown/templateMerge";
import vip from "@/views/vip/vip";
import vipInfo from "@/views/vip/vipInfo";
import webClipPreview from "@/views/webClip/webClipPCPreview";
import web from "@/views/web/webClip";
import webTemplate from "@/views/web/webTemplate";
import lock from "@/views/lock/index";
import lockUser from "@/views/lock/lock";
import appeal from "@/views/lock/appeal";
import examine from "@/views/lock/examine";
import noexamine from "@/views/lock/noexamine";
import udid from "@/views/installAssistant/udid";
import kit from "@/views/installAssistant/kit";
import docIndex from "@/views/installDoc/index";
import deduct from "@/views/installDoc/deduct";
//常见问题静态页面
import list1 from "@/views/installDoc/list1";
import list2 from "@/views/installDoc/list2";
import list3 from "@/views/installDoc/list3";
import list4 from "@/views/installDoc/list4";
import list5 from "@/views/installDoc/list5";
import list6 from "@/views/installDoc/list6";
import list7 from "@/views/installDoc/list7";
//静态结束
import superQuest from "@/views/installDoc/superQuest";
import udidMobile from "@/views/installAssistant/udidMobile";
import cooperative from "@/views/cooperative/index";
import pay from "@/views/yearly/pay";
import payFailure from "@/views/notFount/payFailure";
import authSuccess from "@/views/error/authSuccess";
import authError from "@/views/error/authError";
import payPublicBurningTime from "@/views/yearly/pay/payPublicBurningTime";
import superInfo from "@/views/super/superInfo";
import mobileDocAgreement from "@/views/mobileDoc/agreement";
import mobileDocprivacy from "@/views/mobileDoc/privacy";
import mobileDocdisclaimers from "@/views/mobileDoc/disclaimers";
import invitationCode from "@/views/invitationCode/invitationCode";
import activationCode from "@/views/appManagement/activationCode";
import mobileNo from '@/views/mobilePage/mobileNo'
import buySuccess from '@/views/error/buySuccess'
// 手机购买成功页
// import mobilePriceConfirm from '@/views/mobilePage/common/mobilePriceConfirm.vue'
import mobilePrice from '@/views/mobilePage/common/mobilePrice.vue'
import errorPage from '@/views/error/errorPage'
import versionLast from '@/views/version/versionLast'
import outsourcing from '@/views/outsourcing/outsourcing'
const routes = [{
		path: "/",
		redirect: "/index",
	},
	{
		path: "/:params",
		component: appDown,
	},
	{
		path: "/login",
		name: "login",
		component: Login,
	},
	{
		path: "/errorPage",
		name: "errorPage",
		component: errorPage
	},
	{
		path: "/mobilePrice",
		name: "mobilePrice",
		component: mobilePrice
	},
	{
		path: "/wukong/outsourcing",
		name: "outsourcing",
		component: outsourcing
	},
	{
		path: "/authSuccess",
		name: "authSuccess",
		component: authSuccess
	},
	{
		path: "/authError",
		name: "authError",
		component: authError
	},
	{
		path: "/mobileDocAgreement",
		name: "mobileDocAgreement",
		component: mobileDocAgreement
	},
	{
		path: "/mobileDocprivacy",
		name: "mobileDocprivacy",
		component: mobileDocprivacy
	},
	{
		path: "/mobileDocdisclaimers",
		name: "mobileDocdisclaimers",
		component: mobileDocdisclaimers
	},
	{
		path: "/web",
		name: "web",
		component: web,
		children: [{
			path: "/web/:params",
			component: webTemplate,
		}, ],
	},
	{
		path: "/webClipPreview",
		name: "webClipPreview",
		component: webClipPreview,
	},
	{
		path: "/payFailure",
		name: "payFailure",
		component: payFailure,
	},
	{
		path: "/vip",
		name: "vip",
		component: vip,
		children: [{
			path: "/vip/:params",
			component: vipInfo,
		}, ],
	},
	{
		path: "/invitation/:params",
		name: "invitation",
		component: invitationCode,
	}, 
	{
		path: "/active/:params",
		name: "active",
		component: activationCode,
	}, 
	{
		path: "/udid",
		name: "udidMobile",
		component: udidMobile
	},
	{
		path: "/register",
		name: "register",
		component: Register
	},
	{
		path: "/password",
		name: "password",
		component: Password
	},
	{
		path: "/seeApp",
		name: "seeApp",
		component: seeApp
	},
	{
		path: "/report",
		name: "report",
		component: report
	},
	{
		path: "/trustTeach",
		name: "trustTeach",
		component: trustTeach
	},
	{
		path: "/disclaimer",
		name: "disclaimer",
		component: disclaimer
	},
	{
		path: "/templateMerge",
		name: "templateMerge",
		component: templateMerge,
	},
	{
		path: "/yearly/pay",
		name: "yearlyPay",
		component: pay
	},
	{
		path: "/buySuccess",
		name: "buySuccess",
		component: buySuccess
	},
	{
		path: "/yearly/goPayMethod",
		name: "payPublicBurningTime",
		component: payPublicBurningTime,
	},
	{
		path: "/mobileNo",
		name: "mobileNo",
		component: mobileNo,
	},
	{
		path: "/super/:params",
		name: "superInfo",
		component: superInfo
	},
	{
		path: "/forgetPassword",
		name: "forgetPassword",
		component: ForgetPassword,
	},
	{
		path: "/index",
		name: "index",
		component: Index,
		redirect: "/home",
		children: [{
				path: "/home",
				name: "home",
				component: Home,
			},
			{
				path: "/Login",
				name: "login",
				component: Login,
			},
			{
				path: "/Register",
				name: "register",
				component: Register,
			},
			{
				path: "/errorPage",
				name: "errorPage",
				component: errorPage,
			},
			{
				path: "/aliPayAuthen",
				name: "aliPayAuthen",
				component: aliPayAuthen,
			},
			{
				path: "/kit",
				name: "kit",
				component: kit
			},
			{
				path: "/version/versionLast",
				name: "versionLast",
				component: versionLast
			},
			{
				path: "/cooperative",
				name: "cooperative",
				component: cooperative
			},
			{
				path: "/weXinAuthen",
				name: "weXinAuthen",
				component: weXinAuthen,
			},
			{
				path: "/editApp",
				name: "editApp",
				component: editApp,
			},
			{
				path: "/enterpriseCertification",
				name: "enterpriseCertification",
				component: enterpriseCertification,
			},
			{
				path: "/personalData",
				name: "personalData",
				component: personalData,
			},
			{
				path: "/contract",
				name: "contract",
				component: Contract,
				// redirect: '/contract/blogGroup',
				// meta: {
				// 	requiresAuth: true
				// },
				children:[
					{
						path: "/contract/aboutUs",
						name: "aboutUs",
						component: () => import("../components/contract/contract"),
					},
					{
						path: "/contract/contractUs",
						name: "contractUs",
						component: () => import("../components/contract/contactUs"),
					},
					{
						path: "/contract/serviceGgreement",
						name: "serviceGgreement",
						component: () => import("../components/contract/serviceGgreement"),
					},
					{
						path: "/contract/privacyStatement",
						name: "privacyStatement",
						component: () => import("../components/contract/privacyStatement"),
					},	
					{
						path: "/contract/disclaimers",
						name: "disclaimers",
						component: () => import("../components/contract/disclaimers"),
					},		
					{
						path: "/contract/auditPolicy",
						name: "auditPolicy",
						component: () => import("../components/contract/auditPolicy"),
					},	
					{
						path: "/contract/blogGroup",
						name: "blogGroup",
						component: () => import("../components/contract/blogGroup"),
						children: [
						{
							path: "/blog/developerMode",
							component: () => import("../components/contract/doc1"),
						},
						{
							path: "/blog/untrustedEnterprise",
							component: () => import("../components/contract/doc2"),
						},
						{
							path: "/blog/equipmentUtilization",
							component: () => import("../components/contract/doc3"),
						},
						{
							path: "/blog/expiredEquipment",
							component: () => import("../components/contract/doc4"),
						},
						{
							path: "/blog/temporarilyInvalid",
							component: () => import("../components/contract/doc5"),
						},
						{
							path: "/blog/paidDownload",
							component: () => import("../components/contract/doc6"),
						},
						{
							path: "/blog/domainNameFail",
							component: () => import("../components/contract/doc7"),
						},
						{
							path: "/blog/privatePoolKey",
							component: () => import("../components/contract/doc8"),
						},
						{
							path: "/blog/domainBinding",
							component: () => import("../components/contract/doc9"),
						},
						{
							path: "/blog/signaturePrivate",
							component: () => import("../components/contract/doc10"),
						},
						{
							path: "/blog/superSignature",
							component: () => import("../components/contract/doc11"),
						},
						{
							path: "/blog/setInvitationCode",
							component: () => import("../components/contract/doc12"),
						},
						{
							path: "/blog/downloadSourceFile",
							component: () => import("../components/contract/doc13"),
						},
						{
							path: "/blog/applyMerge",
							component: () => import("../components/contract/doc14"),
						},
						{
							path: "/blog/appUpdate",
							component: () => import("../components/contract/doc15"),
						},
						{
							path: "/blog/internalMeasurement",
							component: () => import("../components/contract/doc16"),
						},
						{
							path: "/blog/signaturePrinciple",
							component: () => import("../components/contract/doc17"),
						},			
						{
							path: "/blog/deductionRules",
							component: () => import("../components/contract/doc18"),
						},	
						{
							path: "/blog/insideProblem",
							component: () => import("../components/contract/doc19"),
						},	
						{
							path: "/blog/autographProblem",
							component: () => import("../components/contract/doc20"),
						},	
						{
							path: "/blog/requestInvoice",
							component: () => import("../components/contract/doc21"),
						},																																																																						
					],
					},
				],
			},
			

			{
				path: "/aggregateCode",
				name: "aggregateCode",
				component: aggregateCode,
			},
			{
				path: "/durationUdidList",
				name: "durationUdidList",
				component: durationUdidList,
			},
			{
				path: "/useUdidList",
				name: "useUdidList",
				component: useUdidList,
			},
			{
				path: "/messageCore",
				name: "messageCore",
				component: messageCore,
			},
			{
				path: "/appManagement",
				name: "appManagement",
				component: appManagement,
			},
			{
				path: "/installAssistant",
				name: "installAssistant",
				component: installAssistant,
			},
			{
				path: "/submitWebClip",
				name: "submitWebClip",
				component: submitWebClip,
			},
			{
				path: "/updateWebClip",
				name: "updateWebClip",
				component: updateWebClip,
			},
			{
				path: "/webClipPCPreview",
				name: "webClipPCPreview",
				component: webClipPCPreview,
			},
			{
				path: "/price",
				name: "price",
				component: price,
			},
			{
				path: "/webClip",
				name: "webClip",
				component: webClip,
			},
			{
				path: "/applySoftness",
				name: "applySoftness",
				component: applySoftness,
			},			
			{
				path: "/icpServices",
				name: "icpServices",
				component: icpServices,
			},
			{
				path: "/installDoc",
				name: "installDoc",
				component: installDoc,
				redirect: "/installDoc/center",
				children: [{
						path: "/installDoc/center",
						component: docIndex,
					},
					{
						path: "/installDoc/deduct",
						component: deduct,
					},
					{
						path: "/installDoc/superQuest",
						component: superQuest,
					},
					{
						path: "/installDoc/list1",
						component: list1,
					},
					{
						path: "/installDoc/list2",
						component: list2,
					},
					{
						path: "/installDoc/list3",
						component: list3,
					},
					{
						path: "/installDoc/list4",
						component: list4,
					},
					{
						path: "/installDoc/list5",
						component: list5,
					},
					{
						path: "/installDoc/list6",
						component: list6,
					},
					{
						path: "/installDoc/list7",
						component: list7,
					},

				],
			},
			{
				path: "/channelCodeDetail",
				name: "channelCodeDetail",
				component: channelCodeDetail,
			}, 
			{
				path: "/activationCodeDetail",
				name: "activationCodeDetail",
				component: activationCodeDetail,
			}, 
			{
				path: "/payDownDetails",
				name: "payDownDetails",
				component: payDownDetails,
			},
			{
				path: "/inviteCodeDetail",
				name: "inviteCodeDetail",
				component: inviteCodeDetail,
			},
			{
				path: "/lock",
				name: "lock",
				component: lock,
				children: [{
						path: "/lock/lockUser",
						component: lockUser,
					},
					{
						path: "/lock/appeal",
						component: appeal,
					},
					{
						path: "/lock/examine",
						component: examine,
					},
					{
						path: "/lock/noexamine",
						component: noexamine,
					},
				],
			},
			{
				path: "/installAssistant/udid",
				name: "udid",
				component: udid,
			}
		],
	},
];
// if(window.location.href){
// 	let str = window.location.href;
// 	let index = str.lastIndexOf('/'); 
// 	let href = str.substring(index+1,str.length);
// 	if(href.length == 4 && href != 'home'){
// 		document.title = "";
// 	}
// }else{
// 	document.title = ""
// }
const router = createRouter({
	history: createWebHistory(),
	routes,
});
router.beforeEach((to, from, next) => {
	// console.log(to)
	console.log(to.matched)  
	console.log(to.matched.length)  
	// if(to.meta.title){
	// 	document.title = to.meta.title
	// }else{
	// 	document.title = "悟空在线内测分发服务平台-苹果IOS超级企业签名,免费APP应用分发,内测分发"
	// }
	if(to.matched[0].path == "/:params") {
		document.title = ''
	}
	if (to.matched.length === 0) {
		next('/errorPage?tips=短链接不存在或已下架')
	}
	// if (to.path.includes("/applySoftness")) {
	// 	store.commit("navActiveChange", ""); 
	// }
	if (to.path.includes("/contract")) {
		store.commit("navActiveChange", ""); 
	}
	if (to.path.includes("/contract/")) {
		store.commit("showLeftChange", false);
	}
	if (to.path.includes("/blog/")) {
		store.commit("showLeftChange", false);
	}
	if (to.path.includes("/home") || to.path == "/") {
		store.commit("navActiveChange", 1);
	}
	if (to.path.includes("/appManagement")) {
		store.commit("navActiveChange", 2);
	}
	if (to.path.includes("/webClip")) {
		store.commit("navActiveChange", 3);
	}
	// if (to.path.includes('/aggregateCode')) {
	//   store.commit('navActiveChange',4)
	// }
	if (to.path.includes("/installAssistant")) {
		store.commit("navActiveChange", 5);
	}
	if (to.path.includes("/price")) {
		store.commit("navActiveChange", 6);
	}
	if (to.path.includes("/login")) {
		store.commit("navActiveChange", 8);
	}
	if (to.path.includes("register")) {
		store.commit("navActiveChange", 9);
	}
	// if (to.path.includes("/installDoc")) {
	//   window.open("https://support.qq.com/products/349734/blog-archive");
	//   store.commit("navActiveChange", 7);
	// }
	NProgress.start();
	next();
});

router.afterEach((to,from) => {
	NProgress.done();
	console.log(to)
	console.log(to.matched)  
	// if(to.matched[0].path == "/templateMerge" || to.matched[0].path == "/:params" || to.path.includes('/vip')){
	// 	document.title = ''
	// }else{
	// 	document.title = '悟空在线内测分发服务平台-苹果IOS超级企业签名,免费APP应用分发,内测分发';
	// }
});
export default router;
