import qs from 'qs';
import {
	request
} from "@/utils/request";

// 展示公告信息
export function versionLast() {
	return request({
		url: '/version/versionLast'
	})
}
// 是否封禁锁定等状态
export function getAuthenticationState() {
	return request({
		url: "/common/getAuthenticationState",
		method: "get",
	});
}
// 新增渠道码
export function reipa(data) {
	return request({
		url: "/product/reipa",
		method: "POST",
		data: qs.stringify(data)
	});
}
//获取小程序接口详情
// export function loginJApplet(data) {
// 	return request({
// 		url: "/common/loginJApplet",
// 		method: "get",
// 	});
// }

//开发者编辑接口
export function editAccount(data) {
	return request({
		url: "/account/editAccount",
		method: "get",
		params: data
	});
}
//账号功能键操作接口
export function accountOperation(data) {
	return request({
		url: "/account/accountOperation",
		method: "POST",
		data: qs.stringify(data)
	});
}
//获取应用截图
export function getScreenshotsImage(data) {
	return request({
		url: "/product/getScreenshotsImage",
		method: "get",
		params: data
	});
}

//删除应用截图
export function deleteScreenshotsImage(data) {
	return request({
		url: "/product/deleteScreenshotsImage",
		method: "get",
		params: data
	});
}

//保存下载量 
export function saveVisitMess(data) {
	return request({
		url: "/product/saveVisitMess",
		method: "get",
		params: data
	});
}
//通过userid查询非实名认证下载次数是否足够
// export function checkAuthEnough(data) {
// 	return request({
// 		url: "/product/checkAuthEnough",
// 		method: "get",
// 		params: data
// 	});
// }
// 获取MMP手机端广告栏
export function getMMPPhoneAdvertising() {
	return request({
		url: "/advertising/getMMPPhoneAdvertising",
		method: "get"
	});
}

// 获取手机端广告栏
export function getPhoneAdvertising(data) {
	return request({
		url: "/advertising/getPhoneAdvertising",
		method: "get",
		params: data
	});
}
//助手扫码获取superInfo信息
export function getSuperInfo(shortUrl) {
	return request({
		url: "/Super/" + shortUrl,
		method: "get",
	});
}

//获取共有时长支付信息
export function goPayMethod(data) {
	return request({
		url: "/yearly/goPayMethod",
		method: "POST",
		data: qs.stringify(data)
	});
}

//获取公有池时长支付Mobile
export function getPayYearMobile(data) {
	return request({
		url: "/yearly/getYearMobile",
		method: "POST",
		data: qs.stringify(data)
	});
}

// 生成公有池购买时长二维码接口
export function createUserMobile(data) {
	return request({
		url: '/product/createUserMobile',
		methods: 'get',
		params: data
	})
}
// 获取UDID
export function udidReceive() {
	return request({
		url: '/pay/udidReceive',
		methods: 'get'
	})
}
//解析CNAME
export function getResolveCNAME(data) {
	return request({
		url: "/user/getResolveCNAME",
		method: "POST",
		data: qs.stringify(data)
	});
}
//添加又拍云二级域名
export function addYouPaiYunSLD(data) {
	return request({
		url: "/user/addYouPaiYunSLD",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 校验二级域名
export function domainVerify(data) {
	return request({
		url: "/user/domainVerify",
		method: "POST",
		data: qs.stringify(data)
	});
}

// 查询支付宝支付状态
export function getAliPayResult(data) {
	return request({
		url: "/ali/getAliPayResult",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 绑定或解绑二级域名接口
export function bindOrUnbindDomain(data) {
	return request({
		url: "/user/bindOrUnbindDomain",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 查看账号是否绑定二级域名接口
export function checkSLDBind(data) {
	return request({
		url: "/user/checkSLDBind",
		method: "get",
		params: data
	});
}
// 申诉提交
export function userAppeal(data) {
	return request({
		url: "/user/userAppeal",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 普通应用短链接
export function getAppInfo(data, url) {
	return request({
		url: "/" + url,
		method: "get",
		params: data
	});
}
//webclip扣减下载次数
export function webClipTakeOut(data) {
	return request({
		url: "/product/clipNumber",
		method: "POST",
		data: qs.stringify(data)
	});
}
// webClip解除合并
export function webShorUrl(data, url) {
	return request({
		url: "/web/" + url,
		method: "get",
		params: data
	});
}
// webClip解除合并
export function webCliprelieveMerge(data) {
	return request({
		url: "/webclip/relieveMerge",
		method: "get",
		params: data
	});
}
//购买web clip时长
export function updateWebClipPayTime(data) {
	return request({
		url: "/webclip/updateWebClipPayTime",
		method: "POST",
		params: data
	});
}
// webClip应用合并
export function webClipMergeNo(data) {
	return request({
		url: "/webclip/merge",
		method: "get",
		params: data
	});
}
// 应用合并web clip搜索接口
export function searchWebClipShortUrl(data) {
	return request({
		url: "/webclip/searchShortUrl",
		method: "get",
		params: data
	});
}
// 应用管理—搜索短链接接口
export function webclipMerge(data) {
	return request({
		url: "/webclip/webclipMerge",
		method: "get",
		params: data
	});
}
//校验应用是否符合下载标准
export function softConformInstallIf(data) {
	return request({
		url: "software/softConformInstallIf",
		method: "POST",
		data: qs.stringify(data)
	});
}
//校验是否购买过应用
export function verifyPersonUDIDPay(data) {
	return request({
		url: "software/verifyPersonUDIDPay",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 邀请码下载应用接口
export function getInvitationCode(inviteCode) {
	return request({
		url: "/invitation/" + inviteCode,
		method: "get",
	});
}
// vip下载验证
export function checkStatus(data) {
	return request({
		url: "/udid/checkStatus?softId=" + data.softId + "&UDID=" + data.UDID,
		method: "get",
	});
}
// vip下载应用接口
export function vipDownInfo(data, shorUrl) {
	return request({
		url: "/vip/" + shorUrl,
		method: "get",
		params: data
	});
}
// 获取vip信息
export function vipMerge(data) {
	return request({
		url: "templet/Merge",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 获取确认密码后信息
export function getDownSoftMessage(data) {
	return request({
		url: "/link/getDownSoftMessage",
		method: "get",
		params: data
	});
}
// 手机端密码验证
export function MobilecheckInviteCode(data) {
	return request({
		url: "/templet/checkInviteCode",
		method: "POST",
		data: qs.stringify(data)
	});
}
//下载消耗流量
export function downConsumptionFlow(data) {
	return request({
		url: "/product/deductionNumber",
		method: "POST",
		data: qs.stringify(data)
	});
}
//editApp下载
export function publishdeductionNumber(publishId) {
	return request({
		url: "/product/publishdeductionNumber?publishId=" + publishId,
		method: "get"
	});
}
//选择模板
export function changeTemplate(data) {
	return request({
		url: "/templet/changeTemplate?template=" + data.template + '&softId=' + data.softId,
		method: "get"
	});
}
//添加苹果私有账号 api
export function addPrivateAccount(data) {
	return request({
		url: "/appStore/addPrivateAccount",
		method: "POST",
		data: qs.stringify(data)
	});
}
//搜索苹果api 账号
export function searchAccount(data) {
	return request({
		url: "/account/searchAccount",
		method: "get",
		params: data
	});
}
//获取苹果api 账号
export function accountList(data) {
	return request({
		url: "/account/accountList",
		method: "get",
		data: data
	});
}
// 价格接口
export function getPrice(data) {
	return request({
		url: "/pay/price",
		method: "get",
		params: data
	});
}
// 基本信息--保存
export function saveBasicMessage(data) {
	return request({
		url: "/product/saveBasicMessage",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 应用截图保存图片接口
export function saveImageUrl(data) {
	return request({
		url: "/software/saveImageUrl",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 举报获取验证码接口
export function reportCode(data) {
	return request({
		url: "/report/getCode",
		method: "get",
		params: data
	});
}

// 举报验证码验证
export function reportCodeVerify(data) {
	return request({
		url: "/report/codeVerify",
		method: "POST",
		data: qs.stringify(data)
	});
}
//签名设备
export function signatureUDID(data) {
	return request({
		url: "/udid/addudidtest",
		method: "POST",
		data: qs.stringify(data)
	});
}
//查看是否有足够的安装设备数
export function checkEquipmentNum(data) {
	return request({
		url: "/udid/checkEquipmentNum",
		method: "POST",
		data: qs.stringify(data)
	});
}
//助手查看安装进度
export function checkHelperStatus(data) {
	return request({
		url: "/software/checkHelperStatus",
		method: "POST",
		data: qs.stringify(data)
	});
}
//助手查询设备是否足够 
export function checkEquipment(data) {
	return request({
		url: "/software/checkEquipment",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 文件本地上传接口
export function upload(data) {
	return request({
		url: "/software/upload",
		method: "POST",
		data: qs.stringify(data)
	});
}
// pc端举报app
export function reportApp(data) {
	return request({
		url: "/report/reportApp",
		method: "POST",
		headers: {
			contentType: 'application/json'
		},
		data: qs.stringify(data,{arrayFormat:'comma'})
	});
}
// 应用管理—搜索短链接接口
export function searchShortUrl(data) {
	return request({
		url: "/product/searchShortUrl",
		method: "get",
		params: data
	});
}
// 应用管理—解除合并接口
export function relieveMerge(data) {
	return request({
		url: "/product/relieveMerge",
		method: "get",
		params: data
	});
}
// 应用管理—合并应用接口
export function Merge(data) {
	return request({
		url: "/product/Merge",
		method: "get",
		params: data
	});
}
// 应用管理—合并应用接口
export function productMerge(data) {
	return request({
		url: "/product/productMerge",
		method: "get",
		params: data
	});
}
// 应用管理--统计详情
export function getDownNumDetail(data) {
	return request({
		url: "/product/getDownNumDetail",
		method: "POST",
		data: qs.stringify(data)
	});
}
//开启支付下载详情
export function openPayDetails(data) {
	return request({
		url: "/product/openPayDetails",
		method: "get",
		params: data
	});
}
//开启支付下载
export function openPay(data) {
	return request({
		url: "/product/openPay",
		method: "get",
		params: data
	});
}
// 渠道--获取渠道码列表
export function channelList(data) {
	return request({
		url: "/product/channelList",
		method: "get",
		params: data
	});
}
// 邀请—搜索查询邀请码列表接口
export function inviteList(data) {
	return request({
		url: "/invite/inviteList",
		method: "get",
		params: data
	});
}
//批量导出邀请码列表
export function exportinviteList(data) {
	return request({
		url: '/invite/exportinviteList',
		method: "get",
		params: data
	});
}

export function checkInviteCode(data) {
	return request({
		url: "/product/checkInviteCode",
		method: "get",
		params: data
	});
}

// 邀请—查询邀请码列表接口
export function addInvite(data) {
	return request({
		url: "/invite/addInvite",
		method: "get",
		params: data
	});
}
// 邀请—改变邀请码开启状态接口
export function changState(data) {
	return request({
		url: "/invite/changState",
		method: "get",
		params: data
	});
}
// 开启—保存成功—更改权限接口
export function changePower2(data) {
	return request({
		url: "/product/changePower",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 开启—保存密码接口
export function savePassword(data) {
	return request({
		url: "/product/savePassword",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 查询微信订单状态—接口
export function queryWXPayStatus(data) {
	return request({
		url: "/pay/queryWXPayStatus",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 创建微信二维码
export function createNative(data) {
	return request({
		url: "/pay/createNative",
		method: "get",
		params: data
	});
}
// 唤醒微信app支付接口
export function wxAppPay(data) {
	return request({
		url: "/wxPay/pay",
		method: "POST",
		data: qs.stringify(data)
	});
}
// ali H5支付接口
export function goAliH5pay(data) {
	return request({
		url: "/ali/goAliH5pay",
		method: "POST",
		data: qs.stringify(data)
	});
}
// ali支付接口
export function aliPay(data) {
	return request({
		url: "/ali/aliPay",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 购买价格表
export function getPriceList(data) {
	return request({
		url: "/product/getPriceList",
		method: "get",
		params: data
	});
}
//应用上传oss成功后做备份
export function softWareBackupCopy(data) {
	return request({
		url: "/software/softWareBackupCopy",
		method: "get",
		params: data
	});
}
// oss上传成功解析存储数据接口
export function resolveSoft(data) {
	return request({
		url: "/software/resolveSoft",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 应用上传新版本接口
export function reuploadSoft(data) {
	return request({
		url: "/software/reupload",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 版本开关检测
export function changerVersion(data) {
	return request({
		url: "/product/changerVersion",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 版本开关检测
export function checkVersion(data) {
	return request({
		url: "/product/checkVersion",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 保存软件日志接口
export function saveLog(data) {
	return request({
		url: "/product/saveLog",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 获取短链接
export function getShortUrl(data) {
	return request({
		url: "/software/getShortUrl",
		method: "get",
		params: data
	});
}
// 应用管理上传--获取上传token
export function getOssToken(data) {
	return request({
		url: "/oss/getOssToken",
		method: "get",
		params: data
	});
}
// 应用预览
export function preview(data) {
	return request({
		url: "/product/preview",
		method: "get",
		params: data
	});
}
//web clip 编辑
export function getWebClipInfo(data) {
	return request({
		url: "/webclip/toUpdateWebclip?id=" + data.id,
		method: "get",
		data: data
	});
}

//web clip 电脑端预览
export function webclipPCPreview(data) {
	return request({
		url: "/webclip/preview?id=" + data.id,
		method: "get",
		data: data
	});
}
// 创建web clip接口
export function saveWebclip(data) {
	return request({
		url: "/webclip/saveWebclip",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 应用管理编辑
export function editProduct(data) {
	return request({
		url: "/product/editProduct",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 应用管理上传
export function checkSoftname(data) {
	return request({
		url: "/software/checkSoftname",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 制作签名
export function makeSignature(data) {
	return request({
		url: "/software/makeSignature",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 获取软件VIP
export function getSoftwareVip(data) {
	return request({
		url: "/software/getSoftwareVip",
		method: "get",
		params: data
	});
}
// web clip修改接口
export function updateWebclip(data) {
	return request({
		url: "/webclip/updateWebclip",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 删除webclip
export function deleteWebclip(data) {
	return request({
		url: "/webclip/deleteWebclip",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 查询或搜索web clip接口
export function getWebclip(data) {
	return request({
		url: "/webclip/getWebclip",
		method: "get",
		params: data
	});
}
// 时长设备接口
export function durationUdidDetail(data) {
	return request({
		url: "/useUdid/durationUdidDetail",
		method: "get",
		params: data
	});
}
// 设备接口
export function useUdidDetail(data) {
	return request({
		url: "/useUdid/useUdidDetail",
		method: "get",
		params: data
	});
}
// VIP安装查询接口
export function seamless(data) {
	return request({
		url: "/product/seamless",
		method: "get",
		params: data
	});
}
// 应用删除
export function deleteProduct(data) {
	return request({
		url: "/product/deleteProduct",
		method: "POST",
		data: qs.stringify(data)
	});
}
// VIP安装开关接口
export function changePower(data) {
	return request({
		url: "/product/changePower",
		method: "get",
		params: data
	});
}
// 搜索应用管理
export function searchSoftName(data) {
	return request({
		url: "/software/searchSoftName",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 查询应用管理
export function appList() {
	return request({
		url: "/software/appList",
		method: "get"
	});
}
// 全部标记已读接口
export function readAll() {
	return request({
		url: "/message/readAll",
		method: "get"
	});
}
// 获取全部消息列表接口
export function messageList() {
	return request({
		url: "/message/messageList",
		method: "get"
	});
}
// 开具发票保存接口
export function makeInvoice(data) {
	return request({
		url: "/invoice/makeInvoice",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 发票管理查询接口
export function getInvoiceList(data) {
	return request({
		url: "/invoice/getInvoiceList",
		method: "get",
		params: data
	});
}
//获取公司已提交认证信息
export function getCompanyInformation(data) {
	return request({
		url: "/certification/company",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 企业认证上传图片接口
export function enterpriseCFuploadImage(data) {
	return request({
		url: "/certification/uploadImage",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 获取校验验证码接口
export function getCertificationCode(data) {
	return request({
		url: "/certification/getCode",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 校验是否认证接口
export function checkCert() {
	return request({
		url: "/certification/checkCert",
		method: "get"
	});
}
// 支付宝认证接口
export function getAliCert(data) {
	return request({
		url: "/certification/getAliCert",
		method: "POST",
		data: qs.stringify(data)
	});
}

// 企业认证接口
export function companyCertification(data) {
	return request({
		url: "/certification/companyCertification",
		method: "POST",
		data: qs.stringify(data)
	});
}

// 微信认证接口
export function getWXCert(data) {
	return request({
		url: "/certification/getWXCert",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 查看认证接口
export function authen(data) {
	return request({
		url: "/certification/authen",
		method: "get",
		params: data
	});
}


// 订单查询或开具发票接口
export function getOrderList(data) {
	return request({
		url: "/invoice/getOrderList",
		method: "get",
		params: data
	});
}

// 修改密码
export function resetPassowrd(data) {
	return request({
		url: "/user/resetPassowrd",
		method: "post",
		data: qs.stringify(data)
	});
}
// 保存个人资料接口
export function changeUserInfo(data) {
	return request({
		url: "/user/changeUserInfo",
		method: "post",
		data: data
	});
}

// 查询个人资料接口
export function userInfo(data) {
	return request({
		url: "/user/userInfo",
		method: "get",
		data
	});
}

// 应用管理广告栏
export function getPCAdvertising(data) {
	return request({
		url: "/advertising/getPCAdvertising",
		method: "get",
		data
	});
}

// 顶部广告栏
export function versionUpdate(data) {
	return request({
		url: "/version/versionUpdate",
		method: "get",
		data
	});
}
// 登录
export function registerUser(data) {
	return request({
		url: "/common/userRegister",
		method: "post",
		data: data,
	});
}
// 注册
export function userLogin(data) {
	return request({
		url: "/common/userLogin",
		method: "post",
		data: data,
	});
}

// 校验账号
export function accountVerify(data) {
	return request({
		url: "/common/accountVerify",
		method: "post",
		data: data,
	});
}

// 获取验证码
export function getCode(data) {
	return request({
		url: "/common/getCode",
		method: "post",
		data: data,
	});
}

// 验证码校验
export function codeVerify(data) {
	return request({
		url: "/common/codeVerify",
		method: "post",
		data: data,
	});
}

// 忘记密码-密码修改
export function forgetPassword(data) {
	return request({
		url: "/common/forgetPassword",
		method: "post",
		data: data,
	});
}

// pc端广告栏
export function getPCDBAdvertising() {
	return request({
		url: '/advertising/getPCDBAdvertising',
		method: 'get',
	})
}
// 时长导出数据集合接口
export function exportdurationUdidDetail() {
	return request({
		url: '/useUdid/exportdurationUdidDetail',
		method: 'get',
	})
}

// // 公有池导出 私有池导出
// export function exportUdidDetail(data) {
// 	return request({
// 		url: '/useUdid/exportUdidDetail',
// 		method: 'get',
// 		params: data
// 	})
// }

//开启激活码
export function openAct(data) {
	return request({
		url: "/product/changePower",
		method: "get",
		params: data
	});
}
// 邀请—搜索查询激活码列表接口
export function actCodeList(data) {
	return request({
		url: "/active/actCodeList",
		method: "get",
		params: data
	});
}
// 邀请—查询邀请码列表接口
export function addActCode(data) {
	return request({
		url: "/active/addActCode",
		method: "get",
		params: data
	});
}
//批量导出激活码列表
export function exportactCodeList(data) {
	return request({
		url: '/active/exportactCodeList',
		method: "get",
		params: data
	});
}
//批量导出公有池私有池列表
export function exportUdidDetail(data) {
	return request({
		url: '/useUdid/exportUdidDetail',
		method: "get",
		params: data
	});
}
// 邀请—改变激活码开启状态接口
export function activeChangState(data) {
	return request({
		url: "/active/changState",
		method: "get",
		params: data
	});
}
// 手机端激活码验证
export function MobilecheckActCode(data) {
	return request({
		url: "/active/checkActCode",
		method: "POST",
		data: qs.stringify(data)
	});
}
// 激活码下载应用接口
export function getActCode(actCode) {
	return request({
		url: "/active/" + actCode,
		method: "get",
	});
}
// 域名接口
export function getSLD(userId) {
	return request({
		url: "user/getSLD/" + userId,
		method: "get",
	});
}
// 域名接口第二步
export function domainPRVerify(data) {
	return request({
		url: "user/domainPRVerify",
		method: "post",
		data: qs.stringify(data),
	});
}

// 新增ICP接口
export function checkICP(icp,softId) {
	return request({
		url: "/user/checkICP/" + icp + '/' + softId,
		method: "get",
	});
}

// 获取火山STS临时密钥
export function getSTSToken() {
    return request({
        url: "/oss/getSTSToken",
        method: "get"
    })
}