<template>
  <div>
	  <div class="mobile" v-if="this.is_mobiState">
		  <mobile-home></mobile-home>
	  </div>
    <div class="pc" v-if="!this.is_mobiState">
		<div class="content">
		  <div class="banner_header">
		    <div class="w">
          <!-- 旧版 -->
		      <!-- <div class="header">
		        <div class="header_left" @click="ceshiClick">测试分发</div>
		        <img src="../../assets/image/heng.png" alt="" />
		        <div class="header_right" @click="webClipClick">Web Clip</div>
		      </div> -->
          <!-- 新版 -->
          <div class="header">
            <div @click="ceshiClick">
		          <img src="../../assets/image/distribute.png" alt="" />
              <span>测试分发</span>
            </div>
            <div @click="webClipClick">
		          <img src="../../assets/image/web.png" alt="" />
              <span>Web Clip</span>
            </div>
            <div @click="applySoftnessClick">
		          <img src="../../assets/image/softness.png" alt="" />
              <span>软著代申请</span>
            </div>
            <div @click="icpServicesClick">
		          <img src="../../assets/image/icp.png" alt="" />
              <span>ICP办理服务</span>
            </div>
		      </div>
		    </div>
		  </div>
		  <el-carousel height="597px" trigger="click">
		    <el-carousel-item v-for="item in bannerList" :key="item.id">
		      <img class="banner_img" :src="item.img" alt="" />
		    </el-carousel-item>
		  </el-carousel>
		
		  <div class="content_body">
		    <div class="w">
		      <div class="font_style">内测分发</div>
		      <div class="content_list">
		        <div
		          class="list_box"
		          v-for="(item, index) in listData"
		          :key="index"
		        >
		          <div class="list_left">
		            <h3>{{ item.title }}</h3>
		            <div class="list_content">{{ item.content }}</div>
		          </div>
		          <div class="list_right">
		            <img :src="item.img" alt="" />
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <div class="safe">
		    <img src="../../assets/image/safe.png" alt="" />
		    <div>安全稳定传输让内测分发更高效</div>
		  </div>
		  <div class="web_style">
		    <div class="w">
		      <div class="web_header">
		        <img src="../../assets/image/image7.png" />
		        <div>Web Clip</div>
		      </div>
		      <div class="web_list">
		        <div
		          class="web_item"
		          v-for="(item, index) in webClipList"
		          :key="index"
		        >
		          <img :src="item.icon" />
		          <h3>{{ item.title }}</h3>
		          <div>{{ item.content }}</div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <div class="content_footer">
		    <div class="footer_img">
		      <div class="footer_fenfa">悟空在线 邀您立即开始分发应用</div>
		      <div class="footer_fenfa">
		        <el-button @click="registerClick" v-show="showRegister">注册使用</el-button>
		      </div>
		    </div>
		  </div>
		</div>
		<div
		  class="sideBar"
		  @mouseover="over1"
		  @mouseleave="leave1"
		>
		  <img
		    src="@/assets/image/wx.png"
		    class="sideBarIcon1"
		    v-show="sideBarIcon1Show"
		  />
		  <span class="sideBarText" v-show="sideBarTextShow">联系我们</span>
		</div>
    <div v-show="sideBar2Show" @mouseleave="leave2">
      <contactUs ref="contactUs"></contactUs>
    </div>
		
		<!-- <div class="sideBar2" v-show="sideBar2Show" @mouseleave="leave2">
		  <div
		    class="sideBar2Item"
		    style="justify-content:center;"
		  >
		    <div>
		      <p>
		        联系电话
		      </p>
		      <p>
		        0371-86550361
		      </p>
		    </div>
		  </div>
		  <div class="sideBarBorder"></div>
		  <div class="sideBar2Item sideBar2ItemContent">
		    <img src="@/assets/image/wxQrcode.jpg" class="sideBarQrcode" />
		    <div>
		      <img src="@/assets/image/smallWX1.png" class="sideBarIcon2" />
		      <p>
		        技术支持微信
		      </p>
		      <p>
		        wkzx2020
		      </p>
		    </div>
		  </div>
		</div> -->
	</div>
  </div>
</template>

<script>
import mobileHome from "@/views/mobilePage/mobileHome.vue"
import contactUs from "@/views/applySoftness/contactUs";
export default {
  name: "home",
  components: {
	  mobileHome,
    contactUs
  },
  data() {
    return {
      sideBar2Show: false,
      sideBarTextShow: false,
      sideBarIcon1Show: true,
      bannerList: [
        {
          id: 1,
          img: require("../../assets/image/banner.png"),
        },
        {
          id: 2,
          img: require("../../assets/image/banner.png"),
        },
      ],
      webClipList: [
        {
          icon: require("../../assets/image/web_1.png"),
          title: "可定制",
          content: "自定义配置信息，自定义Logo 自定义名字等等。",
        },
        {
          icon: require("../../assets/image/web_2.png"),
          title: "方便快捷",
          content: "创建- 配置 - 分发仅需三步便可完成分发。",
        },
        {
          icon: require("../../assets/image/web_3.png"),
          title: "可信任",
          content: "可信任Apple官方配置，可信任。",
        },
        {
          icon: require("../../assets/image/web_4.png"),
          title: "只需一个网站地址",
          content: "就能创建一个属于您自己的移动APP！",
        },
      ],
      listData: [
        {
          title: "稳定高效",
          content:
            "各种浏览器全面兼容，极致体验，我们24小时实时监控，一对一服务，让您的app稳定分发。",
          img: require("../../assets/image/list_1.png"),
        },
        {
          title: "android和ios合二为一",
          content:
            "一键上传应用，生成对应的下载链接和二维码，安卓和ios可以合二为一，在分发的过程中设备自动识别，自动匹配，让您一码分发。",
          img: require("../../assets/image/list_2.png"),
        },
        {
          title: "丰富的分发模板",
          content:
            "无论您是内测或者是企业级分发我们已经给你提供了丰富的分发组建，包括自定义分发模版，动态密码，应用统计等一系列下载组件",
          img: require("../../assets/image/list_3.png"),
        },
        {
          title: "二维码永久唯一",
          content:
            "完善便捷的应用版本控制，同一个app无论你版本如何更新我们都可以做到下载链接和二维码永远不变，永久唯一；突破安装包大小上传限制，让您的游戏或者应用一键内测。",
          img: require("../../assets/image/list_4.png"),
        },
      ],
    };
  },
  computed: {
  	 is_mobiState() {
  		 return this.$store.state.is_mobi
  	 } 
  },
  methods: {
    leave1() {
      this.sideBarIcon1Show = true;
      this.sideBarTextShow = false;
    },
    over1() {
      this.sideBar2Show = true;
      this.sideBarIcon1Show = false;
      this.sideBarTextShow = true;
      this.$refs.contactUs.isShow = true
    },
    leave2() {
      this.sideBar2Show = false;
      this.sideBarIcon1Show = true;
      this.sideBarTextShow = false;
    },
    ceshiClick() {
      this.$router.push("/home");
    },
    webClipClick() {
      this.$router.push("/webClip");
    },
    applySoftnessClick() {
      this.$router.push("/applySoftness");
    },
    icpServicesClick(){
      this.$router.push("/icpServices");
    },
    registerClick() {
      localStorage.clear();
      this.$router.push("/register");
    },
  },
  mounted(){
    this.$refs.contactUs.isShow = true
  },
};
</script>

<style lang="scss" scoped>
.sideBar {
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #eeeeee, 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: fixed;
  right: 50px;
  bottom: 200px;
  z-index: 999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  color: #0098dd;
}

.sideBarIcon1 {
  width: 22px;
  height: 25px;
}

.sideBar2 {
  width: 204px;
  z-index: 999;
  height: 200px;//280
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #eeeeee, 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: fixed;
  right: 120px;
  bottom: 199px;
  padding: 10px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sideBarIcon2 {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.sideBar2Item {
  min-height: 52px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sideBar2Item > div > :nth-child(1) {
  text-align: center;
  color: #666666;
}
.sideBar2Item > div > p {
  text-align: center;
}
.sideBar2Item > div > :nth-child(2) {
  color: #0098dd;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}
.sideBarBorder {
  width: 159px;
  border: 1px dashed #dbdbdb;
}
.sideBarQrcode {
  width: 80px;
  height: 80px;
  margin-right: 8px;
}
::v-deep .el-carousel__indicators--horizontal {
  bottom: 30px;
}
::v-deep .el-carousel__button {
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
}
.web_style {
  width: 100%;
  height: 512px;
  background: #e8ecf2;
  .web_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .web_item {
      width: 289px;
      height: 260px;
      border-radius: 10px;
      background: #fff;
      box-sizing: border-box;
      padding: 35px 30px;
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
  .web_header {
    position: relative;
    div {
      position: absolute;
      font-size: 30px;
      color: #000;
      top: 80px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
.bottom_img {
  width: 100%;
  height: 512px;
}
.safe {
  width: 479px;
  height: 162px;
  margin: auto;
  img {
    width: 100px;
    height: 100px;
    display: block;
    margin: auto;
  }
  div {
    font-size: 30px;
    text-align: center;
  }
}
.content_list {
  width: 100%;
}
.list_right {
  img {
    width: 130px;
    height: 130px;
  }
}
.list_content {
  width: 300px;
  font-size: 14px;
  color: #666666;
}
.list_box {
  display: flex;
  justify-content: space-between;
  float: left;
  margin: 4px;
  width: 592px;
  height: 188px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 40px;
}
.font_style {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}
.content_body {
  overflow: hidden;
  margin-top: 270px;
  margin-bottom: 50px;
}
.content {
  position: relative;
  .banner_header {
    position: absolute;
    top: 574px;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.header {
  width: 100%;
  height: 240px;
  line-height: 240px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  font-size: 20px;
  color: #333333;
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 200px;
    height: 200px;
    cursor: pointer;
    img {
      margin-top: 10px;
      height: 96px;
      width: 96px;
    }
    span {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #333333;
      margin-top: 30px;
    }
  }
  div:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  // .header_left {
  //   cursor: pointer;
  //   width: 599.5px;
  //   float: left;
  // }
  // .header_left:hover {
  //   width: 599.5px;
  //   float: left;
  //   color: #0299dd;
  // }
  img {
    margin-top: 20px;
    height: 96px;
    width: 96px;
  }
  // .header_right {
  //   cursor: pointer;
  //   width: 599.5px;
  //   float: right;
  // }
  // .header_right:hover {
  //   width: 599.5px;
  //   float: right;
  //   color: #0299dd;
  // }
}
.banner_img {
  width: 100%;
  height: 597px;
}
.content_footer {
  position: relative;
  width: 100%;
  height: 238px;
  background: linear-gradient(to right, #0c41ff 0%, #4700ba 100%);
  .footer_img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 238px;
    background: url("../../assets/image/bg.png") no-repeat center top;
  }
  .footer_fenfa {
    padding: 40px 0 20px 0;
    z-index: 999;
    text-align: center;
    font-size: 30px;
    color: #fff;
  }
}
</style>
