<template>
  <div style="height:100%">
    <!-- <template-pc v-if="status == 7" :appInfo="appInfo"></template-pc> -->
    <mobile-template v-if="status == 101" :form="info" :bannerImg="bannerImg" :link="link" :closeAD="closeAD"></mobile-template>
    <mobile-template2
      v-if="status == 106"
      :form="info"
      :imageList="imageList"
      :bannerImg="bannerImg" 
      :link="link"
      :closeAD="closeAD"
    ></mobile-template2>
    <mobile-template3
      v-if="status == 107"
      :form="info"
      :imageList="imageList"
      :bannerImg="bannerImg" 
      :link="link"
      :closeAD="closeAD"
    ></mobile-template3>
    <password-info
      v-if="status == 8"
      :passwordObj="passwordObj"
    ></password-info>
    <!-- <not-fount v-else></not-fount> -->
    <brower-template
        v-if="qqAndWxStatus == 1 || qqAndWxStatus == 2"
        :qqAndWxStatus="qqAndWxStatus" 
        :icon="icon"
        :softname="softName"
    ></brower-template>
  </div>
</template>

<script>
// import notFount from '@/views/notFount/404.vue'
import mixin from "@/mixin"; // 引入mixin文件
import mobileTemplate2 from "@/views/appDown/template/template2";
import mobileTemplate3 from "@/views/appDown/template/template3";
import templatePc from "@/views/appDown/template/templatePc";
import mobileTemplate from "@/views/appDown/template/template1";
import { getInvitationCode,getPhoneAdvertising } from '@/api/api'
import browerTemplate from "@/views/appDown/template/browerTemplate";
import { isEmpty } from "@/common/js/common.js";
export default {
  mixins: [mixin], // 判断是否安卓公共方法
  components: {
    mobileTemplate,
    mobileTemplate2,
    mobileTemplate3,
    templatePc,
    browerTemplate,
    // notFount
  },
  data() {
    return {
      softName: '',
      invitationCode: "",
      status: "",
      appInfo: {},
      downFlag: false,
      qqAndWxStatus: 0,
      icon: "",
      bannerImg: '',
      link: '',
      closeAD: false,//是否不展示广告
      isShowPc:false,
    };
  },
  created() {
    this.invitationCode = window.location.href.substring(
      window.location.href.length - 8
    );
    this.andriodAndApple();
    this.getInvitationCode(this.invitationCode);
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      this.qqAndWxStatus = 1;
    } else {
      if(ua.match(/iphone/i) == "iphone" && ua.match(/qqtheme/i) == "qqtheme"){
          this.qqAndWxStatus = 2;
       }
       if(ua.match(/android/i) == "android" && ua.match(/qqtheme/i) == "qqtheme"){
          this.qqAndWxStatus = 2;
      }
    }
    if(!this.getBrower().mobile) {
      this.isShowPc = true;
    }
  },
  methods: {
    // 获取广告栏数据
    getPhoneAdvertisingTl(val) {
      getPhoneAdvertising({userId: val}).then(res => {
        if(res.code == 0) {
          if(!isEmpty(res.data.advertising)){
             this.bannerImg = res.data.advertising.imgUrl
             this.link = res.data.advertising.link
          }else{
            this.closeAD = true;
          }
        }
      })
    },
    // 判断系统所属安卓还是苹果
    andriodAndApple() {
      if (
        !(
          this.getBrower().ios ||
          this.getBrower().iPhone ||
          this.getBrower().iPad
        )
      ) {
        //非苹果端
        this.downFlag = false;
      } else {
        this.downFlag = true;
      }
    },
    // 调用查询接口
   async getInvitationCode(val) {
      if (val) {
        await getInvitationCode(val).then((res) => {
          console.log(res)
          this.status = res.code;
          if(!this.getBrower().mobile && res.code != 1 && res.code != 11 && res.code != 10 && res.code != 6 && res.code != 3 && res.code != 4) {
            this.status = 101
          }
          console.log(res)
          if(res.code == 1){
            this.$router.push({
              path: "/errorPage",
              query: {
                tips: "邀请码已被关闭",
              },
             });
          }
          if(res.code == 11){
              this.$router.push({
              path: "/errorPage",
              query: {
                tips: "黑名单中存在该应用",
              },
            });
          }
          if(res.code == 10){
              this.$router.push({
              path: "/errorPage",
              query: {
                tips: "邀请码不存在或已关闭",
              },
            });
          }
          if(res.code == 6){
             this.$router.push({
              path: "/errorPage",
              query: {
                tips: "未开启超级签名",
              },
             });
          }
         if(res.code == 3 || res.code ==4){
             this.$router.push({
             path: "/errorPage",
              query: {
                tips: "举报被下架",
              },
             });
          }
        if((res.data.cpSoftId =="0" && res.data.remarks2 =="0") || (res.data.cpSoftId == undefined && res.data.remarks2 == undefined)){
          this.icon = res.data.iconUrl;
          this.softName = res.data.softName;
          }else{
            if (!this.downFlag){
            this.icon = res.data.androidIconUrl;
            this.softName = res.data.androidSoftName;
            }else if(res.data.fitType == 'IOS'){
              this.icon = res.data.iOSIconUrl;
              this.softName = res.data.iOSSoftName;
            }
          } 

          if(this.softName ){
                document.title = this.softName;
              }else{
                document.title = '';
          }
          localStorage.removeItem("udidcache");
          // 获取banner
          this.getPhoneAdvertisingTl(res.data.userId)
        //   if (res.code == 7) {
        //     let obj = {};
        //     let url = res.data.forward;
        //     let urlList = url.split("?")[1].split("&");
        //     urlList.forEach((item) => {
        //       let arr = item.split("=");
        //       let key = arr[0];
        //       let val = arr[1];
        //       obj[key] = val;
        //     });
        //     this.appInfo = obj;

        //   }
          if (res.code == 101) {
            this.info = res.data.info;
            this.icon = res.data.info.iconurl;
          }
          if (res.code == 106 || res.code == 107) {
            this.info = res.data.info;
            this.imageList = res.data.imageList;
          }
          if (res.code == 108) {
            let url = "";
            if (this.downFlag) {
              url = res.data.mergeInfo.iosiconUrl;
            } else {
              url = res.data.mergeInfo.androidiconUrl;
            }
            this.$router.push({
              path: "/templateMerge",
              query: {
                softId: url.split("?")[1].split("=")[1],
              },
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
