import { createApp, nextTick } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 导入全局样式表
import "./assets/css/global.css";
// 引入elementui框架
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 引入阿里图标
import "./assets/fonts/iconfont.css";
import VueWechatTitle from 'vue-wechat-title'
// 绑定注册显示状态
import showRegister from './utils/system.js'


const app = createApp(App);
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(showRegister);
app.use(store);
app.use(VueWechatTitle);
app.use(router);
app.mount("#app");

