<template>
  <div>
	 <mobile-header></mobile-header>
    <div class="content">
      <div class="content_box">
        <div class="content_login">
          <div class="content_tab">
            <el-tabs v-model="activeName" stretch @tab-click="handleClick">
              <el-tab-pane label="邮箱找回" name="first">
                <forget-email></forget-email>
              </el-tab-pane>
              <el-tab-pane label="手机找回" name="second">
                <forget-tel></forget-tel>
              </el-tab-pane>
            </el-tabs>
            <div style="margin-top: -20px;">
              <span>已有账号, </span>
              <el-button type="text" @click="goLogin">马上登录</el-button>
            </div>
          </div>
        </div>
        <div class="content_goback">
          <div class="goback">
            <img src="@/assets/image/home.png" alt="" />
            <span @click="goback">回到首页</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mobileHeader from '@/views/mobilePage/common/mobileHeader'
import forgetEmail from "@/components/forgetPassword/forgetEmail";
import forgetTel from "@/components/forgetPassword/forgetTel";
export default {
  name: "forgetPassword",
  components: {
	  mobileHeader,
    forgetEmail,
    forgetTel
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    goback() {
      this.$router.push('/home')
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
}
.content_box {
  margin: 70px 10px 25px 10px;
  height: 500px;
  .content_login {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    .content_tab {
      padding: 0 20px;
      overflow: hidden;
      margin: auto;
      .ce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .el-tabs .el-form-item .el-button {
        width: 100%;
      }
    }
  }
  .content_goback {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background: #fff;
    color: #999999;
    margin: auto;
    .goback {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
