<template>
  <div>
    <div class="w">
      <div class="header_btn">
        <div :class="appClass" @click="appClick(1)">应用管理</div>
        <div :class="vipClass" @click="appClick(2)">超级签名</div>
        <div :class="appleClass" @click="appClick(3)">苹果证书</div>
      </div>
      <div class="banner" v-if="bannerList.length > 0">
        <el-carousel height="100px" trigger="click">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img
              class="banner_img"
              :src="item.imgUrl"
              alt=""
              @click="bannerClick(item.link)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <app-management-code v-if="appShow"></app-management-code>
      <vip-install v-if="vipShow"></vip-install>
      <apple-certificate v-if="appleShow"></apple-certificate>
    </div>
  </div>
</template>

<script>
import { getPCAdvertising } from "@/api/api";
import appManagementCode from "@/components/appManagement/appManagement";
import vipInstall from "@/components/appManagement/vipInstall";
import appleCertificate from "@/components/appManagement/appleCertificate";
export default {
  name: "appManagement",
  components: {
    appManagementCode,
    vipInstall,
    appleCertificate,
  },
  data() {
    return {
      appShow: true,
      vipShow: false,
      appleShow: false,
      appClass: "btn_active",
      vipClass: "btn",
      appleClass: "btn",
      bannerList: [],
    };
  },
  mounted() {
    if (this.$route.query.vipShow) {
      this.vipShow = true;
      this.appShow = false;
      this.appleShow = false;
      this.appClass = "btn";
      this.vipClass = "btn_active";
      this.appleClass = "btn";
    }
    this.getPCAdvertising();
    this.selectChange()
  },
  methods: {
    // 轮播图跳转
    bannerClick(val) {
      window.open(val);
    },
    // 获取轮播图
    getPCAdvertising() {
      getPCAdvertising().then((res) => {
        if (res.data != null) {
          this.bannerList = res.data.advertisings;
        }
        if (res.code == 401) {
          // this.$message.error(res.msg)
          localStorage.clear();
          this.$router.push("/login");
        }
      });
    },
    // 默认选择项
    selectChange() {
      if(localStorage.getItem('appManagementStatus')) {
        this.appShowAndNo(localStorage.getItem('appManagementStatus'))
      }
      
    },
    appClick(val) {
      this.appShowAndNo(val);
      localStorage.setItem('appManagementStatus',val)
    },
    // 应用管理显示与隐藏
    appShowAndNo(val) {
      if (val == 1) {
        this.appShow = true;
        this.appClass = "btn_active";
        this.vipClass = "btn";
        this.appleClass = "btn";
      } else {
        this.appShow = false;
      }
      if (val == 2) {
        this.vipShow = true;
        this.appClass = "btn";
        this.vipClass = "btn_active";
        this.appleClass = "btn";
      } else {
        this.vipShow = false;
      }
      if (val == 3) {
        this.appleShow = true;
        this.appClass = "btn";
        this.vipClass = "btn";
        this.appleClass = "btn_active";
      } else {
        this.appleShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.banner {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  .banner_img {
    width: 100%;
    height: 110px;
  }
}

.header_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  .btn {
    width: 100px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #666666;
    font-size: 14px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 10px;
  }
  .btn_active {
    width: 100px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #0092ff;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #0092ff;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 10px;
  }
}
</style>
