<template>
  <div>
    <div class="mobile" v-if="this.is_mobiState">
      <mobile-register></mobile-register>
    </div>
    <div class="pc" v-if="!this.is_mobiState">
      <div class="content">
        <div class="content_box">
          <div class="content_logo">
            <img src="../../assets/image/logo.png" alt="" />
          </div>
          <div class="content_login">
            <div class="content_tab">
              <el-tabs v-model="activeName" stretch>
                <el-tab-pane label="注册账号" name="first">
                  <el-form
                    ref="form"
                    :rules="rules"
                    :model="form"
                    style="margin-top: 30px"
                  >
                    <el-form-item prop="userName">
                      <el-input
                        v-model="form.userName"
                        placeholder="手机号/邮箱"
                      ></el-input>
                    </el-form-item>
                    <el-form-item style="position: relative" prop="code">
                      <el-col>
                        <el-input
                          v-model="form.code"
                          placeholder="验证码"
                        ></el-input>
                      </el-col>
                      <el-col style="position: absolute; right: 0">
                        <el-button @click="getCode" :disabled="!show">
                          获取验证码
                          <span v-show="!show" class="count"
                            >({{ count }}s)</span
                          >
                        </el-button>
                      </el-col>
                    </el-form-item>
                    <!-- <el-form-item prop="agree">
		                
		              </el-form-item> -->
                    <div style="height: 34px;">
                      <my-slider-captcha
                        @isSuccess="isSuccess"
                        @codeData="codeData"
                        :scene="scene"
                      ></my-slider-captcha>
                    </div>
                    <div style="margin: 5px 0">
                      <el-checkbox v-model="form.agree"></el-checkbox>
                      <span style="font-size: 7px; color: #999">
                        同意并愿意接受<span
                          class="font_style"
                          @click="docClick(1)"
                          >《悟空在线服务协议》</span
                        >和<span class="font_style" @click="docClick(1)"
                          >《悟空在线隐私申明》</span
                        ></span
                      >
                    </div>
                    <el-form-item>
                      <el-button @click="onSubmit" type="primary"
                        >下一步</el-button
                      >
                    </el-form-item>
                  </el-form>
                  <div style="margin-top: -20px">
                    <span>已有账号, </span>
                    <el-button type="text" @click="goLogin">马上登录</el-button>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="content_goback">
            <div class="goback">
              <img src="../../assets/image/home.png" alt="" />
              <span @click="goback">回到首页</span>
            </div>
          </div>
        </div>
        <footer-bottom></footer-bottom>
      </div>
    </div>
  </div>
</template>

<script>
import mobileRegister from "@/views/mobilePage/loginCommon/mobileRegister.vue";
import { accountVerify, getCode, codeVerify } from "@/api/api.js";
import footerBottom from "@/components/common/footer";
import MySliderCaptcha from "../../components/common/MySliderCaptcha.vue";
export default {
  name: "register",
  components: {
    footerBottom,
    mobileRegister,
    MySliderCaptcha,
  },
  data() {
    var validateUserid = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号/邮箱"));
      } else {
        callback();
      }
    };

    var validateAgree = (rule, value, callback) => {
      if (value === false) {
        callback(new Error("请勾选同意服务条款!"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      scene: 'register',
      checkData: null,
      checkSuccess: false,
      activeName: "first",
      show: true, // 初始启用按钮
      count: "", // 初始化次数
      timer: null,
      rules: {
        userName: [{ validator: validateUserid, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        agree: [{ validator: validateAgree, trigger: "change" }],
      },
      form: {
        userName: "",
        code: "",
        accountType: 0,
        codeType: 1,
        agree: false,
      },
    };
  },
  computed: {
    is_mobiState() {
      return this.$store.state.is_mobi;
    },
  },
  methods: {
    codeData(val) {
      this.checkData = val
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    docClick(val) {
      if (val == 1) {
        this.$router.push("/contract?status=3");
      }
      if (val == 2) {
        this.$router.push("/contract?status=4");
      }
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 获取用户类型 邮箱、手机号
    getuserType() {
      if (this.form.userName.includes("@")) {
        this.form.accountType = 2;
      } else {
        this.form.accountType = 1;
      }
    },
    // 获取验证码
    getCode() {
      if (!this.form.userName) return this.$message.warning("请输入手机/邮箱");
      if (this.checkSuccess) {
        this.getuserType();
        let params = new URLSearchParams();
        params.append("userName", this.form.userName);
        params.append("codeType", this.form.codeType);
        accountVerify(params).then((response) => {
          if (response.code == "1") {
            this.$message({
              type: "warning",
              duration: 2000,
              message: response.msg,
            });
          } else {
            this.codeJiaoyan();
          }
        });
      } else {
        this.$message.warning("请先通过滑块验证");
      }
    },
    // 验证码校验
    codeJiaoyan() {
      let params1 = new URLSearchParams();
      params1.append("userName", this.form.userName);
      params1.append("accountType", this.form.accountType);
      params1.append("codeType", this.form.codeType);
      params1.append("sessionId", this.checkData.sessionId)
      params1.append("sig", this.checkData.sig)
      params1.append("sessiontoken", this.checkData.token)
      params1.append("scene", this.scene)
      if (this.form.userName) {
        getCode(params1).then((res) => {
          if (res.code == 0) {
            this.timerChange();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号/邮箱",
        });
      }
    },
    goback() {
      this.$router.push("/index");
    },
    goLogin() {
      this.$router.push("/login");
    },
    onSubmit() {
      let params = new URLSearchParams();
      params.append("code", this.form.code);
      params.append("userName", this.form.userName);
      codeVerify(params).then((response) => {
        if (response.code == "2") {
          this.$message({
            type: "error",
            duration: 2000,
            message: response.msg,
          });
        } else {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$router.push({
                path: "/password",
                query: {
                  userName: this.form.userName,
                  accountType: this.form.accountType,
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.font_style {
  color: #409eff;
  cursor: pointer;
}
.content {
  height: 100vh;
  position: relative;
}
.content_box {
  position: absolute;
  width: 422px;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content_logo {
    width: 100%;
    height: 56.5px;
    display: flex;
    align-item: center;
    margin-bottom: 50px;
    img {
      width: 200px;
      height: 56.6px;
      margin: auto;
    }
  }
  .content_login {
    width: 100%;
    height: 354px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    .content_tab {
      width: 362px;
      overflow: hidden;
      margin: auto;
      .ce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .el-tabs .el-form-item .el-button {
        width: 100%;
      }
    }
  }
  .content_goback {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background: #fff;
    color: #999999;
    margin: auto;
    .goback {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
