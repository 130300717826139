<template>
  <div>
    <template-pc v-if="status == 7" :info="info" :appInfo="appInfo"></template-pc>
    <mobile-template
      v-if="status == 101"
      :form="info"
      :bannerImg="bannerImg"
      :link="link"
      :closeAD="closeAD"
    ></mobile-template>
    <mobile-template2
      v-if="status == 106"
      :form="info"
      :imageList="imageList"
      :bannerImg="bannerImg"
      :link="link"
      :closeAD="closeAD"
    ></mobile-template2>
    <mobile-template3
      v-if="status == 107"
      :form="info"
      :imageList="imageList"
      :bannerImg="bannerImg"
      :link="link"
      :closeAD="closeAD"
    ></mobile-template3>
    <password-info
      v-if="status == 8"
      :passwordObj="passwordObj"
      :bannerImg="bannerImg"
      :link="link"
      :actState="1"
      :controlState="controlState"
    ></password-info>
    <!-- <not-fount v-else></not-fount> -->
    <brower-template
      v-if="(qqAndWxStatus == 1 || qqAndWxStatus == 2) && flagStatus"
      :qqAndWxStatus="qqAndWxStatus"
      :icon="icon"
      :softname="softName"
      :downFlag="downFlag"
      :isIOS="isIOS"
      :isMerge="isMerge"
    ></brower-template>
    <delete-page
      v-if="status == 3 || status == 4 || status == 2"
      :bannerImg="bannerImg"
      :link="link"
      :softname="softName"
    >
    </delete-page>
    <template-phone
      v-if="status == 9"
      :form="webClip"
      :shortUrl="webClip.webclipUrl"
    ></template-phone>
  </div>
</template>

<script>
// import notFount from '@/views/notFount/404.vue'
import passwordInfo from "./passwordInfo";
import mixin from "@/mixin"; // 引入mixin文件
import mobileTemplate2 from "./template/template2";
import mobileTemplate3 from "./template/template3";
import templatePc from "./template/templatePc";
import mobileTemplate from "./template/template1";
import { getAppInfo, getPhoneAdvertising } from "@/api/api";
import browerTemplate from "@/views/appDown/template/browerTemplate";
import templatePhone from "@/views/web/template/templatePhone";
import deletePage from "@/views/appDown/template/deletePage";
import { isEmpty } from "@/common/js/common.js";

export default {
  mixins: [mixin], // 判断是否安卓公共方法
  components: {
    mobileTemplate,
    mobileTemplate2,
    mobileTemplate3,
    passwordInfo,
    templatePc,
    browerTemplate,
    deletePage,
    templatePhone,
  },
  data() {
    return {
      flagStatus: false,
      softName: "",
      shortUrl: "",
      status: "",
      appInfo: {},
      webClip: {},
      downFlag: false,
      qqAndWxStatus: 0,
      icon: "",
      bannerImg: "",
      link: "",
      isIOS: true,
      isMerge: false, //是否合并
      computer: "", //空是pc  非空是ipad
      closeAD: false, //是否不展示广告
      userId: "", //应用用户id
      flag:false,
      passwordObj:{},
    };
  },
  created() {
    // this.shortUrl = window.location.href.substring(
    //   window.location.href.length - 4
    // );
    // this.shortUrl = window.location.href.split('/')[window.location.href.split('/').length - 1]
    let short = window.location.href;
    this.shortUrl = short.substr(short.lastIndexOf("/") + 1, short.length);
    // if (
    //   this.getBrower().iPad &&
    //   document.body.clientWidth <= 1366 &&
    //   document.body.clientHeight <= 1024
    // ) {
    //   this.computer = 1;
    // }
    if(this.getBrower().mobile) {
      this.computer = 1;
    }
    this.andriodAndApple();
    this.getAppUrl(this.shortUrl);
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      this.qqAndWxStatus = 1;
    } else {
      if (
        ua.match(/iphone/i) == "iphone" &&
        ua.match(/qqtheme/i) == "qqtheme"
      ) {
        this.qqAndWxStatus = 2;
      }
      if (
        ua.match(/android/i) == "android" &&
        ua.match(/qqtheme/i) == "qqtheme"
      ) {
        this.qqAndWxStatus = 2;
      }
    }
  },
  methods: {
    // 获取广告栏数据
    getPhoneAdvertisingTl(val) {
      getPhoneAdvertising({ userId: val }).then((res) => {
        if (res.code == 0) {
          if(!isEmpty(res.data.advertising)) {
            this.bannerImg = res.data.advertising.imgUrl;
            this.link = res.data.advertising.link;
          }else {
            this.closeAD = true;
          }
        }
      });
    },
    // 判断系统所属安卓还是苹果
    andriodAndApple() {
      if (
        !(
          this.getBrower().ios ||
          this.getBrower().iPhone ||
          this.getBrower().iPad
        )
      ) {
        //非苹果端
        this.downFlag = false;
      } else {
        this.downFlag = true;
      }
    },
    // 调用查询接口
    async getAppUrl(val) {
      console.log(val)
      if (val) {
        await getAppInfo({ computer: this.computer }, val).then((res) => {
          console.log(res)
          this.status = res.code;
        //   alert(res.code)
        // alert(JSON.stringify(res.data))
      //     if(!this.getBrower().mobile) {
      //       this.status = 7
      //     }
          if (this.status == 2 || this.status == 3 || this.status == 4) {
            this.flagStatus = false;
          }
          if (res.code == 1 || res.code == 99) {
            this.$router.push({
              path: "/errorPage",
              query: {
                tips: "短链接不存在或已下架",
              },
            });
          }
          if (res.code == 10) {
            this.$router.push({
              path: "/errorPage",
              query: {
                tips: "审核中或审核未通过",
              },
            });
          }
          if (res.code == 11) {
            this.$router.push({
              path: "/errorPage",
              query: {
                tips: "黑名单中存在该应用",
              },
            });
          }
          if(res.code == 98) {
            this.$router.push({
              path: "/errorPage",
              query: {
                tips: "请实名认证后再请进下载",
              },
            });
          }
          if (
            (res.data.cpSoftId == "0" &&
              res.data.remarks2 == "0" &&
              res.data.cpSoftId != "undefined" &&
              res.data.remarks2 != "undefined") ||
            (res.data.cpSoftId != undefined && res.data.remarks2 != undefined)
          ) {
            this.icon = res.data.iconUrl;
            this.softName = res.data.softName;
            // document.title = this.softName
          } else {
            if (!this.downFlag) {
              this.icon = res.data.androidIconUrl;
              this.softName = res.data.androidSoftName;
            } else {
              this.icon = res.data.iOSIconUrl;
              this.softName = res.data.iOSSoftName;
            }
            if (
              res.data.iOSIconUrl != null &&
              res.data.androidIconUrl != null || res.data.thirdurl != null
            ) {
              this.isMerge = true;
              if(res.data.thirdurl) {
                if(res.data.iOSIconUrl) {
                  this.icon = res.data.iOSIconUrl;
                  this.softName = res.data.iOSSoftName;
                } else {
                  this.icon = res.data.androidIconUrl;
                  this.softName = res.data.androidSoftName;
                }
                
              }
            } else {
              if (res.data.iOSIconUrl != null) {
                this.isIOS = true;
              } else {
                this.isIOS = false;
              }
            }
          }
          if (this.softName) {
            document.title = this.softName;
          } else {
            document.title = "";
          }
          // 获取banner
          this.getPhoneAdvertisingTl(res.data.userId);
          if (res.code == 7) {
            let obj = {};
            let url = res.data.forward;
            let urlList = url.split("?")[1].split("&");
            urlList.forEach((item) => {
              let arr = item.split("=");
              let key = arr[0];
              let val = arr[1];
              obj[key] = val;
            });
            this.appInfo = obj;
            this.info = res.data.info;
          }
          if (res.code == 9) {
            this.webClip = res.data.webClip;
          }
          if (res.code == 101) {
            // if (res.data.info.thirdurl) {
            //   return (window.location.href =
            //     "http://" + res.data.info.thirdurl);
            // }
            this.info = res.data.info;
            this.icon = res.data.info.iconurl;
          }
          if (res.code == 106 || res.code == 107) {
            // if (res.data.info.thirdurl) {
            //   return (window.location.href =
            //     "http://" + res.data.info.thirdurl);
            // }
            this.info = res.data.info;
            this.imageList = res.data.imageList;
          }
          // 输入密码状态
          if (res.code == 8) {
            if (res.data.openPassword == 1) {
              this.controlState = 0;
            } else if (res.data.remarks4 == 1) {
              this.controlState = 1;
            }
            this.passwordObj = res.data;
          }
          if(this.downFlag) {
            if(res.data.info) {
              if(res.data.info.fittype == "IOS") {
              if(res.code == 108 && (res.data.info.openPassword != 0 || res.data.info.actcode == 1 || res.data.info.remarks4 == 1)) {
                this.status = 8;
                if (res.data.info.openPassword == 1) {
                  this.controlState = 0;
                }else if(res.data.info.remarks4 == 1) {
                  this.controlState = 1;
                }
                this.passwordObj.iconUrl = res.data.info.iconurl;
                this.passwordObj.softName = res.data.info.softname;
                this.passwordObj.version = res.data.info.versionnum;
                this.passwordObj.actCode = res.data.info.actcode;
                this.passwordObj.softId = res.data.info.softId;
                return
              }
            }else{
              if(res.code == 108 && (res.data.mergeInfo.openPassword != 0  || res.data.mergeInfo.actcode == 1 || res.data.mergeInfo.remarks4 == 1)) {
                this.status = 8;
                if (res.data.mergeInfo.openPassword == 1) {
                  this.controlState = 0;
                }else if(res.data.mergeInfo.remarks4 == 1) {
                  this.controlState = 1;
                }
                else if(res.data.mergeInfo.actcode == 1) {
                  this.controlState = 1;
                }
                console.log(this.controlState)
                this.passwordObj.iconUrl = res.data.mergeInfo.iconurl;
                this.passwordObj.softName = res.data.mergeInfo.softname;
                this.passwordObj.version = res.data.mergeInfo.versionnum;
                this.passwordObj.actCode = res.data.mergeInfo.actcode;
                let iosOran = '';
                if(this.downFlag) {
                  iosOran = res.data.mergeInfo.iosiconUrl
                }else{
                  iosOran = res.data.mergeInfo.androidiconUrl
                }
                let index = iosOran.indexOf('=');
                let softId = iosOran.substring(index + 1)
                this.passwordObj.softId = softId;
                return
              }
            }
            }
          }else{
            if(res.data.info) {
              if(res.data.info.fittype == "Android") {
                if(res.code == 108 && (res.data.info.openPassword != 0 || res.data.info.actcode == 1 || res.data.info.remarks4 == 1)) {
                  this.status = 8;
                  if (res.data.info.openPassword == 1) {
                    this.controlState = 0;
                  }else if(res.data.info.actcode == 1) {
                    this.controlState = 1;
                  }
                  this.passwordObj.iconUrl = res.data.info.iconurl;
                  this.passwordObj.softName = res.data.info.softname;
                  this.passwordObj.version = res.data.info.versionnum;
                  this.passwordObj.actCode = res.data.info.actcode;
                  this.passwordObj.softId = res.data.info.softId;
                  return
                  }
                }else{
                  if(res.code == 108 && (res.data.mergeInfo.openPassword != 0  || res.data.mergeInfo.actcode == 1 || res.data.mergeInfo.remarks4 == 1)) {
                    this.status = 8;
                    if (res.data.mergeInfo.openPassword == 1) {
                      this.controlState = 0;
                    }else if(res.data.mergeInfo.remarks4 == 1) {
                      this.controlState = 1;
                    }
                    console.log(this.controlState)
                    this.passwordObj.iconUrl = res.data.mergeInfo.iconurl;
                    this.passwordObj.softName = res.data.mergeInfo.softname;
                    this.passwordObj.version = res.data.mergeInfo.versionnum;
                    this.passwordObj.actCode = res.data.mergeInfo.actcode;
                    let iosOran = '';
                    if(this.downFlag) {
                      iosOran = res.data.mergeInfo.iosiconUrl
                    }else{
                      iosOran = res.data.mergeInfo.androidiconUrl
                    }
                    let index = iosOran.indexOf('=');
                    let softId = iosOran.substring(index + 1)
                    this.passwordObj.softId = softId;
                    return
                  }
                }
            }

          }
 
          if (res.code == 108) {
            // alert(22222)
            let url = "";
            if (this.downFlag) {
              url = res.data.mergeInfo.iosiconUrl;
            } else {
              url = res.data.mergeInfo.androidiconUrl;
            }
            console.log(url)
            this.$router.push({
              path: "/templateMerge",
              query: {
                softId: url.split("?")[1].split("=")[1],
              },
            });
          }
          this.flagStatus = true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
