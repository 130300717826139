<template>
	<div>
		<el-dialog v-model="dialogVisible" title="新增邀请码数量" center width="460px" :close-on-click-modal="false"
			:before-close="handleClose">
			<div class="dialog_center">
				<el-radio-group v-model="form.num">
					<el-radio-button label="10">新增10个</el-radio-button>
					<el-radio-button label="50">新增50个</el-radio-button>
					<el-radio-button label="100">新增100个</el-radio-button>
				</el-radio-group>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" v-loading="loading" @click="addInviteCode">立即添加</el-button>
				</span>
			</template>
		</el-dialog>
		<div class="w">
			<div class="header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/home' }">悟空在线
					</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/appManagement' }">应用管理</el-breadcrumb-item>
					<el-breadcrumb-item :to="productUrl">产品名称</el-breadcrumb-item>
					<el-breadcrumb-item>邀请码详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="content">
				<div class="content_header">
					<div class="content_title">邀请码详情</div>
					<div>
						<el-button type="primary" @click="exportlist()" v-loading.fullscreen.lock="fullscreenLoading">
							批量导出</el-button>
						<el-button type="primary" @click="search('')">全部</el-button>
						<el-button @click="search(0)">未使用</el-button>
						<el-button @click="search(1)">已使用</el-button>
						<el-input placeholder="请输入内容" v-model="inviteCode" style="width: 300px; margin: 0 10px">
							<template #append>
								<el-button icon="el-icon-search" @click="searchContent"></el-button>
							</template>
						</el-input>
						<el-button type="primary" @click="add">新增邀请码</el-button>
					</div>
				</div>
				<div class="content_table">
					<el-table :data="tableData" height="520" border v-loading="loading">
						<el-table-column type="index" label="序号" width="55" align="center">
						</el-table-column>
						<el-table-column prop="inviteCode" label="邀请码" align="center">
						</el-table-column>
						<el-table-column prop="udid" label="绑定UDID" align="center">
							<template #default="scope">
								<span v-if="scope.row.udid">{{ scope.row.udid }}</span>
								<span v-else>使用后绑定udid</span>
							</template>
						</el-table-column>
						<el-table-column label="邀请链接" align="center">
							<template #default="scope">
								<span :id="'copyBody' + scope.row.id">
									{{
                    "https://wkzx.wang/invitation/" + scope.row.inviteCode
                  }}</span>
								<br />
								<span class="copy_obj" @click="copy" data-clipboard-action="copy"
									:data-clipboard-target="'#copyBody' + scope.row.id">复制</span>
							</template>
						</el-table-column>
						<el-table-column prop="software.softName" label="应用名称" align="center">
						</el-table-column>
						<el-table-column prop="modifyDate" label="激活时间" align="center">
						</el-table-column>
						<el-table-column prop="createDate" label="创建时间" align="center">
						</el-table-column>
						<el-table-column prop="state" label="状态" align="center">
							<template #default="scope">
								<span v-if="scope.row.state == 0" style="color: #666666">未使用</span>
								<span v-if="scope.row.state == 1" style="color: #ff5e2b">已使用</span>
								<span v-if="scope.row.state == 2" style="color: #0092ff">已激活</span>
							</template>
						</el-table-column>
						<el-table-column prop="switchState" label="开关" align="center">
							<template #default="scope">
								<el-switch v-model="scope.row.switchState" @change="switchChange(scope.row)"
									:active-value="1" :inactive-value="0"></el-switch>
							</template>
						</el-table-column>
					</el-table>
					<paging-template :currentPage="currentPage" :pageSize="pageSize" :total="total"
						@pageSizeChange="pageSizeChange" @currentChange="currentChange"></paging-template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Clipboard from "clipboard";
	import {
		exportMethod
	} from '@/utils/request.js'
	import {
		defineComponent
	} from "vue";
	import {
		ElConfigProvider
	} from "element-plus";
	import zhCn from "element-plus/lib/locale/lang/zh-cn";
	import {
		inviteList,
		changState,
		addInvite,
		exportinviteList
	} from "@/api/api";
	import pagingTemplate from '@/components/common/pagingTemplate.vue'
	export default defineComponent({
		name: "inviteCodeDetail",
		components: {
			pagingTemplate
		},
		setup() {
			return {
				ElConfigProvider,
				locale: zhCn,
			};
		},
		data() {
			return {
				searchStatus: '',
				locale: zhCn,
				total: null,
				pageSize: 20,
				currentPage: 1,
				dialogVisible: false,
				tableData: [],
				loading: false,
				softId: "",
				inviteCode: null,
				productUrl: "",
				form: {
					num: "10",
					softId: "",
				},
				fullscreenLoading: false, //加载状态
			};
		},
		created() {
			this.softId = this.$route.query.softId;
			this.form.softId = this.$route.query.softId;
			this.productUrl = {
				path: "/editApp",
				query: {
					softId: this.softId,
				},
			};
			this.getinviteList();
		},
		methods: {
			pageSizeChange(val) {
				this.pageSize = val
			},
			currentChange(val) {
				this.loading = true;
				inviteList({
					softId: this.softId,
					inviteCode: this.inviteCode,
					state: this.searchStatus,
					pageNum: val,
					pageSize: this.pageSize
				}).then((res) => {
					this.loading = false;
					if (res.code == 0) {
						this.total = res.data.InviteCode.total
						this.currentPage = res.data.InviteCode.pageNum
						this.tableData = res.data.InviteCode.list;
					}
				});
			},
			add() {
				this.dialogVisible = true;
			},
			//批量导出
			exportlist() {
				this.fullscreenLoading = true;
				console.log("进入导出方法")
				// this.$message.success("导出数据中,请稍等!");
				exportinviteList({
					softId: this.softId,
					state: this.searchStatus
				}).then((res) => {
					if (res.code == 1) {
						this.$message.error("请求异常，请重试!");
						this.fullscreenLoading = false;
					} else if (res.code == 0) {
						const link = document.createElement('a')
						link.style.display = 'none'
						link.href = res.data.url;
						document.body.appendChild(link)
						link.click()
						document.body.removeChild(link)
						this.$message.success("导出成功!");
						this.fullscreenLoading = false;
					}

				});
			},
			// 添加邀请码
			addInviteCode() {
				this.loading = true;
				addInvite(this.form).then((res) => {
					if (res.code == 0) {
						this.getinviteList();
						this.dialogVisible = false;
						this.$message.success(res.msg);
						this.loading = false;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			handleClose() {
				this.dialogVisible = false;
			},
			// 复制
			copy() {
				let clipboard = new Clipboard(".copy_obj");
				clipboard.on("success", (e) => {
					console.log(e);
					clipboard.destroy();
					return this.$message.success("复制成功");
				});
				clipboard.on("error", (e) => {
					console.log(e);
					// 不支持复制
					clipboard.destroy();
					return this.$message.error("该浏览器不支持复制");
				});
			},
			searchContent() {
				this.getinviteList();
			},
			// 按钮查找
			search(val) {
				this.searchStatus = val
				this.getinviteList(val);
			},
			// 更改开关状态
			switchChange(val) {
				changState({
					id: val.id,
					state: val.switchState,
					switchState: val.state,
				}).then((res) => {
					if (res.code == 0) {
						this.getinviteList();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// 获取列表数据
			getinviteList(val) {
				this.loading = true;
				inviteList({
					softId: this.softId,
					inviteCode: this.inviteCode,
					state: val,
					pageNum: this.currentPage,
					pageSize: this.pageSize
				}).then((res) => {
					this.loading = false;
					if (res.code == 0) {
						// console.log(res)
						this.total = res.data.InviteCode.total
						this.currentPage = res.data.InviteCode.pageNum
						this.pageSize = res.data.InviteCode.pageSize
						this.tableData = res.data.InviteCode.list;
					}
				});
			},
		},
	})
</script>

<style lang="scss" scoped>
	.fenye {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}

	.dialog_center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.copy_obj {
		width: 44px;
		cursor: pointer;
		height: 21px;
		line-height: 21px;
		text-align: center;
		border-radius: 4px;
		display: inline-block;
		background: #e5f1fa;
	}

	.header {
		padding: 20px 0;
		font-size: 12px;
		color: #666666;
	}

	.content {
		// height: 644px;
		background: #ffffff;
		border-radius: 10px;
		margin-bottom: 40px;
		box-sizing: border-box;
		padding: 30px;

		.content_header {
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			margin-bottom: 30px;
		}

		.content_title {
			font-weight: bold;
			font-size: 22px;
			color: #333;
			font-family: PingFang SC;
		}
	}

	::v-deep .el-dialog {
		border-radius: 20px;
	}
</style>
