<template>
	<div>
		<div class="mobile" v-if="this.is_mobiState"><mobile-install-assistant></mobile-install-assistant></div>
		<div class="pc" v-if="!this.is_mobiState">
			<div class="content">
				<el-dialog v-model="erweiVisible" title="扫描下面二维码" center width="400px" :close-on-click-modal="false" :before-close="erweiClose">
					<div class="erwei_box">
						<div id="qrCodeErWei" ref="qrCodeDivErWei"></div>
						<div class="erwei_font">二维码自生成后一个小时内有效，请尽快下载！</div>
						<span>{{ baseUrl }}super/{{ shortUrl }}</span>
					</div>
				</el-dialog>
				<div class="w1">
					<div class="banner"><img src="../../assets/image/install.png" /></div>
				</div>
				<div class="w2">
					<div class="box">
						<div class="tips">
							<span style="margin-right: 5px">问题说明</span>
							<i class="iconfont icon-wenhao" style="font-size: 10px;cursor: pointer" @click="exameDetail"></i>
						</div>
						<div class="box_title">制作流程</div>
						<div class="box_nav">登录 > 选择软件 > 获取UDID > 购买内测分发设备 > 开始签名</div>
						<el-form ref="form" :rules="rules" :model="form" v-loading="loading">
							<el-form-item prop="softId">
								<el-select v-model="form.softId" placeholder="选择要签名的软件" style="width: 500px" clearable filterable @change="softChange(form.softId)">
									<el-option v-for="item in softList" :key="item.softId" :label="item.softName" :value="item.softId"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item prop="UDID">
								<el-input placeholder="输入设备UDID，点击右侧获取" v-model="form.UDID" style="width: 500px">
									<template #append>
										<el-button @click="getUdid">获取UDID</el-button>
									</template>
								</el-input>
							</el-form-item>
						</el-form>
						<el-button type="primary" style="width: 200px" @click="submit">开始制作签名</el-button>
					</div>
					<div class="btn">
						<el-button @click="telClick">联系客服</el-button>
						<el-button @click="sellClick">购买设备</el-button>
					</div>
					<div class="bottom_box">
						<div class="bottom">
							<div @click="showMianze">
								<span class="el-dropdown-link">
									免责声明
									<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
							</div>
						</div>
						<div class="bottom_content" v-if="flag">
							<div style="font-weight: 700;color:#333333">请在使用超级签名服务前，仔细阅读并充分理解以下内容及条款：</div>
							<div>
								1. 您知晓并同意，您提交给卖家（悟空在线）的 App，
								不包括任何违反中国境内相关法律的内容，也不会用于违反中国境内相关法律的任何用途。否则，由此而出现的任何法律风险和后果，将由您自行全部承担；
							</div>
							<div>
								2. 您知晓并同意，您并不会将卖家（悟空在线）所提供的任何一项服务用于违反中国相关法律的用途，否则，由此而出现的所有法律后果，将由您自行全部承担；
							</div>
							<div>3. 您知晓并同意，您购买此服务的用途需符合苹果内测的所有规定，若违反相关规定产生的法律后果由您自行全部承担，我们只提供软件签名的技术服务；</div>
							<div>
								4. 您知晓并同意，您没有向悟空在线提交任何的虚假信息或材料，也没有故意隐瞒您的App中可能存在的违法内容。否则，由此出现的所有后果，将由您自行全部承担；
							</div>
							<div>
								5.
								您知晓并同意，苹果内测因受到苹果政策影响，在未来可能会存在被苹果撤销从而导致应用出现无法安装或闪退等情况，您同意并愿意独立承担该风险以及该风险导致的的后续一切损失；
							</div>
							<div>
								6. 您知晓并同意，您向我们提供的App（手机应用程序）保证您拥有完整独立权，包括但不限于外观设计、著作权、
								文本，图形，徽标，按钮图标，图像，音频剪辑，且不会被用于未经许可的任何有关产品或服务；
							</div>
							<div>
								7.
								我们作为第三方中立平台，不具有对您的内容进行审查的义务，但我们在认为有需要时，我们有权利对您上传的内容进行审查，审查结果及审查后的进一步动作可由我们进行自行裁定。您对此表示理解并同意，并愿意承担因审查出现的全部后果；
							</div>
							<div>8. UDID是每部苹果设备的唯一识别码，用A设备UDID制作的APP只能用A设备安装，其他设备无法安装。</div>
							<div>9. APP定制不会造成设备数据丢失、不会造成软硬件运行异常，请放心使用！设备主板维修可能会改变UDID，原UDID签过的APP将失效。</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mobileInstallAssistant from '@/views/mobilePage/mobileInstallAssistant.vue';
import { getSoftwareVip, makeSignature, checkEquipment, checkHelperStatus } from '@/api/api';
import QRCode from 'qrcodejs2';
export default {
	name: 'installAssistant',
	components: {
		mobileInstallAssistant
	},
	data() {
		return {
			rules: {
				UDID: [{ required: true, message: '请输入设备UDID', trigger: 'blur' }],
				softId: [{ required: true, message: '请选择要签名的软件', trigger: 'change' }]
			},
			form: {
				softId: '',
				softUrl: '',
				UDID: ''
			},
			loading: false,
			flag: false, // 判断声明显示与隐藏
			softList: [],
			stopCheckState: '', //停止轮询变量
			erweiVisible: false,
			baseUrl: BASE_GDOMAIN, //路径
			shortUrl: '' //短链接
		};
	},
	created() {
		this.getAppList();
	},
	computed: {
		is_mobiState() {
			return this.$store.state.is_mobi;
		}
	},
	methods: {
		erweiClose() {
			this.erweiVisible = false;
		},
		telClick() {
			this.$router.push('/contract?status=2');
		},
		exameDetail() {
			// this.$router.push('/installDoc/superQuest');
			window.location.href = 'https://support.qq.com/products/349734/blog-archive'
		},
		sellClick() {
			this.$router.push('/price');
		},
		getUdid() {
			this.$router.push('/installAssistant/udid');
		},
		submit() {
			this.loading = true;
			this.$refs.form.validate(valid => {
				if (valid) {
					checkEquipment(this.form).then(res => {
						if (res.code == 0) {
						} else {
							return this.$message.error('设备数不足');
						}
					});
					makeSignature(this.form).then(res => {
						this.loading = false;
						if (res.code == 0) {
							// this.$message.success(res.msg);
							this.stopCheckState = setInterval(() => {
								this.checkHelperStatus();
							}, 3000); //轮询查看进度
						} else {
							this.$message.error(res.msg);
						}
					});
				} else {
					this.loading = false;
				}
			});
		},
		//轮询查询状态
		checkHelperStatus() {
			let softId = this.form.softId;
			checkHelperStatus(this.form).then(res => {
				if (res.code == 0) {
					if (res.data.status == '3') {
						clearInterval(this.stopCheckState); //清除定时轮询
						this.shortUrl = res.data.shortUrl;
						const url = BASE_GDOMAIN + 'super/' + res.data.shortUrl;
						this.getErweiAuth(url);
					}
				}
			});
		},
		bindQRCode2(val) {
			new QRCode(this.$refs.qrCodeDivErWei, {
				text: val,
				width: 200,
				height: 200,
				colorDark: '#333333', //二维码颜色
				colorLight: '#ffffff', //二维码背景色
				correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
			});
		},
		// 获取二维码认证
		getErweiAuth(val) {
			this.erweiVisible = true;
			if (document.querySelector('#qrCodeErWei')) {
				document.querySelector('#qrCodeErWei').innerHTML = '';
			}
			setTimeout(() => {
				this.bindQRCode2(val);
			}, 200);
		},
		softChange(val) {
			console.log(val);
			let userInfo = JSON.parse(localStorage.getItem('userInfo'));
			console.log(userInfo + '-----------------');
			if (userInfo == null || userInfo == '' || userInfo == undefined) {
				return this.$router.push('/login');
			}
			this.softList.forEach(item => {
				if (item.softId == val) {
					this.form.softUrl = item.softUrl;
				}
			});
		},
		getAppList() {
			getSoftwareVip().then(res => {
				if (res.data != null) {
					this.softList = res.data.software;
				}
			});
		},
		// 显示免责声明
		showMianze() {
			this.flag = !this.flag;
		}
	}
};
</script>
<style lang="scss" scoped>
.el-dropdown-link {
	cursor: pointer;
}
.el-dropdown-link:hover {
	color: #0092ff;
}
.bottom_box {
	margin-bottom: 50px;
}
.bottom_content {
	background: #e9eff3;
	padding: 20px;
	font-size: 12px;
	color: #999999;
	line-height: 24px;
}
.content {
	.w1 {
		width: 800px;
		margin: auto;
		.banner {
			margin: 20px 0;
			img {
				width: 100%;
				height: 270px;
			}
		}
	}
	.w2 {
		width: 620px;
		margin: auto;
		.bottom {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 30px;
			border-radius: 4px;
			background: #fff;
		}
		.btn {
			display: flex;
			justify-content: space-between;
			margin: 10px 0 20px 0;
		}
		.box {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: hidden;
			width: 100%;
			height: 344px;
			background: #fff;
			border-radius: 10px;
			.tips {
				position: absolute;
				top: 15px;
				right: 20px;
				font-size: 12px;
				color: #999999;
			}
			.box_title {
				margin-top: 30px;
				margin-bottom: 20px;
				font-size: 22px;
				font-weight: 700;
			}
			.box_nav {
				font-size: 14px;
				color: #666;
				margin-bottom: 30px;
			}
		}
	}
}
.erwei_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.erwei_font {
	text-align: center;
	margin: 20px auto;
}
</style>
