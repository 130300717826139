<template>
  <div>
    <div class="mobile" v-if="this.is_mobiState">
      <mobile-price></mobile-price>
    </div>
    <div class="pc" v-if="!this.is_mobiState">
      <div class="content">
        <el-dialog
          v-model="erweiVisible"
          :close-on-click-modal="false"
          title="扫码付款"
          center
          width="400px"
          :before-close="erweiClose"
        >
          <div class="erwei_box">
            <div id="qrCodeErWei" ref="qrCodeDivErWei"></div>
            <div class="erwei_font">请使用需要绑定的设备<br />进行扫描付款</div>
          </div>
        </el-dialog>
        <div class="w">
          <div class="content_title">价格方案</div>

          <div class="content_header">
            <div class="header_title">
              <div
                style="font-weight: bold; font-size: 22px; margin-bottom: 30px"
              >
                流量包
              </div>
              <div
                style="
                  font-size: 14px;
                  color: #ff4a4a;
                  line-height: 29px;
                  cursor: pointer;
                "
                @click="exameClick(1)"
              >
                常见问题及扣除规则
              </div>
            </div>
            <div class="no_list" v-if="packageList.length < 1">
              暂无可购买方案
            </div>
            <div class="header_body">
              <div style="width: 1140px; display: flex">
                <div
                  class="body_item"
                  v-for="item in packageList"
                  :key="item.id"
                  @click="packageClick(item)"
                >
                  <div class="body_item_hr"></div>
                  <div class="body_item_content">
                    <div
                      style="
                        margin: 20px 0 0 0;
                        text-align: center;
                        font-size: 22px;
                        font-weight: bold;
                        color: #333;
                      "
                    >
                      {{ item.downnum }}点
                    </div>
                    <div v-show="item.presentnum > 0" style="font-size: 12px; color: red; text-align: center;">
                      (赠送{{ item.presentnum }}点)
                    </div>
                    <!-- <div
                      style="
                        font-size: 12px;
                        color: #666666;
                        text-align: center;
                      "
                    >
                      累计下载流量
                    </div> -->
                  </div>
                  <div class="body_item_footer">
                    <div :class="item.style">
                      <span
                        style="
                          font-size: 14px;
                          font-weight: bold;
                          margin-right: 5px;
                        "
                        >¥</span
                      >
                      <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 0">{{
                        item.totalPrice
                      }}</span>
                      <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 1 ">{{
                        item.discount
                      }}</span>
                      <span class="sellFree" v-show="item.openDiscount == 1 && (item.totalPrice != item.discount)">¥{{ item.totalPrice }}</span>
                    </div>
                    <div
                      class="body_item_btn"
                      v-if="!item.style"
                      @click="packageSell(item)"
                    >
                      购买
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="body_item_tips">
			          优先消耗免费流量，流量包不受时间限制，大陆地区每100兆（私有池每50兆）扣一点，海外以及港澳台地区每20兆（私有池每10兆）扣一点。
			        </div> -->
              <div class="body_item_tips">
                优先消耗赠送流量，流量包点数不受时间限制，根据上传文件大小扣除，大陆地区分发每60M多扣一点，海外以及港澳台地区每20M多扣一点。
                （超级签由于技术的特殊性，每20M多抠一点）
              </div>
            </div>
          </div>

          <div class="content_middle">
            <div class="header_title">
              <div
                style="font-weight: bold; font-size: 22px; margin-bottom: 30px"
              >
                购买设备数量
              </div>
              <div
                style="
                  font-size: 14px;
                  color: #ff4a4a;
                  line-height: 29px;
                  cursor: pointer;
                "
                @click="exameClick(2)"
              >
                内测分发常见问题
              </div>
            </div>
            <div class="content_tips">
              <span class="tips_1">公有池设备 · 按台数购买</span>
              <span class="tips_2">设备掉签会消耗新的设备数。</span>
            </div>
            <div class="no_list" v-if="publicList.length < 1">
              暂无可购买方案
            </div>
            <div class="common_tai_box">
              <div style="width: 1140px; display: flex">
                <div
                  class="body_item"
                  v-for="item in publicList"
                  :key="item.id"
                  @click="publicClick(item)"
                >
                  <div class="body_item_hr"></div>
                  <div class="body_item_content">
                    <div
                      style="
                        margin: 20px 0 0 0;
                        text-align: center;
                        font-size: 22px;
                        font-weight: bold;
                        color: #333;
                      "
                    >
                      {{ item.deviceNum ? item.deviceNum : 0 }} 台
                    </div>
                    <div v-show="item.presentnum > 0" style="font-size: 12px; color: red; text-align: center;">
                      (赠送{{ item.presentnum }}台)
                    </div>
                    <div
                      style="
                        font-size: 12px;
                        color: #666666;
                        text-align: center;
                        margin-top: 5px;
                      "
                    >
                      设备数量
                    </div>
                  </div>
                  <div class="body_item_footer">
                    <div :class="item.style">
                      <span
                        style="
                          font-size: 14px;
                          font-weight: bold;
                          margin-right: 5px;
                        "
                        >¥</span
                      >
                      <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 0">{{
                        item.totalPrice
                      }}</span>
                      <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 1">{{
                        item.discount
                      }}</span>
                      <span class="sellFree" v-show="item.openDiscount == 1 && (item.totalPrice != item.discount)">¥{{ item.totalPrice }}</span>
                    </div>
                    <div
                      class="body_item_btn"
                      v-if="!item.style"
                      @click="publicSell(item)"
                    >
                      购买
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content_tips">
              <span class="tips_1">公有池设备 · 按时间购买</span>
              <span class="tips_2">有效期内同一台设备掉签免费补签</span>
            </div>
            <div class="no_list" v-if="yearList.length < 1">暂无可购买方案</div>
            <div class="common_box">
              <div style="width: 1140px; display: flex">
                <div
                  class="body_item"
                  v-for="item in yearList"
                  :key="item.id"
                  @click="yearClick(item)"
                >
                  <div class="body_item_hr"></div>
                  <div class="body_item_content">
                    <div
                      style="
                        margin: 20px 0 0 0;
                        text-align: center;
                        font-size: 22px;
                        font-weight: bold;
                        color: #333;
                      "
                    >
                      <span v-if="item.remark3 == 1">一年/台</span>
                      <span v-if="item.remark3 == 2">6个月/台</span>
                      <span v-if="item.remark3 == 3">3个月/台</span>
                      <span v-if="item.remark3 == 4">一个月/台</span>
                    </div>
                    <div v-show="item.presentnum > 0" style="font-size: 12px; color: red; text-align: center;">
                      (赠送{{ item.presentnum }}台)
                    </div>
                    <div
                      style="
                        font-size: 12px;
                        color: #666666;
                        text-align: center;
                        margin-top: 5px;
                      "
                    >
                      设备数量
                    </div>
                  </div>
                  <div class="body_item_footer">
                    <div :class="item.style">
                      <span
                        style="
                          font-size: 14px;
                          font-weight: bold;
                          margin-right: 5px;
                        "
                        >¥</span
                      >
                      <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 0">{{
                        item.totalPrice
                      }}</span>
                      <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 1">{{
                        item.discount
                      }}</span>
                      <span class="sellFree" v-show="item.openDiscount == 1 && (item.totalPrice != item.discount)">¥{{ item.totalPrice }}</span>
                    </div>
                    <div
                      class="body_item_btn"
                      v-if="!item.style"
                      @click="yearSell(item)"
                    >
                      购买
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="content_tips">
			        <span class="tips_1">私有池设备</span>
			        <span class="tips_2"
			          >用户自己提供开发者账号，如果您没有苹果开发者账号，请勿购买！<span
			            @click="applyApple"
			            style="color: #0092FF;cursor: pointer"
			            >申请苹果开发者账号</span
			          ></span
			        >
			      </div>
            <div class="no_list" v-if="privateList.length < 1">
              暂无可购买方案
            </div>
			      <div class="close_box">
			        <div style="width: 1140px;">
			          <div
			            class="body_item"
			            v-for="item in privateList"
			            :key="item.id"
			            @click="privateClick(item)"
			          >
			            <div class="body_item_hr"></div>
			            <div class="body_item_content">
			              <div
			                style="margin: 20px 0; text-align: center;font-size: 22px;font-weight: bold;color:#333"
			              >
			                {{ item.deviceNum }} 台
			              </div>
			              <div style="font-size: 12px;color: #666666;text-align: center">
			                设备数量
			              </div>
			            </div>
			            <div class="body_item_footer">
			              <div :class="item.style">
			                <span style="font-size: 14px;font-weight: bold;margin-right: 5px">¥</span>
			                <span style="font-size: 22px;font-weight: bold;">{{
			                  item.totalPrice
			                }}</span>
			              </div>
			              <div
			                class="body_item_btn"
			                v-if="!item.style"
			                @click="prviateSell(item)"
			              >
			                购买
			              </div>
			            </div>
			          </div>
			        </div>
			      </div> -->

            
          </div>
          <div class="content_header">
              <div class="header_title">
                <div
                  style="
                    font-weight: bold;
                    font-size: 22px;
                    margin-bottom: 30px;
                  "
                >
                  存储空间
                </div>
                
              </div>
              <div class="no_list" v-if="spaceList.length < 1">
                暂无可购买方案
              </div>
              <div class="header_body2">
                <div style="width: 1140px; display: flex">
                  <div
                    class="body_item"
                    v-for="item in spaceList"
                    :key="item.id"
                    @click="spaceClick(item)"
                  >
                    <div class="body_item_hr"></div>
                    <div class="body_item_content">
                      <div
                        style="
                          margin: 20px 0 0 0;
                          text-align: center;
                          font-size: 22px;  
                          font-weight: bold;
                          color: #333;
                        "
                      >
                        {{ item.deviceNum }}G
                      </div>
                      <div v-show="item.presentnum > 0" style="font-size: 12px; color: red; text-align: center;">
                        <!-- (赠送{{ item.presentnum > 1024 ? ((item.presentnum / 1024) + 'G'): (item.presentnum + 'M')}}) -->
                        (赠送{{ item.presentnum }}G)
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          color: #666666;
                          text-align: center;
                          margin-top: 5px;
                        "
                      >
                        1年有效期
                      </div>
                    </div>
                    <div class="body_item_footer">
                      <div :class="item.style">
                        <span
                          style="
                            font-size: 14px;
                            font-weight: bold;
                            margin-right: 5px;
                          "
                          >¥</span
                        >
                        <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 0">{{
                          item.totalPrice
                        }}</span>
                        <span style="font-size: 22px; font-weight: bold;" v-show="item.openDiscount == 1">{{
                          item.discount
                        }}</span>
                        <span class="sellFree" v-show="item.openDiscount == 1 && (item.totalPrice != item.discount)">¥{{ item.totalPrice }}</span>
                      </div>
                      <div
                        class="body_item_btn"
                        v-if="!item.style"
                        @click="spaceSell(item)"
                      >
                        购买
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="body_item_tips">
			          优先消耗免费流量，流量包不受时间限制，大陆地区每100兆（私有池每50兆）扣一点，海外以及港澳台地区每20兆（私有池每10兆）扣一点。
			        </div> -->
                <div class="body_item_tips">
                  空间默认5G存储，超过了需要购买存储空间
                </div>
              </div>
            </div>
          <div class="content_footer">
            企业客户需要批量购买方案，私有定制方案，请联系微信：<span
              style="color: #0092ff"
              >wkzx2020</span
            >
          </div>
        </div>
        <sell-red
          v-if="redVisible"
          :dialogVisible="redVisible"
          @redChange="redChange"
          :redObj="redObj"
        ></sell-red>
        <sell-blue
          v-if="blueVisible"
          :dialogVisible="blueVisible"
          @blueChange="blueChange"
          :blueObj="blueObj"
        ></sell-blue>
        <sell-yellow
          v-if="yellowVisible"
          :dialogVisible="yellowVisible"
          @yellowChange="yellowChange"
          :yellowObj="yellowObj"
        ></sell-yellow>
        <sell-green
          v-if="greenVisible"
          :dialogVisible="greenVisible"
          @greenChange="greenChange"
          :greenObj="greenObj"
        ></sell-green>
        <sell-violet
          v-if="violetVisible"
          :dialogVisible="violetVisible"
          @violetChange="violetChange"
          :violetObj="violetObj"
        ></sell-violet>
      </div>
    </div>
  </div>
</template>

<script>
import mobilePrice from "@/views/mobilePage/mobilePrice.vue";
import sellRed from "@/views/price/common/sellRed";
import sellBlue from "@/views/price/common/sellBlue";
import sellGreen from "@/views/price/common/sellGreen";
import sellYellow from "@/views/price/common/sellYellow";
import sellViolet from "@/views/price/common/sellViolet";
import { getPrice, createUserMobile } from "@/api/api";
import QRCode from "qrcodejs2";
export default {
  name: "price",
  components: {
    sellBlue,
    sellViolet,
    sellGreen,
    sellYellow,
    sellRed,
    mobilePrice,
  },
  data() {
    return {
      blueVisible: false,
      yellowVisible: false,
      redVisible: false,
      greenVisible: false,
      violetVisible: false,
      erweiVisible: false,
      priceList: [],
      wxOrderId: "", // 微信订单号
      dialogVisible: false,
      packageList: [],
      spaceList: [], // 空间列表
      privateList: [],
      publicList: [],
      yearList: [],
      blueObj: {},
      redObj: {},
      yellowObj: {},
      greenObj: {},
      violetObj: {},
    };
  },
  mounted() {
    this.getPriceList();
    console.log(this.is_mobiState);
  },
  computed: {
    is_mobiState() {
      return this.$store.state.is_mobi;
    },
  },
  methods: {

    erweiClose() {
      this.erweiVisible = false;
    },
    // 文档跳转
    exameClick(val) {
      this.$store.commit("navActiveChange", 7);
      if (val == 1) {
        this.$router.push("/installDoc/list1");
        // window.open("https://support.qq.com/products/349734/faqs/105066");
      }
      if (val == 2) {
        this.$router.push("/installDoc/list2");
        // window.open("https://support.qq.com/products/349734/faqs/105069");
      }
    },
    blueChange(val) {
      this.blueVisible = val;
    },
    redChange(val) {
      this.redVisible = val;
    },
    yellowChange(val) {
      this.yellowVisible = val;
    },
    greenChange(val) {
      this.greenVisible = val;
    },
    violetChange(val) {
      this.violetVisible = val;
    },
    // 申请苹果账号
    applyApple() {
      window.open("https://developer.apple.com/cn/support/app-account/");
    },
    // 流量点击购买
    packageSell(val) {
      this.blueObj = val;
      this.blueVisible = true;
    },
    // 空间购买
    spaceSell(val) {
      this.redObj = val;
      this.redVisible = true;
    },
    publicSell(val) {
      console.log(val);
      this.greenObj = val;
      this.greenVisible = true;
    },
    yearSell(val) {
      console.log(val);
      // this.yellowObj = val
      // this.yellowVisible = true;
      this.getErweiAuth(val);
    },
    prviateSell(val) {
      console.log(val);
      this.violetObj = val;
      this.violetVisible = true;
    },
    privateClick(val) {
      this.privateList.forEach((item) => {
        if (item.id == val.id) {
          item.style = "";
        } else {
          item.style = "body_footer_title";
        }
      });
      this.yearList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.packageList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.publicList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.spaceList.forEach((item) => {
        item.style = "body_footer_title";
      });
    },
    bindQRCode2(val) {
      new QRCode(this.$refs.qrCodeDivErWei, {
        text: val,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    // 获取二维码认证
    getErweiAuth(val) {
      this.erweiVisible = true;
      if (document.querySelector("#qrCodeErWei")) {
        document.querySelector("#qrCodeErWei").innerHTML = "";
      }
      // this.dialogVisible = false
      createUserMobile({ orderPriceId: val.id }).then((res) => {
        if (res.code == 0) {
          setTimeout(() => {
            this.bindQRCode2(res.data.data);
          }, 200);
        }
      });
    },
    yearClick(val) {
      this.yearList.forEach((item) => {
        if (item.id == val.id) {
          item.style = "";
          console.log("==================" + val.id);
        } else {
          item.style = "body_footer_title";
        }
      });
      this.privateList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.packageList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.publicList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.spaceList.forEach((item) => {
        item.style = "body_footer_title";
      });
    },
    publicClick(val) {
      this.publicList.forEach((item) => {
        if (item.id == val.id) {
          item.style = "";
        } else {
          item.style = "body_footer_title";
        }
      });
      this.privateList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.packageList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.yearList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.spaceList.forEach((item) => {
        item.style = "body_footer_title";
      });
    },
    // 空间
    spaceClick(val) {
      this.spaceList.forEach((item) => {
        if (item.id == val.id) {
          item.style = "";
        } else {
          item.style = "body_footer_title";
        }
      });
      this.privateList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.publicList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.yearList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.packageList.forEach((item) => {
        item.style = "body_footer_title";
      });
    },
    // 流量移入
    packageClick(val) {
      this.packageList.forEach((item) => {
        if (item.id == val.id) {
          item.style = "";
        } else {
          item.style = "body_footer_title";
        }
      });
      this.privateList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.publicList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.yearList.forEach((item) => {
        item.style = "body_footer_title";
      });
      this.spaceList.forEach((item) => {
        item.style = "body_footer_title";
      });
    },
    getPriceList() {
      getPrice().then((res) => {
        if (res.code == 0) {
          this.packageList = res.data.packageList;
          this.packageList.forEach((item, index) => {
            if(index === 0) {
              item.style = "";
            } else {
              item.style = "body_footer_title";
            }
            
          });
          this.privateList = res.data.privateList;
          this.privateList.forEach((item, index) => {
            if(index === 0) {
              item.style = "";
            } else {
              item.style = "body_footer_title";
            }
          });
          this.publicList = res.data.publicList;
          this.publicList.forEach((item, index) => {
            if(index === 0) {
              item.style = "";
            } else {
              item.style = "body_footer_title";
            }
          });
          this.yearList = res.data.yearList;
          this.yearList.forEach((item, index) => {
            if(index === 0) {
              item.style = "";
            } else {
              item.style = "body_footer_title";
            }
          });
          this.spaceList = res.data.spaceList;
          this.spaceList.forEach((item, index) => {
            if(index === 0) {
              item.style = "";
            } else {
              item.style = "body_footer_title";
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no_list {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #666666;
  margin: 50px 0;
}
.content_footer {
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  text-align: center;
  margin-bottom: 30px;
}
.content_tips {
  width: 100%;
  margin-bottom: 30px;
  .tips_1 {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    margin-right: 15px;
  }
  .tips_2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #ffb450;
  }
}
.content_middle {
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  margin: 20px 0 30px 0;
  box-sizing: border-box;
  padding: 30px;
}
.body_item_tips {
  margin-top: 20px;
  font-size: 14px;
  color: #999999;
}
.body_item_footer {
  position: relative;
  color: #ff4a4a;
  text-align: center;
  height: 100%;
  height: 71px;
  //   line-height: 71px;
}

.close_box {
  margin-bottom: 30px;
  .body_item:nth-child(5) {
    margin-right: -5px;
  }
  .body_item {
    float: left;
    width: 210px;
    height: 176px;
    overflow: hidden;
    border: 1px solid #ac40ff;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 23px;
    margin-bottom: 20px;
    .body_item_hr {
      height: 10px;
      background: #ac40ff;
    }
    .body_item_content {
      overflow: hidden;
      height: 95px;
      background: #eed9ff;
    }
    .body_item_btn {
      position: absolute;
      cursor: pointer;
      width: 100%;
      bottom: 0;
      height: 35px;
      background: #ac40ff;
      font-size: 16px;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      line-height: 35px;
    }
  }
}

.common_tai_box {
  margin-bottom: 30px;
  .body_item {
    width: 210px;
    height: 176px;
    overflow: hidden;
    border: 1px solid #009e61;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 23px;
    .body_item_hr {
      height: 10px;
      background: #009e61;
    }
    .body_item_content {
      overflow: hidden;
      height: 95px;
      background: #ccecdf;
    }
    .body_item_btn {
      position: absolute;
      cursor: pointer;
      width: 100%;
      bottom: 0;
      height: 35px;
      background: #009e61;
      font-size: 16px;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      line-height: 35px;
    }
  }
}
.common_box {
  margin-bottom: 30px;
  .body_item {
    width: 210px;
    height: 176px;
    overflow: hidden;
    border: 1px solid #ffcc00;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 23px;
    .body_item_hr {
      height: 10px;
      background: #ffcc00;
    }
    .body_item_content {
      overflow: hidden;
      height: 95px;
      background: #fff5cc;
    }
    .body_item_btn {
      position: absolute;
      cursor: pointer;
      width: 100%;
      bottom: 0;
      height: 35px;
      background: #ffcc00;
      font-size: 16px;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      line-height: 35px;
    }
  }
}
.body_footer_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header_body {
  margin-bottom: 30px;

  .body_item_btn {
    position: absolute;
    cursor: pointer;
    width: 100%;
    bottom: 0;
    height: 35px;
    background: #0098dd;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    line-height: 35px;
  }
  .body_item {
    width: 210px;
    height: 176px;
    overflow: hidden;
    border: 1px solid #0098dd;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 23px;
    .body_item_hr {
      height: 10px;
      background: #0098dd;
    }
    .body_item_content {
      overflow: hidden;
      height: 95px;
      background: #cceaf8;
    }
  }
}
.sellFree {
  padding: 2px 5px; 
  color: #606266; 
  background: #ECECEC;
  border-radius: 10px; 
  margin-left: 5px; 
  text-decoration: line-through;
}

.header_body2 {
  margin-bottom: 30px;

  .body_item_btn {
    position: absolute;
    cursor: pointer;
    width: 100%;
    bottom: 0;
    height: 35px;
    background: red;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    line-height: 35px;
  }
  .body_item {
    width: 210px;
    height: 176px;
    overflow: hidden;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 23px;
    .body_item_hr {
      height: 10px;
      background: red;
    }
    .body_item_content {
      overflow: hidden;
      height: 95px;
      background: #ffccc9;
    }
  }
}
.header_title {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.content_header {
  height: 337px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 30px;
}
.content_title {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #333333;
  margin: 30px 0;
}
.erwei_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.erwei_font {
  text-align: center;
  margin: 20px auto;
}
</style>
