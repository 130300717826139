<template>
  <div class="dis">
    <div class="dis_box">
      <div class="logo">
        <img src="../../assets/image/logo.png" />
      </div>
      <div class="title">免责声明</div>
      <div class="content_font">
        悟空在线内测服务平台为第三方应用提供应用测试托管、应用崩溃检测等服务。应用内容均来源于第三方产品，悟空在线仅为用户提供内测支持，不涉及任何人工编辑和整理，也不对任何来源于第三方的内容（包括但不限于安装包安全、信息描述、应用截图）承担责任，用户可根据描述场景谨慎选择进行内测试用。
      </div>

      <div class="content_font" style="margin-top: 2.5rem">
        任何公司、产品或者个人认为悟空在线涉嫌侵犯您的版权或应用权，您应该及时向我们提供举报声明、书面权利通知，并提供身份证明、权属证明及侵权情况等投诉材料。我们将依法进行处理。
      </div>
    </div>
    <div class="footer">
      <div class="footer_box">
        <span
          style="margin: 0 0.5rem 0 0.5rem;cursor: pointer"
          @click="exemptionClick"
          >免责声明</span
        >
        <span style="color: #333">|</span>
        <span style="margin-left: 0.5rem;cursor: pointer" @click="reportClick"
          >举报</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    reportClick() {
      this.$router.push("/report");
    },
    exemptionClick() {
      this.$router.push("/disclaimer");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: rgba(229, 229, 229, 1);
  span {
    color: rgba(0, 169, 248, 1);
  }
}
.content_font {
  text-indent: 2em;
  font-size: 16px;
  color: #888888;
  line-height: 40px;
  font-family: 微软雅黑;
}
.dis {
  height: 100vh;
  background: #fff;
}
.dis_box {
  padding: 2rem 1rem;
  .logo {
    display: flex;
    height: 5rem;
    border-bottom: 1px solid #ccc;
    img {
      width: 15rem;
      height: 3rem;
    }
  }
  .title {
    font-size: 24px;
    color: #0098dd;
    font-family: 微软雅黑;
    margin: 2rem 0 2.5rem 0;
  }
}
</style>
