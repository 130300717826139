<template>
    <div>
      <el-dialog
        title="购买存储空间"
        v-model="visible"
        width="792px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div v-loading="sellLoading">
          <div class="sell_header1">
            <div class="sell_hr"></div>
            <div class="sell_title">
              {{ redObj.deviceNum }}G
            </div>
          </div>
          <div class="sell_footer">
            <div class="sell_left">
              <div class="sell_left_title">选择支付方式</div>
              <div class="sell_btn">
                <div :class="wxPaystyle" @click="wxPay">
                  <div>微信支付</div>
                  <img
                    v-show="wxPaystyle == 'sell_btn_wx_active'"
                    src="@/assets/image/success1.png"
                    style="width: 15px;height: 15px"
                  />
                </div>
                <div :class="aliPayStyle" @click="zhifuPay">
                  <div>支付宝支付</div>
                  <img
                    v-show="aliPayStyle == 'sell_btn_zfb_active'"
                    src="@/assets/image/success1.png"
                    style="width: 15px;height: 15px"
                  />
                </div>
              </div>
            </div>
            <div class="sell_erwei">
              <div
                id="qrCode"
                ref="qrCodeDiv"
                class="sell_erwei_box"
              ></div>
              <!--  v-loading="wxmaLoading" -->
            </div>
            <div class="sell_right">
              <div class="sell_right_title">支付金额</div>
              <div>
                <span v-if="wxPaystyle == 'sell_btn_wx_active'"> 微信支付</span>
                <span v-else>支付宝支付</span>
                <span
                style="font-size: 14px;color: #BA812F;font-weight: bold;margin-left: 10px"
                >¥<span style="font-size: 36px" v-show="redObj.openDiscount == 0">{{
                  redObj.totalPrice ? redObj.totalPrice : 0
                }}</span>
                <span v-show="redObj.openDiscount == 1" style="font-size: 36px">{{
                  redObj.discount ? redObj.discount : 0
                }}</span>
              </span>
              <span
                v-show="redObj.openDiscount == 1  && (redObj.totalPrice != redObj.discount)"
                class="sell_zhekou"
                style="display: inline-block; margin-left: 10px"
              >
                ¥{{ redObj.totalPrice ? redObj.totalPrice : 0 }}
              </span>
              </div>
              <el-button
                round
                size="mini"
                style="margin-top: 15px"
                @click="updatePay"
                >刷新</el-button
              >
              <span style="font-size: 14px;color: #FF4A4A;margin-left: 10px">
                <!-- {{tips}} -->
                </span>
            </div>
          </div>
          <div class="sell_tips">
            在购买存储空间时有任何疑问请点击<span style="cursor: pointer;color: #0092FF;text-decoration: underline" @click="seeUrl">《常见问题》</span>进行咨询！
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { aliPay, createNative, queryWXPayStatus,getAliPayResult } from "@/api/api";
  import QRCode from "qrcodejs2";
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      redObj: {
        type: Object,
      },
    },
    data() {
      return {
        // 微信支付样式
        wxPaystyle: "sell_btn_wx",
        aliPayStyle: "sell_btn_zfb",
        sellLoading: false,
        wxmaLoading: false,
        priceList: [],
        timer: null,
        wxOrderId: "", // 微信订单号
        price: {
          orderPriceId: "", // 选择价格id
          productType: "", // 类型 0.下载次数 1.公有设备 2.私有设备 3.webclip 4.个人购买下载时长
          payKey: "",
          orderId: "",
          userId: "",
        },
      };
    },
    created() {
      console.log(this.redObj)
      console.log(this.visible)
      this.price.payKey = this.guid();
      this.price.productType = this.redObj.producttype;
      this.wxPay()
    },
     beforeUnmount(){
        this.clearfun();
    },
    computed: {
      visible() {
        return this.dialogVisible
      }
    },
    methods: {
      seeUrl() {
        window.open('https://support.qq.com/products/349734/faqs/105066')
      },
      //用于生成uuid
      S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      },
      guid() {
        return (
          this.S4() +
          this.S4() +
          "-" +
          this.S4() +
          "-" +
          this.S4() +
          "-" +
          this.S4() +
          "-" +
          this.S4() +
          this.S4() +
          this.S4()
        );
      },
      // 购买流量
      handleClose() {
        this.clearfun()
        this.$emit("redChange", false);
      },
      bindQRCode(val) {
        new QRCode(this.$refs.qrCodeDiv, {
          text: val,
          width: 120,
          height: 120,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      },
      // 微信支付状态
      updatePay() {
        this.price.orderId = this.wxOrderId;
        this.wxmaLoading = true;
        if (this.wxPaystyle == "sell_btn_wx_active") {
          this.wxPayStatus();
        }
        if (this.aliPayStyle == "sell_btn_zfb_active") {
          this.aliPayStatus();
        }
      },
      // 微信支付状态
      wxPayStatus() {
        queryWXPayStatus(this.price).then((res) => {
          this.wxmaLoading = false;
          if (res.code != 0) {
            this.tips = res.msg;
          } else {
            this.clearfun();
             this.$emit("redChange", false);
            this.$message.success(res.msg);
          }
        });
      },
       // 支付宝支付状态
      aliPayStatus() {
        getAliPayResult({ orderNumber: this.price.orderId }).then((res) => {
          this.wxmaLoading = false;
          if (res.code != 0) {
            this.tips = res.msg;
          } else {
            this.clearfun();
            this.$emit("redChange", false);
            this.$message.success(res.msg);
          }
        });
      },
      // 微信支付
      wxPay() {
        this.sellLoading = true
        this.clearfun()
        this.wxPaystyle = "sell_btn_wx_active";
        this.aliPayStyle = "sell_btn_zfb";
        if(document.querySelector("#qrCode")) {
          document.querySelector("#qrCode").innerHTML = "";
        }
        
        this.price.orderPriceId = this.redObj.id;
        createNative(this.price).then((res) => {
          this.wxmaLoading = true;
          this.wxOrderId = res.data.out_trade_no;
          if (res.code == 0) {
            this.bindQRCode(res.data.code_url);
            this.sellLoading = false
            // setTimeout(() => {
            //   this.wxmaLoading = false;
            //   this.bindQRCode(res.data.code_url);
            // }, 500);
            this.timer = window.setInterval(() => {
              setTimeout(() => {
                this.updatePay();
              }, 0);
            }, 5000);
          }
        });
      },
      // 清除轮询
      clearfun() {
        clearInterval(this.timer);
        this.timer = null;
      },
      // 支付宝支付
      zhifuPay() {
        this.sellLoading = true
        this.clearfun()
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.wxPaystyle = "sell_btn_wx";
        this.aliPayStyle = "sell_btn_zfb_active";
        // this.price.orderPriceId = this.redObj.id;
        // let obj = {
        //   orderPriceId: this.redObj.id,
        //   productType: this.price.productType,
        //   payKey: this.guid()
        // }
        this.price.userId = userInfo[0].userId;
        document.querySelector("#qrCode").innerHTML = "";
        aliPay(this.price).then((res) => {
          this.wxmaLoading = true;
          this.wxOrderId = res.data.orderNumber;
          if (res.code == 0) {
            this.bindQRCode(res.data.aliPayQrCode);
            this.sellLoading = false
            // setTimeout(() => {
            //   this.wxmaLoading = false;
            //   this.bindQRCode(res.data.aliPayQrCode);
            // }, 500);
            this.timer = window.setInterval(() => {
              setTimeout(() => {
                this.updatePay();
              }, 0);
            }, 5000);
          }
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .sell_tips {
    margin-top: 20px;
  }
  .sell_header1 {
    overflow: hidden;
    height: 142px;
    line-height: 142px;
    text-align: center;
    background: #ffccc9;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 8px;
    .sell_hr {
      height: 10px;
      background: red;
    }
    .sell_title {
      font-weight: bold;
      font-size: 32px;
      color: #333333;
    }
  }
  .sell_erwei {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    .sell_erwei_box {
      width: 120px;
      height: 120px;
      background: #c4c4c4;
    }
  }
  .sell_right {
    width: 225px;
    .sell_right_title {
      font-weight: bold;
      font-size: 14px;
      color: #333;
      margin: 20px 0 10px 0;
    }
  }
  .sell_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    height: 210px;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 30px;
    .sell_left {
      width: 190px;
      .sell_left_title {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        text-align: center;
        margin-bottom: 20px;
      }
      .sell_btn {
        .sell_btn_wx_active {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          width: 190px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 10px;
          background: #09c965;
          color: #fff;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .sell_btn_wx {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          width: 190px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 10px;
          background: rgba(9, 201, 101, 0.2);
          border: 1px solid #09c965;
          box-sizing: border-box;
          color: #09c965;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .sell_btn_zfb {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          width: 190px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #0092ff;
          box-sizing: border-box;
          border-radius: 10px;
          background: rgba(0, 146, 255, 0.2);
          color: #0092ff;
          cursor: pointer;
        }
        .sell_btn_zfb_active {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;
          width: 190px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 10px;
          background: #0092ff;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

.sell_zhekou {
  border-radius: 46px;
  background: #ececec;
  width: 47px;
  padding: 2px;
  text-align: center;
  text-decoration: line-through;
}
  </style>
  