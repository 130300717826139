import axios from "axios";
// 导入 NProgress 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// window.BASE_GDOMAIN='https://test2.wkkc.vip/'    //测试环境
// window.BASE_GDOMAIN='https://wkzx.store/'        //正式环境
window.BASE_GDOMAIN=process.env.VUE_APP_BASE_GDOMAIN         //正式环境
// window.BASE_GDOMAIN='https://wx.wkzx.store'


const BASE_URL=process.env.VUE_APP_BASE_URL      //正式环境
// const BASE_URL='https://wx.wkzx.store/'          //测试环境
// const BASE_URL='https://test2.wkkc.vip/'          //测试环境

// const BASE_URL='http://47.105.38.95:8080/newwkzx/'
// const BASE_URL='http://127.0.0.1:8081/newwkzx/'

axios.defaults.baseURL = BASE_URL;
// 在 request 拦截器中，展示进度条 NProgress.start()
axios.interceptors.request.use((config) => {
  console.log(config)
  NProgress.start();
  if (config.url.indexOf('wkzxsoftapp') > -1) {
  }else{
    config.headers.Authorization = window.localStorage.getItem("token");
  }
  // 在最后必须 return config
  return config;
});
// 在 response 拦截器中，隐藏进度条 NProgress.done()
axios.interceptors.response.use((config) => {
  NProgress.done();
  return config;
});

export function request(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      headers: {
        'Authorization': window.localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded"
      },
      timeout: 100000,
    });
    instance(options)
      .then((response) => {
        if (response.status == "200") {
          if (response.status == "200") {
            resolve(response.data);
          } else {
            reject(response.data.msg);
          }
          if(response.data.code == '401') {
            window.localStorage.removeItem("token");
            window.location.href = '/login';
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default BASE_URL