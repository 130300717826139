import OSS from 'ali-oss'


// oss上传成功解析存储数据接口
export function updateOSS(data) {
  return new OSS({
    region: 'oss-accelerate',
    accessKeyId: data.accessId,
    accessKeySecret: data.accessKey,
    stsToken:data.secretToken,
    bucket: 'wkzxsoft'
  });
}

// 图标上传oss
export function iconUpdateOSS(data) {
  return new OSS({
    region: 'oss-cn-qingdao',
    accessKeyId: data.accessId,
    accessKeySecret: data.accessKey,
    stsToken:data.secretToken,
    bucket: 'wkzximg'
  });
}

// 图标上传oss
export function fileUpdateOSS(data) {
  return new OSS({
    region: 'oss-cn-qingdao',
    accessKeyId: data.accessId,
    accessKeySecret: data.accessKey,
    stsToken:data.secretToken,
    bucket: 'wkzximg'
  });
}