<template>
  <div>
    <div class="w">
      <div class="lock_box">
        <img
          src="../../assets/image/close.png"
          style="width: 220; height: 160px; margin-top: 70px"
        />
        <div class="tips">{{tips}}</div>
        <div class="title">您的账号已被锁定</div>
        <div class="title1">
          悟空在线平台禁止盗版、色情、欺诈、VPN、游戏私服类以及含有病毒的所有违反相关法律法规应
        </div>
        <div class="title1">
          用的上传，
          如果您未上传属于以上类别的应用，可点击下方申诉按钮进行申诉！
        </div>
        <el-button
          type="primary"
          round
          style="margin-top: 50px; width: 148px"
          @click="applyClick"
          >申诉</el-button
        >
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getAuthenticationState } from "@/api/api";
export default {
  name: "lock",
  data() {
    return {
      state: null,
      tips: null,
    };
  },
  created() {
    this.getTokenState()
  },
  methods: {
    // 获取封装状态值
    async getTokenState() {
      const res = await getAuthenticationState();
      console.log(res)
      if (res.code != 0) {
        this.state = res.data.status;
        if (this.state == 1) {
          this.tips = "审核中";
        }
        if (this.state == 2) {
          this.$router.push("/appManagement");
        }
        if (this.state == 3) {
          this.tips = "审核不通过，请更改审核材料重新提交";
        }
      }else if(res.code == 0){
        window.localStorage.removeItem("authentication");
        this.$router.push("/appManagement");
      }
    },
    applyClick() {
      this.$router.push("/lock/appeal");
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin: 30px 0;
  /* identical to box height */

  color: #FF4040;
}
.lock_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 36px 0;
  height: 680px;
  background: #ffffff;
  border-radius: 10px;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    color: #333333;
    margin-bottom: 30px;
  }
  .title1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    color: #666666;
  }
}
</style>
