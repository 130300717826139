import { getSLD } from "@/api/api"
import { ElSelect } from "element-plus";

//随机数

export function randomWord(number){
    var str = "",
        range = number,
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    for(var i=0; i<range; i++){
        let pos = Math.round(Math.random() * (arr.length-1));
        str += arr[pos];
    }
    return str;
  }
//判断字符是否为空
export function isEmpty(obj){
	return (typeof obj === 'undefined' || obj === null || obj === "");
}
//修改域名
export async function domainName(userId,shortUrl){
    return getSLD(userId).then((res) => {
        // console.log(res)
        if (res.code == 200) {                          
            let wHref = window.location.href;
            //地址栏域名
            let windowHref = wHref.substring(0,wHref.indexOf("/",wHref.indexOf("/") + 2));
            //绑定域名
            let sldHref = res.data.sld
            let shortIndex = shortUrl.indexOf("/",shortUrl.indexOf("/") + 2);
            //传入的链接
            let shortHref = shortUrl.substring(0,shortIndex);
            //传入的短链接
            let shortEnd = shortUrl.substring(shortIndex)
            if(isEmpty(sldHref)) {
                if(windowHref == 'https://test2.wkkc.vip') {
                    return 'https://test2.wkkc.vip' +shortEnd
                }else if(windowHref == 'https://wkzx.store') {
                    return 'https://wkzx.store' +shortEnd
                }else if(windowHref == 'https://wkzx.wang') {
                    return 'https://wkzx.wang' +shortEnd
                }else if(windowHref == 'http://localhost:8081') {
                    return 'http://localhost:8081'+shortEnd
                }else if(windowHref == 'http://localhost:8080') {
                    return 'http://localhost:8080'+shortEnd
                }else if(windowHref == 'https://www.wkzx.wang') {
                    return 'https://www.wkzx.wang'+shortEnd
                }
                else if(windowHref == 'https://www.wkzx.store') {
                    return 'https://www.wkzx.store'+shortEnd
                }
                else {
                    return 'https://wkzx.wang' +shortEnd
                }
            }else{
                return 'http://'+sldHref + shortEnd
            }
        }
      });
}
//修改域名
export function getDomain(shortUrl){
    let wHref = window.location.href;
    //地址栏域名
    let windowHref = wHref.substring(0,wHref.indexOf("/",wHref.indexOf("/") + 2));
    let shortIndex = shortUrl.indexOf("/",shortUrl.indexOf("/") + 2);
    //传入的短链接
    let shortEnd = shortUrl.substring(shortIndex)
    if(windowHref == 'https://test2.wkkc.vip') {
        return 'https://test2.wkkc.vip' +shortEnd
    }else if(windowHref == 'https://wkzx.store') {
        return 'https://wkzx.store' +shortEnd
    }else if(windowHref == 'https://wkzx.wang') {
        return 'https://wkzx.wang' +shortEnd
    }else if(windowHref == 'http://localhost:8081') {
        return 'http://localhost:8081'+shortEnd
    }else if(windowHref == 'http://localhost:8080') {
        return 'http://localhost:8080'+shortEnd
    }else if(windowHref == 'https://www.wkzx.wang') {
        return 'https://www.wkzx.wang'+shortEnd
    }
    else if(windowHref == 'https://www.wkzx.store') {
        return 'https://www.wkzx.store'+shortEnd
    }
    else {
        return 'https://wkzx.wang' +shortEnd
    }
}
//修改域名
export function getDomainFront(){
    let wHref = window.location.href;
    //地址栏域名
    let windowHref = wHref.substring(0,wHref.indexOf("/",wHref.indexOf("/") + 2));
    if(windowHref == 'https://test2.wkkc.vip') {
        return 'https://test2.wkkc.vip/'
    }else if(windowHref == 'https://wkzx.store') {
        return 'https://wkzx.store/'
    }else if(windowHref == 'https://wkzx.wang') {
        return 'https://wkzx.wang/'
    }else if(windowHref == 'http://localhost:8081') {
        return 'http://localhost:8081/'
    }else if(windowHref == 'http://localhost:8080') {
        return 'http://localhost:8080/'
    }else if(windowHref == 'https://www.wkzx.wang') {
        return 'https://www.wkzx.wang/'
    }
    else if(windowHref == 'https://www.wkzx.store') {
        return 'https://www.wkzx.store/'
    }
    else {
        return 'https://wkzx.wang/'
    }
}