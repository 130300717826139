<!-- <template>
  <div class="content">
    <div class="w docbox">
      <div class="title_2">开发者服务</div>
      <div class="title_3">
        悟空在线为开发者提供简洁迅速的内测程序分发服务，
        悟空在线提供专业的手机应用内测和iOS企业分发服务,您只需将需要将内测或者分发的应用上传至悟空在线平台,悟空在线会根据不同系统自动生成二维码并且提供不同的下载模板,内测和对外用户通过手机上扫描二维码即可下载。
      </div>
      <hr />
      <div class="footer">
        <div class="left">
          <div class="font_1">上传与安装</div>
          <div class="footer_li">
              <div>>iOS 应用安装失败原因排查</div>
              <div>>在 iOS 9 及以上版本中运行企业版应用</div>
              <div>>内测分发 -使用教程</div>
              <div>>内测分发 - 应用安装失败原因排查</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> -->
<template>
	<div class="xianshi">
		<div class="wenti">
			<div class="xiangxi">
				<span class="xiegang">|</span>
				全部常见问题
			</div>
		</div>
		<!--问题部分-->
		<div class="dafu">
			<el-collapse v-model="activeNames" @change="handleChange" accordion>
				<el-collapse-item name="1">
					<template #title>
						<span class="biao">常见问题及扣除规则 </span>
					</template>
					<div class="neiye">
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;为什么要限制下载流量？</div>
						<div class="hang">根据我们的数据分析，当前每日的下载限制，已经可以满足 99% 的内测用户</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;为什么要限制应用包的保存时长？</div>
						<div class="hang">1. 应用内测是有周期的，一般应用的内测周期为 3个月 (可以通过更新，延长保存时间)</div>
						<div class="hang">2. 降低未被发现的违规应用造成的影响范围</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;需要更多的下载流量为什么要付费？</div>
						<div class="hang">wkzx.wang的平台定位不是应用商店，如果要进行大范围应用公测，我们会根据您选择的不同流量包收取一定的费用用于支付流量费和托管费！</div>
						<div class="quanwen" @click="tiaozhuan(1)">
							<a href="javascript:void(0)">查看全文→</a>
						</div>
					</div>
				</el-collapse-item>
				<el-collapse-item name="2">
					<template #title>
						<span class="biao">内侧分发-常见问题 </span>
					</template>
					<div class="neiye">
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;此安装方式是否需要苹果设备越狱？</div>
						<div class="hang">无需设备越狱即可安装。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;
							是否需要内测分发才能安装？</div>
						<div class="hang">不需要内测分发，上传之后开启内测分发即可内测分发。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;是否需要提供UDID？</div>
						<div class="hang">无需提供UDID，全程自动化。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;签名需要多长时间？</div>
						<div class="hang">我们的签名是完全自动化的，一般可在数秒内完成。</div>
						<div class="quanwen" @click="tiaozhuan(2)"><a href="javascript:void(0)">查看全文→</a></div>
					</div>
				</el-collapse-item>
				<el-collapse-item name="3">
					<template #title>
						<span class="biao">超级签名常见问题 </span>
					</template>
					<div class="neiye">
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;此安装方式是否需要苹果设备越狱？</div>
						<div class="hang">无需设备越狱即可安装。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;
							是否需要内测分发才能安装？</div>
						<div class="hang">不需要内测分发，上传之后开启内测分发即可内测分发。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;是否需要提供UDID？</div>
						<div class="hang">无需提供UDID，全程自动化。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;签名需要多长时间？</div>
						<div class="hang">我们的签名是完全自动化的，一般可在数秒内完成。</div>
						<div class="quanwen" @click="tiaozhuan(3)"><a href="javascript:void(0)">查看全文→</a></div>
					</div>
				</el-collapse-item>
				<el-collapse-item name="4">
					<template #title>
						<span class="biao">购买的服务如何申请发票 </span>
					</template>
					<div class="neiye">
						<div><img style="width: 100%;" src="../../assets/image/kaipiao.png"></div>
						<div class="quanwen" @click="tiaozhuan(4)"><a href="javascript:void(0)">查看全文→</a></div>
					</div>
				</el-collapse-item>
				<el-collapse-item name="5">
					<template #title>
						<span class="biao">超级签如何开启付费下载? </span>
					</template>
					<div class="neiye">
						<div class="hang">1: 登录悟空在线内测分发平台，进入应用列表页面，选中已经开启超级签的app，点击编辑进入详情页面。</div>
						<div><img style="width: 100%;" src="../../assets/image/buzhou1.png"></div>
						<div class="quanwen" @click="tiaozhuan(5)"><a href="javascript:void(0)">查看全文→</a></div>
					</div>
				</el-collapse-item>
				<el-collapse-item name="6">
					<template #title>
						<span class="biao">超级签名使用教程</span>
					</template>
					<div class="neiye">
						<div class="hang">超级签名 – 国内首款IPA在线自动签名安装工具，让您的iOS APP无需提交APP store，无需设置 UDID， 无需企业签名，只需开启超级签名 安装
							，即可在让您的app对外一键分发，帮助您快速完成应用安装过程，降低分发成本，提高分发效率。</div>
						<div class="quanwen" @click="tiaozhuan(6)"><a href="javascript:void(0)">查看全文→</a></div>
					</div>
				</el-collapse-item>
				<el-collapse-item name="7">
					<template #title>
						<span class="biao">域名绑定失败常见原因</span>
					</template>
					<div class="neiye">
						<div class="hang">域名绑定失败可能有多种原因，下文的示例中，我们将使用 fir.yourdomain.com 来说明，您在具体检测时，请将域名替换为您自己配置的二级域名。</div>
						<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;
							CNAME 配置错误</div>
						<div class="quanwen" @click="tiaozhuan(7)"><a href="javascript:void(0)">查看全文→</a></div>
					</div>
				</el-collapse-item>

			</el-collapse>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				//默认打开第几个，目前默认都闭合
				activeNames: ['0'],
				contractList: [{
					id: 1,
					name: "全部常见问题",
					class: "contract_item",
				}, ]
			};
		},
		methods: {
			handleChange(val) {
				//当前打开菜单name
				// console.log(val);
			},
			//跳转切换目录
			tiaozhuan(keyPath) {
				if (keyPath == 1) {
					this.$router.push("/installDoc/list1");
				}
				if (keyPath == 2) {
					this.$router.push("/installDoc/list2");
				}
				if (keyPath == 3) {
					this.$router.push("/installDoc/list3");
				}
				if (keyPath == 4) {
					this.$router.push("/installDoc/list4");
				}
				if (keyPath == 5) {
					this.$router.push("/installDoc/list5");
				}
				if (keyPath == 6) {
					this.$router.push("/installDoc/list6");
				}
				if (keyPath == 7) {
					this.$router.push("/installDoc/list7");
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	.xianshi {
		// width: 100%;
		height: 100%;
		// background-color: blanchedalmond;
		margin-left: 23rem;
		margin-right: 23rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		display: flex;
		justify-content: center;
	}

	//12.7更新UI
	.xiangxi {
		color: #666666;
		font-size: 1rem;
		margin: 0 auto;
	}

	.xiegang {
		color: #0092ff;
		// position: absolute;
		font-weight: 700;
		// left: 31rem;
		padding-right: 30px;
	}

	//12.6更新UI
	.neiye {
		background-color: rgb(247, 249, 250);
		padding: 20px;
		letter-spacing: 1px;
	}

	.hang {
		padding: 5px;
	}

	.biaoti {
		font-weight: bold;
		font-size: 14px;
	}

	.biao {
		font-weight: bold;
		font-size: 16px;
	}

	.quanwen {
		// padding: 5px;

		padding-top: 15px;
	}

	.quanwen a {
		color: #0092ff;
	}

	.wenti {
		background: #fff;
		padding-top: 30px;
		padding-bottom: 30px;
		padding-right: 30px;
		// padding-left: 30px;
		margin-top: 20px;
		overflow: hidden;
		margin-bottom: 30px;
		width: 20%;
		height: 1.375rem;
		margin-right: 1rem;
		border-radius: 10px;
	}

	.dafu {
		background: #fff;
		padding: 30px;
		margin-top: 20px;
		overflow: hidden;
		margin-bottom: 30px;
		width: 50%;
		font-size: 14px;
		border-radius: 10px;
	}

	.text {
		font-size: 14px;
	}

	.item {
		padding: 18px 0;
	}

	.box-card {
		width: 480px;
	}

	//老样式
	.footer_li {
		color: #2c71ff;
		font-family: "PingFang SC", -apple-system, "Helvetica Neue", Helvetica, BlinkMacSystemFont, "Microsoft YaHei", tahoma, Arial, "Open Sans", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
		font-size: 14px;

		div {
			margin-top: 20px;
			cursor: pointer;
		}
	}

	.footer {
		margin-top: 30px;

		.left {
			width: 380px;
			float: left;

			.font_1 {
				font-weight: bold;
				font-size: 22px;
				color: #333333;
				margin-bottom: 30px;
			}
		}
	}

	.docbox {
		background: #fff;
		border-radius: 10px;
		padding: 30px;
		margin-top: 20px;
		overflow: hidden;
		margin-bottom: 30px;
	}

	.content {
		overflow: hidden;
	}

	.title_1 {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		text-align: center;
		color: #333333;
		margin: 30px 0;
	}

	.title_2 {
		font-weight: bold;
		font-size: 22px;
		margin-bottom: 30px;
	}

	.title_3 {
		margin-top: 20px;
		font-size: 18px;
		line-height: 30px;
		color: #999999;
		margin-bottom: 30px;
	}

	/*包含以下五种的链接*/
	a {
		text-decoration: none;
	}

	/*正常的未被访问过的链接*/
	a:link {
		text-decoration: none;
	}

	/*已经访问过的链接*/
	a:visited {
		text-decoration: none;
	}

	/*鼠标划过(停留)的链接*/
	a:hover {
		text-decoration: none;
	}

	/* 正在点击的链接，鼠标在元素上按下还没有松开*/
	a:active {
		text-decoration: none;
	}

	/* 获得焦点的时候 鼠标松开时显示的颜色*/
	a:focus {
		text-decoration: none;
	}

	.contract_box {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 106px;
		color: #666666;
	}

	.contract_left {
		width: 260px;
		height: 324px;
		background: #fff;
		border-radius: 10px;
	}

	.contract_item {
		position: relative;
		width: 100%;
		height: 54px;
		line-height: 54px;
		padding: 0 30px;
		box-sizing: border-box;
		font-size: 14px;
	}

	.contract_item_active {
		position: relative;
		width: 100%;
		height: 54px;
		line-height: 54px;
		padding: 0 30px;
		box-sizing: border-box;

		font-size: 14px;
		color: #0092ff;
	}

	.contract_item_active::after {
		content: "|";
		position: absolute;
		color: #0092ff;
		font-weight: 700;
		left: 0;
	}
</style>
