<template>
  <div>
    <div class="contract_right">
      <div class="title">修改密码</div>
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item
          label="当前密码"
          prop="oldPassword"
          label-width="80px"
        >
          <el-input
            v-model="form.oldPassword"
            placeholder="输入当前密码"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" label-width="80px">
          <el-input
            v-model="form.newPassword"
            placeholder="输入新密码"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPasswordTwo" label-width="80px">
          <el-input
            v-model="form.newPasswordTwo"
            placeholder="再次确认新密码"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {resetPassowrd} from '@/api/api'
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        // password 是表单上绑定的字段
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordTwo: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        newPasswordTwo: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 调用修改密码接口
    editPassword() {
      let formData = {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword
      }
      resetPassowrd(formData).then(res => {
        if(res.code == 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.editPassword()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  width: 920px;
  height: 460px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
</style>
