<template>
  <div class="box">
    <div class="sell_box">
      <div class="w2">
        <div class="sell_header">
          <div class="list_item" v-show="priceType == 1">
            <div class="hr"></div>
            <div class="item">
              <div class="item1">
                <div class="font1">{{ downnum }}</div>
                <div class="font2">累计下载流量</div>
              </div>
              <div class="item2">
                <span class="price1">¥</span>
                <span class="price2">{{ totalPrice }}</span>
              </div>
            </div>
          </div>

          <div class="list_item1" v-show="priceType == 2">
            <div class="hr"></div>
            <div class="item">
              <div class="item1">
                <div class="font1">{{ downnum ? downnum : 0 }}</div>
                <div class="font2">设备数量</div>
              </div>
              <div class="item2">
                <span class="price1">¥</span>
                <span class="price2">{{ totalPrice }}</span>
              </div>
            </div>
          </div>

          <div class="list_item2" v-show="priceType == 3">
            <div class="hr"></div>
            <div class="item">
              <div class="item1">
                <div class="font1">
                  <span v-if="downnum == 1">一年/台</span>
                  <span v-if="downnum == 2">6个月/台</span>
                  <span v-if="downnum == 3">3个月/台</span>
                  <span v-if="downnum == 4">一个月/台</span>
                </div>
                <div class="font2">设备数量</div>
              </div>
              <div class="item2">
                <span class="price1">¥</span>
                <span class="price2">{{ totalPrice }}</span>
              </div>
            </div>
          </div>

          <div class="list_item3" v-show="priceType == 4">
            <div class="hr"></div>
            <div class="item">
              <div class="item1">
                <div class="font1">{{ downnum }}</div>
                <div class="font2">设备数量</div>
              </div>
              <div class="item2">
                <span class="price1">¥</span>
                <span class="price2">{{ totalPrice }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="sell_title">选择支付方式</div>
        <div class="sell_btn">
          <div :class="wxPaystyle" @click="wxPay">微信支付</div>
          <div :class="aliPayStyle" @click="zhifuPay">支付宝支付</div>
        </div>
        <div class="sell_confirm">
          <el-button
            type="primary"
            round
            style="width: 148px; margin: 40px 0"
            @click="confirmSave"
            >确定</el-button
          >
        </div>
        <div class="sell_tips">
          <div>常见问题</div>
          <div>
            <div class="sell_exame">
              <div>1.付款须知</div>
              <i class="el-icon-arrow-down" @click="sellTipsClick(1)"></i>
            </div>
            <div class="sell_exame_font" v-if="payFlag">
              当前付款设备使用更换设备需要重新购买.支付默认同意底部相关协议，最终解释权归悟空在线所有.
            </div>
            <div class="sell_exame">
              <div>2.付款完成后再次下载的时候还需要付款吗？</div>
              <i class="el-icon-arrow-down" @click="sellTipsClick(2)"></i>
            </div>
            <div class="sell_exame_font" v-if="payConfirmFlag">
              不需要，应用是根据时间计算的，付款后在有效期内可以随意下载资源。但不同设备需分别付费.
            </div>
            <div class="sell_exame">
              <div>3.付款后还能退款吗？</div>
              <i class="el-icon-arrow-down" @click="sellTipsClick(3)"></i>
            </div>
            <div class="sell_exame_font" v-if="payOkFlag">
              应用是和设备ID绑定的，付款后无法解绑，所以暂不支持退款，如果您有任何疑问或疑虑，请立即与我们联系。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aliPay, wxAppPay } from "@/api/api";
export default {
  props: {
    UDID: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    shortUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      priceType: "",
      downnum: "",
      totalPrice: "",
      payStatus: "",
      payOkFlag: false,
      payConfirmFlag: false,
      payFlag: false,
      sellLoading: false, // 刷新loading
      wxOrderId: "", // 微信订单号
      // 微信支付样式
      wxPaystyle: "sell_btn_wx",
      aliPayStyle: "sell_btn_zfb",
      // 购买支付对象
      price: {
        orderPriceId: "", // 选择价格id
        productType: "", // 类型 0.下载次数 1.公有设备 2.私有设备 3.webclip 4.个人购买下载时长
        payKey: "",
        orderId: "",
        userId: "",
      },
    };
  },
  created() {
    this.price.payKey = this.guid();
    this.downnum = this.$route.query.downnum;
    this.totalPrice = this.$route.query.totalPrice;
    this.priceType = this.$route.query.priceType;
    this.price.productType = this.$route.query.productType;
    this.price.orderPriceId = this.$route.query.orderPriceId;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.price.userId = userInfo[0].userId;
  },
  methods: {
    //用于生成uuid
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    guid() {
      return (
        this.S4() +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        this.S4() +
        this.S4()
      );
    },
    // 折叠
    sellTipsClick(val) {
      if (val == 1) {
        this.payFlag = !this.payFlag;
      }
      if (val == 2) {
        this.payConfirmFlag = !this.payConfirmFlag;
      }
      if (val == 3) {
        this.payOkFlag = !this.payOkFlag;
      }
    },
    confirmSave() {
      console.log(this.userId + "========================================");
      if (this.payStatus == 2) {
        aliPay(this.price).then((res) => {
          if (res.code == 0) window.location.href = res.data.aliPayQrCode;
        });
      } else if (this.payStatus == 1) {
        wxAppPay({orderPriceId: this.price.orderPriceId,orderType: 1,userId: this.price.userId}).then((res) => {
          if (res.code == 0) {
            window.location.href = res.data.appUrl;
          }
        });
      }
    },
    wxPay() {
      this.wxPaystyle = "sell_btn_wx_active";
      this.aliPayStyle = "sell_btn_zfb";
      this.payStatus = 1;
    },
    zhifuPay() {
      this.wxPaystyle = "sell_btn_wx";
      this.aliPayStyle = "sell_btn_zfb_active";
      this.payStatus = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.list_item3 {
  height: 80px;
  background: #eed9ff;
  border: 1px solid #ac40ff;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
  overflow: hidden;
  .hr {
    height: 10px;
    background: #ac40ff;
  }
}
.list_item2 {
  height: 80px;
  background: #fff5cc;
  border: 1px solid #ffcc00;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
  overflow: hidden;
  .hr {
    height: 10px;
    background: #ffcc00;
  }
}
.list_item1 {
  height: 80px;
  background: #ccecdf;
  border: 1px solid #009e61;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
  overflow: hidden;
  .hr {
    height: 10px;
    background: #009e61;
  }
}
.item {
  display: flex;
  justify-content: space-between;
}
.item1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .font1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    color: #333333;
  }
  .font2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #666666;
  }
}
.item2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.875rem;
  height: 70px;
  background: #fff;
  .price1 {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #ff4a4a;
  }
  .price2 {
    margin-left: 4px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    color: #ff4a4a;
  }
}
.list_item {
  height: 80px;
  background: #cceaf8;
  border: 1px solid #0098dd;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
  overflow: hidden;
  .hr {
    height: 10px;
    background: #0098dd;
  }
}
.sell_exame_font {
  padding: 0 10px;
  color: #0092ff;
  font-size: 12px;
  line-height: 24px;
}
.el-icon-arrow-down {
  width: 20px;
}
.sell_exame {
  display: flex;
  justify-content: space-between;
  color: #666666;
  div {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
  }
}
.sell_confirm {
  display: flex;
  justify-content: center;
}
.sell_tips {
  overflow: hidden;
  padding: 20px;
  background: #f1f6f9;
  border-radius: 10px;
}
.w2 {
  padding: 0 20px;
  box-sizing: content-box;
}
.box {
  overflow: hidden;
  height: 100vh;
  background: #fff;
}
.sell_btn {
  .sell_btn_wx_active {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
    background: rgba(9, 201, 101, 0.2);
    box-sizing: border-box;
    background: #09c965;
    color: #fff;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .sell_btn_wx {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
    background: rgba(9, 201, 101, 0.2);
    border: 1px solid #09c965;
    box-sizing: border-box;
    color: #09c965;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .sell_btn_zfb {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #0092ff;
    box-sizing: border-box;
    border-radius: 10px;
    background: rgba(0, 146, 255, 0.2);
    color: #0092ff;
    cursor: pointer;
  }
  .sell_btn_zfb_active {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 10px;
    background: #0092ff;
    color: #fff;
    cursor: pointer;
  }
}
.sell_title {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
}
.sell_zhekou {
  border-radius: 46px;
  background: #ececec;
  width: 47px;
  text-align: center;
  text-decoration: line-through;
}

.sell_zhekou_acitve {
  border-radius: 46px;
  background: #f8e3bf;
  color: #ba812f;
  width: 47px;
  text-align: center;
  text-decoration: line-through;
}
.sell_header {
  margin: 50px 0;
  //   display: flex;
  overflow: auto;
}

.sell_item_active {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fcf3e6;
  width: 200px;
  margin-left: 20px;
  height: 154px;
  border-radius: 10px;
  border: 2px solid #ebce9f;
}
.sell_item {
  flex-shrink: 0;
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 200px;
  height: 154px;
  border-radius: 10px;
  border: 2px solid #ebce9f;
}
</style>
