<template>
  <div class="content">
    <el-dialog
      title="举报"
      center
      v-model="dialogVisible"
      :close-on-click-modal="false"
      width="630px"
      :before-close="handleClose"
    >
      <div class="dialog_box">
        <div class="dialog_header">
          <span
            >作为一个第三方内测分发平台，我们一直致力于打击违规应用，保护知识产权。非常感谢您的举报，我们会在第一时间安排专人处理您举报的问题，并在处理之后向您反馈处理结果。</span
          >
          <br />
          <span style="margin-top: 20px;display:block;font-size:14px"
            >感谢您对我们的支持，如果有问题欢迎拨打我们的热线电话：0371-86550361</span
          >
        </div>
        <hr style="margin: 20px 0" />
        <el-form ref="formData" :model="formData" label-width="90px">
          <el-form-item label="您的手机号">
            <el-input v-model="formData.phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input v-model="formData.code" style="width: 280px"></el-input>
            <el-button
              type="primary"
              style="float:right;width: 144px"
              @click="getCode"
              :disabled="!show"
              >获取验证码<span v-show="!show" class="count"
                >({{ count }}s)</span
              ></el-button
            >
          </el-form-item>
          <div style="height: 32px">
            <my-slider-captcha
              @isSuccess="isSuccess"
              @codeData="codeData"
              :scene="scene"
            ></my-slider-captcha>
          </div>
          <el-form-item label="举报原因">
            <el-radio-group v-model="formData.report">
              <el-radio :label="1">盗版</el-radio>
              <el-radio :label="2">色情</el-radio>
              <el-radio :label="3">欺诈</el-radio>
              <el-radio :label="4">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="举报内容">
            <el-input
              v-model="formData.contentSupplement"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-button type="text">上传附件</el-button> -->
        <el-upload
          class="upload-demo"
          :headers="myHeaders"
          :action="src + 'software/upload'"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :before-upload="beforeUpload"
          multiple
          :limit="2"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button type="primary">上传附件</el-button>
        </el-upload>
        <br />
        <span
          >为了能够更快处理您的举报信息，建议上传截图或其他相关文件等证据</span
        >
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveData">举报</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="logo">
      <img :src="form.iconUrl" />
    </div>
    <div class="title">
      {{ form.label }}
    </div>
    <div class="verison" v-show="languageToggle == 1">
      版本： {{ form.version }}
    </div>
    <div class="verison" v-show="languageToggle == 2">
      versions： {{ form.version }}
    </div>
    <div class="font_style" v-show="languageToggle == 1">
      时间：{{
        form.remarks2
      }}
    </div>
    <div class="font_style" v-show="languageToggle == 2">
      time:{{
        form.remarks2
      }}
    </div>
    <div class="erwei">
      <div id="qrCode" ref="qrCodeDiv"></div>
	  <!-- <img :src="form.iconUrl" alt=""> -->
    </div>
    <div
      style="font-size: 14px;margin-bottom: 25px;"
      v-show="languageToggle == 1"
    >
      扫描二维码下载
    </div>
    <div
      style="font-size: 14px;margin-bottom: 25px;"
      v-show="languageToggle == 2"
    >
      Scan the QR code to download
    </div>
    <div style="font-size: 14px;" v-show="languageToggle == 1">
      或使用浏览器打开 :{{ form.remarks1 }}
    </div>
    <div style="font-size: 14px;" v-show="languageToggle == 2">
      Or use your mobile browser :{{ form.remarks1 }}
    </div>
    <div class="footer">
      <div class="w">
        <div class="footer_left">
          <span
            v-show="languageToggle == 1"
            style="margin-right: 20px;cursor: pointer"
            @click="exemptionClick"
            >免责声明</span
          >
          <span
            v-show="languageToggle == 2"
            style="margin-right: 20px;cursor: pointer"
            @click="exemptionClick"
            >disclaimer</span
          >
          <span style="color: #333">|</span>
          <span
            v-show="languageToggle == 1"
            style="margin-left: 20px;cursor: pointer"
            @click="reportClick"
            >举报</span
          >
          <span
            v-show="languageToggle == 2"
            style="margin-left: 20px;cursor: pointer"
            @click="reportClick"
            >report</span
          >
        </div>
        <div class="footer_right">
          <img
            src="../../assets/image/china.png"
            style="width: 21px;height: 14px;margin-right: 10px;"
          />
          <span @click="languageClick(1)" style="cursor: pointer;">中文</span>
          <span style="margin: 0 20px;color: #333">|</span>
          <img
            src="../../assets/image/usa.png"
            style="width: 21px;height: 14px;margin-right: 10px;"
          />
          <span @click="languageClick(2)" style="cursor: pointer;">English</span>
        </div>
      </div>
      <div class="w">
        <div
          class="tips"
          style="color: #999;margin-top: 10px;"
          v-show="languageToggle == 1"
        >
          提示：该应用来源于第三方产品，内容不受悟空在线控制，请您仔细甄别，审慎安装。悟空在线仅为信息服务平台，与第三方应用之间不存在任何关联关系，不提供任何担保或承诺。悟空在线不为因此产生的任何投资、购买行为承担任何责任。
        </div>
        <div
          class="tips"
          style="color: #999;margin-top: 10px;"
          v-show="languageToggle == 2"
        >
          Tip: this application comes from a third-party product, and its content is not controlled by Wukong online. 
          Please carefully identify and carefully install it. Wukong online is only an information service platform, 
          and has no relationship with third-party applications, and does not provide any guarantee or commitment. 
          Wukong online will not bear any responsibility for any investment and purchase behavior arising therefrom!
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BASE_URL from "@/utils/request.js";
import {
  getOssToken,
  webclipPCPreview,
  reportCode,
  reportCodeVerify,
  reportApp,
} from "@/api/api";
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord,domainName } from "@/common/js/common.js";
import QRCode from "qrcodejs2";
export default {
  name: "webClipPCPreview",
  data() {
    return {
      scene: "report",
      checkData: null,
      checkSuccess: false,
      show: true,
      count: "",
      dialogVisible: false,
      softId: "",
      src: BASE_URL,
      formData: {
        phone: "",
        code: "",
        report: 1,
        contentSupplement: "",
        softId: "",
        files: [],
      },
      form: {},
      fileList: [],
      token: "",
      myHeaders: null,
      timer: null,
      languageToggle: 1,
    };
  },
  created() {
    this.softId = this.$route.query.id;
    this.formData.softId = this.softId;
    this.getAppShow();
    this.token = window.localStorage.getItem("token");
    this.myHeaders = { Authorization: this.token };
  },
  methods: {
    codeData(val) {
      this.checkData = val;
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    // 选择中英文
    languageClick(val) {
      this.languageToggle = val;
    },
    // 免责声明
    exemptionClick() {
      this.$router.push({
        path: "/contract",
        query: {
          status: 5,
        },
      });
    },
    // 保存
    saveData() {
      this.codeJiaoyan();
      reportApp(this.formData).then((res) => {
        if (res.code == 0) {
          this.$message.success("举报成功");
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取验证码
    getCode() {
      if (!this.formData.phone) return this.$message.warning("请输入手机号");
      if (this.checkSuccess) {
        reportCode({ phone: this.formData.phone,sessionId:this.checkData.sessionId, sig: this.checkData.sig, sessiontoken: this.checkData.token, scene: this.scene }).then((res) => {
        if (res.code == "1") {
          this.$message({
            type: "warning",
            duration: 2000,
            message: res.msg,
          });
        } else {
          this.timerChange();
        }
      });
      } else {
        this.$message.warning("请先通过滑块验证");
      }
      
    },
    codeJiaoyan() {
      if (this.formData.phone) {
        reportCodeVerify({
          phone: this.formData.phone,
          code: this.formData.code,
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
          } else {
            return this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号",
        });
      }
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 举报
    reportClick() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 165,
        height: 165,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    async getAppShow() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo);
      await webclipPCPreview({ id: this.softId}).then(
        (res) => {
          if (res.code == 0) {
            this.form = res.data.webclip;
            setTimeout(() => {
              this.bindQRCode(res.data.webclip.remarks1);
            }, 500);
          }
        }
      );
      // 修改域名
      const res2 = await domainName(this.form.userId,this.form.remarks1);
      this.form.remarks1 = res2;
      console.log(this.form)
    },
    // 删除文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //上传限制数
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件`
      );
      //共选择了 ${files.length + fileList.length }个文件
    },
    //删除弹出框
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //上传文件调用
    beforeUpload(files) {
      //获取后缀名
      var fileName = files.name;
      var index = fileName.lastIndexOf(".");
      var suffix = fileName.substring(index, fileName.length); //后缀名
      fileName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(fileName, files)
            .then((res) => {
              if (res.url) {
                this.formData.files.push(res.url);
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {});
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: left;
}
.dialog_box {
  color: #888;
  padding: 25px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 20px 0;
  background: rgba(229, 229, 229, 1);
  .w {
    display: flex;
    justify-content: space-between;
    span {
      color: #0098dd;
    }
  }
}
#qrCode {
  border: 10px solid #eee;
  padding: 10px;
  border-radius: 16px;
  margin-bottom: 20px;
}
.erwei {
  position: relative;
  height: 205px;
  margin-bottom: 20px;
  img {
    position: absolute;
    width: 24px;
    height: 25px;
    left: 50%;
    top: 50%;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 2px;
    transform: translate(-50%, -50%);
  }
}
.content {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo img {
    width: 140px;
    height: 140px;
    border-radius: 16%;
  }
  .title {
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
  }
  .verison {
    font-size: 15px;
    margin-top: 18px;
    margin-bottom: 16px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
  }
  .font_style {
    margin-bottom: 40px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
</style>