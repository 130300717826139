<template>
  <div>
    <div class="contract_right">
      <div class="title">订单查询</div>
      <el-table
        :data="tableData"
        border
        max-height="300"
        v-loading="loading"
        :header-cell-style="{ background: '#E5F1FA' }"
      >
        <el-table-column
          prop="orderId"
          label="订单号"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="购买时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="购买金额"
          align="center"
        >
          <template #default="scope">
            {{scope.row.money / 100}}
          </template>
        </el-table-column>
        <el-table-column prop="shopType" label="类别" align="center">
          <template #default="scope">
             <div v-if="scope.row.shopType == 0">下载次数</div>
            <div v-if="scope.row.shopType == 1">vip设备</div>
            <div v-if="scope.row.shopType == 2">私有池设备</div>
            <div v-if="scope.row.shopType == 3">按时间购买</div>
            <div v-if="scope.row.shopType == 4">webclip时长</div>
            <div v-if="scope.row.shopType == 5">按时间购买</div>
            <div v-if="scope.row.shopType == 6">按时间购买</div>
            <div v-if="scope.row.shopType == 7">按时间购买</div>
          </template>
        </el-table-column>
        <el-table-column prop="shopNum" label="套餐" align="center">
          <template #default="scope"> 
            <div v-if="scope.row.shopType == 0">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(点)</div>
            <div v-if="scope.row.shopType == 1">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(台)</div>
            <div v-if="scope.row.shopType == 2">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(台)</div>
            <div v-if="scope.row.shopType == 3">年包</div>
            <div v-if="scope.row.shopType == 4">{{ scope.row.shopNum ? scope.row.shopNum : 0}}(天)</div>
            <div v-if="scope.row.shopType == 5">半年包</div>
            <div v-if="scope.row.shopType == 6">季包</div>
            <div v-if="scope.row.shopType == 7">月包</div>
            <!-- {{ scope.row.shopNum ?  scope.row.shopNum: 1}}(点) -->
          </template>
        </el-table-column>
      </el-table>
      <paging-template :currentPage="currentPage" :pageSize="pageSize" :total="total" @pageSizeChange="pageSizeChange" @currentChange="currentChange"></paging-template>
    </div>
  </div>
</template>
<script>
import pagingTemplate from '@/components/common/pagingTemplate'
import { getOrderList } from "@/api/api";
import PagingTemplate from '../common/pagingTemplate.vue';
export default {
   components: {
      pagingTemplate
    },
  data() {
    return {
      currentPage: 1,
      pageSize: 20,
      total: null,
      loading: false,
      tableData: [],
    };
  },
  created() {
    this.orderList();
  },
  methods: {
    orderList() {
      this.loading = true;
      getOrderList({ pageNum: this.currentPage }).then((res) => {
        this.loading = false;
        this.pageSize = res.data.pageInfo.pageSize
        this.currentPage = res.data.pageInfo.pageNum
        this.total = res.data.pageInfo.total
        this.tableData = res.data.pageInfo.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  width: 920px;
  height: 460px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
</style>
