<template>
  <div class="applySoftness">
    <div class="apply">
        <div class="applyTop">
          <div class="text_top">软著代申请</div>
          <div class="text">
            <span>
              悟空在线提供专业代理服务，专业人士对接，无需前往现场，极速拿照，助力企业资质安全，合规合法经营
            </span>
          </div>
          <div class="contact" @click="getAlert">联系我们</div>
        </div>
    </div>
    <div class="website">
      <div class="website_content">
        <div class="top">申请所需材料</div>
        <div class="bot">
          <img src="../../assets/image/materialScience.png" alt="">
          <div class="right">
            <div>法人签署的书面申请</div>
            <div>营业执照(有效复印件)或企业预登记名称证明</div>
            <div>公司概况(公司基本情况，拟从事增值电信业务的人员、场地和设施等)</div>
            <div>公司近期经会计事物所或审计事务所审定的财务报表或验资报告</div>
            <div>公司章程，公司股权结构及股东的有关情况</div>
            <div>业务发展计划及相关技术方案</div>
            <div>《互联网信息服务经营许可证申报表》</div>
            <div>《信息安全责任书》</div>
          </div>
        </div>
      </div>
    </div>
    <div class="licence">
      <div class="text">服务流程</div>
      <div class="middle">
        <div>
          <img src="../../assets/image/business.png" alt="" />
          <span>业务意向咨询</span>
          <span>了解您的情况，确认办理类型</span>
        </div>
        <div>
          <img src="../../assets/image/cooperation.png" alt="" />
          <span>合作洽谈</span>
          <span>根据您的情况，制定适合您的方案</span>
          <img class="right_sj" src="../../assets/image/arrow.png" alt="" />
        </div>
        <div>
          <img src="../../assets/image/file2.png" alt="" />
          <span>材料准备</span>
          <span>协助您准备所需的材料</span>
          <img class="right_sj" src="../../assets/image/arrow.png" alt="" />
        </div>
        <div>
          <img src="../../assets/image/submit.png" alt="" />
          <span>提交审核</span>
          <span>接下来的事情交给悟空在线专业服务人员进行处理</span>
          <img class="right_sj" src="../../assets/image/arrow.png" alt="" />
        </div>
        <div>
          <img src="../../assets/image/certificate.png" alt="" />
          <span>证书下发</span>
          <span>我们会第一时间通知您并下发证书</span>
          <img class="right_sj" src="../../assets/image/arrow.png" alt="" />
        </div>
      </div>
    </div>
    <contactUs ref="contactUs"></contactUs>
  </div>
</template>
<script>
import contactUs from "@/views/applySoftness/contactUs";

export default {
  name: "applySoftness",
  components: {
    contactUs
  },
  data() {
    return {
      isShow:false
    };
  },
  created() {
  },
  methods: {
    getAlert(){
      this.$refs.contactUs.isShow = true
    },
    // 实名认证
    personAuthClick() {
      // this.$router.push("/personalData?status=3");               
    },
  },
};
</script>
<style lang="scss" scoped>
  .applySoftness {
    // margin-bottom: 30px;
    font-family: 'PingFang SC';
    font-style: normal;
  }
  .apply {
    height: 470px;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    // background: url("../../assets/image/banner.png");
    background: url("../../assets/image/applySoft.jpg") no-repeat center top;
    background-color: #e1e1e1;
    .applyTop {
      text-align: center;
      .text_top {
        color: #fff;
        font-weight: 500;
        font-size: 40px;
        line-height: 56px;
        padding-top: 130px;
      }
      .text {
        color: #9FABBD;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        width: 600px;
        transform: translate(-50%, 0);
        margin: 42px 0 60px 50%;
      }
      .contact {
        width: 140px;
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        color: #1B31EC;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .website {
    height: 468px;
    background-color: #fff;
    text-align: center;
    .website_content {
      width: 1040px;
      height: 415px;
      margin: auto;
      .top {
        color: #333333;
        padding-top: 50px;
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 25px;
        line-height: 35px;
      }
      .bot {
        width:894px;
        height: 265px;
        // border: 1px solid red;
        // margin: auto;
        margin-left: 145px;
        display: flex;
        align-items: center;
        .right {
          color: #666666;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          text-align: left;
          width: 451px;
          height: 265px;
          margin-left: 77px;
        }
        .right > div::before{
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #666666;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
  .licence {
      height: 395px;
      background-color: #F1F6F9;
      text-align: center;
      .text {
        padding-top: 60px;
        color: #333333;
        font-weight: 500;
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 40px;
      }
      .middle {
        margin-top: 50px;
        display: flex;
        width: 1200px;
        margin: 0 auto;
        div {
          // border: 1px solid red;
          width: 240px;
          height: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          position: relative;
          :nth-child(2) {
            margin-top: 25px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
          :nth-child(3) {
            margin-top: 10px;
            color: #767676;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            width: 180px;
          }
          .right_sj {
            //手写三角
            // width:0px;
            // height:0px;
            // border-left:14px solid #333F55;
            // border-top:7px solid transparent;
            // border-bottom:7px solid transparent; 
            position: absolute;
            left: 0;
          }
        }
      }

  }
</style>
