<template>
	<div class="content">
		<mobile-header></mobile-header>
		<password-login></password-login>
	</div>
</template>

<script>
import mobileHeader from '@/views/mobilePage/common/mobileHeader.vue'
import passwordLogin from '@/views/mobilePage/loginCommon/passwordLogin.vue'
export default {
	components: {
		mobileHeader,
		passwordLogin
	}
}
</script>

<style lang="scss" scoped>
.content {
	height: 100vh;
	background: #F1F6F9;
}
</style>
