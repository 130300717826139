<template>
  <div>
    <div class="contract_right">
      <div class="header">
        <div class="title">发票申请</div>
      </div>
      <div class="tishi">
        <div>提示:</div>
        <div class="tishi_content">
          <div>1.订单额度累计超过 200 元可以开具发票。</div>
          <div>
            2.请仔细核对并确认您的发票信息正确无误，发票开具完成后，如因信息错误不可重开。
          </div>
          <div>
            3.如需开具增值税专用发票，请确认每条订单的购买方式均为对公转账。
          </div>
        </div>
      </div>

      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item label="订单金额" prop="money" label-width="160px">
          <el-input
            disabled
            v-model="form.money"
            placeholder="输入订单金额"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司名称(发票抬头)"
          prop="invoiceTitle"
          label-width="160px"
        >
          <el-input
            v-model="form.invoiceTitle"
            placeholder="输入公司名称(发票抬头)"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="纳税人识别号(税号)"
          prop="duty"
          label-width="160px"
        >
          <el-input
            v-model="form.duty"
            placeholder="输入纳税人识别号(税号)"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司" prop="company" label-width="160px">
          <el-input
            v-model="form.company"
            placeholder="输入公司名称"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" label-width="160px">
          <el-input
            v-model="form.email"
            placeholder="输入邮箱"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark" label-width="160px">
          <el-input
            v-model="form.remark"
            placeholder="输入备注"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { makeInvoice } from "@/api/api";
export default {
  props: {
    price: {
      type: Number,
      default: 0,
    },
    orderId: {
      type: String,
      default: "",
    },
  },
  created() {
    this.form.money = this.price;
    this.form.order = this.orderId;
  },
  data() {
    return {
      rules: {
        invoiceTitle: [
          { required: true, message: "请输入发票抬头", trigger: "blur" },
        ],
        duty: [{ required: true, message: "请输入税号", trigger: "blur" }],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },
      form: {
        money: "",
        invoiceTitle: "",
        duty: "",
        company: "",
        remark: "",
        order: "",
        email: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          makeInvoice(this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.$emit('reset')
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 20px;
  width: 840px;
  display: flex;
  justify-content: space-between;
  .font {
    line-height: 40px;
  }
}
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  position: relative;
  width: 920px;
  height: 689px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      font-size: 22px;
      font-weight: 700;
    }
  }
  .tishi {
    display: flex;
    height: 93px;
    box-sizing: border-box;
    padding: 15px;
    background: #fff7dc;
    border: 1px solid #ffe17a;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    .tishi_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #9f8c6d;
      margin-left: 10px;
    }
  }
}
</style>
