<template>
  <div>
    <div class="tip_down">
      <div class="tip_title">下载分布</div>
      <div class="down_content">
        <div class="down_left">
          <div id="main" :style="{ width: '465px', height: '300px' }"></div>
        </div>
        <div class="down_right">
          <div class="down_top5">TOP 5</div>
          <div class="top_bang">
            <div
              class="top_bang_item"
              v-for="(item, index) in topList"
              :key="index"
            >
              <div>{{ item.province ? item.province : "暂无" }}</div>
              <div>{{ item.downNum }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "../../../common/js/china.js";
export default {
  props: {
    mapObj: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      topList: [
        {
          province: "",
          downNum: 0,
        },
        {
          province: "",
          downNum: 0,
        },
        {
          province: "",
          downNum: 0,
        },
        {
          province: "",
          downNum: 0,
        },
        {
          province: "",
          downNum: 0,
        },
      ],
      dataList: [
        {
          name: "南海诸岛",
          value: 0,
        },
        {
          name: "北京",
          value: 0,
        },
        {
          name: "天津",
          value: 0,
        },
        {
          name: "上海",
          value: 0,
        },
        {
          name: "重庆",
          value: 0,
        },
        {
          name: "河北",
          value: 0,
        },
        {
          name: "河南",
          value: 0,
        },
        {
          name: "云南",
          value: 0,
        },
        {
          name: "辽宁",
          value: 0,
        },
        {
          name: "黑龙江",
          value: 0,
        },
        {
          name: "湖南",
          value: 0,
        },
        {
          name: "安徽",
          value: 0,
        },
        {
          name: "山东",
          value: 0,
        },
        {
          name: "新疆",
          value: 0,
        },
        {
          name: "江苏",
          value: 0,
        },
        {
          name: "浙江",
          value: 0,
        },
        {
          name: "江西",
          value: 0,
        },
        {
          name: "湖北",
          value: 0,
        },
        {
          name: "广西",
          value: 0,
        },
        {
          name: "甘肃",
          value: 0,
        },
        {
          name: "山西",
          value: 0,
        },
        {
          name: "内蒙古",
          value: 0,
        },
        {
          name: "陕西",
          value: 0,
        },
        {
          name: "吉林",
          value: 0,
        },
        {
          name: "福建",
          value: 0,
        },
        {
          name: "贵州",
          value: 0,
        },
        {
          name: "广东",
          value: 0,
        },
        {
          name: "青海",
          value: 0,
        },
        {
          name: "西藏",
          value: 0,
        },
        {
          name: "四川",
          value: 0,
        },
        {
          name: "宁夏",
          value: 0,
        },
        {
          name: "海南",
          value: 0,
        },
        {
          name: "台湾",
          value: 0,
        },
        {
          name: "香港",
          value: 0,
        },
        {
          name: "澳门",
          value: 0,
        },
      ],
    };
  },
  mounted(){
    
    // this.mapInit();
  },
  watch: {
    mapObj: {
      handler(newV, oldV) {
      if (newV) {
        this.getMapList(newV);
        this.$nextTick(() => {
          this.mapInit();
        })
      }
      
    },
      immediate: true,
    },
  },
  methods: {
    getMapList(val) {
      let list = [];
      for (let key in val) {
        if (key == "totalNum") {
          continue;
        } else {
          let obj1 = { province: key, downNum: val[key] };
          list.push(obj1);
           
        }
      }
      this.dataList.map((item) => {
        list.map((items) => {
          if (items.province.indexOf(item.name) == 0) {
            item.value = items.downNum;
          }
        });
      });
      // if(list.length>0){
      //     list.map((items) => {
      //     this.dataList.map((item) => {
      //         console.log(items.province.indexOf(item.nameit)+"=======判断===============")
      //       if (items.province.indexOf(item.name) == 0) {
      //         item.value = items.downNum;
      //         console.log(items.downNum+"=======值===============")
      //     }
      //     });
      //   });
      // }else{
      //   console.log("=======进初始化===============")
      //   this.mapInit();
      // }
      

      if(list.length < 1) {
          this.dataList.forEach(item => {
              item.value = 0
          })
      }
     list = this.sortKey(list,'downNum');
      if (list.length > 5) {
        this.topList = list.slice(0, 5);
      } else if (list.length < 1) {
        this.topList = [
          {
            province: "",
            downNum: 0,
          },
          {
            province: "",
            downNum: 0,
          },
          {
            province: "",
            downNum: 0,
          },
          {
            province: "",
            downNum: 0,
          },
          {
            province: "",
            downNum: 0,
          },
        ];
      } else {
        list.forEach((item, index) => {
          this.topList[index].province = item.province;
          this.topList[index].downNum = item.downNum;
        });
      }
    },
    sortKey(array,key){//倒叙排序
      return array.sort(function(a,b){
        var x = a[key];
        var y = b[key];
        return ((x>y)?-1:(x<y)?1:0)
      });
   },
   mapInit() {
     document.getElementById('main').removeAttribute('_echarts_instance_')
      // echarts.getInstanceByDom(document.getElementById("main"));
      this.mapInitBox();
    },
	mapInitBox() {
		let map = echarts.init(document.getElementById("main"));
		map.setOption({
		  tooltip: {
		    triggerOn: "mousemove",
		    formatter: function(params) {
		      var a = "<span>" + params.name + "</span>";
		      var b =
		        '<span style="color:' +
		        "#2d82fb" +
		        '">' +
		        params.value +
		        "次</span>";
		      return [a, b].join(" :  ");
		    },
		    backgroundColor: "rgba(29, 38, 71)",
		    // 额外附加的阴影
		    extraCssText: "box-shadow:0 0 4px rgba(11, 19, 43,0.8);",
		  },
		  visualMap: {
		    min: 0,
		    max: 1000,
		    left: 26,
		    bottom: 40,
		    showLabel: !0,
		    inRange: {
		      //定义 在选中范围中 的视觉元素
		      color: ["#b6d0ee"],
		      areaColor: "#f46d43",
		    },
		    show: false,
		  },
		  geo: {
		    map: "china",
		    roam: !1,
		    scaleLimit: {
		      min: 1,
		      max: 2,
		    },
		    zoom: 1,
		    label: {
		      show: false,
		    },
		    emphasis: {
		      label: {
		        show: false,
		      },
		    },
		    itemStyle: {
		      normal: {
		        shadowColor: "rgba(18, 32, 70,0.4)",
		        borderColor: "#fff",
		      },
		      emphasis: {
		        areaColor: "#2d82fb",
		        color: "#fff",
		        shadowOffsetX: 0,
		        shadowOffsetY: 0,
		        borderWidth: 0,
		      },
		    },
		  },
		  series: [
		    {
		      name: "地域分布",
		      type: "map",
		      geoIndex: 0,
		      data: this.dataList,
		    },
		  ],
		},true);
	}
  },
};
</script>

<style lang="scss" scoped>
.tip_title {
  margin: 15px 0;
  font-size: 12px;
  color: #666;
}

.down_right {
  padding: 10 15px;
  .down_top5 {
    font-size: 14px;
    color: #333333;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
  }
}
.top_bang {
  width: 100%;
}
.top_bang_item {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
</style>
