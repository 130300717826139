<template>
  <div>
    <div class="fenye">
      <el-pagination
        :locale="locale"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default defineComponent ({
  setup() {
    return {
      ElConfigProvider,
      locale: zhCn,
    };
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {},
  methods: {
    handleSizeChange(e) {
      this.$emit('pageSizeChange', e)
    },
    handleCurrentChange(e) {
      this.$emit('currentChange',e)
    },
  },
})
</script>
<style lang="scss" scoped>
.fenye {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>