<template>

	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item>超级签名使用教程</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">超级签名使用教程</div>
			<el-divider></el-divider>
			<div class="neitest">超级签名 – 国内首款IPA在线自动签名安装工具，让您的iOS APP无需提交APP store，无需设置 UDID， 无需企业签名，只需开启超级签名 安装
				，即可在让您的app对外一键分发，帮助您快速完成应用安装过程，降低分发成本，提高分发效率。</div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;通过 <a
				 @click="tiaozhuan(1)" href="javascript:void(0)">wkzx.store</a>
				注册并且登陆账号</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/chaoji1.png"></div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;选择<span
				 class="red">应用管理</span>
				页面，点击 <span class="red">添加产品</span>——上传IPA</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/chaoji2.png"></div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;点击
				<span class="red">立即购买</span> 按钮，按需购买
			</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/chaoji3.png"></div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 4&nbsp;&nbsp;</span>&nbsp;选中
				<span class="red">应用管理</span>，选择对应的APP，开启超级签名开关，根据安装包大小等待数秒钟即可开启成功
			</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/chaoji4.png"></div>
			<!-- <div class="dibu">
				<div>164阅读 更新时间 12-1 11:00</div>
				<div>56人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div>
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			tiaozhuan(keyPath) {
				if (keyPath == 1) {
					this.$router.push("/home");
				}
			}
		}
	}
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.red {
		color: red;
	}

	.daohang {
		margin-bottom: 0.625rem;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}

	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
