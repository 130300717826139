<template>
  <div>
    <el-dialog title="购买webClip时长包" v-model="dialogVisibleShow"
      :close-on-click-modal="false" width="792px" :before-close="handleClose">
      <div>
        <div class="sell_header">
          <div :class="item.style" v-for="item in priceList" :key="item.id" @click="sellStyleClick(item)">
            <div style="font-size: 16px;font-weight: bold;color:#333333">
              <span v-if="item.discount == 30">30天/月卡</span>
              <span v-if="item.discount == 90">90天/季卡</span>
              <span v-if="item.discount == 360">360天/年卡</span>
            </div>
            <div style="font-size: 14px;color: #BA812F;font-weight: bold;margin: 10px 0">
              ¥<span style="font-size: 36px">{{ item.totalPrice }}</span>
            </div>
            <!-- <div :class="item.sellZhekouClass">¥{{ item.discount }}</div> -->
          </div>
        </div>
        <div class="sell_footer">
          <div class="sell_left">
            <div class="sell_left_title">选择支付方式</div>
            <div class="sell_btn">
              <div :class="wxPaystyle" @click="wxPay">
                <div>微信支付</div>
                <img v-show="wxPaystyle == 'sell_btn_wx_active'" src="@/assets/image/success1.png"
                  style="width: 15px;height: 15px" />
              </div>
              <div :class="aliPayStyle" @click="zhifuPay">
                <div>支付宝支付</div>
                <img v-show="aliPayStyle == 'sell_btn_zfb_active'" src="@/assets/image/success1.png"
                  style="width: 15px;height: 15px" />
              </div>
            </div>
          </div>
          <div class="sell_erwei" v-loading="wxmaLoading">
            <div id="qrCode" ref="qrCodeDiv" class="sell_erwei_box"></div>
            <!-- v-loading="wxmaLoading" -->
          </div>
          <div class="sell_right">
            <div class="sell_right_title">支付金额</div>
            <div>
              <span v-if="wxPaystyle == 'sell_btn_wx_active'"> 微信支付</span>
              <span v-else>支付宝支付</span>
              <span style="font-size: 14px;color: #BA812F;font-weight: bold;margin-left: 10px">¥<span
                  style="font-size: 36px">{{
                    priceObj.price ? priceObj.price : 0
                  }}</span></span>
              <span v-if="priceObj.openDiscount" class="sell_zhekou" style="display: inline-block;margin-left: 10px">{{
                priceObj.oldPrice ? priceObj.oldPrice : 0 }}</span>
            </div>
            <el-button round size="mini" style="margin-top: 15px" @click="updatePay">刷新</el-button>
            <span style="font-size: 14px;color: #FF4A4A;margin-left: 10px">
              <!-- {{tips}} -->
            </span>
          </div>
        </div>
        <div class="sell_tips">
          在购买下载流量包时有任何疑问请点击<span style="cursor: pointer;color: #0092FF;text-decoration: underline"
            @click="seeUrl">《常见问题》</span>进行咨询！
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getPriceList,
  createNative,
  aliPay,
  queryWXPayStatus,
  getAliPayResult,
} from "@/api/api";
import QRCode from "qrcodejs2";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    iswebClipPay: {
      type: Boolean,
      default: false,
    },
    webclipId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisibleShow: false,
      timer: null, // 定时器
      // 微信支付样式
      wxPaystyle: "sell_btn_wx",
      aliPayStyle: "sell_btn_zfb",
      // sellLoading: false,
      wxmaLoading: false,
      priceList: [],
      // 购买支付对象
      priceObj: {
        price: "",
        oldPrice: "",
      },
      price: {
        orderPriceId: "", // 选择价格id
        productType: "", // 类型 0.下载次数 1.公有设备 2.私有设备 3.webclip 4.个人购买下载时长
        payKey: "",
        userId: "",
        webclipId: "",
      },
      wxOrderId: "", // 微信订单号
    };
  },
  mounted() {
    this.getPriceListInfo();
    this.price.payKey = this.guid();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.price.userId = userInfo[0].userId;
    this.$nextTick(() => {
      this.clearErwei();
    });
  },

  watch: {
    dialogVisible(newValue, oldValue) {
      this.dialogVisibleShow = newValue
    },
    webclipId(newValue, oldValue) {
      this.price.webclipId = newValue
      if(this.price.webclipId) {
        this.wxPay()
      }
    }
  },
  beforeDestory() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    seeUrl() {
      window.open("https://support.qq.com/products/349734/faqs/105066");
    },
    // 清除轮询
    clearfun() {
      clearInterval(this.timer);
      this.timer = null;
    },
    wxPay() {
      this.clearfun();
      this.tips = "";
      this.flagWifi();
      this.wxPaystyle = "sell_btn_wx_active";
      this.aliPayStyle = "sell_btn_zfb";
      this.clearErwei();
      createNative(this.price).then((res) => {
        // this.wxmaLoading = true;
        this.wxOrderId = res.data.out_trade_no;
        if (res.code == 0) {
          this.bindQRCode(res.data.code_url);
          // setTimeout(() => {
          //   this.wxmaLoading = false;
          //   this.bindQRCode(res.data.code_url);
          // }, 500);
          this.timer = window.setInterval(() => {
            setTimeout(() => {
              this.updatePay();
            }, 0);
          }, 5000);
        }
      });
    },
    // 支付宝支付状态
    aliPayStatus() {
      getAliPayResult({ orderNumber: this.price.orderId }).then((res) => {
        // this.wxmaLoading = false;
        if (res.code != 0) {
          this.tips = res.msg;
        } else {
          this.clearfun();
          this.$emit("webClipPay", this.price.orderPriceId);
          this.$emit("saveFunction");
          this.$emit("dialogChange", false);
          this.$message.success(res.msg);
        }
      });
    },
    // 微信支付状态
    updatePay() {
      this.price.orderId = this.wxOrderId;
      // this.wxmaLoading = true;
      if (this.wxPaystyle == "sell_btn_wx_active") {
        this.wxPayStatus();
      }
      if (this.aliPayStyle == "sell_btn_zfb_active") {
        this.aliPayStatus();
      }
    },
    // 微信支付状态
    wxPayStatus() {
      queryWXPayStatus(this.price).then((res) => {
        // this.wxmaLoading = false;
        if (res.code != 0) {
          this.tips = res.msg;
        } else {
          this.clearfun();
          this.$emit("webClipPay", this.price.orderPriceId);
          // this.$emit("saveFunction");
          this.$emit("dialogChange", false);
          this.$router.push("/webClip");
          this.$message.success(res.msg);
          
        }
      });
    },
    zhifuPay() {
      this.clearfun();
      this.flagWifi();
      this.wxPaystyle = "sell_btn_wx";
      this.aliPayStyle = "sell_btn_zfb_active";
      this.clearErwei();
      aliPay(this.price).then((res) => {
        // this.wxmaLoading = true;
        this.wxOrderId = res.data.orderNumber;
        if (res.code == 0) {
          this.bindQRCode(res.data.aliPayQrCode);
          // setTimeout(() => {
          //   this.wxmaLoading = false;
          //   this.bindQRCode(res.data.aliPayQrCode);
          // }, 500);
          this.timer = window.setInterval(() => {
            setTimeout(() => {
              this.updatePay();
            }, 0);
          }, 5000);
        }
      });
    },
    // 判断是否选择流量包
    flagWifi() {
      if (this.priceObj.price == 0) {
        return this.$message.warning("请选择流量包");
      }
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 120,
        height: 120,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    handleClose() {
      this.clearfun();
      this.$emit("dialogChange", false);
    },
    // 获取购买价格表
    getPriceListInfo() {
      getPriceList({ productType: 3 }).then((res) => {
        this.priceList = res.data.orderPriceList;
        this.priceList.forEach((item) => {
          if (item.id == 23) {
            item.style = "sell_item_active";
            item.sellZhekouClass = "sell_zhekou_acitve";
            this.sellStyleClick(item);
            this.wxPay();
          } else {
            item.style = "sell_item";
            item.sellZhekouClass = "sell_zhekou";
          }
        });
      });
    },
    clearErwei() {
      if (document.querySelector("#qrCode")) {
        document.querySelector("#qrCode").innerHTML = "";
      }
    },
    sellStyleClick(val) {
      console.log(val);
      this.clearErwei();

      this.priceObj.price = 0;
      this.priceObj.oldPrice = 0;
      this.price.orderPriceId = val.id;
      this.price.productType = val.producttype;
      this.price.webclipId = this.price.webclipId;
      this.priceObj.price = val.totalPrice;
      this.priceObj.oldPrice = val.totalPrice;


      this.priceList.forEach((item) => {
        if (val.id == item.id) {
          item.style = "sell_item_active";
          item.sellZhekouClass = "sell_zhekou_acitve";
        } else {
          item.style = "sell_item";
          item.sellZhekouClass = "sell_zhekou";
        }
      });
      if (this.wxPaystyle == "sell_btn_wx_active") {
        this.wxPay();
      }
      if (this.aliPayStyle == "sell_btn_zfb_active") {
        this.zhifuPay();
      }
    },
    //用于生成uuid
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    guid() {
      return (
        this.S4() +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        this.S4() +
        this.S4()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 20px;
}

.sell_erwei {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #dddddd;

  .sell_erwei_box {
    width: 120px;
    height: 120px;
    background: #c4c4c4;
  }
}

.sell_zhekou {
  border-radius: 46px;
  background: #ececec;
  width: 47px;
  text-align: center;
  text-decoration: line-through;
}

.sell_zhekou_acitve {
  border-radius: 46px;
  background: #f8e3bf;
  color: #ba812f;
  width: 47px;
  text-align: center;
  text-decoration: line-through;
}

.sell_header {
  display: flex;
  justify-content: space-around;
}

.sell_tips {
  margin-top: 20px;
  font-size: 12px;
}

.sell_right {
  width: 225px;

  .sell_right_title {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin: 20px 0 10px 0;
  }
}

.sell_footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  height: 210px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;

  .sell_left {
    width: 190px;

    .sell_left_title {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 20px;
    }

    .sell_btn {
      .sell_btn_wx_active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        background: #09c965;
        color: #fff;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .sell_btn_wx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        background: rgba(9, 201, 101, 0.2);
        border: 1px solid #09c965;
        box-sizing: border-box;
        color: #09c965;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .sell_btn_zfb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #0092ff;
        box-sizing: border-box;
        border-radius: 10px;
        background: rgba(0, 146, 255, 0.2);
        color: #0092ff;
        cursor: pointer;
      }

      .sell_btn_zfb_active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        background: #0092ff;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.sell_item_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fcf3e6;
  width: 200px;
  height: 154px;
  border-radius: 10px;
  border: 2px solid #ebce9f;
}

.sell_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 200px;
  height: 154px;
  border-radius: 10px;
  border: 2px solid #ebce9f;
}
</style>
