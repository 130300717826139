<template>
    <div>
        <div class="w">
            <div class="box">
                <div class="header">
                    {{formData.title}}
                </div>
                <div class="content">
                    {{formData.content}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { versionLast } from  '@/api/api'
export default {
    data() {
        return {
            formData: {
                title: '',
                content: '',
            }
        }
    },
    created() {
        this.getversionLast();
    },
    methods: {
        async getversionLast() {
            const res = await versionLast()
            if(res.code == 0) {
                this.formData = res.data.ver
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    border-radius: 10px;
    background: #fff;
    height: 500px;
    margin: 20px 0;
    padding: 20px;
}

.content {
    margin-top: 20px;
}
.header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #333333;
    border-bottom: 1px solid #ccc;
}
</style>