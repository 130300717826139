<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" style="margin-top: 30px">
      <el-form-item prop="userName">
        <el-input v-model="form.userName" placeholder="手机号/邮箱"></el-input>
      </el-form-item>
      <el-form-item style="position: relative" prop="code">
        <el-col>
          <el-input v-model="form.code" placeholder="验证码"></el-input>
        </el-col>
        <el-col style="position: absolute; right: 0">
          <el-button @click="getCodeVite" :disabled="!show"
            >获取验证码
            <span v-show="!show" class="count">({{ count }}s)</span>
          </el-button>
        </el-col>
      </el-form-item>

      <el-form-item>
        <my-slider-captcha @isSuccess="isSuccess" @codeData="codeData" :scene="scene"></my-slider-captcha>
        <!-- 使用自定义的滑块验证码组件 -->
        <br />
        <el-button @click="onSubmit" style="width: 100%" type="primary"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { userLogin, getCode } from "@/api/api";
import MySliderCaptcha from "../common/MySliderCaptcha.vue";
export default {
  components: {
    MySliderCaptcha, // 注册自定义组件
  },
  data() {
    return {
      scene: "login",
      count: "", // 初始化次数
      show: true,
      rules: {
        userName: [
          { required: true, message: "请输入手机号/邮箱", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      form: {
        userName: "",
        code: "",
        accountType: 1,
        loginState: 1,
        codeType: 2,
      },
      checkSuccess: false,
      checkData: null,
    };
  },
  methods: {
    codeData(val) {
      this.checkData = val
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 验证码校验
    codeJiaoyan() {
      let params1 = new URLSearchParams();
      params1.append("userName", this.form.userName);
      params1.append("accountType", this.form.accountType);
      params1.append("codeType", this.form.codeType);
      params1.append("sessionId", this.checkData.sessionId)
      params1.append("sig", this.checkData.sig)
      params1.append("sessiontoken", this.checkData.token)
      params1.append("scene", this.scene)
      if (this.form.userName) {
        getCode(params1).then((res) => {
          if (res.code == 0) {
            this.timerChange();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号/邮箱",
        });
      }
    },
    onSubmit() {
      const params = new URLSearchParams();
      params.append("userName", this.form.userName);
      params.append("loginState", this.form.loginState);
      params.append("code", this.form.code);
      this.$refs.form.validate((valid) => {
        if (valid) {
          userLogin(params).then((res) => {
            if (res.code == 0) {
              this.$store.commit("tokenChange", res.data.token);
              window.localStorage.setItem("token", res.data.token);
              this.$store.commit(
                "authenticationChange",
                res.data.authentication
              );

              let obj = {};
              obj.userName = res.data.userName;
              obj.userId = res.data.userId;
              window.localStorage.setItem("userInfo", JSON.stringify([obj]));
              window.localStorage.setItem(
                "authentication",
                res.data.authentication
              );

              if (res.data.authentication == 4) {
                return this.$router.push("/lock/lockUser");
              }
              this.$router.push("/index");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 获取用户类型 邮箱、手机号
    getuserType() {
      if (this.form.userName.includes("@")) {
        this.form.accountType = 2;
      } else {
        this.form.accountType = 1;
      }
    },
    // 获取验证码
    getCodeVite() {
      if (this.form.userName) {
        if (this.checkSuccess) {
          this.getuserType();
          // let params = new URLSearchParams();
          // params.append("userName", this.form.userName);
          // params.append("codeType", this.form.codeType);
          this.codeJiaoyan();
          // getCode(params).then((response) => {
          //   if (response.code == "1") {
          //     this.$message({
          //       type: "warning",
          //       duration: 2000,
          //       message: response.msg,
          //     });
          //   } else {
          //     this.codeJiaoyan();
          //   }
          // });
        } else {
          this.$message.warning("请先通过滑块验证");
        }
      } else {
        this.$message.warning("请输入手机/邮箱");
      }
    },
  },
};
</script>
