<template>
    <div class="contactUs" v-if="isShow">
        <div>
            <div class="top">
                <div style="margin-bottom:15px;">
                    <img style="width:100px;height:29px;" src="../../assets/image/logoF.png" alt="">
                    <img @click="isShow=false" style="float: right;cursor: pointer;" src="../../assets/image/close3.png" alt="">
                </div>
                <div class="hello">你好</div>
                <div class="text">这里是悟空在线团队，欢迎您通过以下方式与我们的支持人员取得沟通，我们将竭诚提供服务咨询</div>
                <div class="top_bot">
                    <div>
                        <img src="../../assets/image/phone3.png" alt="">
                        <span>0371-86550361</span>
                    </div>
                    <div>
                        <img src="../../assets/image/message2.png" alt="">
                        <span>wkzx@wkzx.vip</span>
                    </div>
                </div>
            </div>
            <div style="text-align: center;">
                <img src="../../assets/image/wxsys.png" alt="">
                <div class="addPerson">添加微信联系工作人员</div>
            </div>

        </div>
    </div>
  </template>
  
  <script>

  export default {
    name: "contactUs",
    components: {

    },
    data() {
      return {
        isShow:false,
      };
    },
    created() {
  
    },
    methods: {

    },
  };
  </script>
  
<style lang="scss" scoped>
    .contactUs {
        width: 350px;
        height: 471px;
        background: #fff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px 10px 0px 0px;
        position: fixed;
        bottom: 0;
        right: 110px;
        box-sizing: border-box;
        z-index: 1000;
        .top {
            background: linear-gradient(90deg, #6883FF 0%, #1B31EC 100%);
            padding: 16px 16px 0 16px;
            border-radius: 10px 10px 0px 0px;
            height: 168px;
            margin-bottom: 40px;
            .hello {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                margin-bottom:5px;
                color: #fff;
            }
            .text {
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: rgba(255, 255, 255, 0.8);
            }
            .top_bot {
                position: absolute;
                width: 311px;
                height: 75px;
                background: #fff;
                box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
                border-radius: 5px;
                top: 150px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                :nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                :nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                img {
                    width: 30px;
                    margin-bottom: 6px;
                }
                span {
                    color: #666666;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
        .addPerson {
            color: #999999;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
        }
    }
</style>
  