<template>
  <div>
    <div class="box">
      <img src="@/assets/image/error.png" alt="" />
      <div style="margin-top: 5vw;">实名认证失败</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #fff;
  text-align: center;
}
.box img {
  width: 30vw;
  height: 30vw;
}
</style>
