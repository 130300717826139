<template>
  <div>
    <div class="w">
      <div class="contract_box">
        <div class="contract_left">
          <div
            :class="item.class"
            v-for="item in contractList"
            :key="item.id"
            @click="contractClick(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <person-info v-if="status == 1"></person-info>
        <edit-password v-if="status == 2"></edit-password>
        <real-name-authentication v-if="status == 3"></real-name-authentication>
        <order-query v-if="status == 4"></order-query>
        <control-over-invoices v-if="status == 5"></control-over-invoices>
        <demain-name v-if="status == 6"></demain-name>
      </div>
    </div>
  </div>
</template>

<script>
import personInfo from '@/components/personalData/personInfo'
import editPassword from '@/components/personalData/editPassword'
import orderQuery from '@/components/personalData/orderQuery'
import controlOverInvoices from '@/components/personalData/controlOverInvoices'
import realNameAuthentication from '@/components/personalData/realNameAuthentication'
import demainName from '@/components/personalData/demainName'
export default {
  name: "personalData",
  components: {
      personInfo,
      editPassword,
      orderQuery,
      controlOverInvoices,
      realNameAuthentication,
      demainName
  },
  data() {
    return {
      status: "",
      contractList: [
        {
          id: 1,
          name: "个人资料",
          class: "contract_item",
        },
        {
          id: 2,
          name: "修改密码",
          class: "contract_item",
        },
        {
          id: 3,
          name: "实名认证",
          class: "contract_item",
        },
        {
          id: 4,
          name: "订单查询",
          class: "contract_item",
        },
        {
          id: 5,
          name: "发票管理",
          class: "contract_item",
        },
        {
          id: 6,
          name: "域名绑定",
          class: "contract_item",
        },
      ],
    };
  },
  created() {
    this.status = this.$route.query.status
    if(this.status) {
      this.selectContract(this.status)
    }
  },
  methods: {
    selectContract(val) {
      this.contractList.forEach((item) => {
        if (item.id == val) {
          item.class = "contract_item_active";
        } else {
          item.class = "contract_item";
        }
      });
    },
    contractClick(val) {
      this.status = val.id;
      this.selectContract(val.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.contract_box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 106px;
  color: #666666;
}
.contract_left {
  width: 260px;
  height: 324px;
  background: #fff;
  border-radius: 10px;
}
.contract_item {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 30px;
  box-sizing: border-box;

  font-size: 14px;
}
.contract_item_active {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 30px;
  box-sizing: border-box;

  font-size: 14px;
  color: #0092ff;
}
.contract_item_active::after {
  content: "|";
  position: absolute;
  color: #0092ff;
  font-weight: 700;
  left: 0;
}
</style>
