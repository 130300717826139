<template>
	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item>内测分发——常见问题</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">内测分发——常见问题</div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;此安装方式是否需要苹果设备越狱？</div>
			<div class="neitest">无需设备越狱即可安装。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;是否需要内测分发才能安装？</div>
			<div class="neitest">不需要内测分发，上传之后开启内测分发即可内测分发。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;是否需要提供UDID？</div>
			<div class="neitest">无需提供UDID，全程自动化。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 4&nbsp;&nbsp;</span>&nbsp;签名需要多长时间？</div>
			<div class="neitest">我们的签名是完全自动化的，一般可在数秒内完成。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 5&nbsp;&nbsp;</span>&nbsp;安装之后是否需要信任？</div>
			<div class="neitest">不需要，安装之后即可使用，无需频繁信任设置。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 6&nbsp;&nbsp;</span>&nbsp;是否存在企业分发经常掉签造成APP无法使用的情况？</div>
			<div class="neitest">不保证百分之百不出现，但是概率相对企业签较低。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 7&nbsp;&nbsp;</span>&nbsp;
				什么是私有池？</div>
			<div class="neitest">私有池：使用用户手动添加的个人账号给已上传并选择使用私有版的应用进行签名服务，以设备量为计费方式 私有池，在服务期间若账号发生掉签状况，用户重新下载安装会再次消耗安装流量。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 8&nbsp;&nbsp;</span>&nbsp;
				什么是公有池？</div>
			<div class="neitest">公有池：使用悟空在线提供的个人账号给已上传并选择使用私有版的应用进行签名服务，以设备量为计费方式 公有池，在服务期间若账号发生掉签状况，用户重新下载安装会再次消耗安装流量。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 9&nbsp;&nbsp;</span>&nbsp;私有池添加苹果开发者账号收费吗？</div>
			<div class="neitest">不收费，添加账号是完全免费的。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 10&nbsp;&nbsp;</span>&nbsp;对安装包大小有限制么？？</div>
			<div class="neitest">上传是没有限制的，但是考虑到宽带以及流量成本问题如果您的如果您的app超过1G，您在通过私有池使用VIP时，同一台设备同一个账号下在使用VIP私有设备数时会消耗两点下载，公有池设备数扣费不受安装包大小限制！</div>

			<!-- <div class="dibu">
				<div>1270阅读 更新时间 11-28 16:00</div>
				<div>290人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div>
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.daohang {
		margin-bottom: 0.625rem;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}

	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-bottom: 3rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
