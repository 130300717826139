<template>
  <div>
    <div class="contract_right">
      <div class="title">个人资料</div>
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item label="姓名" prop="email" label-width="80px">
          <el-input
            v-model="form.email"
            placeholder="输入名字"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="telephone" label-width="80px">
          <el-input
            v-model="form.telephone"
            placeholder="输入手机号"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="QQ" prop="qq" label-width="80px">
          <el-input
            v-model="form.qq"
            placeholder="输入QQ号"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName" label-width="80px">
          <el-input
            v-model="form.companyName"
            placeholder="输入公司名称"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { userInfo,changeUserInfo } from '@/api/api';
export default {
  data() {
    return {
      form: {
        email: "",
        telephone: "",
        qq: "",
        companyName: "",
      },
      rules: {
        email: [{ required: true, message: "请输入绑定邮箱", trigger: "blur" }],
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        qq: [{ required: true, message: "请输入QQ号", trigger: "blur" }],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    // 获取个人资料
    getUserInfo() {
      userInfo().then(res => {
        this.form.email = res.data.info.email
        this.form.telephone = res.data.info.telephone
        this.form.qq = res.data.info.qq
        this.form.companyName = res.data.info.companyName
      })
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveInfo()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveInfo() {
      let params = new URLSearchParams();
      params.append("email", this.form.email);
      params.append("telephone", this.form.telephone);
      params.append("qq", this.form.qq);
      params.append("companyName", this.form.companyName);
      changeUserInfo(params).then(res => {
        if(res.code == 0) {
          this.$message.success('保存成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  width: 920px;
  height: 460px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
</style>
