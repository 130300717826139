<template>
  <div class="icpServices">
    <div class="apply">
        <div class="applyTop">
          <div class="text_top">互联网信息经营许可证（ICP）代理</div>
          <div class="text">
            <span>
              悟空在线提供专业的互联网信息经营许可证（ICP）代理服务，
            专业人士对接，无需前往现场，极速拿照，助力企业资质安全，合规合法经营
            </span>
          </div>
          <div class="contact" @click="getAlert">联系我们</div>
        </div>
    </div>
    <div class="licence">
      <div class="text">为什么需要互联网信息经营许可证</div>
      <div class="middle">
        <div>
          <img src="../../assets/image/softness1.png" alt="" />
          <span>合法合规重要依据</span>
        </div>
        <div>
          <img src="../../assets/image/softness2.png" alt="" />
          <span>保障持续经营</span>
        </div>
        <div>
          <img src="../../assets/image/softness3.png" alt="" />
          <span>行业准入资质</span>
        </div>
        <div>
          <img src="../../assets/image/softness4.png" alt="" />
          <span>商业合作前置条件</span>
        </div>
        <div>
          <img src="../../assets/image/softness5.png" alt="" />
          <span>知名网站实力体现</span>
        </div>
      </div>
    </div>
    <div class="website">
      <div class="website_content">
        <div class="top">拥有互联网信息经营许可证你能做什么</div>
        <div class="mid">
          <div>
            <img src="../../assets/image/website1.png" alt="" />
            <span>商类网站</span>
          </div>
          <div>
            <img src="../../assets/image/website2.png" alt="" />
            <span>互联网广告</span>
          </div>
          <div>
            <img src="../../assets/image/website3.png" alt="" />
            <span>游戏网站</span>
          </div>
          <div>
            <img src="../../assets/image/website4.png" alt="" />
            <span>在线交易类网站</span>
          </div>
          <div>
            <img src="../../assets/image/website5.png" alt="" />
            <span>导购类网站</span>
          </div>
        </div>
        <div class="bot">
          <div style="margin-left: 30px;width: 168px;">互联网有偿信息服务，包括</div>
          <div>
            <img src="../../assets/image/websiteIcon1.png" alt="" />
            <span>在线销售</span>
          </div>
          <div>
            <img src="../../assets/image/websiteIcon2.svg" alt="" />
            <span>在线支付</span>
          </div>
          <div>
            <img src="../../assets/image/websiteIcon3.png" alt="" />
            <span>广告招商</span>
          </div>
          <div>
            <img src="../../assets/image/websiteIcon4.png" alt="" />
            <span>会员收费</span>
          </div>
          <div>
            <img src="../../assets/image/websiteIcon5.png" alt="" />
            <span>企业合作</span>
          </div>
          <div>
            <img src="../../assets/image/websiteIcon6.png" alt="" />
            <span>项目投标</span>
          </div>
        </div>
      </div>
    </div>
    <div class="condition">
      <div class="top">申请所需要满足的条件</div>
      <div class="mid">
        <div class="left">
          <div>
            <img src="../../assets/image/correct.png" alt="">
            <span>经营者为依法设立的公司。</span>
          </div>
          <div>
            <img src="../../assets/image/correct.png" alt="">
            <span>有与开展经营活动相适应的资金和专业人员。</span>
          </div>
          <div>
            <img src="../../assets/image/correct.png" alt="">
            <span>有为用户提供长期服务的信誉或者能力。</span>
          </div>
          <div>
            <img src="../../assets/image/correct.png" alt="">
            <span>在省、自治区、直辖市范围内经营的，注册资本最低限额为100万元人民币;在全国或者跨省
              、自治区、直辖市范围经营的，注册资本最低限额为1000万元人民币。</span>
          </div>
          <div>
            <img src="../../assets/image/correct.png" alt="">
            <span>有必要的场地、设施及技术方案。</span>
          </div>
          <div>
            <img src="../../assets/image/correct.png" alt="">
            <span>公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录。国家规定的其他条件。</span>
          </div>
        </div>
        <div>
          <img src="../../assets/image/condition.png" style="margin-left: 60px;margin-top: 30px;" alt="">
        </div>
      </div>
    </div>
    <div class="problem">
      <div class="question">常见问题</div>
      <div class="problem_title">什么是互联网经营许可证（ICP）？是否有法律依据？</div>
      <div class="problem_content">中华人民共和国电信与信息服务业务经营许可证（简称：ICP许可证）是指一般性经营性网站的主办者向当地区县申请的证书证明，
        即《中华人民共和国电信与信息服务业务经营许可证》。ICP证是网站经营的许可证，根据国家《互联网信息服务管理办法》的相关规定，
        经营性网站必须办理ICP证，否则就属于非法经营。国家对经营性ICP实行许可证制度;
      </div>
      <div class="problem_title">什么样的企业需要互联网经营许可证（ICP）？</div>
      <div class="problem_content" style="margin-bottom:35px;">1. 确定是否为经营性网站，利用互联网开展相关业务以盈利为目的企业。 
      2. 一般经营性网站，例如：分类信息网站(58同城、赶集)、社交平台、人才招聘、付费下载、网站制作等网站。 
      3. 涉及前置审批的特殊行业网站，例如：游戏、音乐、直播、动漫、新闻、网络文学、网络学术报告、网络电子杂志、视频、教育、医疗保健信息、药品信息、
      医疗器械等涉及前置审批的行业需要取得行业主管部门批准文件然后申请ICP许可证。
      </div>
      <div class="problem_title">互联网经营许可证通常办理周期有多久？</div>
      <div class="problem_content">由于各省份资质审核要求差异，最快3个工作日提交，提交后5个工作日被受理，受理后有关部门将正式审核并出证；
         ICP申办周期较长，一般需要20-60个工作日，请您优先咨询，耐心等待。
      </div>
    </div>
    <contactUs ref="contactUs"></contactUs>
  </div>
</template>
<script>
import contactUs from "@/views/applySoftness/contactUs";

export default {
  name: "icpServices",
  components: {
    contactUs
  },
  data() {
    return {
      isShow:false
    };
  },
  created() {
  },
  methods: {
    getAlert(){
      this.$refs.contactUs.isShow = true
    },
    // 实名认证
    personAuthClick() {
      // this.$router.push("/personalData?status=3");               
    },
  },
};
</script>
<style lang="scss" scoped>
  .icpServices {
    margin-bottom: 30px;
    font-family: 'PingFang SC';
    font-style: normal;
  }
  .apply {
    height: 470px;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    background: url("../../assets/image/applybg.jpg") no-repeat center top;
    // background-color: #e1e1e1;
    .applyTop {
      text-align: center;
      .text_top {
        color: #fff;
        font-weight: 500;
        font-size: 40px;
        line-height: 56px;
        padding-top: 130px;
      }
      .text {
        color: #9FABBD;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        width: 600px;
        transform: translate(-50%, 0);
        margin: 42px 0 60px 50%;
      }
      .contact {
        width: 140px;
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        color: #1B31EC;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .licence {
      height: 360px;
      background-color: #F1F6F9;
      text-align: center;
      .text {
        padding-top: 100px;
        color: #333333;
        font-weight: 500;
        font-size: 25px;
        line-height: 35px;
      }
      .middle {
        margin-top: 50px;
        display: flex;
        width: 1200px;
        margin: 0 auto;
        div {
          width: 240px;
          height: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          span {
            margin-top: 25px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }

  }
  .website {
    height: 415px;
    background-color: #fff;
    text-align: center;
    .website_content {
      width: 1040px;
      height: 415px;
      margin: auto;
      .top {
        color: #333333;
        padding-top: 80px;
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 25px;
        line-height: 35px;
      }
      .mid {
        width: 1040px;
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          width: 200px;
          height: 85px;
          line-height: 85px;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          background: #F1F6F9;
          border-radius: 10px;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 15px;
          }
        }
      }
      .bot {
        width: 1040px;
        display: flex;
        // justify-content: space-between;
        background: #F1F6F9;
        border-radius: 10px;
        height: 85px;
        margin-top: 10px;
        line-height: 85px;
        div {
          width: 106px;
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          margin-right: 25px;
          align-items: center;
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  .condition {
    background: linear-gradient(90deg, #C1D3FF 0%, #D1EEFF 100%);
    height: 332px;
    position: relative;
    .top {
      text-align: center;
      color: #333333;
      padding-top: 70px;
      font-weight: 500;
      font-size: 25px;
      line-height: 35px;
    }
    .mid {
      width: 1200px;
      background: #fff;
      height: 366px;
      position: absolute;
      top: 150px;
      border-radius: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      box-sizing: border-box;
      padding-top: 60px;
      display: flex;
      .left {
        margin-left: 90px;
        width: 570px;
        height: 250px;
        div {
          display: flex;
          vertical-align: middle;
          align-items: flex-start;
        }
        img {
          margin-right: 10px;
          vertical-align: middle;
          margin-top: 5px;
        }
        span {
          text-align: left;
          line-height: 30px;
        }
        p {
          text-align: left;
          line-height: 30px;
        }
      }
    }
  }
  .problem {
    height: 720px;
    padding-top: 179px;
    box-sizing: border-box;
    text-align: center;
    width: 800px;
    margin: auto;
    text-align: left;
    .question {
      margin-top: 50px;
      margin-bottom: 30px;
      color: #333333;
      font-weight: 500;
      font-size: 25px;
      line-height: 35px;
      text-align: center;
    }
    .problem_title {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
      margin-bottom: 10px;
    }
    .problem_content {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-bottom: 20px;
    }
  }
</style>
