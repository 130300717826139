<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" style="margin-top: 30px;">
      <el-form-item prop="userName">
        <el-input v-model="form.userName" placeholder="手机"></el-input>
      </el-form-item>
      <el-form-item style="position:relative" prop="code">
        <el-col>
          <el-input v-model="form.code" placeholder="验证码"></el-input>
        </el-col>
        <el-col style="position:absolute;right: 0">
          <el-button @click="getCodeNm" :disabled="!show" style="width: 148px"
            >获取验证码 
            <span v-show="!show" class="count">({{ count }}s)</span>
          </el-button>
        </el-col>
      </el-form-item>
      <el-form-item>
        <my-slider-captcha @isSuccess="isSuccess" @codeData="codeData" :scene="scene"></my-slider-captcha>
        <!-- 使用自定义的滑块验证码组件 -->
        <br />
        <el-button @click="onSubmit" style="width: 100%">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { codeVerify, getCode } from "@/api/api";
import MySliderCaptcha from "../common/MySliderCaptcha.vue";
export default {
  components: {
    MySliderCaptcha, // 注册自定义组件
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
    return {
      scene: "forget",
      checkSuccess: false,
      checkData: null,
      count: "", // 初始化次数
      show: true,
      rules: {
        userName: [{ validator: checkPhone, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      form: {
        userName: "",
        code: "",
        accountType: 1,
        loginState: 1,
        codeType: 2,
      },
    };
  },
  methods: {
    codeData(val) {
      this.checkData = val
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 验证码校验
    codeJiaoyan() {
      let params1 = new URLSearchParams();
      params1.append("userName", this.form.userName);
      params1.append("accountType", this.form.accountType);
      params1.append("codeType", this.form.codeType);
      params1.append("sessionId", this.checkData.sessionId)
      params1.append("sig", this.checkData.sig)
      params1.append("sessiontoken", this.checkData.token)
      params1.append("scene", this.scene)
      if (this.form.userName) {
        getCode(params1).then((res) => {
          if (res.code == 0) {
            this.timerChange();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号/邮箱",
        });
      }
    },
    onSubmit() {
      let params = new URLSearchParams();
      params.append("code", this.form.code);
      params.append("userName", this.form.userName);
      codeVerify(params).then((response) => {
        if (response.code == "2") {
          this.$message({
            type: "error",
            duration: 2000,
            message: response.msg,
          });
        } else {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$router.push({
                path: "/password",
                query: {
                  userName: this.form.userName,
                  accountType: this.form.accountType,
                  type: "forget",
                },
              });
            }
          });
        }
      });
    },
    // 获取验证码
    getCodeNm() {
      if (!this.checkSuccess) {
        return this.$message.warning("请先通过滑块验证");
      }
      if(!this.form.userName) return this.$message.warning('请输入手机号')
	    this.codeJiaoyan();
   //    let params = new URLSearchParams();
   //    params.append("userName", this.form.userName);
   //    params.append("codeType", this.form.codeType);
   //    params.append("accountType", this.form.accountType);
   //    getCode(params).then((response) => {
   //      if (response.code == "1") {
			
   //        this.$message({
   //          type: "warning",
   //          duration: 2000,
   //          message: response.msg,
   //        });
   //      } 
   //    });
    },
  },
};
</script>
