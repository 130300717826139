<template>
  <div>
    <div class="content">
      <div class="w">
        <div class="search">
          <el-input
            placeholder="输入名称"
            v-model="displayName"
            style="width: 375px"
          >
            <template #append>
              <el-button icon="el-icon-search" @click="searchClick"></el-button>
            </template>
          </el-input>
        </div>

        <div class="upLoad_box">
          <div class="upload_item" >
            <div class="icon_img" @click="updateClick">
              <img src="../../assets/image/webClip.png" />
              <div>创建Web Clip</div>
            </div>
            <div class="tips_auth" @click="personAuthClick" v-if="authShow">
              <span v-if="authentication == 2">
                未通过
              </span>  
               <span v-if="authentication == 1">
                 审核中
              </span> 
               <span v-else>
                 请实名认证后上传应用
              </span> 
            </div>
            <div class="upload_bottom">
              <el-popover
                placement="top"
                title="注意："
                :width="200"
                trigger="hover"
                content="注意：在创建Web Clip之前，请确保您的App符合悟空在线的悟空在线应用审核规则，违反该规则的App将被删除，屡次上传者将被封禁账号。技术支持微信：wkzx2020"
              >
                <template #reference>
                  <div>
                    <i class="iconfont icon-tanhao"></i>
                    <span style="margin-left: 5px">注意事项</span>
                  </div>
                </template>
              </el-popover>
            </div>
          </div>
          <div class="upload_item2" v-for="item in webclipList" :key="item.id">
            <div class="upload_header">
              <div class="top_icon">
                <div class="tab_icon">
                  webClip
                </div>
                <div style="float:right">
                  <i class="iconfont icon-pingguo" style="color: #999999;font-size: 20px"></i>
                </div>
              </div>
              <!-- <hr style="color: #E4E4E4" /> -->
            </div>
            <div class="upload_content">
              <div class="img">
                <img :src="item.iconUrl" />
                <div class="text">{{ item.label }}</div>
              </div>
              <div class="lianjie">
                <div class="lianjie_title">
                  <div>短链接:</div>
                  <div>唯一标识:</div>
                  <div>最新版本:</div>
                  <div>访问地址:</div>
                  <div>审核状态:</div>
                  <div>分发时长:</div>
                </div>
                <div class="lianjie_title1">
                  <div>
                    {{ item.remarks1 }}
                  </div>
                  <div>{{ item.identifier }}</div>
                  <div>{{ item.version }}</div>
                  <div>
                    {{ item.targetUrl }}
                  </div>
                  <div style="color:#E6A23C" v-if="item.remarks8 == 1">
                    审核中
                  </div>
                  <div style="color: #67C23A" v-if="item.remarks8 == 2">
                    已通过
                  </div>
                  <div style="color: #F56C6C" v-if="item.remarks8 == 3">
                    审核失败
                  </div>
                  <div v-if="item.timeLeft == 0">
                    <span style="color:red">已过期</span>
                    <span>，请</span>
                    <span
                      style="color:red;cursor: pointer;"
                      @click="sellClick(item)"
                      >[购买时长]</span
                    >
                  </div>
                  <div v-else>{{ item.timeLeft }}天</div>
                </div>
                <div style="color: #C7C7C7;margin-top: 5px">
                  <i
                    class="iconfont icon-fuzhi"
                    :id="'copyClass' + item.id"
                    :data-clipboard-text="item.remarks1"
                    style="cursor: pointer;"
                    @click="copy('copyClass' + item.id)"
                  ></i>
                </div>
              </div>
              <div class="upload_bottom">
                <el-button
                  size="mini"
                  style="width: 58px;height: 34px;padding: 0"
                  @click="editWebClip(item)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  style="width: 58px;height: 34px;padding: 0"
                  @click="seeClick(item)"
                  >预览</el-button
                >
                <el-button
                  size="mini"
                  style="width: 58px;height: 34px;padding: 0"
                  @click="sellalignClick(item)"
                  >续费</el-button
                >
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  style="width: 35px;height: 34px;padding: 0"
                  @click="delClick(item.id)"
                ></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sell-web-clip
      v-show="dialogVisible"
      :dialogVisible="dialogVisible"
      :iswebClipPay="true"
      :webclipId = "webClipId"
      @dialogChange="dialogChange"
      @webClipPay="webClipPay"
    ></sell-web-clip>
  </div>
</template>
<script>
import sellWebClip from "@/views/webClip/common/sellWebClip";
import Clipboard from "clipboard";
import { domainName,getDomain,isEmpty } from "@/common/js/common.js";
import { getWebclip, deleteWebclip,updateWebClipPayTime,getSLD } from "@/api/api";
export default {
  name: "webClip",
  components: {
    sellWebClip,
  },
  inject:["reload"],
  data() {
    return {
      displayName: "", // 搜索条件
      webclipList: [],
      loading: false,
      dialogVisible: false,
      authShow: false,
      webClipId:"",
      authentication: 0,//0未认证1.已提交2.未通过3.已通过4.封禁
      userId:'',
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if(!isEmpty(userInfo)) {
      this.userId = userInfo[0].userId;
    }
    this.getWebclipList();
  },
  methods: {
    // 实名认证
    personAuthClick() {
      this.$router.push("/personalData?status=3");
    },
    sellalignClick(val) {
      this.webClipId = val.id;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    dialogChange(val) {
      this.dialogVisible = val;
      this.getWebclipList()
    },
    // 购买时长
    sellClick(val) {
      console.log(val.id+"---------");
      this.webClipId = val.id;
      this.dialogVisible = true;
    },
    webClipPay(val){
      console.log("=============webclip购买时长回调测试========="+val);
       updateWebClipPayTime({id:this.webClipId,orderPriceId:val}).then((res) =>{
        if(res.code == 0){
          this.$message.success(res.msg);
          this.getWebclipList();
        }else{
          this.$message.error(res.msg);
        }
      });
    },
    // 预览
    seeClick(val) {
      let routeData = this.$router.resolve({
        path: "/webClipPreview",
        query: {
          id: val.id,
          userId: val.userId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 复制
    copy(val) {
      let className = "#" + val;
      var clipboard = new Clipboard(className);
      clipboard.on("success", (e) => {
        console.log(e);
        this.$message.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e);
        this.$message.error("该浏览器不支持复制");
        // 不支持复制
        clipboard.destroy();
      });
    },
    // 搜索
    searchClick() {
      this.getWebclipList({ displayName: this.displayName });
    },
    // 删除应用
    delClick(val) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          deleteWebclip({ id: val }).then((res) => {
            this.loading = false;
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.getWebclipList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查询weblcip
    async getWebclipList(val) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (isEmpty(userInfo)) {
        return this.$router.push("/login");
      }
      this.loading = true;
      await getWebclip(val).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          if(res.data.authentication != 3){
            this.authShow = true;
            this.authentication = res.data.authentication;
          }else{
            this.authShow = false;
          }
          this.webclipList = res.data.webclipList;
        }
      });
      console.log(this.webclipList)
      // 修改域名
      if(this.webclipList.length > 0 ) {
          console.log(this.userId)
          const res2 = await getSLD(this.userId)
          console.log(res2)
          let sld = res2.data.sld;
          if(isEmpty(sld)) {
            for (let i = 0; i < this.webclipList.length; i++) {
              let el = this.webclipList[i];
              let res = getDomain(el.remarks1);
              el.remarks1 = res
            }
          }else {
            for (let i = 0; i < this.webclipList.length; i++) {
              let el = this.webclipList[i];
              let short =el.remarks1.substring(el.remarks1.indexOf("/",el.remarks1.indexOf("/") + 2)) 
              console.log(short)
              el.remarks1 = 'http://'+ sld + short;
              console.log(el.remarks1)
            }
          }
        }
    },
    editWebClip(val) {
      this.$router.push({
        path: "/updateWebClip",
        query: {
          id: val.id,
          userId: val.userId,
        },
      });
    },
    updateClick() {
      if(this.authShow){
        return this.$message.error("请实名认证成功后,再创建web clip");
      }
      this.$router.push("/submitWebClip");
    },
  },
};
</script>
<style lang="scss" scoped>
.tips_auth {
  position: absolute;
  line-height: 30px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom:110px;
  transform: translate(-50%, 0);
  border-radius: 5px;
  // margin-top: 20px;
  cursor: pointer;
  height: 30px;
  width: 180px;
  font-size: 14px;
  color: #0092ff;
  background: #fff;
}
.icon-fuzhi:hover {
  color: #0092ff;
}
.content {
  margin-bottom: 30px;
}
.top_icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e4e4;
}
.tab_icon {
  width: 55px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 5px;
  color: #fff;
  background: linear-gradient(to right, #1f43ff, #c51fff);
}
.search {
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.upload_bottom {
  display: flex;
  position: absolute;
  bottom: 20px;
  padding: 0 15px;
  justify-content: space-around;
}
.lianjie {
  display: flex;
  justify-content: space-around;
  padding: 0 15px;
  .lianjie_title {
    font-size: 12px;
    color: #666666;
    margin-right: 15px;
    div {
      width: 57px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 6px 0;
    }
  }
}
.lianjie_title1 {
  font-size: 12px;
  width: 125px;
  color: #666666;
  margin-right: 6px;
  div {
    width: 100%;
    padding: 6px 0;
    //超出部分显示...
    text-overflow: ellipsis;
    //超出部分隐藏
    overflow: hidden;
    //文本禁止换行
    white-space: nowrap;
  }
}
.upLoad_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  .upload_item2 {
    position: relative;
    width: 284px;
    height: 436px;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 0 10px 10px 10px;
    background: #fff;
    margin-right: 16px;
    .upload_content {
      width: 100%;
      .img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        img {
          margin-bottom: 20px;
          width: 80px;
          height: 80px;
          border-radius: 16%;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }
  .upload_item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 16px;
    margin-top: -20px;
    width: 284px;
    height: 436px;
    background: #0092ff;
    border-radius: 10px;
    .icon_img {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 68px;
        height: 68px;
      }
      div {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
    }

    .upload_bottom {
      position: absolute;
      bottom: 20px;
      padding: 0 15px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fec943;
    }
    ::v-deep .el-upload-dragger {
      width: 284px;
      height: 100%;
      border: 0;
      background: #0092ff;
      .el-icon-upload {
        color: #fff;
        margin-top: 180px;
      }
      .el-upload__text {
        color: #fff;
      }
    }
  }
}
</style>
