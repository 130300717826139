<template>
	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item>超级签名常见问题</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">超级签名常见问题</div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;此安装方式是否需要苹果设备越狱？</div>
			<div class="neitest">无需设备越狱即可安装。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;是否需要企业签名才能安装？</div>
			<div class="neitest">不需要企业签名，上传之后开启超级签名即可内测分发。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;是否需要提供UDID？</div>
			<div class="neitest">无需提供UDID，全程自动化。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 4&nbsp;&nbsp;</span>&nbsp;签名需要多长时间？</div>
			<div class="neitest">我们的签名是完全自动化的，一般可在数秒内完成。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 5&nbsp;&nbsp;</span>&nbsp;安装之后是否需要信任？</div>
			<div class="neitest">不需要，安装之后即可使用，无需频繁信任设置。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 6&nbsp;&nbsp;</span>&nbsp;是否存在企业分发经常掉签造成APP无法使用的情况？</div>
			<div class="neitest">不保证百分之百不出现，但是概率相对企业签较低。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 7&nbsp;&nbsp;</span>&nbsp;
				什么是私有池？</div>
			<div class="neitest">私有池：使用用户手动添加的个人账号给已上传并选择使用私有版的应用进行签名服务，以设备量为计费方式 私有池，在服务期间若账号发生掉签状况，用户重新下载安装会再次消耗安装流量。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 8&nbsp;&nbsp;</span>&nbsp;
				什么是公有池？</div>
			<div class="neitest">公有池：使用悟空在线提供的个人账号给已上传并选择使用私有版的应用进行签名服务，以设备量为计费方式 公有池，在服务期间若账号发生掉签状况，用户重新下载安装会再次消耗安装流量。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 9&nbsp;&nbsp;</span>&nbsp;私有池添加苹果开发者账号收费吗？</div>
			<div class="neitest">不收费，添加账号是完全免费的。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 10&nbsp;&nbsp;</span>&nbsp;同一台设备安装不同应用或版本，消耗设备量和签名流量吗？</div>
			<div class="neitest">同一台设备同一个账号下无论安装多少 App、或多次安装同一个 App，都只消耗一个设备个数。但是设备对应账号被封导致需要重新分配账号的则会新扣设备数！</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 11&nbsp;&nbsp;</span>&nbsp;
				支持哪些苹果开发者账号或者证书？</div>
			<div class="neitest">目前支持苹果个人开发者账号和公司账号。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 12&nbsp;&nbsp;</span>&nbsp;添加的账号需要满足什么条件？</div>
			<div class="neitest">首先添加的账号需要购买个人开发者服务并且苹果审核通过，其次添加账号前请确保该账号下至少还能创建一个开发者证书，并且添加账号之后请务必不要删除账号下我们添加的证书以免造成应用出现无法安装或者闪退的情况。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 13&nbsp;&nbsp;</span>&nbsp;超级签名服务买完设备数之后还需要购买下载次数包么？</div>
			<div class="neitest">不需要，超级签只需按需购买设备数即可，没有其他任何费用。下载次数是针对安卓app或者企业签过的app在分发的过程中超过一定分发次数之后产生的额外流量进行的收费。</div>

			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 14&nbsp;&nbsp;</span>&nbsp;
				为什么我在没有换设备使用的情况下会提示”设备数已用完，请联系开发者购买VIP设备”？</div>
			<div class="neitest">出现这个提示的原因是您这台设备对应的账号被封（掉签）所致，当您删除重装的时候需要分配新的开发者账号给您，但是此时您没有多余可用的设备数就会有此提示。出现这种情况，您只需购买新的设备即可，系统会分配新的账号供您使用！
				很多人会问为什么要重新购买，因为账号被封是苹果根据机制进行随机查封，被封之后平台也是需要和苹果重新购买申请的。</div>

			<!-- <div class="dibu">
				<div>8阅读 更新时间 10-13 17:19</div>
				<div>3人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div>
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}
	.daohang {
		margin-bottom: 0.625rem;
	}
	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-bottom: 3rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
