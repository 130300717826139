<template>
  <div style="height:100%">
    <template1 :form="info" v-if="softTemplet == 1"></template1>
    <template2
      :imageList="imageList"
      :form="info"
      v-if="softTemplet == 6"
    ></template2>
    <template3 :form="info" v-if="softTemplet == 7"></template3>
    <!-- <password-info
      v-if="softTemplet == 8"
      :passwordObj="passwordObj"
      :vip="true"
      :UDID="UDID"
      :bannerImg="bannerImg"
      :link="link"
      :controlState="controlState"
    ></password-info> -->
  </div>
</template>
<script>
import { vipMerge,getDownSoftMessage } from "@/api/api";
import mixin from "@/mixin"; // 引入mixin文件
import template1 from "@/views/appDown/template/template1";
import template2 from "@/views/appDown/template/template2";
import template3 from "@/views/appDown/template/template3";
import passwordInfo from "@/views/appDown/passwordInfo";

export default {
  mixins: [mixin],
  components: {
    template1,
    template2,
    template3,
    passwordInfo,
  },
  data() {
    return {
      imageList: [],
      info: {},
      softId: "",
      url: "",
      form: {},
      softTemplet: "", // 应用模板
      isShowPsd:false,
      passwordObj:{},
    };
  },
  created() {
    this.softId = this.$route.query.softId;
    this.url = this.$route.query.softUrl;
    this.getAppShow();
  },
  methods: {
    getvipInfo() {
      vipMerge({ softId: this.softId }).then((res) => {
        console.log(res)
        if(res.code == 3) {
          this.$router.push({
              path: "/errorPage",
              query: {
                tips: "短链接不存在或已下架",
              },
            });
            return
        }
        this.appInfo(res);
      });
    },
    appInfo(res) {
      console.log(res)
      if (res.data.info.softTemplet == 1) {
        // if(res.data.info.thirdurl) {
        //     return window.location.href = 'http://' + res.data.info.thirdurl;
        // }
        this.info = res.data.info;
      }
      if (res.data.info.softTemplet == 6 || res.data.info.softTemplet == 7) {
      //  if(res.data.info.thirdurl) {
      //       return window.location.href = 'http://' + res.data.info.thirdurl;
      //   }
       this.info = res.data.info;
        this.imageList = res.data.imageList;
      }
      this.softTemplet = res.data.info.softTemplet;
      if(!this.getBrower().mobile) {
        this.softTemplet = 1
     }
    },
    getAppShow() {
      var that = this;
      getDownSoftMessage({softId:that.softId,url: that.url})
        .then((res) => {
          console.log(res)
          console.log(1111111111111111)
          if (res.code == 3) {
            that.getvipInfo();
          } else {
            if(res.code == 1) {
                this.$router.push({
                path: "/errorPage",
                query: {
                  tips: res.msg,
                },
              });
              return
            }
            that.appInfo(res);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
