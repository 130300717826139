<template>
  <div>
    <div class="header">
      <div class="w">
        <img class="img" src="@/assets/image/logoF.png" alt="" />
        <div class="nav-style">
          <div
            :class="item.style"
            v-for="item in navList"
            :key="item.id"
            @click="selectClick(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="banner1">
      <img src="@/assets/image/logoF2.png" alt="" />
      <div class="title1">专业的技术团队</div>
      <div class="title2">让产品因创意更有价值</div>
    </div>
    <div class="banner2"></div>
    <div class="banner3">
      <img src="@/assets/image/title1.png" alt="" />
      <div class="title2">客户案例</div>
      <div class="hr"></div>
      <div class="title3">MORE</div>
    </div>
    <div class="banner4">
      <img class="img1" src="@/assets/image/earth.png" alt="" />
      <img class="img2" src="@/assets/image/title2.png" alt="" />
      <div class="title1">联系我们</div>
      <div class="box">
        <div class="box-item">
          <img src="@/assets/image/wx2.png" alt="" />
          <div class="right">
            <div class="title2">微信咨询</div>
            <div class="title3">wkzx2020</div>
          </div>
        </div>
        <div class="box-item">
          <img src="@/assets/image/phone2.png" alt="" />
          <div class="right">
            <div class="title2">电话</div>
            <div class="title3">19937019596</div>
          </div>
        </div>
        <div class="box-item">
          <img src="@/assets/image/email2.png" alt="" />
          <div class="right">
            <div class="title2">邮箱</div>
            <div class="title3">wkzx@wkzx.vip</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_footer">
      <div style="margin-top: 20px">
        Copyright © 2020悟空在线 |<i
          ><img
            style="width: 15px; height: 15px"
            src="@/assets/image/jinghui.png"
            alt=""
        /></i>
        豫公网安备41010502004005号 豫ICP备 18033521号-2 |
        增值电信业务经营许可证编号：豫B2-20191053
      </div>
      <div class="footer_img">
        <img src="@/assets/image/bottom_1.png" alt="" />
        <img src="@/assets/image/bottom_2.png" alt="" />
        <img src="@/assets/image/bottom_3.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import footerBottom from "@/components/common/footer";
export default {
  components: {
    footerBottom,
  },
  data() {
    return {
      navList: [
        {
          id: 1,
          title: "首页",
          style: "nav-item-active",
        },
        {
          id: 2,
          title: "案例",
          style: "nav-item",
        },
        {
          id: 3,
          title: "业务",
          style: "nav-item",
        },
        {
          id: 4,
          title: "联系我们",
          style: "nav-item",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getWindowHeight);
  },
  methods: {
    // 获取页面高度
    getWindowHeight() {
      if (
        document.documentElement.scrollTop >=
        document.querySelector(".banner1").offsetTop  - 100
      ) {
        this.navList.forEach((item) => {
          if (item.id == 1) {
            item.style = "nav-item-active";
          } else {
            item.style = "nav-item";
          }
        });
      }
      if (
        document.documentElement.scrollTop >
        document.querySelector(".banner2").offsetTop  - 100
      ) {
        this.navList.forEach((item) => {
          if (item.id == 2) {
            item.style = "nav-item-active";
          } else {
            item.style = "nav-item";
          }
        });
      }
      if (
        document.documentElement.scrollTop >
        document.querySelector(".banner3").offsetTop - 100
      ) {
        this.navList.forEach((item) => {
          if (item.id == 3) {
            item.style = "nav-item-active";
          } else {
            item.style = "nav-item";
          }
        });
      }
      if (
        document.documentElement.scrollTop >=
        document.querySelector(".banner4").offsetTop - 100
      ) {
        this.navList.forEach((item) => {
          if (item.id == 4) {
            item.style = "nav-item-active";
          } else {
            item.style = "nav-item";
          }
        });
      }
    },
    selectClick(val) {
      this.navList.forEach((item) => {
        if (item.id == val.id) {
          item.style = "nav-item-active";
        } else {
          item.style = "nav-item";
        }
      });
      if (val.id == 1) {
        document.querySelector(".banner1").scrollIntoView();
        // window.scrollTo(0, document.querySelector(".banner1").offsetTop);
      }
      if (val.id == 2) {
        document.querySelector(".banner2").scrollIntoView();

        // window.scrollTo(0, document.querySelector(".banner2").offsetTop);
      }
      if (val.id == 3) {
        document.querySelector(".banner3").scrollIntoView();

        // window.scrollTo(0, document.querySelector(".banner3").offsetTop);
      }
      if (val.id == 4) {
        document.querySelector(".banner4").scrollIntoView();

        // window.scrollTo(0, document.querySelector(".banner4").offsetTop);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footer_img {
  img {
    width: 134px;
    height: 47px;
    margin: 7.5px 7.5px 0 7.5px;
  }
}
.content_footer {
  height: 100px;
  border-top: 1px solid#E1E1E1;
  font-size: 12px;
  color: #c8c8c8;
  text-align: center;
  background: #303a56;
}
.banner1 {
  height: 100vh;
  background-image: url(../../assets/image/b1.png);
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 197px;
    height: 168px;
    margin-bottom: 10px;
  }
  .title2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 53px;
    text-align: center;

    color: #ffffff;
  }
  .title1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 77px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
}
.banner2 {
  height: 100vh;
  background-image: url(../../assets/image/b2.png);
  background-size: 100% 100%;
  background-position: center;
}
.banner3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(../../assets/image/b3.png);
  background-size: 100% 100%;
  background-position: center;
  img {
    width: 482px;
    height: 50px;
    margin-bottom: 20px;
  }
  .hr {
    width: 40px;
    height: 8px;
    background: #0188fc;
    border-radius: 10px;
    margin: 20px 0 40px 0;
  }
  .title2 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height */

    text-align: center;

    color: #333333;
  }
  .title3 {
    width: 131px;
    height: 48px;
    line-height: 48px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* identical to box height */
    text-align: center;
    border: 2px solid #0190f6;
    box-sizing: border-box;
    border-radius: 8px;
    color: #0188fc;
  }
}
.banner4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(../../assets/image/b4.png);
  background-size: 100% 100%;
  background-position: center;
  .box {
    display: flex;
    justify-content: space-between;
    width: 960px;
    margin: 0 auto;
  }
  .img1 {
    width: 222px;
    height: 222px;
    margin-bottom: 10px;
  }
  .img2 {
    width: 362px;
    height: 50px;
    margin-bottom: 20px;
  }
  .title1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height */

    text-align: center;

    color: #333333;
    margin-bottom: 86px;
  }
  .box-item {
    display: flex;
    align-items: center;
    width: 300px;
    height: 100px;
    box-sizing: border-box;
    padding: 27px 44px;
    background: #ffffff;
    border-radius: 10px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 30px;
    }
    .right {
      .title2 {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */

        color: #333333;
      }
      .title3 {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;

        color: #0188fc;
      }
    }
  }
}

.nav-item-active {
  margin-right: 158px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #0188fc;
}
.nav-item {
  margin-right: 158px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
}
.nav-style {
  display: flex;
}
.header {
  position: fixed;
  width: 100%;
  display: flex;
  height: 70px;

  background: rgba(0, 0, 0, 0.4);
  .w {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
  }
  .img {
    width: 141.97px;
    height: 40.03px;
  }
}
</style>