<template>
  <div v-show="mbShow" class="template1">
    <mbcontract-top-vue
      :info="form"
      v-if="form.wx != '' || form.infoqq != ''"
    ></mbcontract-top-vue>
    <div v-if="!sellFlag">
      <div class="content" v-if="qqAndWxStatus == 0">
        <el-dialog
          v-model="DowndialogVisible"
          :close-on-click-modal="false"
          v-if="isShow"
          :show-close="false"
          width="80%"
        >
          <div class="modal-body">
            <div class="modal_item">
              <span class="china">配置文件中</span>
              <!-- <span class="usa">In profile</span> -->
              <img
                ref="a"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
                style="display: none"
              /><img
                ref="a1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
              /><img
                ref="a2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
                style="display: none"
              />
            </div>
            <br />
            <div class="modal_item">
              <span class="china">校验中</span>
              <!-- <span class="usa">Check in</span> -->
              <img
                ref="b"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
                style="display: none"
              /><img
                ref="b1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
                style="display: none"
              /><img
                ref="b2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
              />
            </div>

            <br />
            <div class="modal_item">
              <span class="china">正在下载</span>
              <!-- <span class="usa">Downloading</span> -->
              <img
                ref="c"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
              /><img
                ref="c1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
                style="display: none"
              /><img
                ref="c2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
                style="display: none"
              />
            </div>

            <br />
            <h4 style="color: #0098dd; text-align: center; display: none">
              <span class="china">请不要离开此页面并保持屏幕常亮</span>
              <!-- <span class="usa"
              >Please do not leave this page and keep the screen on</span
            > -->
            </h4>
          </div>
        </el-dialog>
        <el-dialog
          v-model="dialogVisible"
          :close-on-click-modal="false"
          :show-close="false"
          width="80%"
        >
          <div style="height: 400px; position: relative">
            <div style="height: 230px; background: #3abdf8; width: 100%">
              <div style="width: 175px; margin: auto">
                <el-carousel trigger="click">
                  <el-carousel-item
                    v-for="(item, index) in bannerList"
                    :key="index"
                  >
                    <img
                      class="banner_img"
                      :src="item.imgUrl"
                      alt=""
                      style="width: 175px; height: 230px"
                    />
                    <h3>{{ item.title1 }}</h3>
                    <h4 style="height: 45px">{{ item.title2 }}</h4>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div
              style="
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, 0);
              "
            >
              <el-button
                type="primary"
                round
                style="width: 250px"
                @click="nextInstall"
                >继续安装</el-button
              >
            </div>
          </div>
        </el-dialog>
        <div v-if="isBuyPay" class="buy_pay_hint">
          此应用已开启付费下载，请购买后安装
        </div>
        <div class="logo">
          <img :src="form.iconurl" />
        </div>
        <div class="title">
          <span
            class="iconfont icon-pingguo icstyle"
            v-show="form.fittype == 'IOS'"
          ></span>
          <span
            class="iconfont icon-android-fill icstyle"
            v-show="form.fittype == 'Android'"
          ></span>
          <span>&nbsp;{{ form.softname }}</span>
        </div>
        <div class="verison" v-show="languageToggle == 1">
          版本： {{ form.publishName }}
        </div>
        <div class="verison" v-show="languageToggle == 2">
          versions： {{ form.publishName }}
        </div>
        <div class="font_style" v-show="languageToggle == 1">
          大小：{{ form.fileSize }} 更新时间：{{ form.chDate }}
        </div>
        <div class="font_style" v-show="languageToggle == 2">
          size: {{ form.fileSize }} time: {{ form.chDate }}
        </div>
        <div
          class="down_class"
          v-show="languageToggle == 1"
          @click="downClick"
          v-if="downFlag == 0 && isShowPc"
        >
          立即下载
        </div>
        <div
          class="down_class"
          v-show="languageToggle == 1"
          @click="downClick"
          v-if="!isShowPc"
        >
          立即下载
        </div>
        <div
          class="down_class"
          v-show="languageToggle == 2"
          @click="downClick"
          v-if="!isShowPc"
        >
          Download
        </div>
        <div
          class="load_style"
          v-show="loading"
          v-loading="loading"
          element-loading-text="正在下载中"
          element-loading-spinner="el-icon-loading"
        ></div>
        <div
          class="down_class"
          v-show="languageToggle == 2"
          @click="downClick"
          v-if="downFlag == 0 && isShowPc"
        >
          Download
        </div>
        <div
          class="sell_class"
          v-show="languageToggle == 1"
          @click="sellClick"
          v-if="downFlag == 3 && isShowPc"
        >
          购买应用
        </div>
        <div
          class="sell_class"
          v-show="languageToggle == 2"
          @click="sellClick"
          v-if="downFlag == 3 && isShowPc"
        >
          Buy apps
        </div>
        <div
          class="down_class"
          v-if="downFlag == 1 && isShowPc"
          v-show="languageToggle == 1"
        >
          暂不支持此系统
        </div>
        <div
          class="down_class"
          v-if="downFlag == 1 && isShowPc"
          v-show="languageToggle == 2"
        >
          This system is not supported temporarily
        </div>
        <div class="erwei">
          <div id="qrCode" ref="qrCodeDiv"></div>
          <!-- <img :src="form.iconurl" alt="" /> -->
        </div>
        <!-- <div
          style="font-size: 14px; margin-bottom: 25px; margin-top: 20px"
          v-show="languageToggle == 1"
        >
          扫描二维码下载
        </div>
        <div
          style="font-size: 14px; margin-bottom: 25px; margin-top: 20px"
          v-show="languageToggle == 2"
        >
          Scan the QR code to download
        </div> -->
        <div
          class="cxt"
          v-if="form.fittype == 'IOS' && !superShow && languageToggle == 1"
          @click="installClick"
        >
          "未受信任的企业及开发者"的解决办法
        </div>
        <div
          class="cxt"
          v-if="
            form.fittype == 'IOS' && superShow && isHide && languageToggle == 1
          "
          @click="installVipClick"
        >
          "需要启用开发者模式"的解决办法
        </div>
        <div
          class="cxt"
          v-if="form.fittype == 'IOS' && !superShow && languageToggle == 2"
          @click="installClick"
        >
          Solutions for "untrusted enterprises and developers"
        </div>
        <div
          class="cxt"
          v-if="
            form.fittype == 'IOS' && superShow && isHide && languageToggle == 2
          "
          @click="installVipClick"
        >
          Solution to "developer mode needs to be enabled"
        </div>
        <div style="margin-bottom: 40px"></div>
        <footer-bottom
          v-if="isShowPc"
          :softId="form.softId"
          :bannerImg="bannerImg"
          :link="link"
          @languageChange="languageChange"
          :closeAD="closeAD"
        ></footer-bottom>
        <div class="footerBox" v-if="!isShowPc">
          <div
            class="w closeBox"
            style="margin-bottom: 20px"
            v-if="bannerFlag && showBanner"
          >
            <!-- v-show="bannerFlag" -->
            <el-carousel
              indicator-position="none"
              height="100px"
              trigger="click"
              autoplay
              arrow="never"
              v-show="bannerList2[0].imgUrl != null"
            >
              <el-carousel-item
                v-for="(item, index) in bannerList2"
                :key="index"
              >
                <img
                  class="banner_img"
                  :src="item.imgUrl"
                  alt=""
                  @click="bannerClick(item.link)"
                />
              </el-carousel-item>
            </el-carousel>
            <img
              class="closeBtn"
              @click="closeBanner"
              src="@/assets/image/close-btn.png"
              style="width: 20px; height: 20px"
              alt=""
            />
          </div>
          <div class="footer">
            <div class="w">
              <div class="footer_left" v-show="languageToggle == 1">
                <span
                  style="margin-right: 20px; cursor: pointer"
                  @click="exemptionClick"
                  >免责声明</span
                >
                <span style="color: #333">|</span>
                <span
                  style="margin-left: 20px; cursor: pointer"
                  @click="reportClick"
                  >举报</span
                >
              </div>
              <div class="footer_left" v-show="languageToggle == 2">
                <span
                  style="margin-right: 20px; cursor: pointer"
                  @click="exemptionClick"
                  >disclaimer</span
                >
                <span style="color: #333">|</span>
                <span
                  style="margin-left: 20px; cursor: pointer"
                  @click="reportClick"
                  >report</span
                >
              </div>
              <div class="footer_right">
                <img
                  src="../../../assets/image/china.png"
                  style="width: 21px; height: 14px; margin-right: 10px"
                />
                <span @click="languageClick(1)" style="cursor: pointer"
                  >中文</span
                >
                <span style="margin: 0 20px; color: #333">|</span>
                <img
                  src="../../../assets/image/usa.png"
                  style="width: 21px; height: 14px; margin-right: 10px"
                />
                <span @click="languageClick(2)" style="cursor: pointer"
                  >English</span
                >
              </div>
            </div>
            <div class="w">
              <div
                class="tips"
                style="color: #999; margin-top: 10px"
                v-show="languageToggle == 1"
              >
                提示：该应用来源于第三方产品，内容不受悟空在线控制，请您仔细甄别，审慎安装。悟空在线仅为信息服务平台，与第三方应用之间不存在任何关联关系，
                不提供任何担保或承诺。悟空在线不为因此产生的任何投资、购买行为承担任何责任。
                <div v-show="form.icp">APP备案:{{ form.icp }}</div>
              </div>
              
              <div
                class="tips"
                style="color: #999; margin-top: 10px"
                v-show="languageToggle == 2"
              >
                Tip: this application comes from a third-party product, and its
                content is not controlled by Wukong online. Please carefully
                identify and carefully install it. Wukong online is only an
                information service platform, and has no relationship with
                third-party applications, and does not provide any guarantee or
                commitment. Wukong online will not bear any responsibility for
                any investment and purchase behavior arising therefrom!
              </div>
            </div>
          </div>
        </div>
      </div>
      <brower-template
        v-if="qqAndWxStatus == 1 || qqAndWxStatus == 2"
        :qqAndWxStatus="qqAndWxStatus"
      ></brower-template>
    </div>
    <sell-template
      v-if="sellFlag"
      :UDID="UDID"
      :userId="form.userId"
      :shortUrl="form.softUrlSmall"
    ></sell-template>
    <el-dialog
      v-model="centerDialogVisible"
      title="提示"
      width="70%"
      top="38vh"
      center
    >
      <div class="pop-title">提示</div>
      <span class="pop-context"
        >该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false"
            >关闭</el-button
          >
        </span>
      </template>
    </el-dialog>
    <div class="juBao">
      <el-dialog
        title="举报"
        center
        v-model="dialogVisiblePC"
        :show-close="true"
        :close-on-click-modal="false"
        width="630px"
        :before-close="handleClose"
      >
        <div class="dialog_box">
          <div class="dialog_header">
            <span
              >作为一个第三方内测分发平台，我们一直致力于打击违规应用，保护知识产权。非常感谢您的举报，我们会在第一时间安排专人处理您举报的问题，并在处理之后向您反馈处理结果。</span
            >
            <br />
            <span style="margin-top: 20px; display: block; font-size: 14px"
              >感谢您对我们的支持，如果有问题欢迎拨打我们的热线电话：0371-86550361</span
            >
          </div>
          <hr style="margin: 20px 0" />
          <el-form ref="formData" :model="formData" label-width="90px">
            <el-form-item label="您的手机号">
              <el-input v-model="formData.phone"></el-input>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input v-model="formData.code" style="width: 280px"></el-input>
              <el-button
                type="primary"
                style="float: right; width: 144px"
                @click="getCode"
                :disabled="!show"
                >获取验证码<span v-show="!show" class="count"
                  >({{ count }}s)</span
                ></el-button
              >
            </el-form-item>
            <div style="height: 32px">
              <my-slider-captcha
                @isSuccess="isSuccess"
                @codeData="codeData"
                :scene="scene"
              ></my-slider-captcha>
            </div>
            <el-form-item label="举报原因">
              <el-radio-group v-model="formData.report">
                <el-radio :label="1">盗版</el-radio>
                <el-radio :label="2">色情</el-radio>
                <el-radio :label="3">欺诈</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="举报内容">
              <el-input
                v-model="formData.contentSupplement"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- <el-button type="text">上传附件</el-button> -->
          <el-upload
            class="upload-demo"
            :headers="myHeaders"
            :action="src + 'software/upload'"
            :before-remove="beforeRemove"
            :before-upload="beforeUpload"
            multiple
            :limit="2"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button type="primary">上传附件</el-button>
          </el-upload>
          <br />
          <span
            >为了能够更快处理您的举报信息，建议上传截图或其他相关文件等证据</span
          >
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisiblePC = false">取 消</el-button>
            <el-button type="primary" @click="saveData">举报</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <app-DownDoc v-if="isShowDoc" :language="languageToggle"></app-DownDoc>
    <vip-Doc v-if="isShowVipDoc"></vip-Doc>
    <div class="modal" v-if="isShowDoc || isShowVipDoc"></div>
  </div>
</template>
<script>
import MySliderCaptcha from "../../../components/common/MySliderCaptcha.vue";
import BASE_URL from "@/utils/request.js";

import sellTemplate from "@/views/appDown/template/sellTemplate";
import browerTemplate from "@/views/appDown/template/browerTemplate";
import appDownDoc from "@/views/appDown/template/appDownDoc";
import vipDoc from "@/views/appDown/template/vipDoc";

import {
  downConsumptionFlow,
  checkStatus,
  checkEquipmentNum,
  signatureUDID,
  verifyPersonUDIDPay,
  softConformInstallIf,
  saveVisitMess,
  reportCode,
  reportCodeVerify,
  getOssToken,
  reportApp,
  getPCDBAdvertising,
} from "@/api/api";
import mixin from "@/mixin"; // 引入mixin文件
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import QRCode from "qrcodejs2";
import footerBottom from "@/views/appDown/footerBottom";
import Index from "../../Index/index.vue";
import mbcontractTopVue from "../../../components/common/mbcontractTop.vue";
import { isEmpty, randomWord, domainName } from "@/common/js/common.js";
import MobileDetect from "@/common/js/mobile-detect.js";
export default {
  mixins: [mixin],
  components: {
    browerTemplate,
    sellTemplate,
    footerBottom,
    mbcontractTopVue,
    Index,
    appDownDoc,
    vipDoc,
    MySliderCaptcha,
  },
  props: {
    bannerImg: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
    },
    vip: {
      type: Boolean,
      default: false,
    },
    UDID: {
      type: String,
      default: "",
    },
    closeAD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scene: "report",
      checkData: null,
      checkSuccess: false,
      loading: false,
      countryTitle: "中文",
      sellFlag: false,
      DowndialogVisible: false,
      qqAndWxStatus: 0,
      src: BASE_URL,
      showBanner: true,
      formData: {
        phone: "",
        code: "",
        report: 1,
        contentSupplement: "",
        softId: "",
        files: [],
      },
      bannerList2: [],
      bannerList: [
        {
          id: 1,
          imgUrl: require("../../../assets/image/vip_1.png"),
          title1: "第一步",
          title2: "点击“允许”进行下一步操作。",
        },
        {
          id: 2,
          imgUrl: require("../../../assets/image/vip_2.png"),
          title1: "第二步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 3,
          imgUrl: require("../../../assets/image/vip_3.png"),
          title1: "第三步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 4,
          imgUrl: require("../../../assets/image/vip_4.png"),
          title1: "第四步",
          title2: "点击右上角“安装”，然后进行下一步操作。",
        },
        {
          id: 5,
          imgUrl: require("../../../assets/image/vip_5.png"),
          title1: "第五步",
          title2: "点击“安装”即可。",
        },
      ],
      dialogVisible: false,
      dialogVisiblePC: false,
      dialogFlag: false, // vip弹窗组件
      show: true,
      downFlag: 0,
      count: "",
      languageToggle: 1,
      fileList: [],
      token: "",
      myHeaders: null,
      timer: null,
      userId: "",
      vipId: "",
      stopCheckState: "", //停止轮询变量
      isShow: true, //进度条是否显示
      isBuyPay: false, //是否购买
      centerDialogVisible: false,
      mbShow: false,
      actCode: "", //激活码
      isShowDoc: false,
      isShowVipDoc: false,
      superShow: false,
      isHide: false,
      isShowPc: false,
      isUploadSuc: false,
    };
  },
  async created() {
    // 修改域名
    const res = await domainName(this.form.userId, this.form.shortLink);
    this.form.shortLink = res;
    // console.log(this.form)
    if (this.getBrower().mobile) {
      this.isShowPc = true;
    }
    this.showVip();
    if (isEmpty(this.form.wx)) {
      this.form.wx = "";
    }
    if (isEmpty(this.form.infoqq)) {
      this.form.infoqq = "";
    }
    this.actCode = window.location.href.substring(
      window.location.href.length - 8
    );
    this.andriodAndApple();
    if (this.form.softname) {
      document.title = this.form.softname;
    } else {
      document.title = "";
    }
    this.saveVisitMess();
    console.log(this.form.automaticallyRefresh);
    console.log(this.form);
    this.getPCAdvertising();
  },
  mounted() {
    this.vipId = this.$route.query.vipId;
    if (this.vipId) {
      this.DowndialogVisible = true;
      this.vipDownCheck();
    }
    this.getAppShow();
    console.log(this.form);
    if (this.form.chEn == 0) {
      if (
        (navigator.language || navigator.browserLanguage)
          .toLowerCase()
          .includes("zh-cn")
      ) {
        this.languageToggle = 1;
      } else {
        this.languageToggle = 2;
      }
    } else {
      this.languageToggle = this.form.chEn;
    }
  },
  methods: {
    codeData(val) {
      this.checkData = val;
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    handleClose() {
      this.dialogVisiblePC = false;
    },
    closeBanner() {
      this.showBanner = false;
    },
    // 选择中英文
    languageClick(val) {
      this.languageToggle = val;
    },
    showVip() {
      let device_type = navigator.userAgent;
      console.log(device_type);
      let md = new MobileDetect(device_type);
      let os = md.os();
      console.log(os);
      if (os == "iOS") {
        os = md.version("iPhone");
        if (os.toString().indexOf(".") > -1) {
          let index = os.toString().indexOf(".");
          os = os.toString().substring(0, index);
        }
        if (Number(os) < 16) {
          this.isHide = false;
        } else {
          this.isHide = true;
        }
      }
      // console.log(md.version)
      if (this.form.automaticallyRefresh == 1) {
        this.superShow = true;
      }
    },
    // 获取轮播图
    getPCAdvertising() {
      getPCDBAdvertising().then((res) => {
        if (!isEmpty(res.data)) {
          this.bannerList2 = res.data.advertisings;
          this.bannerFlag = true;
        } else {
          this.bannerFlag = false;
        }
        if (res.code == 401) {
          // this.$message.error(res.msg)
          localStorage.clear();
          this.$router.push("/login");
        }
      });
    },
    // 切换中英文
    languageChange(val) {
      this.languageToggle = val;
    },
    sellClick() {
      this.sellFlag = true;
    },
    // 继续安装
    nextInstall() {
      window.location.href = this.form.mobileconfigUrl;
      if (!this.isIOSNew()) {
        setTimeout(function () {
          window.location.href =
            "https://p.udid.store/embedded.mobileprovision";
        }, 2 * 1000); //延
      }
    },
    // 安装教程
    installClick() {
      this.isShowDoc = true;
      // this.$router.push("/trustTeach");
      // this.$router.push("/blog/untrustedEnterprise");
    },
    installVipClick() {
      this.isShowVipDoc = true;
    },
    // 判断系统所属安卓还是苹果
    andriodAndApple() {
      this.mbShow = true;
      if (this.form.fittype == "IOS") {
        if (
          !(
            this.getBrower().ios ||
            this.getBrower().iPhone ||
            this.getBrower().iPad
          )
        ) {
          //非苹果端
          this.downFlag = 1;
          if (this.form.fittype == "IOS" && this.form.thirdurl) {
            return (window.location.href = "http://" + this.form.thirdurl);
          } else {
            this.mbShow = true;
          }
        }
      } else {
        //安卓
        if (
          this.getBrower().ios ||
          this.getBrower().iPhone ||
          this.getBrower().Pad
        ) {
          this.downFlag = 1;
          if (this.form.fittype == "Android" && this.form.thirdurl) {
            return (window.location.href = "http://" + this.form.thirdurl);
          } else {
            this.mbShow = true;
          }
        }
      }
    },
    // 跳转到免责声明
    exemptionClick() {
      // this.$router.push("/disclaimer");
      this.$router.push({
        path: "/contract",
        query: {
          status: 5,
        },
      });
    },
    //上传限制数
    handleExceed(files) {
      this.$message.warning(
        `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件`
      );
      //共选择了 ${files.length + fileList.length }个文件
    },
    //删除弹出框
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 保存
    saveData() {
      if (this.formData.phone == "") {
        return this.$message.error("请输入手机号");
      }
      if (this.formData.code == "") {
        return this.$message.error("请输入验证码");
      }
      if (this.isUploadSuc) {
        return this.$message.error("请等待上传完成");
      }
      this.codeJiaoyan();
    },
    //上传文件调用
    beforeUpload(files) {
      this.isUploadSuc = true;
      //获取后缀名
      var fileName = files.name;
      var index = fileName.lastIndexOf(".");
      var suffix = fileName.substring(index, fileName.length); //后缀名
      fileName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(fileName, files)
            .then((res) => {
              if (res.url) {
                this.formData.files.push(res.url);
                this.isUploadSuc = false;
              } else {
                this.$message.error("文件上传失败");
                this.isUploadSuc = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
          this.isUploadSuc = false;
        }
      });
    },
    // 获取验证码
    getCode() {
      if (!this.formData.phone) return this.$message.warning("请输入手机号");
      if (this.checkSuccess) {
        reportCode({
          phone: this.formData.phone,
          sessionId: this.checkData.sessionId,
          sig: this.checkData.sig,
          sessiontoken: this.checkData.token,
          scene: this.scene,
        }).then((res) => {
          if (res.code == "1") {
            this.$message({
              type: "warning",
              duration: 2000,
              message: res.msg,
            });
          } else {
            this.timerChange();
          }
        });
      } else {
        this.$message.warning("请先通过滑块验证");
      }
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    codeJiaoyan() {
      if (this.formData.phone) {
        reportCodeVerify({
          phone: this.formData.phone,
          code: this.formData.code,
        }).then((res) => {
          if (res.code == 0) {
            this.formData.softId = this.form.softId;
            reportApp(this.formData).then((res) => {
              if (res.code == 0) {
                this.$message.success("举报成功");
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            });
            // this.$message.success(res.msg);
          } else {
            return this.$message.error(res.msg);
          }
        });
      }
      //  else {
      //   this.$message({
      //     type: "warning",
      //     duration: 2000,
      //     message: "请输入手机号",
      //   });
      // }
    },
    // 举报
    reportClick() {
      this.dialogVisiblePC = true;
      // this.$router.push({
      //   path: "/report",
      //   query: {
      //     softId: this.form.softId,
      //   },
      // });
    },
    saveVisitMess() {
      //浏览保存
      saveVisitMess({ softId: this.form.softId }).then(() => {}); //保存下载量
    },
    vipDownCheck() {
      //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
      var udid = localStorage.getItem("udidcache");
      if (
        udid != "undefined" &&
        udid != null &&
        this.UDID != null &&
        this.UDID != ""
      ) {
        if (this.form.openPay == 1) {
          verifyPersonUDIDPay({
            userId: this.form.userId,
            udid: this.UDID,
          }).then((res) => {
            if (res.code != 0) {
              // this.$message.error("此应用已开启付费下载，请购买后安装");
              this.isBuyPay = true;
              this.downFlag = 3;
              this.isShow = false;
            } else {
              this.isBuyPay = false;
              this.getCheckEquipmentNum();
            }
          });
        } else {
          this.getCheckEquipmentNum();
        }
      }
    },
    //校验设备
    getCheckEquipmentNum() {
      // if (this.form.remarks4 == 1) {
      //校验设备
      checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
        (res) => {
          if (res.code == 0) {
            //签名
            signatureUDID({
              softId: this.form.softId,
              UDID: this.UDID,
              softUrl: this.form.softUrlSmall,
            }).then((res) => {
              if (res.code != 0) {
                this.dialogVisible = false;
                this.$message.error(res.msg);
              } else {
                if (res.data.data.result == "downing") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.$message.error("有用户正在下载该应用，请稍后重试");
                } else if (res.data.data.result == "fail") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.DowndialogVisible = false;
                  this.$message.error(res.data.data.msg);
                } else {
                  this.dialogVisible = false;
                  // this.$message.success(res.msg);
                }
              }
            });
            this.stopCheckState = setInterval(() => {
              this.checkStatus();
            }, 3000); //轮询查看vip安装进度
            localStorage.setItem("udidcache", this.UDID); //缓存浏览器
          } else {
            this.DowndialogVisible = false;
            this.$message.error(res.msg);
          }
        }
      );
      //查看状态
      // } else {
      // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
      // }
    },
    checkStatus() {
      //查看vip安装进度
      checkStatus({ softId: this.form.softId, UDID: this.UDID }).then((res) => {
        var status = res.data.result;
        if (status == "1") {
          this.$refs.a2.style.display = "none";
          this.$refs.a1.style.display = "block";
          this.$refs.b.style.display = "none";
          this.$refs.b2.style.display = "block";
        }
        if (status == "2") {
          this.$refs.b2.style.display = "none";
          this.$refs.b1.style.display = "block";
          this.$refs.c2.style.display = "block";
          this.$refs.c.style.display = "none";
        }
        if (status == "3") {
          this.$refs.c2.style.display = "none";
          //获取存放在隐藏域的itemservice链接
          var itemservice = res.data.itemservice;
          if (isEmpty(itemservice)) {
            return;
          }
          clearInterval(this.stopCheckState); //清除定时轮询
          this.DowndialogVisible = false;
          window.location.href = itemservice;
        }
      });
    },
    // 判断是否是ios17
    isIOSNew() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("like mac os x") > 0) {
        var regStr_saf = /os [\d._]*/gi;
        var verinfo = ua.match(regStr_saf);
        var version = (verinfo + "")
          .replace(/[^0-9|_.]/gi, "")
          .replace(/_/gi, ".");
        var arr = version.split(".");
        if (arr[0] >= 17) return true;
      }
    },
    // 下载
    downClick() {
      // debugger;
      if (
        this.form.packType === "企业版" &&
        this.form.automaticallyRefresh != 1
      ) {
        if (this.isIOSNew()) {
          this.isShowDoc = true;
        }
      }
      if (this.form.automaticallyRefresh == 1) {
        if (!this.getVipUserAgent()) {
          if (!this.isShowPc) {
            this.$message.error(
              "该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。"
            );
          } else {
            this.centerDialogVisible = true;
          }
          return;
        }
      } else {
        if (
          (this.getBrower().iPhone || this.getBrower().iPad) &&
          (this.getBrower().presto || this.getBrower().Quark)
        ) {
          if (!this.isShowPc) {
            this.$message.error(
              "该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。"
            );
          } else {
            this.centerDialogVisible = true;
          }
          // alert("该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。");
          return;
        }
      }

      //校验应用是否符合下载标准
      softConformInstallIf({ softId: this.form.softId }).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg);
          return;
        }
      });
      if (this.form.automaticallyRefresh == 1) {
        var ua = navigator.userAgent.toLowerCase();
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          if (ua.indexOf(" mac os x") > 0) {
            if (!navigator.cookieEnabled) {
              this.$message.error("你的浏览器已禁用cookie");
              return;
            }
          }
        } else {
          this.$message.error("请在Safari浏览器中打开本页");
          return;
        }

        //判断浏览器udid
        //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
        var udid = localStorage.getItem("udidcache");
        if (
          udid != "undefined" &&
          udid != null &&
          this.UDID != null &&
          this.UDID != ""
        ) {
          //校验设备
          checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
            (res) => {
              if (res.code == 0) {
                this.DowndialogVisible = true;
                //签名
                signatureUDID({
                  softId: this.form.softId,
                  UDID: this.UDID,
                  softUrl: this.form.softUrlSmall,
                }).then((res) => {
                  if (res.code != 0) {
                    this.dialogVisible = false;
                    this.$message.error(res.msg);
                  } else {
                    if (res.data.data.result == "downing") {
                      clearInterval(this.stopCheckState); //清除定时轮询
                      this.$message.error("有用户正在下载该应用，请稍后重试");
                    } else if (res.data.data.result == "fail") {
                      clearInterval(this.stopCheckState); //清除定时轮询
                      this.DowndialogVisible = false;
                      this.$message.error(res.data.data.msg);
                    } else {
                      this.dialogVisible = false;
                      // this.$message.success(res.msg);
                    }
                  }
                });
                this.stopCheckState = setInterval(() => {
                  this.checkStatus();
                }, 3000); //轮询查看vip安装进度
                localStorage.setItem("udidcache", this.UDID); //缓存浏览器
              } else {
                this.DowndialogVisible = false;
                this.$message.error(res.msg);
              }
            }
          );
          //查看状态
          // } else {
          // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
          // }
        } else {
          this.dialogVisible = true;
        }
      } else {
        let param = {
          softId: this.form.softId,
          userId: this.form.userId,
          adkey: this.$route.query.adkey ? this.$route.query.adkey : "",
        };
        if (this.form.actcode == 1) {
          param.actCode = this.actCode;
        }
        console.log(param);
        downConsumptionFlow(param).then((res) => {
          if (res.code == 0) {
            this.downFlag = 6;
            this.loading = true;
            if (!this.getBrower().mobile) {
              this.loading = false;
            }
            let _this = this;
            setTimeout(() => {
              _this.loading = false;
              _this.downFlag = 0;
            }, 3000);
            // window.location.href = this.publishList[0].downUrl;
            window.location.href = res.data.downUrl;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    getAppShow() {
      setTimeout(() => {
        let href = window.location.href;
        if (href.indexOf("active") > -1) {
          this.bindQRCode(href);
        } else if (href.indexOf("invitation") > -1) {
          this.bindQRCode(href);
        } else {
          this.bindQRCode(this.form.shortLink);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height: 326px;
}
.template1 {
  background: #fff !important;
  height: 100%;
}
.erwei {
  position: relative;
  height: 140px;
  img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 50%;
    background: #fff;
    border: 3px solid #fff;
    border-radius: 5px;
    transform: translate(-50%, -50%);
  }
}
::v-deep .el-dialog {
  border-radius: 10px !important;
  .pop-title {
    font-size: 3vh;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .pop-context {
    text-align: center;
    display: block;
    height: auto;
  }
}
.modal-body {
  padding: 30px;
  .modal_item {
    display: flex;
    justify-content: space-between;
    .img_dialog {
      width: 20px;
      height: 20px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
::v-deep .el-dialog__header {
  display: none;
}
.cxt {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 152, 221, 1);
  margin-top: 20px;
}
.footer {
  margin-top: 40px;
  padding: 15px 20px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background: #f1f6f9;
  .cnAm_toggle {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 25px;
    background: #cce9ff;
    border-radius: 4px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    color: #0092ff;
  }
  .cnAm_middle {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #0092ff;
  }
  .cnAm_footer {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    // text-align: center;

    color: #666666;
  }
}
.sell_class {
  width: 9rem;
  padding: 0 1.5rem;
  height: 3rem;
  text-align: center;
  background: #ba812f;
  border-radius: 1.5rem;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin: 20px 0 30px 0;
}
.load_style {
  width: 200px;
  padding: 0 2.875rem;
  margin-top: 1.125rem;
  margin-bottom: 42px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
::v-deep .el-icon-loading {
  font-size: 30px;
  font-weight: 700;
}
.down_class {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 9rem;
  padding: 0 1rem;
  height: 44px;
  text-align: center;
  background: #0098dd;
  border-radius: 22px;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin-top: 1.125rem;
  margin-bottom: 42px;

  font-family: PingFang SC;
  font-style: normal;
  // font-weight: bold;
  font-size: 14px;
}

#qrCode {
  border-radius: 16px;
  margin-bottom: 20px;
}
.content {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .buy_pay_hint {
    position: relative;
    bottom: -30px;
    padding: 10px 30px;
    margin-bottom: -20px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 67px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  }
  .logo img {
    width: 100px;
    height: 100px;
    border-radius: 16%;
    margin-top: 80px;
  }
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    margin: 20px 0 10px 0;
    /* identical to box height */

    text-align: center;

    color: #000000;
  }
  .verison {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #666666;
  }
  .font_style {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin: 14px 0 18px 0;
    color: #999999;
  }
}

h3 {
  margin-top: 12px;
  font-size: 15px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
  text-align: center;
}
h4 {
  font-size: 12px;
  font-family: PingFang-SC-Regular;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 18px;
  text-align: left;
}
.icstyle {
  font-size: 30px;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
.footerBox {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  background: rgba(229, 229, 229, 1);
  .w {
    display: flex;
    justify-content: space-between;
    span {
      color: #0098dd;
    }
  }
}
.closeBox {
  position: relative;
  overflow: hidden;
  .closeBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.dialog_box {
  color: #888;
  padding: 25px;
}
@media only screen and (min-width: 1024px) {
  .appDownDoc {
    width: 450px !important;
    margin-left: 50%;
    transform: translate(-50%);
  }
  ::v-deep .el-dialog {
    width: 400px !important;
  }
  .juBao {
    ::v-deep .el-dialog {
      width: 630px !important;
    }
    ::v-deep .el-dialog__header {
      display: block;
    }
  }
}
</style>
