<template>
	<div>
		<div class="w">
			<div class="header">  
				<el-breadcrumb separator="/">
					<el-breadcrumb-item @click="urlPath">实名认证</el-breadcrumb-item>
					<el-breadcrumb-item>企业认证</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="content">
				<div class="content_title">企业实名认证</div>
				<div class="content_form" v-loading="loading">
					<el-form ref="form" :model="form">
						<el-form-item><el-input v-model="form.companyName" placeholder="企业名称" style="width: 400px"></el-input></el-form-item>
						<el-form-item><el-input v-model="form.realName" placeholder="企业联系人姓名" style="width: 400px"></el-input></el-form-item>
						<el-form-item><el-input v-model="form.telephone" placeholder="手机号" style="width: 400px"></el-input></el-form-item>
						<el-form-item>
							<el-input v-model="form.code" placeholder="验证码" style="width: 222px;"></el-input>
							<el-button style="margin-left: 30px;width: 148px" @click="getCode" :disabled="!show">获取验证码
								<span v-show="!show" class="count">({{ count }}s)</span>
							</el-button>
						</el-form-item>
						<el-form-item><el-input v-model="form.creditCode" placeholder="统一社会信用代码" style="width: 400px"></el-input></el-form-item>
						<el-form-item>
							<div class="idCard_style">联系人手持身份证照片</div>
							<div class="upload_style">
								<el-upload :accept="uploadType" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :before-upload="beforeUploadIcon1">
									<img v-if="form.idCard" :src="form.idCard" class="avatar upload-style" />
									<i v-else class="el-icon-plus upload-style"></i>
								</el-upload>
								<div class="upload_right">
									<img style="width: 148px;height: 148px" src="../../../assets/image/people.png" alt="" />
									<div class="upload_font">示例</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="idCard_style">公司营业执照</div>
							<div class="upload_style">
								<el-upload :accept="uploadType" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :before-upload="beforeUploadIcon2">
									<img v-if="form.company" :src="form.company" class="avatar upload-style" />
									<i v-else class="el-icon-plus upload-style"></i>
								</el-upload>
								<div class="upload_right">
									<img style="width: 148px;height: 148px" src="../../../assets/image/zhizhao.png" alt="" />
									<div class="upload_font">示例</div>
								</div>
							</div>
							<div class="tips">* 提示：请上传营业执照原件或者加盖公章的营业执照复印件</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="content_btn"><el-button type="primary" style="width: 200px" @click="submit">确定</el-button></div>
			</div>
		</div>
	</div>
</template>

<script>
import { getOssToken,getCertificationCode, companyCertification,enterpriseCFuploadImage,getCompanyInformation } from '@/api/api';
import { fileUpdateOSS } from '@/common/js/OssClient.js';
import { randomWord } from '@/common/js/common.js';
export default {
	name: 'enterpriseCertification',
	data() {
		return {
			show: true,
			uploadType:'.jpg,.jpeg,.png,.JPG,.JPEG',
			loading: false,
			dialogImageUrl: '',
			dialogVisible: false,
			form: {
				companyName: '',
				realName: '',
				creditCode: '',//信用码
				code: '',//验证码
				telephone: '',
				company: '',
				idCard: '',
			}
		};
	},
	created(){
		if (window.location.href.includes("?userId=")) {
			const userId = window.location.href.substring(window.location.href.split("?userId=")[0].length+8);
			if(userId != null && userId != undefined && userId !=''){
				this.getCompanyInformation(userId);
			}
		}
	},
	methods: {
		timerChange() {
		const TIME_COUNT = 60;
		if (!this.timer) {
			this.count = TIME_COUNT;
			this.show = false;
			this.timer = setInterval(() => {
			if (this.count > 0 && this.count <= TIME_COUNT) {
				this.count--;
			} else {
				this.show = true;
				clearInterval(this.timer); // 清除定时器
				this.timer = null;
			}
			}, 1000);
		}
		},
		getCompanyInformation(val){//已提交获取公司信息
			getCompanyInformation(val).then((res) => {
				if(res.code == 0){
					this.form.companyName = res.data.company.companyName;
					this.form.realName = res.data.company.linkman;
					this.form.telephone = res.data.company.telephone;
					this.form.creditCode = res.data.company.licenseNum;
					this.form.idCard = res.data.idcard;
					this.form.company = res.data.trading;
				}
			});
		},
		beforeUploadIcon1(file) {
			//获取后缀名
			var imageName = file.name;
			var index = imageName.lastIndexOf('.');
			var suffix = imageName.substring(index, imageName.length); //后缀名
			imageName = new Date().getTime() + randomWord(17) + suffix;
			this.loading = true
			getOssToken().then(res => {
				if (res.code == 0) {
					let ossClient = fileUpdateOSS(res.data);
					ossClient.put(imageName, file).then(res => {
						this.loading = false
							if (res.url) {
								this.form.idCard = res.url;
								console.log(res.url+"=======================");
								enterpriseCFuploadImage({imagePath: res.url,imageSort: 4 }).then((res) => {
									if(res.code == 0){
										console.log("成功");
									}else{
										this.$message.error(res.msg);
									}
								});
							} else {
								this.$message.error('文件上传失败');
							}
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		beforeUploadIcon2(file) {
			//获取后缀名
			var imageName = file.name;
			var index = imageName.lastIndexOf('.');
			var suffix = imageName.substring(index, imageName.length); //后缀名
			imageName = new Date().getTime() + randomWord(17) + suffix;
			this.loading = true
			getOssToken().then(res => {
				if (res.code == 0) {
					let ossClient = fileUpdateOSS(res.data);
					ossClient.put(imageName, file).then(res => {
						this.loading = false
							if (res.url) {
								this.form.company = res.url;
								enterpriseCFuploadImage({imagePath: res.url,imageSort: 5 }).then((res) => {
									if(res.code == 0){
										console.log("成功");
									}else{
										this.$message.error(res.msg);
									}
								});
							} else {
								this.$message.error('文件上传失败');
							}
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		getCode(){//获取验证码
			if(!this.form.telephone) {
				return this.$message.warning('请输入手机号')
			}
			getCertificationCode({telephone: this.form.telephone}).then((res) =>{
				if(res.code == 0){
					this.timerChange();
					this.$message.success("获取成功");
				}
			});	
		},
		submit() {
			this.loading = true
			companyCertification(this.form).then((res) => {
				this.loading = false
				if(res.code == 0){
					this.$router.push('/appManagement');
					this.$message.success("成功");
				}else{
					this.$message.error(res.msg);
				}
			  });
		},
		urlPath() {
			this.$router.push({
				path: '/personalData',
				query: {
					status: 3
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
	.upload-style {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 148px;
	  height: 148px;
	  background: #f8f8f8;
	  border: 1px dashed #acacac;
	  box-sizing: border-box;
	  border-radius: 10px;
	}
.upload_right {
	display: flex;
	align-items: center;
	.upload_font {
		font-size: 14px;
		color: #666;
	}
}
::v-deep .el-form-item__label {
	text-align: left;
}
.upload_style {
	display: flex;
	justify-content: space-between;
}
.tips {
	font-size: 14px;
	color: #ff4a4a;
}
.header {
	margin: 20px 0;
}

.idCard_style {
	font-size: 14px;
	color: #333333;
	font-weight: 700;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	width: 100%;
	border-radius: 10px;
	background: #fff;
	margin-bottom: 30px;
	padding-bottom: 30px;
	.content_title {
		font-size: 22px;
		font-weight: 700;
		color: #0092ff;
		margin-top: 50px;
		margin-bottom: 30px;
	}
	.content_form_font {
		color: #666666;
		font-size: 12px;
	}
}
</style>
