<template>
  <div class="content">
    <div class="title_1">内测分发——常见问题</div>
    <div class="w docbox">
      <div class="title_2">1. 此安装方式是否需要苹果设备越狱？</div>
      <div class="title_3">
       无需设备越狱即可安装。
      </div>
      <div class="title_2">2. 是否需要内测分发才能安装？</div>
      <div class="title_3">
        不需要内测分发，上传之后开启内测分发即可内测分发。
      </div>
      <div class="title_2">3. 是否需要提供UDID？</div>
      <div class="title_3">
        无需提供UDID，全程自动化。
      </div>
      <div class="title_2">4.签名需要多长时间？</div>
      <div class="title_3">
         我们的签名是完全自动化的，一般可在数秒内完成。
      </div>
      <div class="title_2">5. 安装之后是否需要信任？</div>
      <div class="title_3">
        不需要，安装之后即可使用，无需频繁信任设置。
      </div>
      <div class="title_2">6. 是否存在企业分发经常掉签造成APP无法使用的情况？</div>
      <div class="title_3">
        不保证百分之百不出现，但是概率相对企业签较低。
      </div>
      <div class="title_2">7. 什么是私有池？</div>
      <div class="title_3">
        私有池：使用用户手动添加的个人账号给已上传并选择使用私有版的应用进行签名服务，以设备量为计费方式 私有池，在服务期间若账号发生掉签状况，用户重新下载安装会再次消耗安装流量
      </div>
      <div class="title_2">8. 什么是公有池？</div>
      <div class="title_3">
        公有池：使用悟空在线提供的个人账号给已上传并选择使用私有版的应用进行签名服务，以设备量为计费方式 公有池，在服务期间若账号发生掉签状况，用户重新下载安装会再次消耗安装流量
      </div>
      <div class="title_2">9. 私有池添加苹果开发者账号收费吗？</div>
      <div class="title_3">
        不收费，添加账号是完全免费的。
      </div>
      <div class="title_2">10. 对安装包大小有限制么？</div>
      <div class="title_3">
        上传是没有限制的，但是考虑到宽带以及流量成本问题如果您的如果您的app超过1G，您在通过私有池使用VIP时，同一台设备同一个账号下在使用VIP私有设备数时会消耗两点下载，公有池设备数扣费不受安装包大小限制！
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.docbox {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}
.content {
  overflow: hidden;
}
.title_1 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #333333;
  margin: 30px 0;
}
.title_2 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 30px;
}
.title_3 {
  margin-top: 20px;
  font-size: 18px;
  line-height: 30px;
  color: #999999;
  margin-bottom: 30px;
}
</style>
