<template>
  <div>
    <div class="w">
      <div class="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }"
            >悟空在线
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/appManagement' }"
            >应用管理</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="productUrl">产品名称</el-breadcrumb-item>
          <el-breadcrumb-item>付费下载详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="content_header">
          <div class="content_title">付费下载详情</div>
        </div>
        <div class="content_table">
          <el-table :data="tableData" border v-loading="loading">
            <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column prop="udid" label="设备UDID" align="center"></el-table-column>
            <el-table-column prop="createDate" label="购买时间" align="center"></el-table-column>
            <el-table-column prop="modifyDate" label="到期时间" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openPayDetails } from "@/api/api";
export default {
  name: "payDownDetails",
  data() {
    return {
      tableData: [],
      productUrl: null,
      softId: "",
      loading: false,
    };
  },
  created() {
    this.softId = this.$route.query.softId;
    this.productUrl = {
      path: "/editApp",
      query: {
        softId: this.softId,
      },
    };
    this.getPayDetails();
  },
  methods: {
    getPayDetails() {
      this.loading = true;
      openPayDetails({ softId: this.softId, pageNum: 1 }).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
          console.log(this.tableData+"==================================");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 20px 0;
  font-size: 12px;
  color: #666666;
}
.content {
  height: 644px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 40px;
  box-sizing: border-box;
  padding: 30px;
  .content_header {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .content_title {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    font-family: PingFang SC;
  }
}
</style>
