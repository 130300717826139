export default {
  data() {
    return {
      name: "mixin",
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    // 获取浏览器版本
    getBrower() {
      let u = navigator.userAgent;
      let ua = navigator.userAgent.toLocaleLowerCase();
      return {
        trident: u.indexOf("Trident") > -1, // IE内核
        presto: u.indexOf("Presto") > -1 || u.indexOf("OPT") > -1 || u.indexOf("opt") > -1, // opera内核
        webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
        gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, // 火狐内核
        mobile: !!u.match(/Mobile/i), // 是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // IOS终端
        android: u.indexOf("Android") > -1, // 安卓终端
        iPhone: u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1 || u.indexOf("Mac") > -1 || u.indexOf("mac") > -1, // 是否为iphone或QQHD浏览器
        iPad: u.indexOf("iPad") > -1 || u.indexOf("ipad") > -1 || u.indexOf("Macintosh") > -1 || u.indexOf("macintosh") > -1, // 是否为iPad
        webApp: u.indexOf("Safari") == -1, // 是否web应用程序，没有头部与底部
        safari: (u.indexOf("safari") > -1 || u.indexOf("Safari") > -1), // 是否web应用程序，没有头部与底部
        QQbrw: u.indexOf("MQQBrowser") > -1 || u.indexOf("mqqbrowser") > -1, // QQ浏览器
        Quark: u.indexOf("Quark") > -1 || u.indexOf("quark") > -1,//夸克浏览器
        weiXin: u.indexOf("MicroMessenger") > -1, // 微信
        QQ: ua.match(/QQ/i) == "qq", // QQ
        weiBo: u.match(/WeiBo/i) == "weibo", // 微博
        UC: u.indexOf("UCBrowser") > -1 || u.indexOf("ucbrowser") > -1,//UC浏览器
        ucLowEnd: u.indexOf("UCWEB7.") > -1, //
        ucSpecial: u.indexOf("rv:1.2.3.4") > -1,
        webview:
          !(u.match(/Chrome\/([\d.]+)/) || u.match(/CriOS\/([\d.]+)/)) &&
          u.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
        ucweb: (function() {
          try {
            return (
              parseFloat(
                u
                  .match(/ucweb\d+\.\d+/gi)
                  .toString()
                  .match(/\d+\.\d+/)
                  .toString()
              ) >= 8.2
            );
          } catch (e) {
            if (u.indexOf("UC") > -1) {
              return true;
            }
            return false;
          }
        })(),
        Symbian: u.indexOf("Symbian") > -1,
        ucSB: u.indexOf("Firofox/1.") > -1,
      };
    },
    getVipUserAgent(){
      var ua = navigator.userAgent.toLowerCase();
      if ((ua.match(/iphone/i) == "iphone" || ua.match(/ipad/i) == "ipad" || ua.match(/macintosh/i) == "macintosh") && ua.match(/safari/i) == "safari") {
        if(ua.match(/mqqbrowser/i) == "mqqbrowser" || ua.match(/mqbhd/i) == "mqbhd" || ua.match(/crios/i) == "crios"){
            return false;
        }else{
          return true;
        }  
      }
      return false;
    },
  },
};
