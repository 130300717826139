<template>
  <div class="content">
    <div class="w">
      <div class="title">聚合码</div>
      <div class="upLoad_box">
        <div class="upload_item" @click="updateClick">
          <div class="icon_img">
            <img src="../../assets/image/webClip.png" />
            <div>创建聚合码</div>
          </div>
          <div class="upload_bottom">
            <el-popover
              placement="top"
              title="注意："
              :width="200"
              trigger="hover"
              content="注意：在创建Web Clip之前，请确保您的App符合悟空在线的悟空在线应用审核规则，违反该规则的App将被删除，屡次上传者将被封禁账号。技术支持微信：wkzx2020。"
            >
              <template #reference>
                <div>
                  <i class="iconfont icon-tanhao"></i>
                  <span style="margin-left: 5px">注意事项</span>
                </div>
              </template>
            </el-popover>
          </div>
        </div>
        <div class="upload_item2">
          <div class="upload_header">
            <div class="top_icon">
              <div class="tab_icon">
                webClip
              </div>
              <div>
                <i class="iconfont icon-pingguo" style="color: #999999"></i>
              </div>
            </div>
            <hr style="color: #E4E4E4" />
          </div>
          <div class="upload_content">
            <div class="img">
              <img src="../../assets/image/app_1.png" />
              <div class="text">Facetune</div>
            </div>
            <div class="lianjie">
              <div class="lianjie_title">
                <div>短链接:</div>
                <div>唯一标识:</div>
                <div>最新版本:</div>
                <div>访问地址:</div>
                <div>审核状态:</div>
                <div>分发时长:</div>
              </div>
              <div class="lianjie_title">
                <div>
                  <a href="https://wkzx.wang/JCKE">https://wkzx.wang/JCKE</a>
                </div>
                <div>com.lightricks.Facetune</div>
                <div>2.7.3(Build 268439409)</div>
                <div>企业版</div>
                <div>wkzx_FWE32DF</div>
                <div>6天</div>
              </div>
            </div>
            <div class="upload_bottom">
              <el-button style="width: 88px" @click="editWebClip"
                >编辑</el-button
              >
              <el-button style="width: 88px">预览</el-button>
              <el-button icon="el-icon-delete"></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  height: 600px;
}
.title {
  margin-top: 30px;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

.top_icon {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tab_icon {
  width: 48px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  font-size: 5px;
  color: #fff;
  background: linear-gradient(to right, #1f43ff, #c51fff);
}
.upload_bottom {
  display: flex;
  justify-content: space-around;
}
.lianjie {
  display: flex;
  justify-content: space-around;
  .lianjie_title {
    font-size: 12px;
    color: #666666;
    div {
      padding: 7px 0;
    }
  }
}
.upLoad_box {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  .upload_item2 {
    width: 284px;
    height: 436px;
    margin-left: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
    .upload_content {
      width: 100%;
      .img {
        margin: 20px 0;
        margin-left: 90px;
        img {
          width: 80px;
          height: 80px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }
  .upload_item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 284px;
    height: 436px;
    background: #0092ff;
    border-radius: 10px;
    .icon_img {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 68px;
        height: 68px;
      }
      div {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
    }
    .upload_bottom {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fec943;
    }
    ::v-deep .el-upload-dragger {
      width: 284px;
      height: 100%;
      border: 0;
      background: #0092ff;
      .el-icon-upload {
        color: #fff;
        margin-top: 180px;
      }
      .el-upload__text {
        color: #fff;
      }
    }
  }
}
</style>
