<template>
	<div class="box">
		<div class="sell_box">
			<div class="sell_header">
				<div :class="item.style" v-for="item in priceList" :key="item.id" @click="sellStyleClick(item)">
					<div style="font-size: 16px;font-weight: bold;color:#333333">
						<span v-if="item.remark3 == 1">一年/台</span>
						<span v-if="item.remark3 == 2">6个月/台</span>
						<span v-if="item.remark3 == 3">3个月/台</span>
						<span v-if="item.remark3 == 4">一个月/台</span>
					</div>
					<div style="font-size: 14px;color: #BA812F;font-weight: bold;margin: 10px 0">
						¥
						<span style="font-size: 36px">{{ item.discount }}</span>
					</div>
					<div v-if="item.openDiscount == 1" :class="item.sellZhekouClass">¥{{ item.totalPrice }}</div>
				</div>
			</div>
			<div class="w2">
				<div class="sell_title">选择支付方式</div>
				<div class="sell_btn">
					<div :class="wxPaystyle" @click="wxPay">微信支付</div>
					<div :class="aliPayStyle" @click="zhifuPay">支付宝支付</div>
				</div>
				<div class="sell_confirm"><el-button type="primary" round style="width: 148px;margin: 40px 0;" @click="confirmSave">确定</el-button></div>
				<div class="sell_tips">
					<div>常见问题</div>
					<div>
						<div class="sell_exame">
							<div>1.付款须知</div>
							<i class="el-icon-arrow-down" @click="sellTipsClick(1)"></i>
						</div>
						<div class="sell_exame_font" v-if="payFlag">当前付款设备使用更换设备需要重新购买.支付默认同意底部相关协议，最终解释权归悟空在线所有.</div>
						<div class="sell_exame">
							<div>2.付款完成后再次下载的时候还需要付款吗？</div>
							<i class="el-icon-arrow-down" @click="sellTipsClick(2)"></i>
						</div>
						<div class="sell_exame_font" v-if="payConfirmFlag">不需要，应用是根据时间计算的，付款后在有效期内可以随意下载资源。但不同设备需分别付费.</div>
						<div class="sell_exame">
							<div>3.付款后还能退款吗？</div>
							<i class="el-icon-arrow-down" @click="sellTipsClick(3)"></i>
						</div>
						<div class="sell_exame_font" v-if="payOkFlag">应用是和设备ID绑定的，付款后无法解绑，所以暂不支持退款，如果您有任何疑问或疑虑，请立即与我们联系。</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getPriceList,goAliH5pay,wxAppPay } from '@/api/api';
export default {
	props: {
		UDID: {
			type: String,
			default: "",
		},
		userId: {
			type: String,
			default: "",
		},
		shortUrl: {
			type: String,
			default: "",
    	},
	},
	data() {
		return {
			payStatus: '',
			payOkFlag: false,
			payConfirmFlag: false,
			payFlag: false,
			priceList: [],
			sellLoading: false, // 刷新loading
			wxOrderId: '', // 微信订单号
			// 微信支付样式
			wxPaystyle: 'sell_btn_wx',
			aliPayStyle: 'sell_btn_zfb',
			// 购买支付对象
			priceObj: {
				price: '',
				oldPrice: ''
			},
			price: {
				orderPriceId: '', // 选择价格id
				productType: '', // 类型 0.下载次数 1.公有设备 2.私有设备 3.webclip 4.个人购买下载时长
				payKey: ''
			}
		};
	},
	created() {
		this.getPriceListInfo();
		this.price.payKey = this.guid();
	},
	methods: {
		//用于生成uuid
		S4() {
		  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		},
		guid() {
		  return (
		    this.S4() +
		    this.S4() +
		    "-" +
		    this.S4() +
		    "-" +
		    this.S4() +
		    "-" +
		    this.S4() +
		    "-" +
		    this.S4() +
		    this.S4() +
		    this.S4()
		  );
		},
		// 折叠
		sellTipsClick(val) {
			if (val == 1) {
				this.payFlag = !this.payFlag;
			}
			if (val == 2) {
				this.payConfirmFlag = !this.payConfirmFlag;
			}
			if (val == 3) {
				this.payOkFlag = !this.payOkFlag;
			}
		},
		confirmSave() {
			console.log(this.userId+"========================================");
			if(this.payStatus == 2) {
				if (this.priceObj.price == 0) {
				  return this.$message.warning("请选择套餐");
				}
				goAliH5pay({orderPriceId: this.price.orderPriceId,udid: this.UDID,userId: this.userId,orderType: 1,shortUrl: this.shortUrl}).then((res) => {
				//   let divForm = document.getElementsByTagName("divform");
				//   if (divForm.length) {
				//     document.body.removeChild(divForm[0]);
				//   }
				//   const div = document.createElement("divform");
				//   div.innerHTML = res.data.body; // data就是接口返回的form 表单字符串
				//   document.body.appendChild(div);
				//   document.forms[0].setAttribute("target", "_blank"); // 新开窗口跳转
				//   document.forms[0].submit();
				     if(res.code==0){
						 console.log("======================");
						document.querySelector('body').innerHTML = res.data.body; //查找到当前页面的body，将后台返回的form替换掉他的内容
						document.forms[0].submit();  //执行submit表单提交，让页面重定向，跳转到支付宝页面
                	}
				});
			} else if(this.payStatus == 1) {
				if (this.priceObj.price == 0) {
				  return this.$message.warning("请选择套餐");
				}
				wxAppPay({orderPriceId: this.price.orderPriceId,udid: this.UDID,userId: this.userId,orderType: 1,shortUrl: this.shortUrl}).then((res) => {
				  if (res.code == 0) {
				    window.location.href = res.data.appUrl;
				  }
				});
			}
		},
		wxPay() {
			this.wxPaystyle = 'sell_btn_wx_active';
			this.aliPayStyle = 'sell_btn_zfb';
			this.payStatus = 1
		},
		zhifuPay() {
			this.wxPaystyle = 'sell_btn_wx';
			this.aliPayStyle = 'sell_btn_zfb_active';
			this.payStatus = 2
		},
		// 获取购买价格表
		getPriceListInfo() {
			getPriceList({ productType: 1, publicType:1}).then(res => {
				this.priceList = res.data.orderPriceList;
				this.priceList.forEach(item => {
					item.style = 'sell_item';
					item.sellZhekouClass = 'sell_zhekou';
				});
			});
		},
		sellStyleClick(val) {
			this.priceObj.price = 0;
			this.priceObj.oldPrice = 0;
			this.price.orderPriceId = val.id;
			this.price.productType = val.status;
			this.priceObj.price = val.discount;
			this.priceObj.oldPrice = val.totalPrice;
			this.priceList.forEach(item => {
				if (val.id == item.id) {
					item.style = 'sell_item_active';
					item.sellZhekouClass = 'sell_zhekou_acitve';
				} else {
					item.style = 'sell_item';
					item.sellZhekouClass = 'sell_zhekou';
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.sell_exame_font {
	padding: 0 10px;
	color: #0092ff;
	font-size: 12px;
	line-height: 24px;
}
.el-icon-arrow-down {
	width: 20px;
}
.sell_exame {
	display: flex;
	justify-content: space-between;
	color: #666666;
	div {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 24px;
	}
}
.sell_confirm {
	display: flex;
	justify-content: center;
}
.sell_tips {
	overflow: hidden;
	padding: 20px;
	background: #f1f6f9;
	border-radius: 10px;
}
.w2 {
	padding: 0 20px;
	box-sizing: content-box;
}
.box {
	overflow: hidden;
	height: 100vh;
	background: #fff;
}
.sell_btn {
	.sell_btn_wx_active {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		border-radius: 10px;
		background: rgba(9, 201, 101, 0.2);
		box-sizing: border-box;
		background: #09c965;
		color: #fff;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.sell_btn_wx {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		border-radius: 10px;
		background: rgba(9, 201, 101, 0.2);
		border: 1px solid #09c965;
		box-sizing: border-box;
		color: #09c965;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.sell_btn_zfb {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		border: 1px solid #0092ff;
		box-sizing: border-box;
		border-radius: 10px;
		background: rgba(0, 146, 255, 0.2);
		color: #0092ff;
		cursor: pointer;
	}
	.sell_btn_zfb_active {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		box-sizing: border-box;
		border-radius: 10px;
		background: #0092ff;
		color: #fff;
		cursor: pointer;
	}
}
.sell_title {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	margin-bottom: 20px;
}
.sell_zhekou {
	border-radius: 46px;
	background: #ececec;
	width: 47px;
	text-align: center;
	text-decoration: line-through;
}

.sell_zhekou_acitve {
	border-radius: 46px;
	background: #f8e3bf;
	color: #ba812f;
	width: 47px;
	text-align: center;
	text-decoration: line-through;
}
.sell_header {
	margin: 50px 0;
	display: flex;
	overflow: auto;
}

.sell_item_active {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #fcf3e6;
	width: 200px;
	margin-left: 20px;
	height: 154px;
	border-radius: 10px;
	border: 2px solid #ebce9f;
}
.sell_item {
	flex-shrink: 0;
	display: flex;
	margin-left: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 200px;
	height: 154px;
	border-radius: 10px;
	border: 2px solid #ebce9f;
}
</style>
