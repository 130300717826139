<template>
  <div>
    <div v-if="typeSceen" class="w">
      <div class="contract_box">
        <div class="contract_left">
          <div>
            <div
              style="border-radius: 10px;margin-bottom: 20px;"
             :class="urlPath == '/blogGroup' || urlPath.includes('/blog')  ?  'contract_item_active' : 'contract_item'"
              @click="contractClick({id: 7,path: '/contract/blogGroup'})"
            >
              博客团队
            </div>
          </div>
          <div style="border-radius: 10px;overflow: hidden;">
            <div
              :class="urlPath == item.path ? 'contract_item_active' : 'contract_item'"
              v-for="item in contractList"
              :key="item.id"
              @click="contractClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </div>
    <div v-else>
      <div class="contract_box">
        <div class="contract_left" v-if="getshowLeft">
          <div>
            <div
              style="border-radius: 10px;margin-bottom: 20px;"
             :class="urlPath == '/blogGroup' || urlPath.includes('/blog')  ?  'contract_item_active' : 'contract_item'"
              @click="contractClick({id: 7,path: '/contract/blogGroup'})"
            >
              博客团队
              <img class="goClass" src="../../components/contract/img/go.png" alt="">
            </div>
          </div>
          <div style="border-radius: 10px;overflow: hidden;">
            <div
              :class="urlPath == item.path ? 'contract_item_active' : 'contract_item'"
              v-for="item in contractList"
              :key="item.id"
              @click="contractClick(item)"
            >
              {{ item.name }}
              <img class="goClass" src="../../components/contract/img/go.png" alt="">
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from "@/store";
export default {
  name: "contract",
  components: {
  },
  data() {
    return {
      typeSceen:true,
      urlPath: '',
      status: 1,
      contractList: [
        {
          id: 1,
          name: "关于我们",
          class: "contract_item",
          path: "/contract/aboutUs",
        },
        {
          id: 2,
          name: "联系我们",
          class: "contract_item",
          path: "/contract/contractUs",
        },
        {
          id: 3,
          name: "服务协议",
          class: "contract_item",
          path: "/contract/serviceGgreement",
        },
        {
          id: 4,
          name: "隐私声明",
          class: "contract_item",
          path: "/contract/privacyStatement",
        },
        {
          id: 5,
          name: "免责声明",
          class: "contract_item",
          path: "/contract/disclaimers",
        },
        {
          id: 6,
          name: "审核规则",
          class: "contract_item",
          path: "/contract/auditPolicy",
        },
      ],
    };
  },
  created() {
    // this.status = this.$route.query.status;
    this.urlPath = this.$route.path
    console.log(this.$route.path)
    // if(this.$route.path.includes("/contract/")){
    //   store.commit("showLeftChange", false);
    // }
  },
  computed: {
    ...mapGetters(['getshowLeft'])
  },
  async mounted() {
    this.selectContract(this.status);
        //判断 屏幕的宽度
    let t = this;
    if (document.body.clientWidth < 750) {
        t.typeSceen = false;
    } else {
        t.typeSceen = true;
        if(t.$route.path == '/contract'){
          t.$router.push('/contract/blogGroup')
        }
    }
  },
  watch: {
    $route(to) {
      console.log(to)
      this.urlPath = to.path
      // this.status = to.query.status;
      // this.selectContract(to.query.status);
    },
  },
  methods: {
    selectContract(val) {
      this.contractList.forEach((item) => {
        if (item.id == val) {
          item.class = "contract_item_active";
          if(item.id == 7){
            item.class = " contract_item_active lastStyle";
          }
        } else {
          item.class = "contract_item";
          if(item.id == 7){
            item.class = "contract_item lastStyle";
          }
        }
      });
    },
    contractClick(val) {
      console.log(val)
      this.status = val.id
      this.selectContract(val.id);
      this.$router.replace(val.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.contract_box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #666666;
}
.contract_left {
  width: 260px;
  // height: 54px;
  background: #fff;
  border-radius: 10px;
}
.contract_item {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 30px;
  box-sizing: border-box;
  color: #333;
  font-size: 18px;
  background: #fff;
}
.contract_item_active {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 30px;
  box-sizing: border-box;

  font-size: 18px;
  color: #0092ff;
  background: #fff;
}
.contract_item_active::after {
  content: "|";
  position: absolute;
  color: #0092ff;
  font-weight: 700;
  left: 0;
}
.lastStyle {
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
}
.goClass {
  margin-top: 16px;
  float: right;
}
@media only screen and (min-width: 321px) and (max-width: 1024px) {
  
  .contract_left {
      width: 90%;
      margin-left: 5%;
      height: 54px;
      background: #fff;
      border-radius: 10px;
  }
  .contract_item {
    border-bottom: 1px solid #eee;
    padding: 0 20px;
  }
  .contract_box {
    min-height: 440px;
  }
  .footer {
    position: fixed !important;
    bottom: 10px !important;
  }
}
</style>
