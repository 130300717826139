<template>
  <div class="content">
    <nav-header v-if="!is_mobiState"></nav-header>
	<mobile-header v-if="is_mobiState"></mobile-header>
    <router-view></router-view>
    <footer-bottom v-if="!is_mobiState"></footer-bottom>
	<mobile-footer v-if="is_mobiState"></mobile-footer>
  </div>
</template>

<script>
import mobileHeader from '@/views/mobilePage/common/mobileHeader.vue'
import mobileFooter from '@/views/mobilePage/common/mobileFooter.vue'
import navHeader from '@/components/common/header'
import footerBottom from '@/components/common/footer2'
export default {
  components: {
    navHeader,
    footerBottom,
	mobileHeader,
	mobileFooter
  },
  data() {
    return {
      is_mobi: false
    };
  },
  created() {
	this.getPcAndMobile()
  },
  computed: {
	 is_mobiState() {
		 return this.$store.state.is_mobi
	 } 
  },
  methods: {
	  // 获取是否是pc
	  getPcAndMobile() {
		  this.is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
		  this.$store.commit('is_mobiChange',this.is_mobi)
	  }
  },
};
</script>

<style lang="scss" scoped>

</style>
