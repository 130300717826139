<template>
  <div>
    <div class="header">
      <img src="@/assets/image/san.png" class="img" @click="navClick" />
      <img src="@/assets/image/logoM.png" class="img2" />
      <img src="@/assets/image/user.png" class="img" @click="loginClick" />
    </div>
    <el-drawer
      v-model="drawer"
      :with-header="false"
      direction="ltr"
      width="290px"
    >
      <div>
        <div class="drawer_header">
          <img src="@/assets/image/logoM.png" />
          <div @click="closeClick">×</div>
        </div>
        <div class="nav_box">
          <div
            :class="item.style"
            v-for="item in navList"
            :key="item.id"
            @click="daohangClick(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
// import { loginJApplet } from "@/api/api";
// import axios from "axios";
import store from "@/store";
export default {
  data() {
    return {
      drawer: false,
      navList: [
        {
          id: 1,
          name: "首页",
          style: "nav_item",
        },
        {
          id: 2,
          name: "应用管理",
          style: "nav_item",
        },
        {
          id: 3,
          name: "Web Clip",
          style: "nav_item",
        },
        {
          id: 5,
          name: "安装助手",
          style: "nav_item",
        },
        {
          id: 6,
          name: "价格",
          style: "nav_item",
        },
        {
          id: 7,
          name: "文档说明",
          style: "nav_item",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    launch(e) {
      console.log("success", e);
    },
    error(e) {
      console.log("error", e);
    },
    loginClick() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        window.location.href = "weixin://dl/business/?t=pTa3Pdhy79p";
      } else {
        this.$message(
          "暂不支持手机浏览器登录，请微信小程序内搜索“悟空内测分发服务平台” 或者电脑访问登录"
        );
      }
    },
    closeClick() {
      this.drawer = false;
    },
    navClick() {
      this.drawer = true;
    },
    daohangClick(val) {
      this.drawer = false;
      this.navList.forEach((item) => {
        if (item.id == val) {
          item.style = "nav_item_active";
        } else {
          item.style = "nav_item";
        }
      });
      if (val == 1) {
        this.$router.push("/home");
      }
      if (val == 2) {
        this.$router.push("/mobileNo");
      }
      if (val == 3) {
        this.$router.push("/mobileNo");
      }
      if (val == 4) {
        this.$router.push("/aggregateCode");
      }
      if (val == 5) {
        this.$router.push("/installAssistant");
      }
      if (val == 6) {
        this.$router.push("/price");
      }
      if (val == 7) {
        this.$router.push("/contract");
        store.commit("showLeftChange", true);
        // window.open('https://support.qq.com/products/349734/blog-archive')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_item_active {
  height: 44px;
  line-height: 44px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #0092ff;
}
.nav_item {
  height: 44px;
  line-height: 44px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
}
.nav_box {
  padding-left: 1.875rem;
}
.drawer_header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin-left: 1.875rem;
    width: 6.625rem;
    height: 26px;
  }
  div {
    font-size: 30px;
    line-height: 50px;
    color: #0092ff;
    margin-right: 1.4375rem;
  }
}
::v-deep .el-drawer {
  width: 18.125rem !important;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  // width: 100%;
  height: 50px;
  background: #fff;
  .img {
    width: 1.25rem;
    height: 20px;
  }
  .img2 {
    width: 5.625rem;
    height: 20px;
  }
}
</style>
