<template>
	<div>
		<footer-bottom></footer-bottom>
	</div>
</template>

<script>
import footerBottom from '@/views/appDown/footerBottom.vue'
export default {
	components: {
		footerBottom
	}
}
</script>

<style>
</style>
