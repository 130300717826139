<template>
    <div style="width: 100%;">
        <div class="hr">
            ————  更换认证方式  ————
        </div>
        <div class="bigBox">
            <div class="box">
                <div class="zhifu">
                    <img src="../../assets/image/zfb.png" alt="">
                    <span class="title">支付宝认证</span>
                </div>
                <span class="renzheng" @click="renzhengClick(3)">立即认证</span>
            </div>
            <div class="box">
                <div class="zhifu">
                    <img src="../../assets/image/wx.png" alt="">
                    <span class="title">微信认证</span>
                </div>
                <span class="renzheng" @click="renzhengClick(4)">立即认证</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        renzhengClick(val) {
            let urlPath = '';
            if(val == 3) {
                urlPath = "/aliPayAuthen"
            } 
            if(val == 4) {
                urlPath = "/weXinAuthen"
            }
            this.$router.push({
                path: urlPath,
                query: {
                    status: val,
                },
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .bigBox {
        display: flex;
        justify-content: space-between;
        
    }
    .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 396px;
        height: 90px;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px 30px;
        .zhifu {
            display: flex;
            align-items: center;
        }
        img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
        }
        .title {
            font-family: 'PingFang SC';
            font-weight: 700;
            font-size: 18px;
            color: #333333;
        }
        .renzheng {
            display: inline-block;
            width: 84px;
            height: 34px;
            background: #0092FF;
            border-radius: 4px;
            font-family: 'PingFang SC';
            font-size: 14px;
            color: #fff;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
        }
    }
    .hr {
        font-family: 'PingFang SC';
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        margin: 50px 0;
        color: #999999;

    }
</style>