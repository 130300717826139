<template>

	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item>域名绑定失败常见原因</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">域名绑定失败常见原因</div>
			<el-divider></el-divider>
			<div class="neitest">域名绑定失败可能有多种原因，下文的示例中，我们将使用 fir.yourdomain.com 来说明，您在具体检测时，请将域名替换为您自己配置的二级域名。</div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;CNAME
				配置错误</div>
			<div class="neitest">如何检测CNAME是否配置成功呢？</div>
			<div class="neitest">您可以通过站长工具检测<a href="https://tool.chinaz.com/dns/?type=5" rel="nofollow">【CNAME 配置】</a></div>
			<div class="neitest">1.输入你配置的域名，点击【检测】</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/jiance1.png"></div>
			<div class="neitest">2.查看 GNAME 返回值知否为 ****.aicdn.com</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/jiance2.png"></div>
			<div class="neitest">如果返回值，如图，为*****.aicdn，请点击"我已修改配置，重新检测"，部分检测稍有延迟；</div>
			<div style="height: 1.25rem;"></div>
			<div class="neitest">如果返回值并非*****.aicdn,情检测具体的GNAME配置值，配置是否正确。</div>
			<div class="neitest">注意事项:</div>
			<div class="neitest">如果您已经配置成功，但是因使用了第三方服务导致无法直接检测出CNAME记录的情况，请联系客服进行特殊处理。</div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;wkzx配置的域名和GNAME域名不同</div>
			<div class="neitest">比如wkzx设置二级域名 app.yourdomain.com，但是配置CNAME时您填写的主机记录为apps，即配置的二级域名为app.yourdomain.com，将导致下载检测失败。</div>
			<!-- <div class="dibu">
				<div>1564阅读 更新时间 10-6 19:27</div>
				<div>566人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div> -->
			<!-- 
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			tiaozhuan(keyPath) {
				if (keyPath == 1) {
					this.$router.push("/home");
				}
			}
		}
	}
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.red {
		color: red;
	}

	.daohang {
		margin-bottom: 0.625rem;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}

	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
