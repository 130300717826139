<template>
	<div class="content_right" v-loading="loading">
		<div class="tips">
			<div class="tip_header" style="font-weight: 700;color:#555555;line-height: 20px;font-size: 14px">提示:</div>
			<div class="tip_body">
				<div style="font-weight: 700;color: #555555;margin-bottom: 10px;">
					前往
					<span style="color:#0092FF;cursor: pointer" @click="tipsClick(1)">AppStore Connect</span>
					，按照
					<span @click="tipsClick(2)" style="color:#0092FF;cursor: pointer">此步骤</span>
					获取API密钥，将获取到的密钥添加到这里。
				</div>
				<div>1.添加后，请勿撤销API密钥，否则会导致用户安装的软件闪退或者无法安装！</div>
				<div>2.每个开发者账号最多可创建两本证书，请确保至少还可以创建一本证书！</div>
				<div>3.添加后，系统会自动创建证书，设备和描述文件， 请勿删除这些文件，否则会导致用户安装的软件闪退或无法安装！</div>
				<div>4.申请苹果个人开发者账号时，请不要使用同一张信用卡用于支付多个账号订单，否则容易导致封号。</div>
				<div>5.技术问题请联系官方微信:<span style="color: #0092FF">wkzx2020</span></div>
			</div>
		</div>
		<div class="title">修改Connect API</div>
		<div class="form">
			<el-form ref="form" :rules="rules" :model="form">
				<el-form-item label="账号名称" prop="remark"><el-input v-model="form.remark" placeholder="请输入账户名称"></el-input></el-form-item>
				<el-form-item label="Issuer ID" prop="iss"><el-input v-model="form.iss" placeholder="请输入Issuer ID"></el-input></el-form-item>
				<el-form-item label="密钥 ID" prop="kid"><el-input v-model="form.kid" placeholder="请输入密钥 ID"></el-input></el-form-item>
				<el-form-item label="点击上传密钥 P8 文件" prop="apiKey">
					<el-upload
						:headers="myHeaders"
						:action="src + 'account/uploadAPI'"
						:before-remove="beforeRemove"
						:file-list="fileList"
						drag
						accept=".p8"
						:on-preview="handlePreview"
						:on-success="handleFilUploadSuccess"
						:limit="1"
					>
						<img src="@/assets/image/p8.png" />
						<div class="el-upload__text"><em>点击上传密钥 P8 文件</em></div>
					</el-upload>
				</el-form-item>
				<el-form-item><el-button type="primary" @click="submitAPI">提交</el-button></el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { editAccount,addPrivateAccount } from '@/api/api';
import BASE_URL from '@/utils/request.js';
export default {
	name: 'editApi',
	props: {
		id: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			loading: false,
			src: BASE_URL,
			fileList: [],
			myHeaders: null,
			rules: {
				remark: [{ required: true, message: '请输入账户名称', trigger: 'blur' }],
				iss: [{ required: true, message: '请输入Issuer ID', trigger: 'blur' }],
				kid: [{ required: true, message: '请输入密钥Id', trigger: 'blur' }],
				apiKey: [{ required: true, message: '请输入密钥Id', trigger: 'change' }]
			},
			form: {
				remark: '',
				iss: '',
				kid: '',
				apiKey: '',
				p12name: '',
				url: '',
			}
		};
	},
	created() {
		var token = window.localStorage.getItem('token');
		this.myHeaders = { Authorization: token };
		this.editAccount();
	},
	methods: {
		// 获取数据
		editAccount() {
			editAccount({ id: this.id }).then(res => {
				if (res.code == 0) {
					let name = res.data.apiPath;
					name = name.substring(name.lastIndexOf("/")+1,name.length);
					this.fileList.push({ url: res.data.apiPath,name:name });
					this.form.remark = res.data.account.remark2;
					this.form.iss = res.data.account.iss;
					this.form.kid = res.data.account.keyid;
					this.form.apiKey = res.data.account.apikey;
					this.form.p12name = res.data.account.p12name;
					this.form.url = res.data.apiPath;
				}
			});
		},
		tipsClick(val) {
			if (val == 1) {
				window.open('https://appstoreconnect.apple.com/login?targetUrl=%2Faccess%2Fapi&authResult=FAILED');
			}
			if (val == 2) {
				window.open('https://support.qq.com/products/349734/blog/514104');
			}
		},
		//删除弹出框
		beforeRemove(file) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		//上传文件成功后回调 获取路径
		handleFilUploadSuccess(res) {
			this.form.apiKey = res.data.filePath;
			console.log(this.form.apiKey + '---------------------');
		},
		//下载文件
		handlePreview(file){
			this.$confirm(`确定下载 ${file.name}？`).then(()=>{
				window.location.href = this.form.url;
			});	
		},
		//提交
		submitAPI() {
			this.loading = true;
			addPrivateAccount(this.form).then(res => {
				this.loading = false;
				if (res.code == 0) {
					this.$message.success('添加成功');
					this.$emit('flagChange', 2);
					this.$router.push('/appManagement');
				} else {
					this.$message.error(res.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 860px;
	height: 200px;
	background-color: #f4f4f4;
}
::v-deep .el-form-item {
	flex-direction: column;
}
::v-deep .el-form-item__label {
	text-align: left;
}
.title {
	font-size: 22px;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 15px;
	color: #333;
}
.content_right {
	width: 920px;
	overflow: hidden;
	background: #ffffff;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 20px 30px;
	.tips {
		display: flex;
		width: 860px;
		height: 150px;
		background: #fff7dc;
		border: 1px solid #ffe17a;
		box-sizing: border-box;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 15px;
		font-size: 12px;
		color: #9f8c6d;
		.tip_body {
			font-size: 14px;
			line-height: 20px;
		}
	}
}
.p8_file > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.p8 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 860px;
	height: 200px;
	background: #f4f4f4;
	border: 1px dashed #dddddd;
	box-sizing: border-box;
	border-radius: 4px;
}
</style>
