<template>
    <div class="box">
      <img src="@/assets/image/pay_success.png" alt="" />
    </div>
</template>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;
  text-align: center;
}
// .box img {
//   width: 45vw;
//   height: 60vw;
// }
</style>
