import { createStore } from 'vuex'

export default createStore({
  state: {
	  is_mobi: false, // �ж�pc�����ֻ�
    domainFlag: true,
    token: '',
    authentication: 0, // 是否封禁状�
    domainStatus: 0, // 域名状�
    activeIndex: '', // 导航栏路由状�
    showLeft:true,
  },
  getters: {
    getDomainFlag(state) {
      return state.domainFlag
    },
    getDomainStatus(state) {
      return state.domainStatus
    },
    getshowLeft(state){
      return state.showLeft
    },
  },
  mutations: {
	  is_mobiChange(state,val) {
		state.is_mobi = val
	  },
    // 存储authentication状�
    authenticationChange(state,val) {
      state.authentication = val
    },
    // 设置token�
    tokenChange(state,val) {
      state.token = val
    },
    // 导航栏�中状�修�
    navActiveChange(state,val) {
      state.activeIndex = val
    },
    showLeftChange(state, val) {
      state.showLeft = val
    },
    domainFlagChange(state,val) {
      state.domainFlag = val
    },
    domainStatusChange(state,val) {
      state.domainStatus = val
    }
  },
  actions: {
  },
  modules: {
  }
})
