<template>
  <div>
    <div class="w">
      <div class="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }">悟空在线
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/webClip' }">Web Clip</el-breadcrumb-item>
          <el-breadcrumb-item>提交Web Clip</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content_box" v-loading="loading">
        <div class="title">Web Clip信息</div>
        <div class="zhuti">
          <div class="zhuti_left">
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
              <el-form-item label="桌面图标">
                <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false" :accept="uploadType" :before-upload="beforeUploadIcon">
                  <img v-if="form.iconUrl" :src="form.iconUrl" class="avatar" />
                  <img v-else src="../../assets/image/webClipIcon.png" class="avatar" />
                </el-upload>
              </el-form-item>
              <el-form-item label="桌面名称" prop="label">
                <el-input v-model="form.label" placeholder="请输入桌面名称"></el-input>
              </el-form-item>
              <el-form-item label="配置文件名称" prop="displayname">
                <el-input v-model="form.displayname" placeholder="请输入配置文件名称"></el-input>
              </el-form-item>
              <el-form-item label="唯一标识" prop="identifier">
                <el-input v-model="form.identifier" placeholder="请输入唯一标识"></el-input>
              </el-form-item>
              <el-form-item label="版本" prop="version">
                <el-input v-model="form.version" placeholder="请输入版本号"></el-input>
              </el-form-item>
              <el-form-item label="访问地址" prop="targetUrl">
                <el-input v-model="form.targetUrl" placeholder="请输入访问地址"></el-input>
              </el-form-item>
              <el-form-item label="下载时长" prop="versions">
                <el-radio-group v-model="form.versions">
                  <el-radio-button label="0">7天试用</el-radio-button>
                  <el-radio-button label="1">正式版购买</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="全屏" prop="fullScreen">
                <el-select v-model="form.fullScreen" placeholder="请选择是否全屏">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="简介" prop="description">
                <el-input v-model="form.description" type="textarea" placeholder="请输入简介"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="zhuti_right">
            <el-carousel height="678px" trigger="click">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <img class="banner_img" :src="item.imgUrl" alt="" />
                <div
                  style="text-align: center;margin-top: 20px;font-size: 24px;font-family: MicrosoftYaHei;font-weight: 400;">
                  {{ item.title }}
                </div>
              </el-carousel-item>
            </el-carousel>
            <!-- <img src="../../assets/image/webClip_2.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <sell-web-clip v-show="dialogWebClip" :webclipId="webclipId" :dialogVisible="dialogWebClip" :iswebClipPay="true"
      @dialogChange="dialogChange" @saveFunction="saveFunction" @webClipPay="webClipPay"></sell-web-clip>
  </div>
</template>

<script>
import sellWebClip from "@/views/webClip/common/sellWebClip";
import { saveWebclip, getOssToken } from "@/api/api";
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord } from "@/common/js/common.js";
export default {
  name: "submitWebClip",
  components: {
    sellWebClip,
  },
  data() {
    return {
      uploadType: ".jpg,.jpeg,.png,.JPG,.JPEG",
      loading: false,
      rules: {
        label: [{ required: true, message: "请输入桌面名称", trigger: "blur" }],
        displayname: [
          { required: true, message: "请输入配置文件名称", trigger: "blur" },
        ],
        identifier: [
          { required: true, message: "请输入唯一标识", trigger: "blur" },
        ],
        targetUrl: [
          { required: true, message: "请输入访问地址", trigger: "blur" },
        ],
        versions: [
          { required: true, message: "请输入下载时长", trigger: "blur" },
        ],
        fullScreen: [
          { required: true, message: "请选择是否全屏", trigger: "change" },
        ],
        description: [
          { required: true, message: "请输入简介", trigger: "blur" },
        ],
        iconUrl: [{ required: true, message: "请选择图标", trigger: "change" }],
      },
      options: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      webclipId: '',  // 提交回传id
      form: {
        label: "",
        displayname: "",
        version: "1.0",
        identifier: "",
        targetUrl: "",
        versions: "0",
        fullScreen: 1,
        description: "",
        iconUrl: "",
        orderPriceId: "",//价格id
      },
      bannerList: [
        {
          imgUrl: require("../../assets/image/1.jpg"),
          title: "第一步",
        },
        {
          imgUrl: require("../../assets/image/2.jpg"),
          title: "第二步",
        },
        {
          imgUrl: require("../../assets/image/3.jpg"),
          title: "第三步",
        },
        {
          imgUrl: require("../../assets/image/4.jpg"),
          title: "第四步",
        },
        {
          imgUrl: require("../../assets/image/5.jpg"),
          title: "第五步",
        },
      ],
      dialogWebClip: false,
    };
  },
  methods: {
    dialogChange(val) {
      this.dialogWebClip = val;
    },
    beforeUploadIcon(file) {
      //获取后缀名
      var imageName = file.name;
      var index = imageName.lastIndexOf(".");
      var suffix = imageName.substring(index, imageName.length); //后缀名
      imageName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(imageName, file)
            .then((res) => {
              if (res.url) {
                console.log(res.url + "===================");
                this.form.iconUrl = res.url;
                // this.form.iconImageName = imageName;
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    webClipPay(val) {
      console.log("=============webclip保存回调测试=========" + val);
      this.form.orderPriceId = val;
    },
    // 提交方法
    saveFunction() {
      var that = this
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.loading = true;
          saveWebclip(that.form).then((res) => {
            that.loading = false;
            if (that.form.versions == 1) {
              that.webclipId = res.data
              that.dialogWebClip = true;
            } else {
              if (res.code == 0) {
                that.$message.success(res.msg);
                that.$router.push("/webClip");
              } else {
                that.$message.error(res.msg);
              }
            }

          });
        }
      });
    },
    // 提交
    submit() {
      if (!this.form.iconUrl) {
        return this.$message.error("请上传图标");
      }
      this.saveFunction()
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  resize: none;
}

.banner_img {
  width: 300px;
  height: 600px;
}

.avatar {
  width: 54px;
  height: 54px;
}

// ::v-deep .el-form-item {
//   margin-bottom: 10px;
// }
::v-deep .el-form-item__label {
  text-align: left;
}

.zhuti {
  display: flex;
}

.zhuti_left {
  width: 562px;
  overflow: hidden;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 30px;
  margin-right: 151px;
}

.zhuti_right {
  width: 300px;
}

.header {
  padding: 20px 0;
  font-size: 12px;
  color: #666666;
}

.content_box {
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 30px;

  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
</style>
