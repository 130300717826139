<template>
  <div class="content">
    <el-dialog
      v-model="erweiVisible"
      title="扫码付款"
      center
      width="400px"
      :before-close="erweiClose"
      :close-on-click-modal="false"
    >
      <div class="erwei_box">
        <div id="qrCodeErWei" ref="qrCodeDivErWei"></div>
        <div class="erwei_font">请使用需要绑定的设备<br />进行扫描付款</div>
      </div>
    </el-dialog>
    <el-dialog
      title="购买流量包"
      v-model="dialogVisibles"
      :close-on-click-modal="false"
      width="792px"
      :before-close="handleCloses"
    >
      <div v-loading="sellLoading">
        <div class="sell_header">
          <div
            :class="item.style"
            v-for="item in priceList"
            :key="item.id"
            @click="sellStyleClick(item)"
          >
            <div style="font-size: 16px; font-weight: bold; color: #333333">
              {{ item.downnum }}/累计流量
            </div>
            <div
              style="
                font-size: 14px;
                color: #ba812f;
                font-weight: bold;
                margin: 10px 0;
              "
            >
              ¥
              <span v-if="item.openDiscount == 0" style="font-size: 36px">{{
                item.totalPrice
              }}</span>
              <span v-else style="font-size: 36px">{{ item.discount }}</span>
            </div>
            <div v-if="item.openDiscount == 1" :class="item.sellZhekouClass">
              ¥{{ item.totalPrice }}
            </div>
          </div>
        </div>
        <div class="sell_footer">
          <div class="sell_left">
            <div class="sell_left_title">选择支付方式</div>
            <div class="sell_btn">
              <div :class="wxPaystyle" @click="wxPay">
                <div>微信支付</div>
                <img
                  v-show="wxPaystyle == 'sell_btn_wx_active'"
                  src="@/assets/image/success1.png"
                  style="width: 15px; height: 15px"
                />
              </div>
              <div :class="aliPayStyle" @click="zhifuPay">
                <div>支付宝支付</div>
                <img
                  v-show="aliPayStyle == 'sell_btn_zfb_active'"
                  src="@/assets/image/success1.png"
                  style="width: 15px; height: 15px"
                />
              </div>
            </div>
          </div>
          <div class="sell_erwei">
            <div id="qrCodes" ref="qrCodeDivs" class="sell_erwei_box"></div>
            <!-- v-loading="wxmaLoading" -->
          </div>
          <div class="sell_right">
            <div class="sell_right_title">支付金额</div>
            <div>
              <span v-if="wxPaystyle == 'sell_btn_wx_active'">微信支付</span>
              <span v-else>支付宝支付</span>
              <span
                style="
                  font-size: 14px;
                  color: #ba812f;
                  font-weight: bold;
                  margin-left: 10px;
                "
              >
                ¥
                <span style="font-size: 36px">{{
                  priceObj.price ? priceObj.price : 0
                }}</span>
              </span>
              <span
                v-if="priceObj.openDiscount"
                class="sell_zhekou"
                style="display: inline-block; margin-left: 10px"
              >
                {{ priceObj.oldPrice ? priceObj.oldPrice : 0 }}
              </span>
            </div>
            <el-button
              round
              size="mini"
              style="margin-top: 15px"
              @click="updatePay"
              >刷新</el-button
            >
            <span style="font-size: 14px; color: #ff4a4a; margin-left: 10px"
              ><!-- {{tips}} --></span
            >
          </div>
        </div>
        <div class="sell_tips">
          在购买下载流量包时有任何疑问请点击
          <span
            style="cursor: pointer; color: #0092ff; text-decoration: underline"
            @click="seeUrl"
            >《常见问题》</span
          >
          进行咨询！
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="购买设备数量"
      v-model="dialogVisible"
      width="792px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div v-loading="sellLoading">
        <div class="sell_select" v-if="productType == 1">
          <div class="no_list" v-if="sellSelectList.length < 1">
            暂无可购买方案
          </div>
          <div
            :class="item.sellSelect"
            v-for="item in sellSelectList"
            :key="item.id"
            @click="sellSelectClick(item)"
          >
            <div
              style="
                font-weight: bold;
                font-size: 18px;
                color: #333;
                text-align: center;
              "
            >
              {{ item.title }}
            </div>
            <div style="font-size: 14px; color: #666666; text-align: center">
              {{ item.tips }}
            </div>
          </div>
        </div>
        <div class="sell_header">
          <div class="no_list" v-if="priceList.length < 1">暂无可购买方案</div>
          <div
            :class="item.style"
            v-for="item in priceList"
            :key="item.id"
            @click="sellStyleClick(item)"
          >
            <div style="font-size: 16px; font-weight: bold; color: #333333">
              <span v-if="item.remark3 == 1">一年/台</span>
              <span v-if="item.remark3 == 2">6个月/台</span>
              <span v-if="item.remark3 == 3">3个月/台</span>
              <span v-if="item.remark3 == 4">1个月/台</span>
              <span v-if="item.remark3 == 0 && item.deviceNum != 0"
                >{{ item.deviceNum }}台</span
              >
            </div>
            <div
              style="
                font-size: 14px;
                color: #ba812f;
                font-weight: bold;
                margin: 10px 0;
              "
            >
              ¥
              <span v-if="item.openDiscount == 0" style="font-size: 36px">{{
                item.totalPrice
              }}</span>
              <span v-else style="font-size: 36px">{{ item.discount }}</span>
            </div>
          </div>
        </div>
        <div class="sell_footer" v-if="timeSellFlag">
          <div class="sell_left">
            <div class="sell_left_title">选择支付方式</div>
            <div class="sell_btn">
              <div :class="wxPaystyle" @click="wxPay">
                <div>微信支付</div>
                <img
                  v-show="wxPaystyle == 'sell_btn_wx_active'"
                  src="@/assets/image/success1.png"
                  style="width: 15px; height: 15px"
                />
              </div>
              <div :class="aliPayStyle" @click="zhifuPay">
                <div>支付宝支付</div>
                <img
                  v-show="aliPayStyle == 'sell_btn_zfb_active'"
                  src="@/assets/image/success1.png"
                  style="width: 15px; height: 15px"
                />
              </div>
            </div>
          </div>
          <div class="sell_erwei">
            <div class="btn_erwei" v-show="selectFlag" @click="selectClick">
              <img src="@/assets/image/qr_perch_image.png" />
            </div>
            <div id="qrCode" ref="qrCodeDiv" class="sell_erwei_box"></div>
          </div>
          <div class="sell_right">
            <div class="sell_right_title">支付金额</div>
            <div>
              <span v-if="wxPaystyle == 'sell_btn_wx_active'"> 微信支付</span>
              <span v-else>支付宝支付</span>
              <span
                style="
                  font-size: 14px;
                  color: #ba812f;
                  font-weight: bold;
                  margin-left: 10px;
                "
                >¥<span style="font-size: 36px">{{
                  priceObj.price ? priceObj.price : 0
                }}</span></span
              >
              <span
                v-if="priceObj.producttype == 1"
                class="sell_zhekou"
                style="display: inline-block; margin-left: 10px"
                >{{ priceObj.oldPrice ? priceObj.oldPrice : 0 }}</span
              >
            </div>
            <el-button
              round
              size="mini"
              style="margin-top: 15px"
              @click="refreshQR"
              >刷新</el-button
            >
            <span style="font-size: 14px; color: #ff4a4a; margin-left: 10px">
            </span>
          </div>
        </div>
        <div class="sell_tips">
          <el-checkbox
            v-model="checked1"
            label="服务使用条款"
            style="margin-right: 10px; color: #0092ff"
          ></el-checkbox>
          <span>技术支持、商务合作、批量购买请联系 微信：wkzx2020</span>
        </div>
      </div>
    </el-dialog>
    <div class="content_table">
      <table border="0" cellspacing="0" cellpadding="0" class="table_border">
        <tr>
          <th colspan="5">公有池</th>
        </tr>
        <tr>
          <td colspan="5" class="table_title">悟空在线提供开发者账号</td>
        </tr>
        <tr>
          <td class="table_body">类别</td>
          <td class="table_body">总设备数</td>
          <td class="table_body">使用设备数</td>
          <td class="table_body">剩余设备数</td>
          <td class="table_body">购买设备</td>
        </tr>
        <tr>
          <td class="table_body">台数设备</td>
          <td class="table_body">{{ appInfo.equipmentNum }}台</td>
          <td class="table_body" style="color: #0092ff">
            <el-button type="text" @click="useEquipmentClick"
              >{{ appInfo.useEquipmentNum }}台</el-button
            >
          </td>
          <td class="table_body">
            {{ appInfo.equipmentNum - appInfo.useEquipmentNum }}台
          </td>
          <td rowspan="2">
            <el-button type="primary" @click="sellClick(1)">立即购买</el-button>
          </td>
        </tr>
        <tr>
          <td class="table_body">时长设备</td>
          <td class="table_body">{{ appInfo.durationUdidNum }}台</td>
          <td class="table_body" style="color: #0092ff">
            <el-button type="text" @click="usedurationUdidClick"
              >{{ appInfo.usedurationUdidNum }}台</el-button
            >
          </td>
          <td class="table_body">{{ appInfo.remainderUdid }}台</td>
        </tr>
      </table>

      <table border="0" cellspacing="0" cellpadding="0" class="table_border">
        <tr>
          <th colspan="4">私有池</th>
        </tr>
        <tr>
          <td colspan="4" class="table_title">
            用户自己提供开发者账号，如果您没有开发者账号，请勿购买！申请苹果开发者账号
          </td>
        </tr>
        <tr>
          <td class="table_body">总设备数</td>
          <td class="table_body">使用设备数</td>
          <td class="table_body">
            <span>可用设备数 </span>
            <el-popover placement="top-start" :width="400" trigger="hover">
              <template #reference>
                <img
                  src="../../assets/image/wenhao.png"
                  alt=""
                  style="width: 13px; height: 13px"
                />
              </template>
              <span class="tipsContent"
                >可用设备数由您添加的苹果证书个数确定，苹果规定1个苹果证书单个续约年度内最多添加100台手机设备和100台iPad平板设备，假如您配置了n个证书上去，那么您的可用设备数最大是n*100（实际数据以API返回为准），只有在您可用设备数大于0的情况下，用户才能正常安装。</span
              >
            </el-popover>
          </td>
          <td class="table_body">购买流量包</td>
        </tr>
        <tr>
          <td class="table_body table_common">{{ appInfo.privateNum }}台</td>
          <td class="table_body table_common" style="color: #0092ff">
            <el-button type="text" @click="usePrivateequipClick"
              >{{ appInfo.usePrivateequipNum }}台</el-button
            >
          </td>
          <td class="table_body table_common">
            {{ appInfo.availablephone }}台
          </td>
          <td rowspan="2">
            <el-button type="primary" @click="sellClicks()">立即购买</el-button>
          </td>
        </tr>
      </table>
    </div>
    <div class="content_app">
      <div class="content_app_header">
        <div style="font-size: 22px; font-weight: 700">我的应用</div>
        <el-button type="text" @click="jiaochengClick">使用教程</el-button>
      </div>
      <div class="upLoad_box" v-loading="loading">
        <div class="upload_item2" v-for="item in vipInfoList" :key="item.id">
          <div class="upload_header">
            <div :class="item.style">
              <div v-if="item.automaticallyRefresh == 1" class="vip_style">
                超级签名
              </div>
              <div style="float: right">
                <i
                  class="iconfont icon-pingguo"
                  style="color: #999999; font-size: 20px"
                ></i>
              </div>
            </div>
            <!-- <hr style="color: #E4E4E4" /> -->
          </div>
          <div class="upload_content">
            <div class="img">
              <img :src="item.iconurl" />
              <div class="text">{{ item.softname }}</div>
            </div>
            <div class="lianjie">
              <div class="lianjie_title">
                <div>短链接:</div>
                <div v-if="item.packageName">BundleID:</div>
                <div v-if="item.publishName">最新版本:</div>
                <div v-if="item.packType">打包类型:</div>
                <div v-if="item.remarks3">渠道号:</div>
                <div>超级签名:</div>
              </div>
              <div class="lianjie_title1">
                <div>
                  {{ item.softUrl }}
                </div>
                <div v-if="item.packageName">{{ item.packageName }}</div>
                <div v-if="item.publishName">{{ item.publishName }}</div>
                <div v-if="item.packType">{{ item.packType }}</div>
                <div v-if="item.remarks3" style="height: 18px">
                  {{ item.remarks3 }}
                </div>
                <div style="margin-top: -10px">
                  <el-switch
                    v-model="item.automaticallyRefresh"
                    :active-value="1"
                    :inactive-value="0"
                    @change="handleSwitch($event, item)"
                  />
                </div>
              </div>
              <div style="color: #c7c7c7; margin-top: 5px">
                <i
                  class="iconfont icon-fuzhi"
                  :id="'copyClass' + item.softId"
                  :data-clipboard-text="item.softUrl"
                  style="cursor: pointer"
                  @click="copy('copyClass' + item.softId)"
                ></i>
              </div>
            </div>
            <div class="upload_bottom">
              <el-button
                style="width: 88px; height: 34px"
                @click="editClick(item)"
                size="mini"
                >编辑</el-button
              >
              <el-button
                style="width: 88px; height: 34px"
                @click="seeClick(item)"
                size="mini"
                >预览</el-button
              >
              <el-button
                size="mini"
                style="height: 34px"
                icon="el-icon-delete"
                @click="delClick(item.softId)"
              ></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import Clipboard from "clipboard";
import { domainName, getDomain, isEmpty } from "@/common/js/common.js";
import {
  seamless,
  getPriceList,
  createNative,
  aliPay,
  changePower,
  queryWXPayStatus,
  getAliPayResult,
  createUserMobile,
  deleteProduct,
  getSLD,
} from "@/api/api";
export default {
  name: "vipInstall",
  data() {
    return {
      selectFlag: false,
      timer: null,
      selectStatus: 1,
      timeSellFlag: true, // 按时间购买隐藏支付
      checked1: true, // 同意条款
      erweiVisible: false,
      orderPriceId: "", //订单价格id
      sellSelectList: [
        {
          id: 0,
          sellSelect: "sell_select_item_active",
          title: "按台数购买",
          tips: "设备掉签会消耗新的设备数。",
        },
        {
          id: 1,
          sellSelect: "sell_select_item",
          title: "按时间购买",
          tips: "有效期内同一台设备掉签免费补签。",
        },
      ],
      dialogVisible: false,
      dialogVisibles: false,
      wxOrderId: "",
      // 购买支付对象
      priceObj: {
        price: "",
        oldPrice: "",
      },
      price: {
        orderPriceId: "", // 选择价格id
        productType: "", // 类型 0.下载次数 1.公有设备 2.私有设备 3.webclip 4.个人购买下载时长
        payKey: "",
        userId: "",
      },
      commonList: [],
      privateList: [],
      loading: false,
      vipInfoList: [],
      appInfo: {
        durationUdidNum: "",
        usedurationUdidNum: "",
        remainderUdid: "",
        equipmentNum: "",
        privateNum: "",
        useEquipmentNum: "",
        usePrivateequipNum: 0,
        authentication: "",
        signPermission: "",
        availablephone: 0,
      },
      priceList: [],
      // 微信支付样式
      wxPaystyle: "sell_btn_wx",
      aliPayStyle: "sell_btn_zfb",
      wxmaLoading: false,
      sellLoading: false,
      tips: "",
      productType: "", // 0.购买流量 1.公有池 2.私有池
      publicType: 0, // 默认选择项
      visableFlag: false,
      refreshQRCode: "", //刷新的二维码
      userId: "",
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!isEmpty(userInfo)) {
      this.userId = userInfo[0].userId;
    }
    this.getVipInfo();
  },
  //  beforeDestroy(){
  //     this.clearfun();
  // },
  methods: {
    seeUrl() {
      window.open("https://support.qq.com/products/349734/faqs/105066");
    },
    // 删除应用
    delClick(val) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteProduct({ softId: val }).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.getVipInfo();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 请选择套餐
    selectClick() {
      this.$router.push("/price");
    },
    // 微信支付状态
    wxPayStatus() {
      queryWXPayStatus(this.price).then((res) => {
        this.wxmaLoading = false;
        if (res.code != 0) {
          this.tips = res.msg;
        } else {
          this.clearfun();
          this.dialogVisible = false;
          this.dialogVisibles = false;
          this.getVipInfo();
          this.$message.success(res.msg);
        }
      });
    },
    erweiClose() {
      this.erweiVisible = false;
    },
    handleSwitch(val, item) {
      let obj = {};
      obj.type = "automaticallyRefresh";
      obj.automaticallyRefresh = val;
      obj.softId = item.softId;
      changePower(obj).then((res) => {
        if (res.code == 0) {
          this.getVipInfo();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //用于生成uuid
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    guid() {
      return (
        this.S4() +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        this.S4() +
        this.S4()
      );
    },
    prcieInit() {
      this.priceObj = {
        price: "",
        oldPrice: "",
      };
      this.price = {
        orderPriceId: "", // 选择价格id
        productType: "", // 类型 0.下载次数 1.公有设备 2.私有设备 3.webclip 4.个人购买下载时长
        payKey: "",
      };
    },
    sellClicks() {
      // this.clearErwei();
      this.productType = 0;
      this.dialogVisibles = true;
      this.wxPaystyle = "sell_btn_wx";
      this.aliPayStyle = "sell_btn_zfb";
      this.getPriceListInfos();
    },
    sellClick(val) {
      this.clearErwei();
      if (val == 1 || val == 2) {
        this.timeSellFlag = true;
        this.selectStatus = val;
        // let obj = {
        //   id: 0
        // }
        // this.sellSelectClick(obj)
      } else {
        this.timeSellFlag = false;
        this.selectStatus = val;
      }
      (this.sellSelectList = [
        {
          id: 0,
          sellSelect: "sell_select_item_active",
          title: "按台数购买",
          tips: "设备掉签会消耗新的设备数。",
        },
        {
          id: 1,
          sellSelect: "sell_select_item",
          title: "按时间购买",
          tips: "有效期内同一台设备掉签免费补签。",
        },
      ]),
        (this.publicType = 1);
      this.wxPaystyle = "sell_btn_wx";
      this.aliPayStyle = "sell_btn_zfb";

      this.prcieInit();
      this.productType = val;
      this.dialogVisible = true;
      this.getPriceListInfo();
    },
    // 获取购买价格表
    getPriceListInfos() {
      this.price.payKey = this.guid();
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.price.userId = userInfo[0].userId;
      getPriceList({ productType: 0 }).then((res) => {
        this.priceList = res.data.orderPriceList;
        this.priceList.forEach((item) => {
          // 设置默认选中项
          if (item.id == this.priceList[0].id) {
            item.style = "sell_item_active";
            item.sellZhekouClass = "sell_zhekou_acitve";
            this.sellStyleClick(item);
            this.wxPay();
          } else {
            item.style = "sell_item";
            item.sellZhekouClass = "sell_zhekou";
          }
        });
      });
    },
    //旧的数据
    getPriceListInfo(val) {
      this.price.payKey = this.guid();
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.price.userId = userInfo[0].userId;
      if (val != undefined) {
        this.publicType = val;
      } else {
        this.publicType = 0;
      }
      getPriceList({
        productType: this.productType,
        publicType: this.publicType,
      }).then((res) => {
        this.priceList = res.data.orderPriceList;
        if (this.priceList.length < 1) {
          this.selectFlag = true;
        } else {
          this.selectFlag = false;
        }
        this.priceList.forEach((item) => {
          if (item.id == this.priceList[0].id) {
            item.style = "sell_item_active";
            item.sellZhekouClass = "sell_zhekou_acitve";
            this.sellStyleClick(item);
            this.wxPay();
            return;
          }
          if (item.id == this.priceList[0].id) {
            item.style = "sell_item_active";
            item.sellZhekouClass = "sell_zhekou_acitve";
            this.sellStyleClick(item);
            this.wxPay();
            return;
          }
          if (item.id == this.priceList[0].id) {
            this.priceList.forEach((items) => {
              items.style = "sell_item";
              items.sellZhekouClass = "sell_zhekou";
            });
            return;
          }
        });
      });
    },
    bindQRCode2(val) {
      new QRCode(this.$refs.qrCodeDivErWei, {
        text: val,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 120,
        height: 120,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    bindQRCode3(val) {
      new QRCode(this.$refs.qrCodeDivs, {
        text: val,
        width: 120,
        height: 120,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    updatePay() {
      this.price.orderId = this.wxOrderId;
      this.wxmaLoading = true;
      if (this.wxPaystyle == "sell_btn_wx_active") {
        this.wxPayStatus();
      }
      if (this.aliPayStyle == "sell_btn_zfb_active") {
        this.aliPayStatus();
      }
    },
    refreshQR() {
      //刷新
      this.clearErwei();
      // this.bindQRCode(this.refreshQRCode);
      // this.bindQRCode3(this.refreshQRCode);
      if (document.querySelector("#qrCode")) {
        this.bindQRCode(this.refreshQRCode);
      }
      if (document.querySelector("#qrCodes")) {
        this.bindQRCode3(this.refreshQRCode);
      }
    },
    // 支付宝支付状态
    aliPayStatus() {
      getAliPayResult({ orderNumber: this.price.orderId }).then((res) => {
        this.wxmaLoading = false;
        if (res.code != 0) {
          this.tips = res.msg;
        } else {
          this.clearfun();
          this.dialogVisible = false;
          this.dialogVisibles = false;
          this.getVipInfo();
          this.$message.success(res.msg);
        }
      });
    },
    // 获取二维码认证
    getErweiAuth() {
      if (document.querySelector("#qrCodeErWei")) {
        document.querySelector("#qrCodeErWei").innerHTML = "";
      }
      this.dialogVisible = false;
      this.dialogVisibles = false;
      createUserMobile({ orderPriceId: this.orderPriceId }).then((res) => {
        if (res.code == 0) {
          setTimeout(() => {
            this.bindQRCode2(res.data.data);
          }, 200);
        }
      });
    },
    wxPay() {
      this.sellLoading = true
      this.clearfun();
      this.tips = "";
      if (this.priceObj.price == 0) {
        return this.$message.warning("请选择流量包");
      }
      if (!this.checked1) {
        return this.$message.warning("请同意服务条款");
      }
      this.wxPaystyle = "sell_btn_wx_active";
      this.aliPayStyle = "sell_btn_zfb";
      this.clearErwei();
      this.price.productType = this.productType;
      console.log(this.price);
      createNative(this.price).then((res) => {
        this.wxmaLoading = true;
        this.wxOrderId = res.data.out_trade_no;
        if (res.code == 0) {
          this.refreshQRCode = res.data.code_url;
          if (document.querySelector("#qrCode")) {
            this.bindQRCode(res.data.code_url);
          }
          if (document.querySelector("#qrCodes")) {
            this.bindQRCode3(res.data.code_url);
          }
          this.sellLoading = false
          // this.bindQRCode(res.data.code_url);
          // this.bindQRCode3(res.data.code_url);

          // setTimeout(() => {
          //   this.wxmaLoading = false;
          //   this.bindQRCode(res.data.code_url);
          // }, 500);
          this.timer = window.setInterval(() => {
            setTimeout(() => {
              this.updatePay();
            }, 0);
          }, 5000);
        }
      });
    },
    zhifuPay() {
      this.sellLoading = true
      this.clearfun();
      this.tips = "";
      if (this.priceObj.price == 0) {
        return this.$message.warning("请选择流量包");
      }
      if (!this.checked1) {
        return this.$message.warning("请同意服务条款");
      }
      if (this.visableFlag) {
        this.getErweiAuth();
        return (this.erweiVisible = true);
      }

      this.wxPaystyle = "sell_btn_wx";
      this.aliPayStyle = "sell_btn_zfb_active";
      this.clearErwei();
      aliPay(this.price).then((res) => {
        this.wxmaLoading = true;
        this.wxOrderId = res.data.orderNumber;
        if (res.code == 0) {
          this.refreshQRCode = res.data.aliPayQrCode;
          // this.bindQRCode(res.data.aliPayQrCode);
          // this.bindQRCode3(res.data.aliPayQrCode);
          if (document.querySelector("#qrCode")) {
            this.bindQRCode(res.data.aliPayQrCode);
          }
          if (document.querySelector("#qrCodes")) {
            this.bindQRCode3(res.data.aliPayQrCode);
          }
          this.sellLoading = false
          // setTimeout(() => {
          //   this.wxmaLoading = false;
          //   this.bindQRCode(res.data.aliPayQrCode);
          // }, 500);
          this.timer = window.setInterval(() => {
            setTimeout(() => {
              this.updatePay();
            }, 0);
          }, 5000);
        }
      });
    },
    // 清除轮询
    clearfun() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 清除二维码
    clearErwei() {
      if (document.querySelector("#qrCode")) {
        document.querySelector("#qrCode").innerHTML = "";
      }
      if (document.querySelector("#qrCodes")) {
        document.querySelector("#qrCodes").innerHTML = "";
      }
    },
    sellSelectClick(val) {
      this.clearErwei();
      if (val.id == 1) {
        this.timeSellFlag = false;
        this.wxPaystyle = "sell_btn_wx";
        this.aliPayStyle = "sell_btn_zfb";
        if (this.sellSelectList.length < 1) {
          this.selectFlag = true;
        } else {
          this.selectFlag = false;
        }
      } else {
        this.timeSellFlag = true;
      }
      this.prcieInit();
      this.getPriceListInfo(val.id);
      this.sellSelectList.forEach((item) => {
        if (val.id == item.id) {
          item.sellSelect = "sell_select_item_active";
        } else {
          item.sellSelect = "sell_select_item";
        }
      });
    },
    sellStyleClick(val) {
      console.log(val);
      // 判断时间弹窗开启
      if (val.remark3 > 0 && val.producttype) {
        this.visableFlag = true;
        this.orderPriceId = val.id;
        if (this.visableFlag) {
          this.getErweiAuth();
          return (this.erweiVisible = true);
        }
      } else {
        this.visableFlag = false;
      }
      this.tips = "";
      this.clearErwei();
      this.priceObj.price = 0;
      this.priceObj.oldPrice = 0;
      this.price.orderPriceId = val.id;
      this.price.productType = val.status;
      this.priceObj.price = val.totalPrice;
      this.priceObj.oldPrice = val.totalPrice;
      this.priceList.forEach((item) => {
        if (val.id == item.id) {
          item.style = "sell_item sell_item_active";
          item.sellZhekouClass = "sell_zhekou_acitve";
        } else {
          item.style = "sell_item";
          item.sellZhekouClass = "sell_zhekou";
        }
      });
      if (
        this.wxPaystyle == "sell_btn_wx_active" &&
        (this.selectStatus == 1 || this.selectStatus == 2)
      ) {
        this.wxPay();
      }
      if (
        this.aliPayStyle == "sell_btn_zfb_active" &&
        (this.selectStatus == 1 || this.selectStatus == 2)
      ) {
        this.zhifuPay();
      }
    },
    // sellStyleClick2(val) {
    //   this.tips = "";
    //   console.log(val);
    //   document.querySelector("#qrCodes").innerHTML = "";
    //   this.priceObj.price = 0;
    //   this.priceObj.oldPrice = 0;
    //   this.price.orderPriceId = val.id;
    //   this.price.productType = val.status;
    //   this.priceObj.price = val.totalPrice;
    //   this.priceObj.oldPrice = val.discount;
    //   this.priceList.forEach((item) => {
    //     if (val.id == item.id) {
    //       item.style = "sell_item sell_item_active";
    //       item.sellZhekouClass = "sell_zhekou_acitve";
    //     } else {
    //       item.style = "sell_item";
    //       item.sellZhekouClass = "sell_zhekou";
    //     }
    //   });

    //   if (this.wxPaystyle == "sell_btn_wx_active") {
    //     this.wxPay();
    //   }
    //   if (this.aliPayStyle == "sell_btn_zfb_active") {
    //     this.zhifuPay();
    //   }
    // },
    handleCloses() {
      this.clearfun();
      this.clearErwei();
      // document.querySelector("#qrCodes").innerHTML = "";
      this.priceList.forEach((item) => {
        item.style = "sell_item";
        item.sellZhekouClass = "sell_zhekou";
      });
      this.dialogVisibles = false;
    },
    handleClose() {
      this.clearfun();
      this.clearErwei();
      this.priceList.forEach((item) => {
        item.style = "sell_item";
        item.sellZhekouClass = "sell_zhekou";
      });
      this.dialogVisible = false;
    },
    editClick(val) {
      this.$router.push({
        path: "/editApp",
        query: {
          softId: val.softId,
        },
      });
    },
    // 复制
    copy(val) {
      let className = "#" + val;
      var clipboard = new Clipboard(className);
      clipboard.on("success", (e) => {
        console.log(e);
        clipboard.destroy();
        return this.$message.success("复制成功");
      });
      clipboard.on("error", (e) => {
        console.log(e);
        // 不支持复制
        clipboard.destroy();
        return this.$message.error("该浏览器不支持复制");
      });
    },
    // 预览
    seeClick(val) {
      window.open(val.softUrl, "_blank");
      return;
      let routeData = this.$router.resolve({
        path: "/seeApp",
        query: {
          softId: val.softId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 台数设备
    useEquipmentClick() {
      this.$router.push({
        path: "/useUdidList",
        query: {
          pattern: 0,
        },
      });
    },
    // 时长设备
    usedurationUdidClick() {
      this.$router.push("/durationUdidList");
    },
    // 私有池设备
    usePrivateequipClick() {
      this.$router.push({
        path: "/useUdidList",
        query: {
          pattern: 1,
        },
      });
    },
    jiaochengClick() {
      window.open("http://blog.wkzx.store/");
    },
    // 获取vip信息
    async getVipInfo() {
      this.loading = true;
      await seamless().then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.appInfo.authentication = res.data.authentication;
          this.appInfo.durationUdidNum = res.data.durationUdidNum;
          this.appInfo.equipmentNum = res.data.equipmentNum;
          this.appInfo.privateNum = res.data.privateNum;
          this.appInfo.remainderUdid = res.data.remainderUdid;
          this.appInfo.signPermission = res.data.signPermission;
          this.appInfo.useEquipmentNum = res.data.useEquipmentNum;
          this.appInfo.usePrivateequipNum = res.data.usePrivateequipNum;
          this.appInfo.usedurationUdidNum = res.data.usedurationUdidNum;
          this.appInfo.availablephone = res.data.availablephone;
          this.vipInfoList = res.data.infoList;
          console.log(this.vipInfoList);
          this.vipInfoList.forEach((item) => {
            if (item.automaticallyRefresh == 1) {
              item.style = "vip_box box_item_vip";
            } else {
              item.style = "vip_box";
            }
          });
        }
      });
      // 修改域名
      if (this.vipInfoList.length > 0) {
        // let userId = this.vipInfoList[0].userId;
        console.log(this.userId);
        const res2 = await getSLD(this.userId);
        console.log(res2);
        let sld = res2.data.sld;
        if (isEmpty(sld)) {
          for (let i = 0; i < this.vipInfoList.length; i++) {
            let el = this.vipInfoList[i];
            let res = getDomain(el.softUrl);
            el.softUrl = res;
          }
        } else {
          for (let i = 0; i < this.vipInfoList.length; i++) {
            let el = this.vipInfoList[i];
            let short = el.softUrl.substring(
              el.softUrl.indexOf("/", el.softUrl.indexOf("/") + 2)
            );
            console.log(short);
            el.softUrl = "http://" + sld + short;
            console.log(el.softUrl);
          }
        }
      }
      // 修改域名
      //         if(this.vipInfoList.length > 0 ) {
      //           for (let i = 0; i < this.vipInfoList.length; i++) {
      //             const el = this.vipInfoList[i];
      //             const res = await domainName(el.userId,el.softUrl)
      //             el.softUrl = res
      //           }
      //         }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn_erwei:hover {
  color: #0092ff;
}
.btn_erwei img {
  cursor: pointer;
  width: 123px;
  height: 123px;
  position: absolute;
  top: 12px;
}
.no_list {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #666666;
  margin: 50px 0;
}
::v-deep .el-dialog {
  border-radius: 20px;
}
.erwei_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.erwei_font {
  text-align: center;
  margin: 20px auto;
}
.table_common {
  height: auto !important;
}
.table_title {
  font-family: PingFang SC;
  height: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  color: #666666;
}
.sell_tips {
  margin-top: 20px;
}
.sell_select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .sell_select_item_active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 80px;
    background: #fffaf2;
    border: 1px solid #ebce9f;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .sell_select_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 80px;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 10px;
  }
}
.sell_right {
  width: 225px;
  .sell_right_title {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin: 20px 0 10px 0;
  }
}
.sell_footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  height: 210px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  .sell_left {
    width: 190px;
    .sell_left_title {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 20px;
    }
    .sell_btn {
      .sell_btn_wx_active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        background: #09c965;
        color: #fff;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .sell_btn_wx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        background: rgba(9, 201, 101, 0.2);
        border: 1px solid #09c965;
        box-sizing: border-box;
        color: #09c965;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .sell_btn_zfb {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #0092ff;
        box-sizing: border-box;
        border-radius: 10px;
        background: rgba(0, 146, 255, 0.2);
        color: #0092ff;
        cursor: pointer;
      }
      .sell_btn_zfb_active {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 190px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        background: #0092ff;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
.sell_item_active {
  // float: left;
  // float:left;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // margin-right: 20px;
  // justify-content: center;
  // cursor: pointer;
  background: #fcf3e6 !important;
  // width: 173px;
  // height: 154px;
  // box-sizing: border-box;
  // border-radius: 10px;
  // border: 2px solid #ebce9f;
  margin-right: 5px;
}
// .sell_item:nth-child(4) {
//   margin-right: 0px;
// }
.sell_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 200px;
  height: 154px;
  border-radius: 10px;
  border: 2px solid #ebce9f;
  margin-right: 5px;
}
.icon_active {
  color: #0092ff;
}
.sell_erwei {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  .sell_erwei_box {
    width: 120px;
    height: 120px;
    background: #c4c4c4;
  }
}
.sell_zhekou {
  border-radius: 46px;
  background: #ececec;
  width: 47px;
  text-align: center;
  text-decoration: line-through;
}

.sell_zhekou_acitve {
  border-radius: 46px;
  background: #f8e3bf;
  color: #ba812f;
  width: 47px;
  text-align: center;
  text-decoration: line-through;
}
.sell_header {
  display: flex;
  justify-content: space-around;
  // overflow: hidden;
  // display: flex;
  // justify-content: space-around;
}
.icon-fuzhi:hover {
  color: #0092ff;
}
.box_item_vip {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vip_box {
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e4e4;
}
.vip_style {
  float: left;
  width: 55px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-family: PingFang SC;
  background: linear-gradient(90deg, #ff1f1f 0%, #ff8a1f 100%);
  border-radius: 4px;
  font-size: 7px;
  color: #fff;
}
table {
  width: 555px;
  border-radius: 10px;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}
table th {
  background: #e5f1fa;
  font-size: 16px;
  height: 44px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: #333333;
  border-left: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
}
.table_body {
  height: 40px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  /* identical to box height */

  text-align: center;

  color: #666666;
}
table td {
  border-left: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
}
.upload_bottom {
  display: flex;
  position: absolute;
  bottom: 20px;
  padding: 0 15px;
  justify-content: space-around;
}

.lianjie_title1 {
  font-size: 12px;
  width: 125px;
  color: #666666;
  margin-right: 6px;
  div {
    width: 100%;
    padding: 6px 0;
    //超出部分显示...
    text-overflow: ellipsis;
    //超出部分隐藏
    overflow: hidden;
    //文本禁止换行
    white-space: nowrap;
  }
}

.tipsContent {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  color: #999999;
}
.lianjie {
  display: flex;
  justify-content: space-around;
  padding: 0 15px;
  .lianjie_title {
    font-size: 12px;
    color: #666666;
    margin-right: 15px;
    div {
      width: 57px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 6px 0;
    }
  }
}
.upLoad_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  .upload_item2:nth-child(4n) {
    margin-right: 0;
  }
  .upload_item2 {
    position: relative;
    width: 284px;
    height: 436px;
    margin-right: 21px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 10px 10px 10px;
    background: #fff;
    margin-bottom: 20px;
    .upload_content {
      width: 100%;
      .img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;

        img {
          width: 80px;
          height: 80px;
          margin-bottom: 20px;
          border-radius: 16%;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }
  .upload_item {
    position: relative;
    width: 284px;
    height: 436px;
    margin-right: 16px;
    background: #0092ff;
    border-radius: 10px;
    .upload_bottom {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fec943;
    }
    ::v-deep .el-upload-dragger {
      width: 284px;
      height: 100%;
      border: 0;
      background: #0092ff;
      .el-icon-upload {
        color: #fff;
        margin-top: 180px;
      }
      .el-upload__text {
        color: #fff;
      }
    }
  }
}
.content_app_header {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.content {
  margin-bottom: 30px;
}
.content_table {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  height: 262px;
  background: #fff;
  border-radius: 10px;
}
.table_border tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.table_border tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.table_border tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.table_border tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
</style>
