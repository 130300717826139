<template>
    <div>
      <div id="nc"></div> <!-- 这是用于显示滑块验证码的容器 -->
    </div>
  </template>
  
  <script>
  export default {
    props: {
      scene: {
        type: String,
        default: "123456"
      },
      sliderWidth: {
        type: Number,
        default: 362
      }
    },
    data() {
      return {
        sliderValue: 0
      }
    },
    mounted() { // 当组件被挂载时执行以下操作
      // 实例化nc
      AWSC.use("nc", (state, module) => { // 使用阿里云滑块验证码库
        // 初始化
        window.nc = module.init({
          appkey: "FFFF0N0000000000B7C6", // 替换为你的appkey
          scene: this.scene, // 替换为你的场景标识
          renderTo: "nc", // 指定滑块验证码的渲染目标
          width: this.sliderWidth,
          success: (data) => { // 滑块验证成功时触发的回调
            // 验证通过传递
            this.$emit('isSuccess', true)
            this.$emit('codeData', data)
            console.log(data.sessionId); // 打印会话ID
            console.log(data.sig); // 打印签名
            console.log(data.token); // 打印请求唯一标识
          },
          fail: (failCode) => { // 滑块验证失败时触发的回调
            console.log(failCode); // 打印错误代码
          },
          error: (errorCode) => { // 验证码加载出现异常时触发的回调
            console.log(errorCode); // 打印错误代码
          },
        });
      });
    },
  };
  </script>
  