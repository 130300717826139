<template>
	<div>
		<div class="content_login">
			<div class="content_tab">
				<el-tabs v-model="activeName" stretch @tab-click="handleClick">
					<el-tab-pane label="密码登录" name="first">
						<el-form ref="form" :model="form" :rules="rules" style="margin-top: 30px;">
							<el-form-item prop="userName"><el-input v-model="form.userName" placeholder="手机号/邮箱"></el-input></el-form-item>
							<el-form-item prop="password"><el-input type="password" v-model="form.password" placeholder="密码"></el-input></el-form-item>
							<el-form-item><el-button @click="onSubmit" type="primary">登录</el-button></el-form-item>
						</el-form>
					</el-tab-pane>
					<el-tab-pane label="免密登录" name="second">
						<mianmi-login></mianmi-login>
					</el-tab-pane>
				</el-tabs>
				<div class="ce_box">
					<el-button type="text" @click="registerClick" v-show="showRegister">账号注册</el-button>
					<el-button type="text" @click="forgetClick">忘记密码</el-button>
				</div>
			</div>
		</div>
		<div class="content_goback">
			<div class="goback">
				<img src="@/assets/image/home.png" alt="" />
				<span @click="goback">回到首页</span>
			</div>
		</div>
	</div>
</template>

<script>
import { userLogin } from "@/api/api";
import mianmiLogin from '@/components/login/mianmiLogin'
export default {
	components: {
		mianmiLogin
	},
	data() {
		return {
			activeName: 'first',
			rules: {
				userName: [{ required: true, message: '请输入手机号/邮箱', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
			},
			form: {
				userName: '',
				password: '',
				loginState: 0,
				accountType: 1
			}
		};
	},
	methods: {
		// 忘记密码
		forgetClick() {
			this.$router.push('/forgetPassword');
		},
		handleClick(tab, event) {
			console.log(tab, event);
			this.form.userName = '';
			this.form.password = '';
		},
		// 注册账号
		registerClick() {
			this.$router.push('/register');
		},
		goback() {
			this.$router.push('/index');
		},
		onSubmit() {
			alert(111222)
			const params = new URLSearchParams();
			params.append('userName', this.form.userName);
			params.append('loginState', this.form.loginState);
			params.append('password', this.form.password);
			this.$refs.form.validate(valid => {
				if (valid) {
					userLogin(params).then(res => {
						if (res.code == 0) {
							this.$store.commit('tokenChange', res.data.token);
							this.$store.commit('authenticationChange', res.data.authentication);
							window.localStorage.setItem('token', res.data.token);
							let obj = {};
							obj.userName = res.data.userName;
							obj.userId = res.data.userId;
							window.localStorage.setItem('userInfo', JSON.stringify([obj]));
							if (res.data.authentication == 0 || res.data.authentication == 1) {
								return this.$router.push('/personalData?status=3');
							}
							if (res.data.authentication == 2) {
								return this.$router.push('/lock/lockUser');
							}
							if (res.data.authentication == 4) {
								return this.$router.push('/lock/lockUser');
							}
							this.$router.push('/mobileNo');
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
	margin-bottom: 20px!important;
}
.content_login {
	margin: 70px 10px 20px 10px;
	background: #fff;
	border-radius: 10px;
	padding: 20px;
	.content_tab {
		overflow: hidden;
		margin: auto;
		.ce_box {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		.el-tabs .el-form-item .el-button {
			width: 100%;
		}
	}
}
.content_goback {
	width: 100px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 12px;
	border-radius: 15px;
	background: #fff;
	color: #999999;
	margin: auto;
	.goback {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		img {
			width: 18px;
			height: 18px;
		}
	}
}
</style>
