<template>
  <div>
    <div class="w">
      <div class="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item @click="urlPath">实名认证</el-breadcrumb-item>
          <el-breadcrumb-item>支付宝认证</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="content_title">
          支付宝实名认证
        </div>
        <div class="content_form">
          <el-form ref="form" :model="form">
            <el-form-item label="姓名" label-width="80px">
              <el-input
                v-model="form.realName"
                placeholder="请输入姓名"
                style="width: 400px"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" label-width="80px">
              <el-input
                v-model="form.idCard"
                placeholder="请输入身份证号"
                style="width: 400px"
              ></el-input>
              <div class="content_form_font">
                <div>1.填写身份证信息，点击确认生成认证二维码</div>
                <div>2.打开手机支付宝进行扫码，开始进行人脸认证</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="content_btn">
          <el-button type="primary" style="width: 200px" @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      v-model="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="handleClose"
    >
      <div class="dialog_box">
        <div
          style="margin: 30px 0;font-size: 18px;color: #333;font-weight: bold;"
        >
          <span v-if="isError">{{error}}</span>
          <span v-else >请使用支付宝扫码，进行实名认证</span> 
        </div>
        <div id="qrCode" ref="qrCodeDiv"></div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            style="width: 150px"
            @click="VerificationClick"
            >已完成认证</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { getAliCert, checkCert } from "@/api/api";
export default {
  name: "aliPayAuthen",
  data() {
    return {
      tipImg: "",
      dialogVisible: false,
      form: {
        realName: "",
        idCard: "",
        authType: "1",
      },
      url: '',
      isCreated: false,//是否已创建
      error: '',//错误信息提示
      isError: false, //是否返沪错误信息
    };
  },
  methods: {
    // 认证校验 已完成认证
    VerificationClick() {
      checkCert().then((res) => {
        if (res.code == 0) {
          this.dialogVisible = false;
          this.$router.push("/personalData?status=3")
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    submit() {
      //防止二维码多次生成
      if(this.isCreated){
        document.querySelector("#qrCode").innerHTML = "";
      }
      getAliCert(this.form).then((res) => {
        if (res.code == 0) {
          this.isCreated = true;
          // this.tipsImg = res.data.certifyUrl;
          this.url = res.data.url;
          this.dialogVisible = true;
          setTimeout(() => {
            this.bindQRCode(this.url);
          }, 500);
        } else{
          this.error = res.msg;
          this.isError = true;
        }
        this.dialogVisible = true;
      });
    },
    urlPath() {
      this.$router.push({
        path: "/personalData",
        query: {
          status: 3,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.dialog_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-form-item__label {
  text-align: left;
}
.header {
  margin: 20px 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 414px;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 54px;
  .content_title {
    font-size: 22px;
    font-weight: 700;
    color: #0092ff;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .content_form_font {
    color: #666666;
    font-size: 12px;
  }
}
</style>
