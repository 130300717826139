<template>
  <div>
    <div class="contract_right" v-if="userInfo.authentication == 0">
      <div class="zfrz">
        <div
          class="content_item"
          v-for="(item, index) in realList"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <div class="content_title">
            {{ item.title }}
          </div>
          <div class="content_body">
            {{ item.content }}
          </div>
          <div class="tips">
            {{ item.tips }}
          </div>
          <el-button type="primary" @click="select(item)">立即认证</el-button>
        </div>
      </div>
      <div class="qyrz">
        <div class="content_item">
          <img src="../../assets/image/qrz.png" alt="" />
          <div class="content_title">
            企业认证
          </div>
          <div class="content_body">
            需要提供：联系人、企业名称、营业执照等
          </div>
          <div class="tips"></div>
          <el-button type="primary" @click="enterClick">立即认证</el-button>
        </div>
        <div class="qyrz_box">
          <div class="qyrz_title">
            身份证认证
          </div>
          <div class="qyrz_content">
            <div style="padding-bottom: 10px">
              需要提供：姓名、手机号、身份证好、照片等
            </div>
            <div>需人工审核，最迟不超过24小时！</div>
          </div>
          <el-button type="text" @click="idCardClick">立即认证</el-button>
        </div>
      </div>
    </div>
    <qiyeAuth
      v-if="userInfo.authenticationType == 2 && userInfo.authentication !=0"
      :company="company"
      :reason="reason"
      :state="userInfo.authentication"
      :userId="userId"
    ></qiyeAuth>
    <alipayConfirm
      v-if="userInfo.authenticationType == 3 && userInfo.authentication !=0"
      :realName="userInfo.realName"
      :idNumber="userInfo.idNumber"
    ></alipayConfirm>
    <weixinPay
      v-if="userInfo.authenticationType == 4 && userInfo.authentication !=0"
      :realName="userInfo.realName"
      :idNumber="userInfo.idNumber"
    ></weixinPay>
    
  </div>
</template>

<script>
import alipayConfirm from "@/components/personalData/authComfirm/alipay";
import weixinPay from "@/components/personalData/authComfirm/weixinPay";
import qiyeAuth from "@/components/personalData/authComfirm/qiyeAuth";
import { authen } from "@/api/api";
export default {
  components: {
    alipayConfirm,
    qiyeAuth,
    weixinPay
  },
  data() {
    return {
      // flag: false, // 显示与隐藏
      company: "",
      userInfo: {
        realName: "",
        idNumber: "",
        authenticationType: "",
        authentication: 0,
      },
      realList: [
        {
          img: require("../../assets/image/zfb.png"),
          title: "支付宝认证",
          content: "需要提供：手机号、身份证等",
          tips: "无需等待，方便快捷",
          id: 1,
        },
        {
          img: require("../../assets/image/wx.png"),
          title: "微信认证",
          content: "需要提供：手机号、身份证等",
          tips: "无需等待，方便快捷",
          id: 2,
        },
      ],
      reason:"",
      userId:"",
    };
  },
  mounted() {
    this.seeAuth();
  },
  methods: {
    idCardClick() {
      this.$message.warning("此功能暂未开放");
    },
    // 查看认证
    seeAuth() {
      authen().then((res) => {
        if (res.code == 0) {
      
            this.userInfo.authentication = res.data.user.authentication;
            this.userInfo.authenticationType = res.data.user.authenticationType;
            this.userId = res.data.user.userId;
          // if (res.data.user.authenticationType == 0) {
          //   this.flag = true;
          // } else {
          //   this.flag = false;
          // }
          if(res.data.user.authentication ==3 ){
              this.userInfo.realName = res.data.user.realName;
              let idNumber = res.data.user.idNumber;
              if (idNumber) {
                idNumber =
                  idNumber.substr(0, 3) +
                  " ************ " +
                  idNumber.substr(15, 18);
                this.userInfo.idNumber = idNumber;
              }
              if (this.userInfo.authenticationType == 2) {
                this.company = res.data.company;
              }
            }else{
              if (this.userInfo.authenticationType == 2) {
                  if(this.userInfo.authentication == 2){
                      this.company = res.data.company;
                      this.reason = res.data.reason;
                  }else{
                    this.$router.push("/enterpriseCertification?userId="+this.userId);
                  }
              }
            }  
        }
      });
    },
    // 企业认证
    enterClick() {
      this.$router.push("/enterpriseCertification");
    },
    select(val) {
      if (val.id == 1) {
        this.$router.push("/aliPayAuthen");
      } else {
        //微信认证
        this.$router.push("/weXinAuthen");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qyrz {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.qyrz_box {
  width: 396px;
  height: 178px;
  padding: 30px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 10px;
  .qyrz_title {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 10px;
  }
  .qyrz_content {
    font-size: 14px;
    color: #666666;
    margin: 10px 0;
  }
}
.zfrz {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contract_right {
  width: 920px;
  height: 747px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .content_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 396px;
    height: 325px;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 10px;
    img {
      width: 64px;
      height: 64px;
      margin: 48px 0 38px 0;
    }
    .content_title {
      font-size: 20px;
      font-weight: 700;
      color: #333333;
      margin-bottom: 10px;
    }
    .content_body {
      font-size: 14px;
      color: #666666;
      margin: 10px 0;
    }
    .tips {
      color: #ff4a4a;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
</style>
