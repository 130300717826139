<template>

	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item>超级签如何开启付费下载</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">超级签如何开启付费下载?</div>
			<el-divider></el-divider>
			<div class="neitest">1: 登录<a @click="tiaozhuan(1)" href="javascript:void(0)">悟空在线内测分发平台</a>，
				进入应用列表页面，选中已经<a @click="tiaozhuan(2)" href="javascript:void(0)">开启超级签</a>的app，点击编辑进入详情页面。</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/buzhou1.png"></div>
			<div class="neitest">2:
				进入详情页面，选择权限控制，打开<span class="red">开启付费下载</span>开关即可。用户在通过此链接安装App进行内测时，
				针对未付费设备会提示付费下载，付费之后有效期内同一台设备可以无限安装此账号下的所有合法合规App。</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/buzhou2.png"></div>
			<!-- <div class="dibu">
				<div>562阅读 更新时间 11-27 18:00</div>
				<div>157人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div>
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		methods: {
			tiaozhuan(keyPath) {
				if (keyPath == 1) {
					this.$router.push("/home");
				}
				if (keyPath == 2) {
					this.$router.push("/installDoc/list6");
				}
			}
		}
	}
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.red {
		color: red;
	}

	.daohang {
		margin-bottom: 0.625rem;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}

	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
