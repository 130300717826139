<template>
  <div>
    <div class="w">
      <div class="nav">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/appManagement' }"
            >应用管理</el-breadcrumb-item
          >
          <el-breadcrumb-item @click="goBack">超级签名</el-breadcrumb-item>
          <el-breadcrumb-item>时长设备详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="header">
          <div class="title_box">
            <div class="title">时长设备使用详情</div>
            <div class="title_footer">时长设备</div>
          </div>
          <div class="header_btn">
            <el-button type="primary" @click="exportClick">批量导出</el-button>
          </div>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            id="table"
            border
            max-height="500"
            v-loading="loading"
            :header-cell-style="{ background: '#E5F1FA' }"
          >
            <el-table-column
              width="55"
              label="序号"
              align="center"
              type="index"
            ></el-table-column>
            <el-table-column
              prop="udid"
              label="设备UDID"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createDate"
              label="使用时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="expireDate"
              label="结束时间"
              align="center"
            ></el-table-column>
          </el-table>
          <paging-template :currentPage="currentPage" :pageSize="pageSize" :total="total" @pageSizeChange="pageSizeChange" @currentChange="currentChange"></paging-template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import XLXS from "xlsx";
import FileSaver from "file-saver";
import pagingTemplate from '@/components/common/pagingTemplate'
import { durationUdidDetail,exportdurationUdidDetail } from "@/api/api";
export default {
  name: "durationUdidList",
  components: {
      pagingTemplate
    },
  data() {
    return {
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: null
    };
  },
  created() {
    this.getuseUdid();
    let time = pd.to_datetime('2022-03-23 14:21:45')
    console.log(time)
  },
  methods: {
    // 批量导出
    exportClick() {
        exportdurationUdidDetail().then((res) => {
          console.log(res)
          if (res.code == 1) {
            this.$message.error(res.msg);
            // this.fullscreenLoading = false;
          } else if (res.code == 0) {
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = res.data.url;
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$message.success("导出成功!");
            // this.fullscreenLoading = false;
          }
        });
      // const filename = "时长设备使用详情.xlsx";
      //   // 导出表格加id,通过id获取要导出的表单
      //   const wb = XLXS.utils.table_to_book(document.getElementById("table"));
      //   const wbout = XLXS.write(wb, {
      //     bookType: "xlsx",
      //     bookSST: true,
      //     type: "array",
      //   });
      //   try {
      //     FileSaver.saveAs(
      //       new Blob([wbout], {
      //         type: "application/octet-stream",
      //       }),
      //       filename
      //     );
      //   } catch (e) {
      //     console.log(e);
      //   }
      //   return wbout;
    },
    pageSizeChange(val) {
      this.pageSize = val
    },
    currentChange(val) {
      this.currentPage = val
      this.getuseUdid()
    },
    // 返回vip页面
    goBack() {
      this.$router.push({
        path: "/appManagement",
        query: {
          vipShow: true,
        },
      });
    },
    getuseUdid() {
      this.loading = true;
      durationUdidDetail({ pageNum: this.currentPage }).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.pageSize = res.data.UdidAnnual.pageSize
          this.currentPage = res.data.UdidAnnual.pageNum
          this.total = res.data.UdidAnnual.total
          this.tableData = res.data.UdidAnnual.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  margin: 20px 0;
}

.content {
  // height: 644px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .title_box {
    display: flex;
    .title {
      color: #333333;
      font-weight: bold;
      font-size: 22px;
      margin-right: 10px;
    }
    .title_footer {
      font-size: 14px;
      line-height: 40px;
      color: #666666;
    }
  }
}
</style>
