<template>
  <div class="box">
    <div v-if="isIOS != downFlag && !isMerge" class="scan_error_bg">
        <span class="context">
          <!--该应用是 <span v-if="isIOS">Ios</span>
          <span v-else> Android</span>系统 -->
          该应用暂不支持此系统
        </span>
    </div>
    <div v-else>
        <div v-show="qqAndWxStatus == 1" class="isWechat-fix">
          <div :class="{'isWechat-fix-ct':!downFlag,'isWechat-fix-ct2':downFlag}">
            <div>
              <div class="isWechat-fix-bg">
                <img :src="icon" alt="" />
                <div class="font-style">{{ softname }}</div>
                <div class="font-style2">
                  点击右上角...<br />
                  用<span style="color:#0092FF;margin-top: 3px;display:inline-block">
                    <span v-if="downFlag">Safari</span>
                    <span v-else>浏览器</span>
                  </span>打开下载
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="qqAndWxStatus == 2" class="isWechat-fix">
          <div :class="{'isWechat-fix-ct':!downFlag,'isWechat-fix-ct2':downFlag}">
            <div>
              <div class="isWechat-fix-bg">
                <img :src="icon" alt="" />
                <div class="font-style">{{ softname }}</div>
                <div class="font-style2">
                  点击右上角...<br />
                  用<span style="color:#0092FF;margin-top: 3px;display:inline-block">
                    <span v-if="downFlag">Safari</span>
                    <span v-else>浏览器</span>
                    </span>打开下载
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
    qqAndWxStatus: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    softname: {
      type: String,
      default: "",
    },
    downFlag: {
      type: Boolean,
      default: true,
    },
     isIOS: {
      type: Boolean,
      default: true,
    },
    isMerge:{
      type: Boolean,
      default: false,
    }
  },
  created() {
    if(this.softname) {
      document.title = this.softname
    } else {
      document.title = ''
    }
  },
  mounted(){

  },
};
</script>

<style lang="scss" scoped>
.font-style2 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin-top: 5px;
  color: #999999;
}
.font-style {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.box {
  height: 100vh;
  background-color: #fff;
}
.isWechat-fix {
  position: fixed;
  top: 4rem;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: #fff;
}
.isWechat-fix-ct {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  height: 560px;
  overflow: hidden;
  background: url(../../../assets/image/android.gif) center center/130% 130%
    no-repeat;
}
.isWechat-fix-ct2 {
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  height: 560px;
  overflow: hidden;
  background: url(../../../assets/image/iosMask.gif) center center/130% 130%
    no-repeat;
}
.isWechat-fix-bg {
  margin-top: 34%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: #fff;
  img {
    max-width: 100%;
  }
}
.isWechat-fix-ct img {
  margin-top: 3%;
  height: 4em;
  border-radius: 1rem;
  // margin-bottom: 1em;
}
.isWechat-fix-ct2 img {
  margin-top: 3%;
  height: 4em;
  border-radius: 1rem;
  // margin-bottom: 1em;
}
.isWechat-fix-ct2 p {
  margin: 0;
  font-size: 12px;
}
.isWechat-fix-ct p {
  margin: 0;
  font-size: 12px;
}
// @media screen and (min-width: 350px){
//   .isWechat-fix-ct{
//     margin-top: 5%;
//   }
// }
.scan_error_bg{
  height: 100%;
  width: 100%;
  background:#f1f6f9 url(../../../assets/image/scan_error_bg.png) center center/70% 50% no-repeat;
}
.scan_error_bg .context{
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%,-50%);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}
</style>
