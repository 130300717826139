<template>
	<div>
		<div class="banner">
			<img src="@/assets/image/bannerM.png">
		</div>
		<div class="header">
		  <div class="header_left" @click="ceshiClick">测试分发</div>
		  <img src="@/assets/image/heng.png" alt="" />
		  <div class="header_right" @click="webClipClick">Web Clip</div>
		</div>
		<div class="neice">
			内测分发
		</div>
		<div class="content_list">
		  <div
		    class="list_box"
		    v-for="(item, index) in listData"
		    :key="index"
		  >
		    <div class="list_left">
		      <div class="list_title">{{ item.title }}</div>
		      <div class="list_content">{{ item.content }}</div>
		    </div>
		    <div class="list_right">
		      <img :src="item.img" alt="" />
		    </div>
		  </div>
		</div>
		<div class="safe">
		  <img src="@/assets/image/safe.png" alt="" />
		  <div>安全稳定传输让内测分发更高效</div>
		</div>
		<div class="web_style">
		    <div class="web_header">
		      <img src="@/assets/image/image7.png" style="width: 100%;height:100%" />
		      <div>Web Clip</div>
		    </div>
		    <div class="web_list">
		      <div
		        class="web_item"
		        v-for="(item, index) in webClipList"
		        :key="index"
		      >
		        <img :src="item.icon" />
		        <div class="web_list_title">{{ item.title }}</div>
		        <div class="web_list_content">{{ item.content }}</div>
		      </div>
		    </div>
		</div>
		<div class="content_footer">
		  <div class="footer_img">
		    <div class="footer_fenfa"><div>悟空在线</div> <div>邀您立即开始分发应用</div></div>
		    <div class="footer_fenfa">
		      <el-button @click="registerClick" v-show="showRegister">注册使用</el-button>
		    </div>
		  </div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'home',
	data() {
		return {
			webClipList: [
			  {
			    icon: require("@/assets/image/web_1.png"),
			    title: "可定制",
			    content: "自定义配置信息，自定义Logo 自定义名字等等。",
			  },
			  {
			    icon: require("@/assets/image/web_2.png"),
			    title: "方便快捷",
			    content: "创建- 配置 - 分发仅需三步便可完成分发。",
			  },
			  {
			    icon: require("@/assets/image/web_3.png"),
			    title: "可信任",
			    content: "可信任Apple官方配置，可信任。",
			  },
			  {
			    icon: require("@/assets/image/web_4.png"),
			    title: "只需一个网站地址",
			    content: "就能创建一个属于您自己的移动APP！",
			  },
			],
			listData: [
			  {
			    title: "稳定高效",
			    content:
			      "各种浏览器全面兼容，极致体验，我们24小时实时监控，一对一服务，让您的app稳定分发。",
			    img: require("../../assets/image/list_1.png"),
			  },
			  {
			    title: "android和ios合二为一",
			    content:
			      "一键上传应用，生成对应的下载链接和二维码，安卓和ios可以合二为一，在分发的过程中设备自动识别，自动匹配，让您一码分发。",
			    img: require("../../assets/image/list_2.png"),
			  },
			  {
			    title: "丰富的分发模板",
			    content:
			      "无论您是内测或者是企业级分发我们已经给你提供了丰富的分发组建，包括自定义分发模版，动态密码，应用统计等一系列下载组件",
			    img: require("../../assets/image/list_3.png"),
			  },
			  {
			    title: "二维码永久唯一",
			    content:
			      "完善便捷的应用版本控制，同一个app无论你版本如何更新我们都可以做到下载链接和二维码永远不变，永久唯一；突破安装包大小上传限制，让您的游戏或者应用一键内测。",
			    img: require("../../assets/image/list_4.png"),
			  },
			],
		}
	}
}
</script>

<style lang="scss" scoped>
.content_footer {
  position: relative;
  height: 200px;
  background: linear-gradient(to right, #0c41ff 0%, #4700ba 100%);
  .footer_img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background: url("../../assets/image/bg.png") no-repeat center top;
  }
  .footer_fenfa {
    padding: 30px 0 20px 0;
    z-index: 999;
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
	color: #fff;
  }
}
.web_style {
  overflow: hidden;
  background: #e8ecf2;
  padding-bottom: 50px;
  .web_list {
    display: flex;
        flex-wrap: wrap;
        justify-content: center;
    // justify-content: space-between;
	.web_list_content {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #666666;
	}
	.web_list_title {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		color: #333333;
	}
	.web_item:nth-child(2n) {
		margin-right: 0;
	}
    .web_item {
		float: left;
		    width: 46%;
			margin: 0 10px 10px 0;
      height: 208px;
      border-radius: 10px;
      background: #fff;
      box-sizing: border-box;
      padding: 20px;
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
  .web_header {
    position: relative;
	height: 100px;
	img {
		width: 100%;
		height: 100%;
	}
    div {
      position: absolute;
      top: 50%;
	  left: 50%;
	  font-family: PingFang SC;
	  font-style: normal;
	  font-weight: bold;
	  font-size: 30px;
	  line-height: 42px;
      transform: translate(-50%, -50%);
    }
  }
}
.safe {
  margin: auto;
  margin-bottom: 30px;
  img {
    width: 100px;
    height: 100px;
    display: block;
    margin: auto;
  }
  div {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #333333;
  }
}
.list_title {
	margin-top:10px;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 10px;
	color: #333333;
}
.list_content {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #666666;
}
.list_box {
  display: flex;
  justify-content: space-between;
  float: left;
  margin: 0 10px 10px 10px;
  // height: 150px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 10px 20px;
}
.list_right {
  img {
    width: 130px;
    height: 130px;
  }
}
.neice {
	margin: 25px 0 22px 0;
	text-align: center;
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	color: #333333;
}
.banner {
	height: 170px;
	img {
		width: 100%;
		height: 100%;
	}
}

.header {
	display: flex;
	justify-content: space-around;
	align-items: center;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #fff;
  margin: 8px 10px;
  border-radius: 8px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  .header_left {
    cursor: pointer;
  }
  .header_left:hover {
    color: #0299dd;
  }
  img {
       width: 1px;
       height: 20px
  }
  .header_right:hover {
    color: #0299dd;
  }
}
</style>
