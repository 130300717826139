<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="kit-banner">
          <h1>开发者工具箱</h1>
          <h3>悟空在线为移动应用开发者提供的常用工具</h3>
        </div>
        <div class="w">
          <div class="col-sm-12 kit-tit">开发者常用工具</div>
          <div class="box_item">
            <div class="col-sm-3">
              <div class="kit-box kit-prin" @click="getUdid">
                <img src="@/assets/image/udid_3.png" alt="" />
                <h3 class="">获取UDID</h3>
                <h5>扫描二维码，快速获取UDID</h5>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="kit-box kit-prin" @click="helpMe">
                <img src="@/assets/image/help.png" alt="" />
                <h3>安装助手</h3>
                <h5>点击立即安装</h5>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="kit-box">
                <img src="@/assets/image/kitHelp.png" alt="" />
                <h3>更多工具</h3>
                <h5>正在开发中</h5>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="kit-box">
                <div class="kit-in">
                  <img
                    src="@/assets/image/sandian.png"
                    alt=""
                    style="height: 14px;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'kit',
    methods: {
        getUdid() {
            this.$router.push('/installAssistant/udid')
        },
        helpMe() {
            this.$router.push('/installAssistant')
        }
    }
}
</script>
<style lang="scss" scoped>
.box_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
.kit-in {
    display: flex;
    justify-content: center;
    align-items: center;
}
.col-sm-3 {
  width: 270px;
  height: 270px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 10px 0px #ddd;
  border-radius: 6px;
  .kit-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
      margin-top: 28px;
      height: 97px;
    }
    h3 {
      margin-top: 20px;
      font-size: 30px;
      font-weight: 400;
      padding: 0;
    }
    h5 {
      margin-top: 24px;
      color: rgba(153, 153, 153, 1);
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
  }
}
.kit-tit {
  font-size: 32px;
  font-family: SimHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin: 30px 0 44px 0;
}
.kit-banner {
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 300px;
  background-image: url("../../assets/image/bannerKit.png");
  background-repeat: no-repeat; //不重复
  background-size: 100% 100%; // 满屏
  color: #fff;
  h1 {
    font-size: 60px;
    font-weight: 400;
  }
  h3 {
    font-size: 30px;
    margin-top: 40px;
    font-weight: 400;
  }
}
</style>
