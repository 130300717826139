<template>
  <div>
    <div class="content">
      <div class="content_left">
        <div class="header">
          <el-button type="primary" style="width: 200px" @click="addClick"
            >添加 Connect API</el-button
          >
        </div>
        <div class="search">
          <el-input
            style="width: 200px"
            placeholder="搜索账号"
            v-model="account"
          >
            <template #append>
              <el-button
                icon="el-icon-search"
                @click="searchAccount"
              ></el-button>
            </template>
          </el-input>
        </div>
        <div>
          <div
            v-for="item in accountList"
            :key="item.id"
            class="id_item"
            @click="accountClick(item)"
          >
            <div class="hr_style">
              <div :class="item.selectHr"></div>
              <div :class="item.activeDian">.</div>
            </div>
            <div>
              <div :class="item.font">{{ item.remark2 }}</div>
              <div :class="item.bg">ID:{{ item.appleaccount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_right" v-if="flag == 0">
        <div class="w2">
          <div class="img"><img src="../../assets/image/api.png" alt="" /></div>
          <div class="title">您还没有添加 Connect API</div>
          <div class="body">
            AppStore Connect API是一个REST
            API，用于构建自定义工作流，作为应用程序开发生命周期的一部分，并在App
            Store连接中实现自动化操作。您可以从组织的App Store
            Connect帐户获得创建令牌的密钥，
            <span
              style="cursor: pointer;color: #0092FF;text-decoration: underline"
              @click="seeUrl"
              >了解更多</span
            >
          </div>
          <div class="btn">
            <el-button
              type="primary"
              style="width: 200px;margin-bottom: 30px"
              @click="addClick"
              >添加 Connect API</el-button
            >
          </div>
        </div>
      </div>
      <add-api v-if="flag == 1" @flagChange="flagChange"></add-api>
      <api-detail
        v-if="flag == 2"
        :appleList="appleList"
        @statusId="statusId"
        @editShow="editShow"
        @getAccountList="getAccountList"
        @idChange="idChange"
      ></api-detail>
      <edit-api v-if="flag == 3" @flagChange="flagChange" :id="id"></edit-api>
    </div>
  </div>
</template>

<script>
import addApi from "@/components/appManagement/addApi";
import editApi from "@/components/appManagement/editApi";
import apiDetail from "@/components/appManagement/apiDetail";
import { accountList, searchAccount } from "@/api/api";
export default {
  components: {
    addApi,
    apiDetail,
    editApi,
  },
  data() {
    return {
      flag: 0,
      id: "",
      editList: null, // 修改数据
      accountList: [], //账号数组
      account: "", //账号
      accountName: "", //账号名
      appleList: null,
    };
  },
  created() {
    this.getAccountList();
  },
  methods: {
    statusId(val) {
      accountList().then((res) => {
        this.accountList = res.data.accountList.list;
        this.accountList.forEach((item) => {
          if (item.status == 1) {
            item.activeDian = "dian";
          } else {
            item.activeDian = "dian_active";
          }
          if (item.id == val) {
            this.accountClick(item);
          }
        });
      });
    },
    idChange(val) {
      this.id = val;
    },
    editShow(val) {
      this.flag = val;
    },
    // 选中一个苹果证书
    accountClick(val) {
      this.flag = 2;
      this.appleList = val;
      this.accountList.forEach((item) => {
        if (val.id == item.id) {
          item.selectHr = "hr_1";
          item.font = "font_1";
          item.bg = "font_2";
        } else {
          item.selectHr = "hr";
          item.bg = "font_2_no";
          item.font = "font_1_no";
        }
      });
    },
    // 显示列表
    flagChange(val) {
      this.flag = val;
      this.getAccountList();
    },
    seeUrl() {
      window.open("https://developer.apple.com/app-store-connect/api/");
    },
    addClick() {
      this.flag = 1;
      this.accountList.forEach((item) => {
        item.selectHr = "hr";
        item.bg = "font_2_no";
        item.font = "font_1_no";
      });
    },
    //获取账号
    getAccountList() {
      accountList().then((res) => {
        this.accountList = res.data.accountList.list;
        this.accountList.forEach((item, index) => {
          if (item.status == 1) {
            item.activeDian = "dian";
          } else {
            item.activeDian = "dian_active";
          }
          if (index == 0) {
            item.selectHr = "hr_1";
            item.bg = "font_2";
            item.font = "font_1";
          } else {
            item.selectHr = "hr";
            item.bg = "font_2_no";
            item.font = "font_1_no";
          }
        });
        if (this.accountList.length > 0) {
          this.appleList = this.accountList[0];
          this.flag = 2;
        }
        // console.log(this.accountList[0].appleaccount+"-----------------");
        // console.log(this.accountList[0].remark2+"===================");
      });
    },
    //搜索账号
    searchAccount() {
      searchAccount({ account: this.account }).then((res) => {
        if (res.code == 0) {
          this.accountList = res.data;
          this.accountList.forEach((item, index) => {
            if (item.status == 1) {
              item.activeDian = "dian";
            } else {
              item.activeDian = "dian_active";
            }
            if (index == 0) {
              item.selectHr = "hr_1";
              item.font = "font_1";
              item.bg = "font_2";
            } else {
              item.selectHr = "hr";
              item.font = "font_1_no";
              item.bg = "font_2_no";
            }
          });
          //  console.log(this.accountList[0].appleaccount+"-----------------");
          // console.log(this.accountList[0].remark2+"===================");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.font_1 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #0092ff;
}
.font_1_no {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}
.font_2 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  width: auto;
  height: 19px;
  background: rgba(0, 146, 255, 0.2);
  border-radius: 4px;
  padding: 0 4px;
  color: #0092ff;
}
.font_2_no {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  width: auto;
  height: 19px;
  background: #f4f4f4;
  border-radius: 4px;
  padding: 0 4px;
  color: #999999;
}
.hr_style {
  display: flex;
  width: 30px;
}
.hr {
  width: 13px;
}
.hr_1 {
  width: 13px;
  border-left: 2px solid #0092ff;
  font-size: 30px;
  box-sizing: border-box;
  // color: #0092ff;
}
.dian_active {
  font-size: 30px;
  color: #ec6941;
}
.dian {
  font-size: 30px;
  color: #00d922;
}
.id_item {
  display: flex;
  align-items: center;
  height: 65px;
  cursor: pointer;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.w2 {
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_left {
  width: 260px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #dcdcdc;
  }
  .search {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}

.content_right {
  width: 920px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  .img {
    overflow: hidden;
    margin: 100px 0 30px 0;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
  .body {
    font-size: 14px;
    color: #666666;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
