<template>
  <div class="renzheng_box" >
    <div @click="getGoAuth">
      <div class="renzheng_title">
        企业实名认证 
      </div>
      <div class="renzheng_img_box">
        <div class="renzheng_left">
          <div class="renzheng_left_top">
            <div
              style="font-weight: bold;color: #fff;font-size: 20px;margin-bottom: 10px"
            >
              {{ company.companyName }}
            </div>
            <div style="font-weight: bold;color: #fff;font-size: 14px;">
              企业联系人：{{ company.linkman }}
            </div>
            <div style="font-weight: bold;color: #fff;font-size: 14px;">
              联系人手机号：{{ company.telephone }}
            </div>
          </div>
          <div class="renzheng_left_bottom">企业实名认证</div>
        </div>
        <div class="renzheng_right">
          <img src="../../../assets/image/qiye.png" />
        </div>
        <div v-if="state==1 || state==2" class="state_button" @click="getGoAuth">
            <span v-if="state==1">已提交</span>
            <span v-else>未通过</span>
        </div>
      </div>
     <div v-if="state==2" class="reason_context">
       未通过原因: {{reason}}
      </div>
      
    </div>
    <commonSGS></commonSGS>
  </div>
</template>
<script>
import commonSGS from "../commonSGS.vue"
export default {
    components: {
      commonSGS
    },
    props: {
        company: {
            type: Object
        },
        reason: {
          type: String,
          default: ''
        },
        state: {
          type: String,
          default: ''
        },
        userId:{
          type: String,
          default: ''
        }
    },
    data() {
        return {

        }
    },
    methods:{
      getGoAuth(){
        if(this.state ==3){
            return this.$message.success("认证已成功");
        }
        this.$router.push("/enterpriseCertification?userId="+this.userId);
      }
    }
}
</script>
<style lang="scss" scoped>
.renzheng_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.renzheng_img_box {
  display: flex;
  justify-content: space-between;
}
.renzheng_right {
  img {
    width: 131px;
    height: 96px;
  }
}
.renzheng_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 920px;
  // height: 470px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .renzheng_title {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  .renzheng_img_box {
    width: 473px;
    height: 180px;
    background: linear-gradient(90deg, #FF5346 0%, #FF8B40 100%);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px;
  }
  .renzheng_left_bottom {
    font-weight: bold;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.state_button{
  opacity: 0.5;
  font-size: 14px;
  font-weight: bold;
}
.reason_context{
  font-size: 14px;
  font-weight: bold;
  color: #FF5346;
  text-align: center;
}
</style>