<template>

	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item >常见问题及扣除规则</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">常见问题及扣除规则</div>
			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 1&nbsp;&nbsp;</span>&nbsp;为什么要限制下载流量？</div>
			<div class="neitest">根据我们的数据分析，当前每日的下载限制，已经可以满足 99% 的内测用户 2)降低未被发现的违规应用造成的影响范围</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 2&nbsp;&nbsp;</span>&nbsp;为什么要限制应用包的保存时长？</div>
			<div class="neitest1">1. 应用内测是有周期的，一般应用的内测周期为 3个月 (可以通过更新，延长保存时间)</div>
			<div class="neitest">2. 降低未被发现的违规应用造成的影响范围</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 3&nbsp;&nbsp;</span>&nbsp;需要更多的下载流量为什么要付费？</div>
			<div class="neitest">wkzx.wang的平台定位不是应用商店，如果要进行大范围应用公测，我们会根据您选择的不同流量包收取一定的费用用于支付流量费和托管费！</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 4&nbsp;&nbsp;</span>&nbsp;超过免费下载量限制后，应用就不能下载了对吗？</div>
			<div class="neitest">是的，超过限制后将不能下载，请根据实际测试需求提前充值购买流量包。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 5&nbsp;&nbsp;</span>&nbsp;如果应用有出现被竞争对手恶意刷量的情况怎么处理？</div>
			<div class="neitest">如果发现下载流量存在异常，可发送邮件给wkzx@wkzx.vip,
				我们会排查原因，确认存在恶意刷量，我们会根据具体情况恢复下载流量。建议最好设置访客密码，或仅允许团队成员下载， 避免这种情况的出现。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 6&nbsp;&nbsp;</span>&nbsp;购买下载流量包应该充值到哪个账户？</div>
			<div class="neitest">wkzx.wang上的下载流量统一从应用的管理员账户扣除，充值时请注意在管理员账户购买下载流量包，请勿在测试成员的账户下购买，否则，购买的下载流量包只能针对购买账户为应用管理员的应用使用。</div>


			<div class="biaoti"><span style="background-color:#000000; color: #F1F6F9;">&nbsp; 7&nbsp;&nbsp;</span>&nbsp;购买的流量包使用期限是多久？</div>
			<div class="neitest">购买的流量是账号下所有应用的累计下载流量，用完即止，无过期时间。</div>
			<!-- <div class="dibu">
				<div>92阅读 更新时间 11-15 15:00</div>
				<div>0人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div>
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.daohang {
		margin-bottom: 0.625rem;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}

	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-bottom: 3rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
