<template>
  <div>
    <div class="renzheng_box">
      <div class="renzheng_title">
        已实名认证
      </div>
      <div class="renzheng_img_box">
        <div class="renzheng_left">
          <div class="renzheng_left_top">
            <div
              style="font-weight: bold;color: #fff;font-size: 20px;margin-bottom: 10px"
            >
              {{ realName }}
            </div>
            <div style="font-weight: bold;color: #fff;font-size: 16px;">
              {{ idNumber }}
            </div>
          </div>
          <div class="renzheng_left_bottom">支付宝实名认证</div>
        </div>
        <div class="renzheng_right">
          <img src="../../../assets/image/zhifubao.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        realName: {
            type: String,
            default: ''
        },
        idNumber: {
            type: String,
            default: ''
        }
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.renzheng_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.renzheng_img_box {
  display: flex;
  justify-content: space-between;
}
.renzheng_right {
  img {
    width: 131px;
    height: 96px;
  }
}
.renzheng_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 920px;
  height: 470px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .renzheng_title {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
  }
  .renzheng_img_box {
    width: 473px;
    height: 180px;
    background: linear-gradient(90deg, #0085ff 0%, #00c2ff 100%);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px;
  }
  .renzheng_left_bottom {
    font-weight: bold;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
}
</style>