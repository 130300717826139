<template>
    <div class="appDownDoc">
        <div class="docTop">
            <h4 style="display: inline-block;font-weight: 400;"> "苹果iOS16需要启用开发者模式" 解决方法</h4>
            <img @click="close" style="position: absolute;right: 20px;" src="@/assets/image/docClose.png" alt="">
        </div>
        <div class="content_box">
            <div class="doc">
                <p>1. 返回手机桌面依次找到 <b class="bStyle">设置- 隐私与安全性</b>。</p>
                <img src="https://s1.ax1x.com/2022/06/27/jV2jIA.jpg">
                <p> 2. 进入点击 <b class="bStyle">隐私与安全性</b>，下拉到底部，找到 <b class="bStyle">开发者模式</b>。 </p>
                <img src="https://s1.ax1x.com/2022/06/27/jV2xPI.jpg" alt="">
                <p>3. 进入 <b class="bStyle">开发者模式</b> ，并且打开 <b class="bStyle">开发者模式</b> 开关，打开 <b class="bStyle">开发者模式</b>，手机会要求重启才能生效，根据提示操作即可。</p>
                <img src="https://s1.ax1x.com/2022/06/27/jV2zGt.jpg">
            </div>
        </div>
        <div class="docBot">
            <div @click="close">关闭</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow:true,
        }
    },
    methods: {
        close(){
            this.$parent.isShowVipDoc = false;
        },
    }
}
</script>
<style lang="scss" scoped>
.appDownDoc {
    position: absolute;
    top: 80px;
    background: #fff;
    margin: 0 15px;
    box-sizing: border-box;
    z-index: 1000;
    border-radius: 12px;
}
.docTop {
    background: #0092FF;
    height: 44px;
    line-height: 44px;
    color: #fff;
    font-weight: 400;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}
.docBot {
    height: 46px;
    box-shadow: inset 0px 1px 0px #ececec;
    line-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #CCE9FF;
        border-radius: 4px;
        width: 100px;
        height: 33px;
        color: #0092FF;
        font-size: 13px;
        font-weight: 500;
    }
}
.content_box {
  max-height: 550px;
  overflow: auto;
  padding: 0 15px;
}
.goback:hover {
    color: #1e9fff;
}
.goback {
    cursor: pointer;
    font-family: PingFang SC;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 18px;

color: #666666;
margin: 20px 0;
}
.title1 {
    font-family: PingFang SC;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 34px;


color: #333333;

}

p {
    font-family: PingFang SC;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 35px;


color: #555555;
}

img {
    max-width: 100%;
}
</style>