<template>
  <div>
    <div class="upgradeContent">
      <!-- 判断微信提示 -->
      <div class="wxDailog" v-if="wxShow">
        <div class="wxDailogContent">
          <div class="wxDaiimgBox">
            <img class="wxDaiimg" src="@/assets/image/dailogArrow.png" />
          </div>
          <div class="witherDailog">
            点击右上角按钮，然后在弹出的菜单中，点击
            <span class="wx_blue">
              Safari
            </span>
            中打开，即可安装
          </div>
        </div>
      </div>

      <header class="commonMobileTitle">
        <img src="@/assets/image/leftArrow.png" alt="" style="opacity: 0;" />
        <span>
          升级为VIP
        </span>

        <img src="@/assets/image/leftArrow.png" alt="" style="opacity: 0;" />
      </header>

      <div class="vipMobileImg">
        <img src="@/assets/image/vipMobileImg.png" alt="" />

        <span class="mt10">一年</span>
      </div>

      <div class="textVip">
        <div>
          常见问题：
        </div>
        <div>
          <p>
            1:购买设备有效期内同一台设备可以免费多次下载，但是如果您更改为其他设备，则需要再次付款。
          </p>
          <p>
            2:同一台设备只需要支付一次，有效期内无需为同一台设备支付第二次费用，有效期内掉签免费补签。
          </p>
          <p>
            3:同一台设备激活之后送同等时间悟空助手VIP特权，有效期内您可以免费下载悟空助手（www.wkzs.vip）的所有应用
          </p>
        </div>
      </div>
      <div class="vipPayBtn" id="pay" v-show="payShow" @click="payClick">
        支付
      </div>
      <div class="vipPayBtn" id="pay2" v-show="pay2Show">
        暂不支持该系统
      </div>
    </div>
  </div>
</template>
<script>
import { getPayYearMobile } from "@/api/api";
export default {
  name: "yearlyPay",
  data() {
    return {
      wxShow: false, // 微信提示显示
      payShow: false,
      pay2Show: false,
      params:"",
    };
  },
  created() {
    this.getWxShow();
  },
  methods: {
    // 支付
    payClick() {
      // this.$router.push()
      if (window.location.href.includes("?params=")) {
      this.params = window.location.href.substring(window.location.href.split("?params=")[0].length+8);
    }
      getPayYearMobile({params: this.params}).then((res) =>{
        if(res.code == 0){
           window.location.href = res.data;
           setTimeout(
							function() {
								window.location.href = 'https://p.udid.store/embedded.mobileprovision'
							}, 2 * 1000);//延
        }else{
          this.$message.error("获取失败");
        }
      });
    },
    getWxShow() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.wxShow = true;
        this.payShow = true;
        this.pay2Show = false;
      } else if (ua.match(/QQ/i) == "qq") {
        this.wxShow = true;
        this.payShow = true;
        this.pay2Show = false;
      } else {
        this.wxShow = false;
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          this.payShow = true;
          this.pay2Show = false;
        } else if (/alipayclient/.test(ua) && /iphone/.test(ua)) {
          this.wxShow = true;
          this.payShow = true;
          this.pay2Show = false;
        } else if (/alipayclient/.test(ua) && /android/.test(ua)) {
          this.pay2Show = true;
          this.payShow = false;
        } else {
          this.payShow = false;
          this.pay2Show = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

.commonMobileTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 11.73vw;
  background: #f2f2f2;
  font-size: 4.53vw;
}
.commonMobileTitle img {
  width: 4.53vw;
  height: 4.53vw;
}

.upgrdeVIP_content {
  padding: 0 3.73vw;
}
.vipMobileImg {
  width: 92.53vw;
  height: 56.8vw;
  margin-top: 3.72vw;

  margin: 5vw auto 0;

  font-size: 5.87vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.vipMobileImg img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.vipMobileImg span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 5.87vw;
  color: #fff;
  margin-top: 4vw;
  z-index: 9;
}
.mt10 {
  margin-top: 5vw;
}
.textVip {
  width: 92.53vw;
  min-height: 58.67vw;
  background: #f6f6f6;
  border-radius: 3vw;
  margin: 5.33vw auto 0;
  padding: 5.33vw 3.73vw 3.73vw;
  box-sizing: border-box;
}
.textVip > :nth-child(1) {
  font-size: 6vw;
  font-weight: bold;
  color: #666666;
}
.textVip > :nth-child(2) {
  font-size: 4vw;
  margin-top: 2.35vw;
  line-height: 6.35vw;
  color: #999999;
}
.vipPayBtn {
  width: 92.53vw;
  height: 13.33vw;
  background: linear-gradient(180deg, #fdc84e 0%, #ff9100 100%);
  border-radius: 3vw;
  margin: 8vw auto 3vw;
  text-align: center;
  color: #ffffff;
  line-height: 13.33vw;
}

.rachangeCenterTop {
  width: 100%;
  height: 33.07vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.73vw;
}
.rachangeCenterTop > :nth-child(2) {
  margin-top: 2.67vw;
  font-size: 8vw;
  color: #ff4444;
}

.rachangeContainer {
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
}

.rachangeCenterBottom {
  width: 92.53vw;
  height: 37.33vw;
  background: #ffffff;
  border-radius: 3vw;
  margin: 8vw auto 9.87vw;
  padding: 0 2.67vw;
  box-sizing: border-box;
}
.rachangeCenterBottom > label {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rachangeCenterBottom > :nth-child(2) {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

#vipPayComfrim {
  width: 92.53vw;
  height: 13.33vw;
  background: #2e63ff;
  border-radius: 3vw;
  line-height: 13.33vw;
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 4.53vw;
}

.payedImg {
  width: 48.27vw;
  height: 48.27vw;
  display: block;
  margin: 13.33vw auto 10.67vw;
}

.payedMsg {
  width: 100%;
  text-align: center;
  font-size: 4.27vw;
  color: #666666;
  font-weight: bold;
}

.payedBtn {
  width: 80vw;
  height: 13.33vw;
  background: #2e63ff;
  line-height: 13.33vw;
  text-align: center;
  font-size: 4.53vw;
  color: #fff;
  margin: 24vw auto 0;
  border-radius: 3vw;
}

.upgradeContent {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
}
.wxDailog {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 19;

  display: flex;

  box-sizing: border-box;
  padding: 10px;
}
.wxDailogText {
  width: 90%;
}

.wxDailogContent {
  width: 98%;
  margin: 0 auto;
}

.witherDailog {
  height: 24vw;
  background: #ffffff;
  border-radius: 5px;
  padding: 23px 20px;
  box-sizing: border-box;
  font-size: 14px;
}
.wxDaiimgBox {
  text-align: right;
}
.wxDaiimg {
  width: 5px;
  height: 38px;
  margin-right: 5px;
}
.wx_blue {
  color: #0d81f9;
}
</style>
