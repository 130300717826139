<template>
  <div>
    <div class="content">
      <div class="content_box">
        <div class="content_logo">
          <img src="../../assets/image/logo.png" alt="" />
        </div>
        <div class="content_login">
          <div class="content_tab">
            <el-tabs v-model="activeName" stretch>
              <el-tab-pane label="设置密码" name="first">
                <el-form
                  ref="form"
                  :rules="rules"
                  :model="form"
                  style="margin-top: 30px;"
                >
                  <el-form-item prop="password">
                    <el-input
                      type="password"
                      v-model="form.password"
                      placeholder="输入密码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="repassword">
                    <el-input
                      type="password"
                      v-model="form.repassword"
                      placeholder="再次输入密码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="onSubmit">完成</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="content_goback">
          <div class="goback">
            <img src="../../assets/image/home.png" alt="" />
            <span @click="goback">回到首页</span>
          </div>
        </div>
      </div>
      <footer-bottom></footer-bottom>
    </div>
  </div>
</template>

<script>
import { registerUser, forgetPassword } from "@/api/api";
import footerBottom from "@/components/common/footer";
export default {
  name: "password",
  components: {
    footerBottom,
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        // password 是表单上绑定的字段
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      activeName: "first",
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      form: {
        userName: "",
        password: "",
        repassword: "",
        // repeatPassword: '',
        accountType: "",
      },
      type: "", // 是否忘记密码
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.form.userName = this.$route.query.userName;
    this.form.accountType = parseInt(this.$route.query.accountType);
  },
  methods: {
    goback() {
      this.$router.push("/index");
    },
    goLogin() {
      this.$router.push("/login");
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type == "forget") {
            const params = new URLSearchParams();
            params.userName = params.append("userName", this.form.userName);
            params.password = params.append("password", this.form.password);
            forgetPassword(params).then((res) => {
              if (res.code == 0) {
                this.$router.push("/login");
              } else {
                this.$message.warning(res.msg)
              }
            });
          } else {
            const params = new URLSearchParams();
            params.userName = params.append("userName", this.form.userName);
            params.password = params.append("password", this.form.password);
            params.accountType = params.append(
              "accountType",
              this.form.accountType
            );
            registerUser(params).then((res) => {
              if (res.code == 0) {
                this.$router.push("/login");
              } else {
                this.$message.warning(res.msg)
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  position: relative;
}
.content_box {
  position: absolute;
  width: 422px;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content_logo {
    width: 100%;
    height: 56.6px;
    display: flex;
    align-item: center;
    margin-bottom: 50px;
    img {
      width: 200px;
      height: 56.6px;
      margin: auto;
    }
  }
  .content_login {
    width: 100%;
    height: 354px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    .content_tab {
      width: 362px;
      overflow: hidden;
      margin: auto;
      .ce_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .el-tabs .el-form-item .el-button {
        width: 100%;
      }
    }
  }
  .content_goback {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 15px;
    background: #fff;
    color: #999999;
    margin: auto;
    .goback {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
