<template>
  <div>
    <div>
        <template-pc v-if="qqAndWxStatus == 3"
        :form="form"
        :shortUrl="shortUrl"
        ></template-pc>
    </div>
    <div>
      <template-phone v-if="qqAndWxStatus == 0"
        :form="form"
        :shortUrl="shortUrl"
        ></template-phone>
    </div>
    <brower-template
          v-if="qqAndWxStatus == 1 || qqAndWxStatus == 2"
          :qqAndWxStatus="qqAndWxStatus"
          :icon="form.iconUrl"
          :softname="form.label"
    ></brower-template>
  </div>
</template>

<script>
import browerTemplate from "@/views/appDown/template/browerTemplate";
import templatePc from "@/views/web/template/templatePc";
import templatePhone from "@/views/web/template/templatePhone";
import mixin from "@/mixin"; // 引入mixin文件
import { webShorUrl } from "@/api/api"
export default {
  mixins: [mixin], // 判断是否安卓公共方法
  components: {
    templatePc,
    browerTemplate,
    templatePhone
  },
  data() {
      return {
          shortUrl: '',
          form:{
          },
          qqAndWxStatus: 0,
      }
  },
  created() {
     
    if (window.location.href.includes("?")) {
      let short = window.location.href;
      short = short.split("?")[0];
      short = short.substr(short.lastIndexOf("/")+1,short.length);
      this.shortUrl =short;
    } else {
      let short = window.location.href;
      this.shortUrl = short.substr(short.lastIndexOf("/")+1,short.length);
    }
    this.getWebShortInfo();
    const ua = navigator.userAgent.toLowerCase();
     if (ua.match(/micromessenger/i) == "micromessenger") {
      this.qqAndWxStatus = 1;
    } else if(ua.match(/macintosh/i) == "macintosh" || ua.match(/windows/i) == "windows"){
      if (this.getBrower().mobile) {
            this.qqAndWxStatus = 0;
       }else{
          this.qqAndWxStatus = 3;
       }
    } else {
      if(ua.match(/iphone/i) == "iphone" && ua.match(/qqtheme/i) == "qqtheme"){
          this.qqAndWxStatus = 2;
       }
       if(ua.match(/android/i) == "android" && ua.match(/qqtheme/i) == "qqtheme"){
          this.qqAndWxStatus = 2;
      }
    }
  },
  methods: {
     async getWebShortInfo() {
      // alert(this.shortUrl)
        await webShorUrl('',this.shortUrl).then(res => {
              if(res.code == 1){
                 this.$router.push({
                  path: '/errorPage',
                  query: {
                    tips: '审核中或审核未通过'
                  }
              })
            }else if(res.code == 2){
                 this.$router.push({
                  path: '/errorPage',
                  query: {
                    tips: '已删除或不存在'
                  }
              });
            }else if(res.code == 3){
                 this.$router.push({
                  path: '/errorPage',
                  query: {
                    tips: '已过期,请续费'
                  }
              });
            }else if(res.code == 107){
                this.$router.push({
                  path: '/templateMerge',
                  query: {
                    softId: res.data.webClip.remarks7,
                    userId: res.data.webClip.userId,
                    softUrl: res.data.softUrl
                  }
                });
              }else if(res.code == 101){
                this.form.label = res.data.label;
                this.form.iconUrl = res.data.iconUrl;
                if(this.qqAndWxStatus == 1 || this.qqAndWxStatus == 2){
                  if(this.form.label){
                      document.title = this.form.label;
                    }else{
                      document.title = '';
                  }
               }  
              }else{
                this.form = res.data.webClip
              }
          })
      }
  }
};
</script>
