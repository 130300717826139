<template>
  <div v-show="mbShow">
    <mbcontract-top-vue
      :info="form"
      v-if="form.wx != '' || form.infoqq != ''"
    ></mbcontract-top-vue>
    <div v-if="!sellFlag">
      <div class="content" v-if="qqAndWxStatus == 0">
        <el-drawer
          title="安装提示"
          center
          v-model="drawer"
          direction="btt"
          :before-close="drawerClose"
          destroy-on-close
        >
          <div class="drawer_box">
            <div class="drawer_title">
              1.同意安装，桌面将会生成
              <span style="color: #0092ff">{{ form.softname }}</span>
            </div>
            <div class="drawer_img">
              <img class="img1" src="@/assets/image/iosbanner.png" />
              <div class="img2">
                <img :src="form.iconurl" />
                <div
                  style="
                    font-size: 0.75rem;
                    color: #fff;
                    text-align: center;
                    margin-top: -0.3125rem;
                  "
                >
                  {{ form.softname }}
                </div>
              </div>
            </div>
            <div class="drawer_title">
              2. 安装后，首次打开需要信任描述文件
              <span
                class="jc"
                v-if="
                  (form.fittype == 'IOS' && !superShow) ||
                  (form.fittype == 'IOS' && superShow && isHide)
                "
                @click="installClick"
                >安装教程</span
              >
            </div>
            <div class="setting_box">
              <img src="@/assets/image/setting.png" />
              <div class="setting_title">
                {{
                  form.certificate == null || form.certificate == ""
                    ? "TAKARITAS CLEAN Korla..."
                    : form.certificate
                }}
              </div>
            </div>
            <div class="drawer_footer">
              <el-button
                type="primary"
                round
                style="width: 9.25rem; height: 2.75rem; margin-top: 1.25rem"
                @click="queryClick"
                >快速信任</el-button
              >
            </div>
          </div>
        </el-drawer>
        <el-dialog
          v-model="DowndialogVisible"
          :close-on-click-modal="false"
          v-if="isShow"
          :show-close="false"
          width="80%"
        >
          <div class="modal-body">
            <div class="modal_item">
              <span class="china">配置文件中</span>
              <!-- <span class="usa">In profile</span> -->
              <img
                ref="a"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
                style="display: none"
              /><img
                ref="a1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
              /><img
                ref="a2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
                style="display: none"
              />
            </div>
            <br />
            <div class="modal_item">
              <span class="china">校验中</span>
              <!-- <span class="usa">Check in</span> -->
              <img
                ref="b"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
                style="display: none"
              /><img
                ref="b1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
                style="display: none"
              /><img
                ref="b2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
              />
            </div>

            <br />
            <div class="modal_item">
              <span class="china">正在下载</span>
              <!-- <span class="usa">Downloading</span> -->
              <img
                ref="c"
                class="img_dialog"
                src="../../../assets/image/right_1.png"
              /><img
                ref="c1"
                class="img_dialog"
                src="../../../assets/image/right_2.png"
                style="display: none"
              /><img
                ref="c2"
                class="img_dialog"
                src="../../../assets/image/loading1.gif"
                style="display: none"
              />
            </div>

            <br />
            <h4 style="color: #0098dd; text-align: center; display: none">
              <span class="china">请不要离开此页面并保持屏幕常亮</span>
              <!-- <span class="usa"
              >Please do not leave this page and keep the screen on</span
            > -->
            </h4>
          </div>
        </el-dialog>
        <el-dialog v-model="dialogVisible" :show-close="false" width="80%">
          <div style="height: 400px; position: relative">
            <div style="height: 230px; background: #3abdf8; width: 100%">
              <div style="width: 175px; margin: auto">
                <el-carousel trigger="click">
                  <el-carousel-item
                    v-for="(item, index) in bannerList"
                    :key="index"
                  >
                    <img
                      class="banner_img"
                      :src="item.imgUrl"
                      alt=""
                      style="width: 175px; height: 230px"
                    />
                    <h3>{{ item.title1 }}</h3>
                    <h4 style="height: 45px">{{ item.title2 }}</h4>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div
              style="
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%, 0);
              "
            >
              <el-button
                type="primary"
                round
                style="width: 250px"
                @click="nextInstall"
                >继续安装</el-button
              >
            </div>
          </div>
        </el-dialog>
        <div v-if="isBuyPay" class="buy_pay_hint">
          此应用已开启付费下载，请购买后安装
        </div>
        <div class="logo">
          <img :src="form.iconurl" />
        </div>
        <div class="title">
          <span
            class="iconfont icon-pingguo icstyle"
            v-show="form.fittype == 'IOS'"
          ></span>
          <span
            class="iconfont icon-android-fill icstyle"
            v-show="form.fittype == 'Android'"
          ></span>
          <span>&nbsp;{{ form.softname }}</span>
        </div>
        <div class="verison" v-show="languageToggle == 1">
          版本： {{ form.publishName }}
        </div>
        <div class="verison" v-show="languageToggle == 2">
          versions： {{ form.publishName }}
        </div>
        <div class="font_style" v-show="languageToggle == 1">
          大小：{{ form.fileSize }} 更新时间：{{ form.chDate }}
        </div>
        <div class="font_style" v-show="languageToggle == 2">
          size: {{ form.fileSize }} time: {{ form.chDate }}
        </div>
        <div class="install_class" style="display: none">
          <!-- v-show="languageToggle == 1"  -->
          请不要离开此页面并保持屏幕常亮
        </div>
        <div class="install_class" style="display: none">
          <!-- v-show="languageToggle == 2"  -->
          Installing, please press home key to view
        </div>
        <div
          class="down_class"
          v-show="languageToggle == 1"
          @click="downClick"
          v-if="downFlag == 0"
        >
          立即下载
        </div>
        <div
          class="load_style"
          v-show="loading"
          v-loading="loading"
          element-loading-text="正在下载中"
          element-loading-spinner="el-icon-loading"
        ></div>
        <div
          class="down_class"
          v-show="languageToggle == 2"
          @click="downClick"
          v-if="downFlag == 0"
        >
          Download
        </div>
        <div
          class="sell_class"
          v-show="languageToggle == 1"
          @click="sellClick"
          v-if="downFlag == 3"
        >
          购买应用
        </div>
        <div
          class="sell_class"
          v-show="languageToggle == 2"
          @click="sellClick"
          v-if="downFlag == 3"
        >
          Buy apps
        </div>
        <div
          class="down_class"
          v-if="downFlag == 1"
          v-show="languageToggle == 1"
        >
          暂不支持此系统
        </div>
        <div
          class="down_class"
          v-if="downFlag == 1"
          v-show="languageToggle == 2"
        >
          This system is not supported temporarily
        </div>
        <div class="erwei">
          <div id="qrCode" ref="qrCodeDiv">
            <!-- <img id='qrCodeIco' :src='form.iconurl' /> -->
          </div>
          <!-- <img :src='form.iconurl' /> -->
        </div>
        <!-- <div style="font-size: 14px;margin-top: 20px" v-show="languageToggle == 1">
          扫描二维码下载
        </div>
        <div style="font-size: 14px;margin-top: 20px" v-show="languageToggle == 2">
          Scan the QR code to download
        </div> -->
        <div
          class="cxt"
          v-if="form.fittype == 'IOS' && !superShow && languageToggle == 1"
          @click="installClick"
        >
          "未受信任的企业及开发者"的解决办法
        </div>

        <div
          class="cxt"
          v-if="
            form.fittype == 'IOS' && superShow && isHide && languageToggle == 1
          "
          @click="installClick"
        >
          "需要启用开发者模式"的解决办法
        </div>

        <div
          class="cxt"
          v-if="form.fittype == 'IOS' && !superShow && languageToggle == 2"
          @click="installClick"
        >
          Solutions for "untrusted enterprises and developers"
        </div>
        <div
          class="cxt"
          v-if="
            form.fittype == 'IOS' && superShow && isHide && languageToggle == 2
          "
          @click="installClick"
        >
          Solution to "developer mode needs to be enabled"
        </div>

        <div style="margin-bottom: 40px"></div>
        <footer-bottom
          :softId="form.softId"
          :softInfo="form"
          @languageChange="languageChange"
          :bannerImg="bannerImg"
          :link="link"
          :closeAD="closeAD"
        ></footer-bottom>
      </div>
      <brower-template
        v-if="qqAndWxStatus == 1 || qqAndWxStatus == 2"
        :qqAndWxStatus="qqAndWxStatus"
      ></brower-template>
    </div>
    <sell-template
      v-if="sellFlag"
      :UDID="UDID"
      :userId="form.userId"
      :shortUrl="form.softUrlSmall"
    ></sell-template>
    <el-dialog
      v-model="centerDialogVisible"
      title="提示"
      top="38vh"
      width="70%"
      center
    >
      <div class="pop-title">提示</div>
      <span class="pop-context"
        >该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false"
            >关闭</el-button
          >
        </span>
      </template>
    </el-dialog>
    <app-DownDoc v-if="isShowDoc" :language="languageToggle"></app-DownDoc>
    <vip-Doc v-if="isShowVipDoc"></vip-Doc>
    <div class="modal" v-if="isShowDoc || isShowVipDoc"></div>
  </div>
</template>
<script>
import sellTemplate from "@/views/appDown/template/sellTemplate";
import browerTemplate from "@/views/appDown/template/browerTemplate";
import footerBottom from "@/views/appDown/footerBottom";
import appDownDoc from "@/views/appDown/template/appDownDoc";
import vipDoc from "@/views/appDown/template/vipDoc";

import {
  downConsumptionFlow,
  checkEquipmentNum,
  signatureUDID,
  checkStatus,
  verifyPersonUDIDPay,
  softConformInstallIf,
  saveVisitMess,
} from "@/api/api";
import mixin from "@/mixin"; // 引入mixin文件
import QRCode from "qrcodejs2";
import mbcontractTopVue from "../../../components/common/mbcontractTop.vue";
import { isEmpty, domainName } from "@/common/js/common.js";
import MobileDetect from "@/common/js/mobile-detect.js";

export default {
  mixins: [mixin],
  components: {
    browerTemplate,
    sellTemplate,
    footerBottom,
    mbcontractTopVue,
    appDownDoc,
    vipDoc,
  },
  props: {
    bannerImg: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
    },
    vip: {
      type: Boolean,
      default: false,
    },
    UDID: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    closeAD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mbShow: false,
      loading: false,
      drawer: false,
      isShow: true, //进度条是否显示
      sellFlag: false,
      dialogVisible: false,
      DowndialogVisible: false,
      bannerList: [
        {
          id: 1,
          imgUrl: require("../../../assets/image/vip_1.png"),
          title1: "第一步",
          title2: "点击“允许”进行下一步操作。",
        },
        {
          id: 2,
          imgUrl: require("../../../assets/image/vip_2.png"),
          title1: "第二步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 3,
          imgUrl: require("../../../assets/image/vip_3.png"),
          title1: "第三步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 4,
          imgUrl: require("../../../assets/image/vip_4.png"),
          title1: "第四步",
          title2: "点击右上角“安装”，然后进行下一步操作。",
        },
        {
          id: 5,
          imgUrl: require("../../../assets/image/vip_5.png"),
          title1: "第五步",
          title2: "点击“安装”即可。",
        },
      ],
      show: true,
      downFlag: 0,
      count: "",
      languageToggle: 1,
      fileList: [],
      token: "",
      myHeaders: null,
      timer: null,
      stopCheckState: "", //停止轮询变量
      qqAndWxStatus: 0,
      isBuyPay: false, //是否购买
      centerDialogVisible: false,
      actCode: "", //激活码
      isShowDoc: false,
      isHide: false,
      superShow: false,
      isShowVipDoc: false,
    };
  },
  async created() {
    // 修改域名
    const res = await domainName(this.form.userId, this.form.shortLink);
    this.form.shortLink = res;
    console.log(this.form);
    this.showVip();
    if (isEmpty(this.form.wx)) {
      this.form.wx = "";
    }
    if (isEmpty(this.form.infoqq)) {
      this.form.infoqq = "";
    }
    this.actCode = window.location.href.substring(
      window.location.href.length - 8
    );
    this.andriodAndApple();
    if (this.form.softname) {
      document.title = this.form.softname;
    } else {
      document.title = "";
    }
  },
  mounted() {
    this.vipId = this.$route.query.vipId;
    if (this.vipId) {
      this.DowndialogVisible = true;
      this.vipDownCheck();
    }
    this.getAppShow();
    this.saveVisitMess();
    console.log(this.form);
    if (this.form.chEn == 0) {
      if (
        (navigator.language || navigator.browserLanguage)
          .toLowerCase()
          .includes("zh-cn")
      ) {
        this.languageToggle = 1;
      } else {
        this.languageToggle = 2;
      }
    } else {
      this.languageToggle = this.form.chEn;
    }
    console.log(this.languageToggle);
  },
  methods: {
    showVip() {
      let device_type = navigator.userAgent;
      // console.log(device_type)
      let md = new MobileDetect(device_type);
      let os = md.os();
      // console.log(os)
      if (os == "iOS") {
        os = md.version("iPhone");
        if (os.toString().indexOf(".") > -1) {
          let index = os.toString().indexOf(".");
          os = os.toString().substring(0, index);
        }
        if (Number(os) < 16) {
          this.isHide = false;
        } else {
          this.isHide = true;
        }
      }
      // console.log(md.version)
      if (this.form.automaticallyRefresh == 1) {
        this.superShow = true;
      }
    },
    // 安装教程
    installClick() {
      if (!this.superShow) {
        this.isShowDoc = true;
      } else {
        this.isShowVipDoc = true;
      }
      this.drawer = false;
      // this.$router.push("/trustTeach");
      // this.$router.push("/blog/untrustedEnterprise");
    },
    // 快速信任
    queryClick() {
      var ua = navigator.userAgent.toLowerCase();
      if (
        ua.match(/safari/i) == "safari" &&
        ua.match(/mqqbrowser/i) != "mqqbrowser"
      ) {
        window.location.href = "https://p.udid.store/embedded.mobileprovision";
      } else {
        this.$message.error("仅支持safai浏览器！");
      }
    },
    drawerClose() {
      this.drawer = false;
    },
    // 切换中英文
    languageChange(val) {
      this.languageToggle = val;
    },
    sellClick() {
      this.sellFlag = true;
    },
    // 判断是否是ios17
    isIOSNew() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("like mac os x") > 0) {
        var regStr_saf = /os [\d._]*/gi;
        var verinfo = ua.match(regStr_saf);
        var version = (verinfo + "")
          .replace(/[^0-9|_.]/gi, "")
          .replace(/_/gi, ".");
        var arr = version.split(".");
        if (arr[0] >= 17) return true;
      }
    },
    // 继续安装
    nextInstall() {
      if (/Safari/.test(navigator.userAgent)) {
        window.location.href = this.form.mobileconfigUrl;
        if (!this.isIOSNew()) {
          setTimeout(function () {
            window.location.href =
              "https://p.udid.store/embedded.mobileprovision";
          }, 2 * 1000); //延
        }
      } else {
        this.$message("仅支持safai浏览器！");
      }
    },
    // 判断系统所属安卓还是苹果
    andriodAndApple() {
      // debugger
      // if (
      //   !(
      //     this.getBrower().ios ||
      //     this.getBrower().iPhone ||
      //     this.getBrower().iPad
      //   )
      // ) {
      //   //非苹果端
      //   this.downFlag = 1;
      // if (this.form.fittype == "IOS" && this.form.thirdurl) {
      //   return window.location.href = 'http://' + this.form.thirdurl;
      // } else {
      //   this.mbShow = true;
      // }
      // } else {
      //   // 苹果端
      //   if (
      //     this.getBrower().ios ||
      //     this.getBrower().iPhone ||
      //     this.getBrower().Pad
      //   ) {
      //     this.downFlag = 1;
      //   }
      //   if (this.form.fittype == "Android" && this.form.thirdurl) {
      //     return window.location.href = 'http://' + this.form.thirdurl;
      //   } else {
      //     this.mbShow = true;
      //   }
      // }

      this.mbShow = true;
      if (this.form.fittype == "IOS") {
        if (
          !(
            this.getBrower().ios ||
            this.getBrower().iPhone ||
            this.getBrower().iPad
          )
        ) {
          //非苹果端
          this.downFlag = 1;
          if (this.form.fittype == "IOS" && this.form.thirdurl) {
            return (window.location.href = "http://" + this.form.thirdurl);
          } else {
            this.mbShow = true;
          }
        }
      } else {
        //安卓
        if (
          this.getBrower().ios ||
          this.getBrower().iPhone ||
          this.getBrower().Pad
        ) {
          this.downFlag = 1;
          if (this.form.fittype == "Android" && this.form.thirdurl) {
            return (window.location.href = "http://" + this.form.thirdurl);
          } else {
            this.mbShow = true;
          }
        }
      }
    },
    languageClick(val) {
      this.languageToggle = val;
    },
    // 跳转到免责声明
    exemptionClick() {
      this.$router.push("/disclaimer");
    },
    // 举报
    reportClick() {
      this.$router.push({
        path: "/report",
        query: {
          softId: this.form.softId,
        },
      });
    },
    saveVisitMess() {
      //浏览保存
      saveVisitMess({ softId: this.form.softId }).then(() => {}); //保存下载量
    },
    vipDownCheck() {
      //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
      var udid = localStorage.getItem("udidcache");
      if (
        udid != "undefined" &&
        udid != null &&
        this.UDID != null &&
        this.UDID != ""
      ) {
        if (this.form.openPay == 1) {
          verifyPersonUDIDPay({ userId: this.userId, udid: this.UDID }).then(
            (res) => {
              if (res.code != 0) {
                // this.$message.error("此应用已开启付费下载，请购买后安装");
                this.isBuyPay = true;
                this.downFlag = 3;
                this.isShow = false;
              } else {
                this.isBuyPay = false;
                this.getCheckEquipmentNum();
              }
            }
          );
        } else {
          this.getCheckEquipmentNum();
        }
      }
    },
    //校验设备
    getCheckEquipmentNum() {
      // if (this.form.remarks4 == 1) {
      //校验设备
      checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
        (res) => {
          if (res.code == 0) {
            //签名
            signatureUDID({
              softId: this.form.softId,
              UDID: this.UDID,
              softUrl: this.form.softUrlSmall,
            }).then((res) => {
              if (res.code != 0) {
                this.dialogVisible = false;
                this.$message.error(res.msg);
              } else {
                if (res.data.data.result == "downing") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.$message.error("有用户正在下载该应用，请稍后重试");
                } else if (res.data.data.result == "fail") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.DowndialogVisible = false;
                  this.$message.error(res.data.data.msg);
                } else {
                  this.dialogVisible = false;
                  // this.$message.success(res.msg);
                }
              }
            });
            this.stopCheckState = setInterval(() => {
              this.checkStatus();
            }, 3000); //轮询查看vip安装进度
            localStorage.setItem("udidcache", this.UDID); //缓存浏览器
          } else {
            this.DowndialogVisible = false;
            this.$message.error(res.msg);
          }
        }
      );
      //查看状态
      // } else {
      // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
      // }
    },
    checkStatus() {
      var that = this;
      //查看vip安装进度
      checkStatus({ softId: that.form.softId, UDID: that.UDID }).then((res) => {
        let status = res.data.result;
        if (status == "1") {
          that.$refs.a2.style.display = "none";
          that.$refs.a1.style.display = "block";
          that.$refs.b.style.display = "none";
          that.$refs.b2.style.display = "block";
        }
        if (status == "2") {
          that.$refs.b2.style.display = "none";
          that.$refs.b1.style.display = "block";
          that.$refs.c2.style.display = "block";
          that.$refs.c.style.display = "none";
        }
        if (status == "3") {
          that.$refs.c2.style.display = "none";
          //获取存放在隐藏域的itemservice链接
          let itemservice = res.data.itemservice;
          if (isEmpty(itemservice)) {
            return;
          }
          clearInterval(that.stopCheckState); //清除定时轮询
          that.DowndialogVisible = false;
          window.location.href = itemservice;
        }
      });
    },
    // 下载
    downClick() {
      if (
        this.form.packType === "企业版" &&
        this.form.automaticallyRefresh != 1
      ) {
        if (this.isIOSNew()) {
          this.isShowDoc = true;
        }
      }
      if (this.form.automaticallyRefresh == 1) {
        if (!this.getVipUserAgent()) {
          this.centerDialogVisible = true;
          return;
        }
      } else {
        if (
          (this.getBrower().iPhone || this.getBrower().iPad) &&
          (this.getBrower().presto || this.getBrower().Quark)
        ) {
          this.centerDialogVisible = true;
          // alert("该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。");
          return;
        }
      }
      //校验应用是否符合下载标准
      softConformInstallIf({ softId: this.form.softId }).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg);
          return;
        }
      });
      if (this.isIOSNew()) {
        this.drawer = false;
      } else {
        if (this.form.fittype == "IOS") {
          if (this.form.automaticallyRefresh == 0) {
            this.drawer = true;
          } else {
            this.drawer = false;
          }
        }
      }

      if (this.form.automaticallyRefresh == 1) {
        var ua = navigator.userAgent.toLowerCase();
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          if (ua.indexOf(" mac os x") > 0) {
            if (!navigator.cookieEnabled) {
              this.$message.error("你的浏览器已禁用cookie");
              return;
            }
          }
        } else {
          this.$message.error("请在Safari浏览器中打开本页");
          return;
        }
        //判断浏览器udid
        //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
        var udid = localStorage.getItem("udidcache");

        if (
          udid != "undefined" &&
          udid != null &&
          this.UDID != null &&
          this.UDID != ""
        ) {
          // if (this.form.remarks4 == 1) {
          //校验设备
          checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
            (res) => {
              if (res.code == 0) {
                this.DowndialogVisible = true;
                //签名
                signatureUDID({
                  softId: this.form.softId,
                  UDID: this.UDID,
                  softUrl: this.form.softUrlSmall,
                }).then((res) => {
                  if (res.code != 0) {
                    this.dialogVisible = false;
                    this.$message.error(res.msg);
                  } else {
                    if (res.data.data.result == "downing") {
                      clearInterval(this.stopCheckState); //清除定时轮询
                      this.$message.error("有用户正在下载该应用，请稍后重试");
                    } else if (res.data.data.result == "fail") {
                      clearInterval(this.stopCheckState); //清除定时轮询
                      this.DowndialogVisible = false;
                      this.$message.error(res.data.data.msg);
                    } else {
                      this.dialogVisible = false;
                      // this.$message.success(res.msg);
                    }
                  }
                });
                this.stopCheckState = setInterval(() => {
                  this.checkStatus();
                }, 3000); //轮询查看vip安装进度
                localStorage.setItem("udidcache", this.UDID); //缓存浏览器
              } else {
                this.DowndialogVisible = false;
                this.$message.error(res.msg);
              }
            }
          );
          //查看状态
          // } else {
          // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
          // }
        } else {
          this.dialogVisible = true;
        }
      } else {
        let param = {
          softId: this.form.softId,
          userId: this.form.userId,
          adkey: this.$route.query.adkey ? this.$route.query.adkey : "",
        };
        if (this.form.actcode == 1) {
          param.actCode = this.actCode;
        }
        downConsumptionFlow(param).then((res) => {
          if (res.code == 0) {
            this.downFlag = 6;
            // this.loading = true;
            let _this = this;
            setTimeout(() => {
              _this.loading = false;
              _this.downFlag = 0;
            }, 3000);
            // window.location.href = this.publishList[0].downUrl; 
            window.location.href = res.data.downUrl;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    getAppShow() {
      setTimeout(() => {
        this.bindQRCode(this.form.shortLink);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height: 326px;
}
.load_style {
  width: 200px;
  padding: 0 2.875rem;
  margin-top: 1.125rem;
  margin-bottom: 42px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
::v-deep .el-icon-loading {
  font-size: 30px;
  font-weight: 700;
}
.drawer_box {
  padding: 1.25rem;
  .setting_box {
    display: flex;
    align-items: center;
    height: 3.75rem;
    background: #f1f6f9;
    border-radius: 0.25rem;
    padding: 0.5rem 0.9375rem;
    .setting_title {
      font-family: PingFang SC;
      font-style: normal; 
      font-weight: bold;
      font-size: 0.9375rem;
      line-height: 3.75rem;
      color: #333333;
    } 
    img {
      width: 2.75rem;
      height: 2.75rem;
      margin-right: 10px;
    }
  }
  .drawer_title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9375rem;
    margin: 10px 0;
    color: #333333;
    .jc {
      cursor: pointer;
      display: inline-block;
      width: 5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9375rem;
      color: #0092ff;
      background: #cae8ff;
      border-radius: 4px;
    }
  }
  .drawer_img {
    position: relative;
    width: 100%;
    height: 6rem;
    .img1 {
      width: 100%;
      height: 100%;
    }
    .img2 {
      position: absolute;
      right: 1.625rem;
      top: 0.6rem;
      img {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
      }
    }
  }

  .drawer_footer {
    display: flex;
    justify-content: center;
  }
}
::v-deep .el-drawer {
  border-radius: 1.25rem 1.25rem 0px 0px;
  height: 26rem !important;
}
::v-deep .el-drawer__header {
  text-align: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375rem;
  color: #333333;
  margin-bottom: 0;
  span {
    margin-left: 1.7rem;
  }
  .el-drawer__close {
    font-size: 0.9375rem;
    color: #7b7b7b;
  }
}
.modal-body {
  padding: 30px;
  .modal_item {
    display: flex;
    justify-content: space-between;
    .img_dialog {
      width: 20px;
      height: 20px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px !important;
  .pop-title {
    font-size: 3vh;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .pop-context {
    text-align: center;
    display: block;
    height: auto;
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
::v-deep .el-dialog__header {
  display: none;
}
.install_class {
  font-size: 13px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(0, 152, 221, 1);
  line-height: 35px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 0.5rem;
  background: rgba(229, 229, 229, 1);
  .footer_header {
    display: flex;
    justify-content: space-between;
    span {
      color: #0098dd;
    }
  }
}
.down_class {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 9rem;
  padding: 0 1rem;
  height: 44px;
  text-align: center;
  background: #0098dd;
  border-radius: 22px;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin-top: 1.125rem;
  margin-bottom: 42px;

  font-family: PingFang SC;
  font-style: normal;
  // font-weight: bold;
  font-size: 14px;
}
.mobile_box {
  background: #fff;
}

#qrCode {
  border-radius: 16px;
  margin-bottom: 20px;
}
.content {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .buy_pay_hint {
    position: relative;
    bottom: -30px;
    padding: 10px 30px;
    margin-bottom: -20px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 67px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
  }
  .logo {
    margin-top: 80px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 20px;
    }
  }

  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    margin: 20px 0 10px 0;
    /* identical to box height */

    text-align: center;

    color: #000000;
  }
  .verison {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #666666;
  }
  .font_style {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin: 14px 0 18px 0;
    color: #999999;
  }
}
h3 {
  margin-top: 12px;
  font-size: 15px;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 23px;
  text-align: center;
}
h4 {
  font-size: 12px;
  font-family: PingFang-SC-Regular;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 18px;
  text-align: left;
}
.sell_class {
  width: 9rem;
  padding: 0 1.5rem;
  height: 3rem;
  text-align: center;
  background: #ba812f;
  border-radius: 1.5rem;
  line-height: 3rem;
  color: #fff;
  cursor: pointer;
  margin: 20px 0 30px 0;
}

.erwei {
  position: relative;
  height: 140px;
  img {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 50%;
    top: 50%;
    background: #fff;
    border: 3px solid #fff;
    border-radius: 5px;
    transform: translate(-50%, -50%);
  }
}
.icstyle {
  font-size: 30px;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
.cxt {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 152, 221, 1);
  margin-top: 20px;
}
</style>
