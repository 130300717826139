<template>
  <div>
    <div class="contract_right" v-if="!getDomainFlag">
      <div class="title">已绑定域名</div>
      <div class="box">您的账户已绑定域名：【{{ form.SLD }}】</div>
      <div class="footer">
        <div class="btn" @click="resetClick">解除绑定</div>
        <div class="tips">如需更换绑定，请点击解除绑定重新进行绑定操作</div>
      </div>
    </div>
    <div class="contract_right" v-if="getDomainFlag">
      <div class="title">未绑定域名</div>
      <div class="title2">
        <!-- 应用分发请绑定您自己的域名，使用平台域名进行分发将出现
        中间页提示下载风险 -->
        应用分发支持绑定您自己的域名，给您更好的用户下载体验！
      </div>
      <div class="btn2" @click="bindDemain">
        绑定域名
      </div>
    </div>
    <dialog-domain
      :dialogVisible="dialogVisible"
      @dialogChange="dialogChange"
      @getDemain="getDemain"
    ></dialog-domain>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialogDomain from "@/components/common/dialogDomain";
import { checkSLDBind, bindOrUnbindDomain } from "@/api/api";
export default {
  components: {
    dialogDomain,
  },
  data() {
    return {
      form: {
        domainState: "",
        cloudServiceType: "",
        SLD: "",
      },
      flag: false,
      dialogVisible: false,
    };
  },
  created() {
    this.getDemain();
  },
  computed: {
    ...mapGetters(['getDomainFlag'])
  },
  methods: {
    dialogChange(val) {
      this.dialogVisible = val;
    },
    // 绑定域名
    bindDemain() {
      this.$store.commit("domainStatusChange", 0);
      this.dialogVisible = true;
    },
    // 查询绑定
    getDemain() {
      checkSLDBind().then((res) => {
        if (res.code == 0) {
          this.$store.commit("domainFlagChange", false);
          this.form.cloudServiceType = res.data.info.cloudServiceType;
          this.form.SLD = res.data.info.sld;
        } else {
          this.$store.commit("domainFlagChange", true);
        }
      });
    },
    // 解除绑定
    resetClick() {
      this.$confirm("解除绑定域名后，分发将出现中间页提示下载风险", "提示", {
        confirmButtonText: "解绑",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.form.domainState = 1;
          bindOrUnbindDomain(this.form).then((res) => {
            if (res.code == 0) {
              this.$store.commit("domainStatusChange", 0);
              this.$message.success(res.msg);
              this.getDemain();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title2 {
  width: 320px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 30px 0;
}
.footer {
  width: 200px;
}
.tips {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #999999;
}
.btn2 {
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #0092ff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
}
.btn {
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #ff4a4a;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #ff4a4a;
  cursor: pointer;
}
.box {
  padding: 0 30px;
  overflow: hidden;
  height: 40px;
  text-align: center;
  background: #f1f6f9;
  border-radius: 4px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  /* identical to box height */

  color: #333333;
  margin: 30px 0;
}
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 920px;
  height: 460px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .title {
    font-size: 22px;
    font-weight: 700;
  }
}
</style>
