<template>
	<div class="content">
		<mobile-header></mobile-header>
		<img src="@/assets/image/noPage.png" class="img">
		<div class="tips">请登录电脑端操作</div>
	</div>
</template>

<script>
import mobileHeader from '@/views/mobilePage/common/mobileHeader.vue'
export default {
	components: {
		mobileHeader
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	height: 100vh;
	background: #fff;
}
.tips {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #0092FF;
}
.img {
	margin-top: 78px;
	height: 250px;
	margin-bottom: 10px;
}
</style>
