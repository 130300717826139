<template>
    <!-- language == 1中文 -->
    <div class="appDownDoc" v-show="language == 1">
      <div class="docTop">
        <h4 style="display: inline-block; font-weight: 400">
          "未受信任的企业级开发者"的解决方法
        </h4>
        <img
          @click="close"
          style="position: absolute; right: 20px; cursor: pointer"
          src="@/assets/image/docClose.png"
          alt=""
        />
      </div>
      <div class="content_box">
        <div class="doc">
          <p>
            因苹果政策原因，在iOS系统中首次安装企业版应用时会出现 “<b
              style="color: black"
              >未受信任的企业级开发者</b
            >”如图提示
          </p>
          <img src="@/components/contract/img/zx2_1.png" alt="" />
          <p>安装完成后需要根据以下步骤授权：</p>
          <p>
            1、在苹果手机中打开 <b style="color: black">设置</b> 功能，选择“<b
              style="color: black"
              >通用</b
            >”
          </p>
          <img src="https://s1.ax1x.com/2022/06/27/jVhKQP.jpg" alt="" />
          <p>
            2、在通用中，选择 <b style="color: black">VPN与设备管理</b> 功能
          </p>
          <img src="https://s1.ax1x.com/2022/06/27/jVhnzt.jpg" alt="" />
          <p>
            3、在<b style="color: black">VPN与设备管理</b>中，
            下拉到企业级App，选择同名的描述文件，点击进入。
          </p>
          <img src="https://s1.ax1x.com/2022/06/27/jVhmRI.jpg" alt="" />
          <p>4、根据提示 点击<b style="color: black">信任</b>即可。</p>
          <img src="https://s1.ax1x.com/2022/06/27/jVhMsf.png" alt="" />
        </div>
      </div>
      <div class="docBot">
        <div @click="close">关闭</div>
      </div>
    </div>
    <!-- language == 2 英文 -->
    <div class="appDownDoc" v-show="language == 2">
      <div class="docTop">
        <h4 style="display: inline-block; font-weight: 400">
          "Untrusted Enterprise Developer" solution
        </h4>
        <img
          @click="close"
          style="position: absolute; right: 20px; cursor: pointer"
          src="@/assets/image/docClose.png"
          alt=""
        />
      </div>
      <div class="content_box">
        <div class="doc">
          <p>
            solution Due to Apple's policy, the ”<b style="color: black"
              >Untrusted Enterprise Developer</b
            >” will appear when you install the Enterprise app for the first time
            in the iOS system
          </p>
          <img src="@/assets/image/01.jpg" alt="" />
          <p>The solution is as follows：</p>
          <p>
            1、Open the "<b style="color: black">Settings</b>" function in the
            iPhone and select “<b style="color: black">General</b>”
          </p>
          <img src="@/assets/image/02.jpg" alt="" />
          <p>
            2、In “<b style="color: black">General</b> ”", select "<b
              style="color: black"
              >VPN & Device Management</b
            >".
          </p>
          <img src="@/assets/image/03.jpg" alt="" />
          <p>
            3、In <b style="color: black">VPN & Device Management</b>， drop
            down to Enterprise Apps, select the description file , and click to
            enter.
          </p>
          <img src="@/assets/image/04.jpg" alt="" />
          <p>4、Click “<b style="color: black">Trust xxxx CO.,Ltd</b>”</p>
          <img src="@/assets/image/05.jpg" alt="" />
          <p>5、Return to the desktop to start the app</p>
        </div>
      </div>
      <div class="docBot">
        <div @click="close">Close</div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    language: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  created() {
    console.log(this.language, 2222222);
  },
  methods: {
    close() {
      this.$parent.isShowDoc = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/* 媒体查询：宽度大于720px */
@media (min-width: 480px) {
    .appDownDoc {
        /* 在宽度大于720px时设置宽度为450px */
        position: absolute;
        width: 450px !important;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.appDownDoc {
  position: absolute;
  top: 80px;
  // 居中
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background: #fff;
//   margin: 0 15px;
  box-sizing: border-box;
  z-index: 1000;
  border-radius: 12px;
}
.docTop {
  background: #0092ff;
  height: 44px;
  line-height: 44px;
  color: #fff;
  font-weight: 400;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.docBot {
  height: 46px;
  box-shadow: inset 0px 1px 0px #ececec;
  line-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cce9ff;
    border-radius: 4px;
    width: 100px;
    height: 33px;
    color: #0092ff;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
  }
}
.content_box {
  max-height: 550px;
  overflow: auto;
  padding: 0 15px;
}
.goback:hover {
  color: #1e9fff;
}
.goback {
  cursor: pointer;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  color: #666666;
  margin: 20px 0;
}
.title1 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;

  color: #333333;
}

p {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 35px;

  color: #555555;
}

img {
  max-width: 100%;
}
</style>