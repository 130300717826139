<template>
  <div class="content">
    <div class="title_1">常见问题及扣除规则</div>
    <div class="w docbox">
      <div class="title_2">1.为什么要限制下载流量？</div>
      <div class="title_3">
        1)根据我们的数据分析，当前每日的下载限制，已经可以满足 99% 的内测用户
        2)降低未被发现的违规应用造成的影响范围
      </div>
      <div class="title_2">2.为什么要限制应用包的保存时长？</div>
      <div class="title_3">
        1)应用内测是有周期的，一般应用的内测周期为 1 个月
        (可以通过更新，延长保存时间)<br />
        2)降低未被发现的违规应用造成的影响范围
      </div>
      <div class="title_2">3.需要更多的下载流量为什么要付费？</div>
      <div class="title_3">
        wkzx.wang的平台定位不是应用商店，如果要进行大范围应用公测，我们会根据您选择的不同流量包收取一定的费用用于支付七牛的流量费和托管费！
      </div>
      <div class="title_2">4.经过实名认证后，90 天有效期会有到期提醒吗？</div>
      <div class="title_3">
        会有，我们会通过在线消息或者邮件提醒。开发者可在网站应用管理页面点击“激活”，激活后应用可下载时间延期 90 天。有应用版本更新的话，会根据更新日期自动延期 90 天。
      </div>
      <div class="title_2">5.超过每日下载量限制后，应用就不能下载了对吗？</div>
      <div class="title_3">
        是的，超过限制后将不能下载，请根据实际测试需求提前充值购买流量包。
      </div>
      <div class="title_2">6.如何查看应用下载流量？</div>
      <div class="title_3">
        在应用管理页面的右上角有账号剩余下载流量显示。如果要针对某次测试分发统计（包含针对某个二维码的下载统计），可使用我们应用编辑页面的高级统计功能，生成统计链接。 付费开发者，有费用扣除发生后，我们会每日发送邮件告知前日下载统计。
      </div>
      <div class="title_2">7.如果应用有出现被竞争对手恶意刷量的情况怎么处理？</div>
      <div class="title_3">
        如果发现下载流量存在异常，可发送邮件给wkzx@wkzx.vip, 我们会排查原因，确认存在恶意刷量，我们会根据具体情况恢复下载流量。建议最好设置访客密码，或仅允许团队成员下载， 避免这种情况的出现。
      </div>
      <div class="title_2">8.购买下载流量包应该充值到哪个账户？</div>
      <div class="title_3">
        wkzx.wang上的下载流量统一从应用的管理员账户扣除，充值时请注意在管理员账户购买下载流量包，请勿在测试成员的账户下购买，否则，购买的下载流量包只能针对购买账户为应用管理员的应用使用。
      </div>
      <div class="title_2">9.购买的流量包使用期限是多久？</div>
      <div class="title_3">
        购买的流量是账号下所有应用的累计下载流量，用完即止，无过期时间。
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.docbox {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}
.content {
  overflow: hidden;
}
.title_1 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: #333333;
  margin: 30px 0;
}
.title_2 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 30px;
}
.title_3 {
  margin-top: 20px;
  font-size: 18px;
  line-height: 30px;
  color: #999999;
  margin-bottom: 30px;
}
</style>
