<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      title="新增渠道码"
      center
      width="460px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="dialog_center">
        <el-input placeholder="设置渠道码" v-model="formData.channel"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-loading="loading" @click="addInviteCode">立即添加</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="w">
      <div class="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }"
            >悟空在线
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/appManagement' }"
            >应用管理</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="productUrl">产品名称</el-breadcrumb-item>
          <el-breadcrumb-item>渠道码详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="content_header">
          <div class="content_title">渠道码详情</div>
          <div>
            <el-button type="primary" @click="add">新增渠道码</el-button>
          </div>
        </div>
        <div class="content_table">
          <el-table :data="tableData" border v-loading="loading">
            <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column label="渠道码" align="center" prop="remarks3"></el-table-column>
            <el-table-column label="分发链接" align="center" prop="remarks1"></el-table-column>
            <el-table-column label="应用名称" align="center" prop="softName"></el-table-column>
            <el-table-column label="创建时间" align="center" prop="createDate"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { channelList,reipa } from "@/api/api";
export default {
  name: "channelCodeDetail",
  data() {
    return {
      dialogVisible: false,
      formData: {
        channel: '',
        softId: ''
      },
      tableData: [],
      productUrl: null,
      softId: "",
      loading: false,
    };
  },
  created() {
    this.softId = this.$route.query.softId;
    this.formData.softId = this.$route.query.softId;
    this.productUrl = {
      path: "/editApp",
      query: {
        softId: this.softId,
      },
    };
    this.getChanneList()
  },
  methods: {
    add() {
      this.dialogVisible = true
    },
    // 添加qudaoma
    addInviteCode() {
      this.loading = true;
      reipa(this.formData).then(res => {
        this.dialogVisible = false
        if(res.code == 0) {
          this.$message.success('添加成功');
          this.loading = false;
          this.getChanneList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getChanneList() {
      this.loading = true;
      channelList({ softId: this.softId, pageNum: 1 }).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data.PageInfo.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 20px 0;
  font-size: 12px;
  color: #666666;
}
.content {
  height: 644px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 40px;
  box-sizing: border-box;
  padding: 30px;
  .content_header {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .content_title {
    font-weight: bold;
    font-size: 22px;
    color: #333;
    font-family: PingFang SC;
  }
}
::v-deep .el-dialog {
  border-radius: 20px;
}
</style>
