<template>
	<div>
		<div class="content_footer">
			<div style="margin-top: 20px">
				Copyright © 2020悟空在线 |<i><img style="width: 15px;height: 15px" src="../../assets/image/jinghui.png"
						alt="" /></i>
				豫公网安备41010502004005号 <span style="color:#C8C8C8;cursor: pointer;" @click="openNew" >豫ICP备{{icp}}</span> |
				增值电信业务经营许可证编号：豫B2-20191053
			</div>
			<div class="footer_img">
				<img src="../../assets/image/bottom_1.png" alt="" />
				<img src="../../assets/image/bottom_2.png" alt="" />
				<img src="../../assets/image/bottom_3.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "footer",
		data() {
			return {
				icp: "",
			}
		},
		methods: {
			route() { 
				// console.log("域名" + window.location.href);
				let url = window.location.href;
				let numsplit = url.split("//")[1];
				let numurl = numsplit.split("/")[0];
				if (numurl == "wkzx.wang" || numurl == "www.wkzx.wang") {
					this.icp = "18033521号-2";
				} else {
					this.icp = "18033521号-1";
				}
				// alert("第二次" + numurl);
				// console.log(numsplit);
			},
			openNew(){
				window.open('https://beian.miit.gov.cn/')
			}

		},
		mounted() {
			this.route();
		}
	};
</script>
<style lang="scss" scoped>
	.footer_img {
		img {
			width: 134px;
			height: 47px;
			margin: 7.5px 7.5px 0 7.5px;
		}
	}

	.content_footer {
		position: absolute;
		bottom: 0;
		width: 1200px;
		height: 100px;
		left: 50%;
		transform: translate(-50%);
		border-top: 1px solid#E1E1E1;
		font-size: 12px;
		color: #C8C8C8;
		text-align: center;
	}
</style>
