<template>
  <div>
    <div class="header">
      <img src="@/assets/image/banner_5.png" alt="" />
    </div>
    <div class="w">
      <div class="nav">
        <span style="float: left">合作伙伴</span>
        <span style="float: right">支持渠道</span>
      </div>
      <div class="nav_item">
        <div class="title1">合作伙伴</div>
        <div class="img">
          <!-- <img src="@/assets/image/jiujiu.png" @click="goUrl(1)" alt="" /> -->
          <img src="@/assets/image/sqj.png" @click="goUrl(2)" alt="" />
        </div>
        <hr />
        <div class="title1" style="margin-top: 30px">支持渠道</div>
        <div class="img">
          <img src="@/assets/image/aliyun.png" alt="" />
          <img src="@/assets/image/qiniu.png" alt="" />
          <img src="@/assets/image/apple.png" alt="" />
          <img src="@/assets/image/fengsheng.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "cooperative",
  methods: {
    goUrl(val) {
      if(val === 1) {
        window.open("http://www.99yx.com/");
      }
      if(val === 2) {
        window.open("https://hao.logosc.cn/")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_item {
  margin-bottom: 100px;
}
.img {
  margin-top: 40px;
  img {
    width: 152px;
    height: 72px;
    padding: 0 15px;
  }
}

.title1 {
  text-align: center;
  font-size: 36px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
}
.nav {
  height: 30px;
  font-size: 20px;
  width: 268px;
  color: rgba(0, 152, 221, 1);
  padding: 3px;
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  border-bottom: 1px solid rgba(0, 152, 221, 1);
  margin: 70px auto;
}
.header {
  img {
    width: 100%;
    height: 310px;
  }
}
</style>
