<template>
  <div class="content">
    <div class="title">举报</div>
    <div style="margin-top: 20px">
      作为一个第三方内测分发平台，我们一直致力于打击违规应用，保护知识产权。非常感谢您的举报，我们会在第一时间安排专人处理您举报的问题，并在处理之后向您反馈处理结果。
    </div>
    <div style="margin-top: 20px">
      感谢您对我们的支持，如果有问题欢迎拨打我们的热线电话：0371-86550361
    </div>
    <hr style="margin: 20px 0 25px 0" />

    <el-form ref="formData" :model="formData" label-width="85px" size="mini">
      <el-form-item label="您的手机号">
        <el-input v-model="formData.phone"></el-input>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="formData.code" style="width: 200px"></el-input>
        <el-button
          type="primary"
          style="float: right; width: 106px"
          @click="getCode"
          :disabled="!show"
          >获取验证码<span v-show="!show" class="count"
            >({{ count }}s)</span
          ></el-button
        >
      </el-form-item>
      <div style="height: 32px">
        <my-slider-captcha
          @isSuccess="isSuccess"
          @codeData="codeData"
          :scene="scene"
          :sliderWidth="334"
        ></my-slider-captcha>
      </div>
      <el-form-item label="举报原因">
        <el-radio-group v-model="formData.report">
          <el-radio :label="1">盗版</el-radio>
          <el-radio :label="2">色情</el-radio>
          <el-radio :label="3">欺诈</el-radio>
          <el-radio :label="4">其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="举报内容">
        <el-input
          v-model="formData.contentSupplement"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- <el-button type="text">上传附件</el-button> -->
    <el-upload
      class="upload-demo"
      :headers="myHeaders"
      :action="src + 'software/upload'"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      multiple
      :limit="2"
      :on-exceed="handleExceed"
      :file-list="fileList"
    >
      <el-button type="primary" size="mini">上传附件</el-button>
    </el-upload>
    <br />
    <span>为了能够更快处理您的举报信息，建议上传截图或其他相关文件等证据</span>
    <div class="footer_btn">
      <el-button type="primary" @click="saveData" size="mini">举报</el-button>
      <el-button @click="resetClick" size="mini">取消</el-button>
    </div>
  </div>
</template>

<script>
import MySliderCaptcha from "../../components/common/MySliderCaptcha.vue";
import BASE_URL from "@/utils/request.js";
import {
  getOssToken,
  reportCode,
  reportCodeVerify,
  reportApp,
} from "@/api/api";
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord } from "@/common/js/common.js";
export default {
  components: {
    MySliderCaptcha,
  },
  name: "report",
  data() {
    return {
      shortUrl: "",
      show: true,
      count: "",
      dialogVisible: false,
      softId: "",
      src: BASE_URL,
      formData: {
        phone: "",
        code: "",
        report: 1,
        contentSupplement: "",
        softId: "",
        files: [],
      },
      form: {
        iconurl: "",
        softname: "",
        publishName: "",
        fileSize: "",
        modifyDate: "",
        softUrlSmall: "",
      },
      fileList: [],
      token: "",
      myHeaders: null,
      timer: null,
      isUploadSuc: false,
      scene: 'report',
      checkData: null,
      checkSuccess: false,
    };
  },
  created() {
    this.formData.softId = this.$route.query.softId;
  },
  methods: {
    codeData(val) {
      this.checkData = val
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    // 取消
    resetClick() {
      this.$router.go(-1);
    },
    // 保存
    saveData() {
      if (this.formData.phone == "") {
        return this.$message.error("请输入手机号");
      }
      if (this.formData.code == "") {
        return this.$message.error("请输入验证码");
      }
      if (this.isUploadSuc) {
        return this.$message.error("请等待上传完成");
      }
      this.codeJiaoyan();
    },
    // 获取验证码
    getCode() {
      if (!this.formData.phone) return this.$message.warning("请输入手机号");
      if (this.checkSuccess) {
        reportCode({ phone: this.formData.phone,sessionId:this.checkData.sessionId, sig: this.checkData.sig, sessiontoken: this.checkData.token, scene: this.scene }).then((res) => {
          if (res.code == "1") {
            this.$message({
              type: "warning",
              duration: 2000,
              message: res.msg,
            });
          } else {
            this.timerChange();
          }
        });
      } else {
        this.$message.warning("请先通过滑块验证");
      }
      
    },
    codeJiaoyan() {
      if (this.formData.phone) {
        reportCodeVerify({
          phone: this.formData.phone,
          code: this.formData.code,
        }).then((res) => {
          if (res.code == 0) {
            // this.$message.success(res.msg);
            reportApp(this.formData).then((res) => {
              if (res.code == 0) {
                this.$message.success("举报成功");
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            return this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号",
        });
      }
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 删除文件
    handleRemove(file) {
      console.log(file);
    },
    //上传限制数
    handleExceed(files) {
      this.$message.warning(
        `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件`
      );
      //共选择了 ${files.length + fileList.length }个文件
    },
    //删除弹出框
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //上传文件调用
    beforeUpload(files) {
      this.isUploadSuc = true;
      //获取后缀名
      var fileName = files.name;
      var index = fileName.lastIndexOf(".");
      var suffix = fileName.substring(index, fileName.length); //后缀名
      fileName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(fileName, files)
            .then((res) => {
              if (res.url) {
                this.formData.files.push(res.url);
                this.isUploadSuc = false;
              } else {
                this.$message.error("文件上传失败");
                this.isUploadSuc = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
          this.isUploadSuc = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
}
::v-deep .el-form-item__label {
  text-align: left;
}
::v-deep .el-form-item {
  flex-direction: column;
}

.content {
  background: #fff;
  padding: 2rem;
  font-size: 15px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(136, 136, 136, 1);
  .title {
    text-align: center;
    margin-top: 0px;
    font-size: 22px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }
}
@media only screen and (min-width: 1024px) {
  .content {
    width: 500px;
    margin: 30px auto;
  }
}
</style>
