<template>
	<div>
		<div class="banner" @click="bannerClick">
			<img :src="bannerObj.image" style="width:100%;height:100%" />
		</div>
		<!-- 绑定域名判断v-if="demainFlag" -->
		<div class="tips_box" v-if="getDomainFlag" v-show="tokenFlag">
			<div>
				<span>
					<!-- 应用分发请绑定您自己的域名，使用平台域名进行分发将出现
          中间页提示下载风险 -->
					应用分发支持绑定您自己的域名，给您更好的用户下载体验！
				</span>
				<el-button style="margin-left: 20px" size="medium" @click="domainClick">立即绑定</el-button>
			</div>
		</div>
		<div class="content_header">
			<div class="nav_box">
				<div class="header_left" @click="goback">
					<img src="../../assets/image/logo.png" />
				</div>
				<div class="header_middle">
					<el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect">
						<el-menu-item v-for="item in navList" :key="item.id" :index="item.id">{{ item.name }}</el-menu-item>
					</el-menu>
				</div>
				<div class="header_right">
					<div class="have_token" v-if="token">
						<span style="margin-right: 15px">
							<el-popover placement="bottom" :width="270" trigger="hover">
								<template #reference>
									<el-badge is-dot class="item"><i class="iconfont icon-lingdang" @click="messageClick"></i></el-badge>
								</template>
								<div class="tip_box">
									<common-tips></common-tips>
									<div class="tip_footer">
										<div @click="seeAll">查看全部</div>
										<div @click="readAll">全部标记已读</div>
									</div>
								</div>
							</el-popover>
						</span>

						<el-popover placement="bottom" :width="160" trigger="hover">
							<template #reference>
								<span class="el-dropdown-link">{{ userName }}</span>
							</template>
							<div class="person_box">
								<div class="person_style" @click="handleCommand('a')">个人资料</div>
								<div class="person_style" @click="handleCommand('c')">退出</div>
							</div>
						</el-popover>
					</div>
					<div class="no_token" v-if="!token">
						<!-- <span style="margin-right: 30px;cursor: pointer;" @click="goLogin"
              >登录</span
            > -->
						<el-button type="primary" @click="handleSelect(8)">登录</el-button>
						<span style="margin-left: 20px;cursor: pointer;" @click="handleSelect(9)" v-show="showRegister">注册使用</span>
						<!-- <el-button @click="registerClick"
              >注册使用</el-button
            > -->
					</div>
				</div>
			</div>
		</div>
		<dialog-domain :dialogVisible="dialogVisible" @dialogChange="dialogChange" @getDemain="getDemain"></dialog-domain>
	</div>
</template>
<script>
	import {
		mapGetters,
		mapState
	} from "vuex";
	import dialogDomain from "./dialogDomain";
	import commonTips from "./tips";
	import {
		versionUpdate,
		readAll,
		checkSLDBind
	} from "@/api/api";
	export default {
		components: {
			commonTips,
			dialogDomain,
		},
		data() {
			return {
				tokenFlag: false,
				userName: "",
				bannerObj: {
					image: "",
					link: "",
				},
				// activeIndex: '',
				navList: [{
						id: 1,
						name: "首页",
					},
					{
						id: 2,
						name: "应用管理",
					},
					// {
					// 	id: 3,
					// 	name: "Web Clip",
					// },
					// {
					//   id: 4,
					//   name: "聚合码",
					// },
					{
						id: 5,
						name: "安装助手",
					},
					{
						id: 6,
						name: "价格",
					},
					{
						id: 7,
						name: "常见问题",
					},
				],
				// token: '',
				dialogVisible: false,
				demainFlag: false, // 二级域名是否绑定状态
			};
		},
		created() {
			// this.activeIndex = window.localStorage.getItem('activeIndex');
			// this.getDemain();
			console.log(this.token);
		},
		updated(){
			console.log("更新了");
			this.getUserInfo();
		},
		computed: {
			...mapGetters(["getDomainFlag"]),
			...mapState(["activeIndex"]),
			...mapState(["token"]),
		},
		mounted() {
			this.getBanner();
			this.getToken();
			this.getUserInfo();
			
		},
		methods: {
			// 查看是否绑定二级域名
			getDemain() {
				checkSLDBind().then((res) => {
					if (res.code == 0) {
						this.$store.commit("domainFlagChange", false);
					} else {
						this.$store.commit("domainFlagChange", true);
					}
				});
			},
			dialogChange(val) {
				this.dialogVisible = val;
			},
			// 打开域名绑定弹窗
			domainClick() {
				this.dialogVisible = true;
			},
			// 消息中心
			seeAll() {
				this.$router.push("/messageCore");
			},
			// 标记已读
			readAll() {
				readAll().then((res) => {
					if (res.code == 0) {
						this.$message.success(res.msg);
					} else {
						this.$message.success(res.msg);
					}
				});
			},
			// 获取头部用户信息
			getUserInfo() {
				let user = [];
				user = JSON.parse(localStorage.getItem("userInfo"));
				console.log(user);
				if (user) {
					if (user[0].userName.includes("@")) { //判断是否是邮箱账号
						this.userName =
							user[0].userName.substr(0, 3) +
							"***" +
							user[0].userName.split("@")[1];
					} else { //判断是否为手机账号
						this.userName =
							user[0].userName.substr(0, 3) +
							"***" +
							user[0].userName.substr(7, 4);
					}
				}
			},
			// 获取token 显示用户信息
			getToken() {
				//取出session
				let token2 = window.localStorage.getItem("token");
				this.$store.commit("tokenChange", token2);
				//   this.$store.commit("navActiveChange", '');
				if (!this.token) {
					this.tokenFlag = false;
				} else {
					this.tokenFlag = true;
					this.getDemain();
				}
			},
			handleCommand(command) {
				if (command == "a") {
					this.$store.commit("navActiveChange", "");
					// window.localStorage.setItem('activeIndex', '');
					// this.activeIndex = window.localStorage.getItem('activeIndex');
					this.$router.push({
						path: "/personalData",
						query: {
							status: 1,
						},
					});
				}
				if (command == "c") {
					this.$confirm("是否退出登录?", "提示", {
							confirmButtonText: "是",
							cancelButtonText: "否",
							type: "warning",
						})
						.then(() => {
							localStorage.clear();
							this.$store.commit("tokenChange", "");
							this.$router.push("/home");
						})
						.catch(() => {
							this.$message({
								type: "info",
								message: "取消退出登录",
							});
						});
				}
			},
			bannerClick() {
				window.open(this.bannerObj.link);
			},
			// 获取顶部图片
			getBanner() {
				versionUpdate().then((res) => {
					this.bannerObj.image = res.data.image;
					this.bannerObj.link = res.data.link;
				});
			},
			handleSelect(keyPath) {
				// console.log(window.localStorage.getItem("authentication"))

				this.$store.commit("navActiveChange", keyPath);
				if (keyPath == 6) {
					return	this.$router.push("/price");
				}
				if (keyPath == 7) {
					// window.open('https://support.qq.com/products/349734/blog-archive')
					// this.$router.push("/installDoc");
					return	this.$router.push("/contract/blogGroup");
				}
				// if (this.$store.state.authentication == 2) {
				// 	return this.$router.push('/lock/lockUser')
				// }
				if(window.localStorage.getItem("authentication")==4){
					return this.$router.push('/lock/lockUser')
				}
				// if (this.$store.state.authentication == 4) {
				// 	return this.$router.push('/lock/lockUser')
				// }
				// this.activeIndex = keyPath;
				if (keyPath == 1) {
					this.$router.push("/home");
				}
				if (keyPath == 2) {
					this.$router.push("/appManagement");
				}
				if (keyPath == 3) {
					this.$router.push("/webClip");
				}
				if (keyPath == 4) {
					this.$router.push("/aggregateCode");
				}
				if (keyPath == 5) {
					this.$router.push("/installAssistant");
				}

				if (keyPath == 8) { //登录
					this.$router.push("/login");
				}
				if (keyPath == 9) { //注册
					this.$router.push("/register");
				}
			},
			// goLogin() {
			// 	this.$router.push("/login");
			// },
			// // 注册
			// registerClick() {
			// 	this.$router.push("/register");
			// },
			goback() {
				if(window.localStorage.getItem("authentication")==4){
					return this.$router.push('/lock/lockUser')
				}
				this.$router.push("/home");
			},
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .el-menu-item {
		margin: 0 10px !important;
	}

	.person_box {
		padding: 5px 0;
	}

	.person_style {
		width: 160px;
		height: 40px;
		border-radius: 6px;
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 40px;
		text-align: center;
		color: #666666;
		cursor: pointer;
	}

	.person_style:hover {
		background: #F1F6F9;
	}

	::v-deep .is-active {
		font-weight: 700;
	}

	.header_right {
		margin-right: 80px;
	}

	::v-deep .el-menu--horizontal>.el-menu-item {
		line-height: 80px;
		height: 80px;
	}

	.tip_box {
		position: relative;
		padding: 15px 10px 0 10px;
		height: 326px;

		.tip_footer {
			position: absolute;
			z-index: 999;
			bottom: 0;
			width: 250px;
			display: flex;
			justify-content: space-between;
			height: 30px;
			font-family: PingFang SC;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 30px;
			/* identical to box height */
			color: #666666;

			div {
				cursor: pointer;
			}

			div:hover {
				color: #0092ff;
			}
		}
	}

	.tips_box {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 52px;
		line-height: 52px;
		background: #ff5656;
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #fff;
	}

	.banner {
		overflow: hidden;
		height: 80px;

		.img {
			width: 100%;
			height: 100%;
		}
	}

	.content_header {
		overflow: hidden;
		//   position: fixed;
		//   top: 0;
		width: 100%;
		height: 80px;
		background: #fff;

		.nav_box {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.header_left {
				margin-left: 80px;

				img {
					width: 142px;
					height: 40px;
				}
			}

			.header_middle {
				display: flex;
				justify-content: center;
				width: 760px;
				font-size: 14px;
			}
		}
	}
</style>
