<template>
  <sell-template
    v-if="sellFlag"
    :UDID="UDID"
    :userId="form.userId"
    :shortUrl="form.softUrlSmall"
  ></sell-template>
  <div v-else class="content">
    <contractTopVue
      :info="form"
      v-if="form.wx != '' || form.infoqq != ''"
    ></contractTopVue>
    <el-dialog
      title="举报"
      center
      v-model="dialogVisible"
      :close-on-click-modal="false"
      width="630px"
      :before-close="handleClose"
    >
      <div class="dialog_box">
        <div class="dialog_header">
          <span
            >作为一个第三方内测分发平台，我们一直致力于打击违规应用，保护知识产权。非常感谢您的举报，我们会在第一时间安排专人处理您举报的问题，并在处理之后向您反馈处理结果。</span
          >
          <br />
          <span style="margin-top: 20px; display: block; font-size: 14px"
            >感谢您对我们的支持，如果有问题欢迎拨打我们的热线电话：0371-86550361</span
          >
        </div>
        <hr style="margin: 20px 0" />
        <el-form ref="formData" :model="formData" label-width="90px">
          <el-form-item label="您的手机号">
            <el-input v-model="formData.phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <el-input v-model="formData.code" style="width: 280px"></el-input>
            <el-button
              type="primary"
              style="float: right; width: 144px"
              @click="getCode"
              :disabled="!show"
              >获取验证码<span v-show="!show" class="count"
                >({{ count }}s)</span
              ></el-button
            >
          </el-form-item>
          <div style="height: 32px">
            <my-slider-captcha
              @isSuccess="isSuccess"
              @codeData="codeData"
              :scene="scene"
              :sliderWidth="530"
            ></my-slider-captcha>
          </div>
          <el-form-item label="举报原因">
            <el-radio-group v-model="formData.report">
              <el-radio :label="1">盗版</el-radio>
              <el-radio :label="2">色情</el-radio>
              <el-radio :label="3">欺诈</el-radio>
              <el-radio :label="4">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="举报内容">
            <el-input
              v-model="formData.contentSupplement"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-button type="text">上传附件</el-button> -->
        <el-upload
          class="upload-demo"
          :headers="myHeaders"
          :action="src + 'software/upload'"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :before-upload="beforeUpload"
          multiple
          :limit="2"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button type="primary">上传附件</el-button>
        </el-upload>
        <br />
        <span
          >为了能够更快处理您的举报信息，建议上传截图或其他相关文件等证据</span
        >
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveData">举报</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="logo">
      <img :src="form.iconurl" />
    </div>
    <div class="title">
      <span
        class="iconfont icon-pingguo icstyle"
        v-show="form.fittype == 'IOS'"
      ></span>
      <span
        class="iconfont icon-android-fill icstyle"
        v-show="form.fittype == 'Android'"
      ></span>
      <span>&nbsp;{{ form.softname }}</span>
    </div>
    <div class="verison" v-show="languageToggle == 1">
      版本： {{ form.publishName }}
    </div>
    <div class="verison" v-show="languageToggle == 2">
      versions： {{ form.publishName }}
    </div>
    <div class="font_style" v-show="languageToggle == 1">
      大小：{{ form.fileSize }} 更新时间：{{ form.chDate }}
    </div>
    <div class="font_style" v-show="languageToggle == 2">
      size：{{ form.fileSize }} time：{{ form.chDate }}
    </div>
    <!-- 激活码邀请码输入框 -->
    <div v-if="isShowInput">
      <el-input
        v-model="password"
        style="width: 250px; display: block"
        :placeholder="tips"
      ></el-input>
      <el-button
        v-if="form.actcode == 1 && languageToggle == 1"
        type="primary"
        style="width: 148px; margin: 10px 0 10px 50px"
        round
        @click="actSave"
        >确认</el-button
      >
      <el-button
        v-if="form.actcode == 1 && languageToggle == 2"
        type="primary"
        style="width: 148px; margin: 10px 0 10px 50px"
        round
        @click="actSave"
        >confirm</el-button
      >
      <el-button
        v-if="form.actcode != 1 && languageToggle == 1"
        type="primary"
        style="width: 148px; margin: 10px 0 10px 50px"
        round
        @click="save"
        >确认</el-button
      >
      <el-button
        v-if="form.actcode != 1 && languageToggle == 2"
        type="primary"
        style="width: 148px; margin: 10px 0 10px 50px"
        round
        @click="save"
        >confirm</el-button
      >
    </div>
    <div
      class="downClass"
      v-if="!isShowInput && languageToggle == 1 && downFlag == 0"
    >
      <div
        class="ljInstall"
        style="margin-right: 20px"
        @click="appleDown"
        v-show="
          (form.fittype == 'IOS' && form.softId != 0) ||
          (form.fittype == 'Android' && form.cpSoftId != 0)
        "
      >
        <i :class="appleClass" style="font-size: 20px"></i>
        <span>苹果下载</span>
      </div>
      <!-- <div class="ljInstall" @click="clickFile" >
        立即下载
      </div> -->
      <div
        class="ljInstall"
        @click="andriodDown"
        v-show="
          (form.fittype == 'Android' && form.softId != 0) ||
          (form.fittype == 'IOS' && form.cpSoftId != 0)
        "
      >
        <i :class="andriodClass" style="font-size: 20px"></i>
        <span>安卓下载</span>
      </div>
    </div>

    <div
      v-if="!isShowInput && languageToggle == 2 && downFlag == 0"
      class="downClass"
    >
      <div class="ljInstall" style="margin-right: 20px" @click="appleDown">
        <i :class="appleClass" style="font-size: 20px"></i>
        <span>IOS</span>
      </div>
      <!-- <div class="ljInstall" @click="clickFile" >
        立即下载
      </div> -->
      <div class="ljInstall" @click="andriodDown">
        <i :class="andriodClass" style="font-size: 20px"></i>
        <span>Android</span>
      </div>
    </div>
    <div
      v-if="languageToggle == 1 && downFlag == 3"
      class="ljInstall"
      style="background: #ba812f"
      @click="sellClick"
    >
      购买应用
    </div>
    <div
      v-if="languageToggle == 2 && downFlag == 3"
      class="ljInstall"
      style="background: #ba812f"
      @click="sellClick"
    >
      Buy apps
    </div>
    <div class="erwei">
      <div id="qrCode" ref="qrCodeDiv"></div>
      <!-- <img :src="form.iconurl" alt=""> -->
    </div>
    <!-- <div
      style="font-size: 14px;margin-bottom: 25px;"
      v-show="languageToggle == 1"
    >
      扫描二维码下载
    </div>
    <div
      style="font-size: 14px;margin-bottom: 25px;"
      v-show="languageToggle == 2"
    >
      Or use your mobile browser :
    </div> -->
    <!-- form.softUrlSmall.length > 4 ? form.softUrlSmall : -->
    <div
      class="cxt"
      v-if="form.fittype == 'IOS' && !superShow && languageToggle == 1"
      @click="installClick"
    >
      "未受信任的企业及开发者"的解决办法
    </div>
    <div
      class="cxt"
      v-if="form.fittype == 'IOS' && !superShow && languageToggle == 2"
      @click="installClick"
    >
      Solutions for "untrusted enterprises and developers"
    </div>
    <!-- <div style="font-size: 14px;">
      或使用浏览器打开 :{{  form.shortLink }}
    </div> -->
    <div class="footerBox">
      <div
        class="w closeBox"
        style="margin-bottom: 20px"
        v-if="bannerFlag && bannerList2.length > 0"
      >
        <!-- v-show="bannerFlag" -->
        <el-carousel
          indicator-position="none"
          height="100px"
          trigger="click"
          autoplay
          arrow="never"
          v-show="bannerList2[0].imgUrl != null"
        >
          <el-carousel-item v-for="(item, index) in bannerList2" :key="index">
            <img
              class="banner_img"
              :src="item.imgUrl"
              alt=""
              @click="bannerClick(item.link)"
            />
          </el-carousel-item>
        </el-carousel>
        <img
          class="closeBtn"
          @click="closeBanner"
          src="@/assets/image/close-btn.png"
          style="width: 20px; height: 20px"
          alt=""
        />
      </div>
      <div class="footer">
        <div class="w">
          <div class="footer_left" v-show="languageToggle == 1">
            <span
              style="margin-right: 20px; cursor: pointer"
              @click="exemptionClick"
              >免责声明</span
            >
            <span style="color: #333">|</span>
            <span
              style="margin-left: 20px; cursor: pointer"
              @click="reportClick"
              >举报</span
            >
          </div>
          <div class="footer_left" v-show="languageToggle == 2">
            <span
              style="margin-right: 20px; cursor: pointer"
              @click="exemptionClick"
              >disclaimer</span
            >
            <span style="color: #333">|</span>
            <span
              style="margin-left: 20px; cursor: pointer"
              @click="reportClick"
              >report</span
            >
          </div>
          <div class="footer_right">
            <img
              src="../../../assets/image/china.png"
              style="width: 21px; height: 14px; margin-right: 10px"
            />
            <span @click="languageClick(1)" style="cursor: pointer">中文</span>
            <span style="margin: 0 20px; color: #333">|</span>
            <img
              src="../../../assets/image/usa.png"
              style="width: 21px; height: 14px; margin-right: 10px"
            />
            <span @click="languageClick(2)" style="cursor: pointer"
              >English</span
            >
          </div>
        </div>
        <div class="w">
          <div
            class="tips"
            style="color: #999; margin-top: 10px"
            v-show="languageToggle == 1"
          >
            提示：该应用来源于第三方产品，内容不受悟空在线控制，请您仔细甄别，审慎安装。悟空在线仅为信息服务平台，与第三方应用之间不存在任何关联关系，
            不提供任何担保或承诺。悟空在线不为因此产生的任何投资、购买行为承担任何责任。
            <div v-show="form.icp">APP备案:{{ form.icp }}</div>
            
          </div>
          <div
            class="tips"
            style="color: #999; margin-top: 10px"
            v-show="languageToggle == 2"
          >
            Tip: this application comes from a third-party product, and its
            content is not controlled by Wukong online. Please carefully
            identify and carefully install it. Wukong online is only an
            information service platform, and has no relationship with
            third-party applications, and does not provide any guarantee or
            commitment. Wukong online will not bear any responsibility for any
            investment and purchase behavior arising therefrom!
          </div>
        </div>
      </div>
    </div>
    <!-- //超级签 -->
    <div class="content">
      <el-dialog
        v-model="DowndialogVisible"
        :close-on-click-modal="false"
        v-if="isShow"
        :show-close="false"
        width="400px"
      >
        <div class="modal-body">
          <div class="modal_item">
            <span class="china">配置文件中</span>
            <!-- <span class="usa">In profile</span> -->
            <img
              ref="a"
              class="img_dialog"
              src="../../../assets/image/right_1.png"
              style="display: none"
            /><img
              ref="a1"
              class="img_dialog"
              src="../../../assets/image/right_2.png"
            /><img
              ref="a2"
              class="img_dialog"
              src="../../../assets/image/loading1.gif"
              style="display: none"
            />
          </div>
          <br />
          <div class="modal_item">
            <span class="china">校验中</span>
            <!-- <span class="usa">Check in</span> -->
            <img
              ref="b"
              class="img_dialog"
              src="../../../assets/image/right_1.png"
              style="display: none"
            /><img
              ref="b1"
              class="img_dialog"
              src="../../../assets/image/right_2.png"
              style="display: none"
            /><img
              ref="b2"
              class="img_dialog"
              src="../../../assets/image/loading1.gif"
            />
          </div>

          <br />
          <div class="modal_item">
            <span class="china">正在下载</span>
            <!-- <span class="usa">Downloading</span> -->
            <img
              ref="c"
              class="img_dialog"
              src="../../../assets/image/right_1.png"
            /><img
              ref="c1"
              class="img_dialog"
              src="../../../assets/image/right_2.png"
              style="display: none"
            /><img
              ref="c2"
              class="img_dialog"
              src="../../../assets/image/loading1.gif"
              style="display: none"
            />
          </div>

          <br />
          <h4 style="color: #0098dd; text-align: center; display: none">
            <span class="china">请不要离开此页面并保持屏幕常亮</span>
            <!-- <span class="usa"
              >Please do not leave this page and keep the screen on</span
            > -->
          </h4>
        </div>
      </el-dialog>
      <el-dialog
        v-model="dialogBanner"
        :close-on-click-modal="false"
        :show-close="false"
        width="400px"
      >
        <div style="height: 400px; position: relative">
          <div style="height: 230px; background: #3abdf8; width: 100%">
            <div style="width: 175px; margin: auto">
              <el-carousel trigger="click">
                <el-carousel-item
                  v-for="(item, index) in bannerList"
                  :key="index"
                >
                  <img
                    class="banner_img"
                    :src="item.imgUrl"
                    alt=""
                    style="width: 175px; height: 230px"
                  />
                  <h3>{{ item.title1 }}</h3>
                  <h4 style="height: 45px">{{ item.title2 }}</h4>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div
            style="
              position: absolute;
              bottom: 20px;
              left: 50%;
              transform: translate(-50%, 0);
            "
          >
            <el-button
              type="primary"
              round
              style="width: 250px"
              @click="nextInstall"
              >继续安装</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <app-DownDoc v-if="isShowDoc" :language="languageToggle"></app-DownDoc>
    <div class="modal" v-if="isShowDoc"></div>
  </div>
</template>

<script>
import MySliderCaptcha from "../../../components/common/MySliderCaptcha.vue";
// import notFount from '@/views/notFount/404.vue'
import BASE_URL from "@/utils/request.js";
import contractTopVue from "../../../components/common/contractTop.vue";
// import mbcontractTopVue from "../../../components/common/mbcontractTop.vue";
import appDownDoc from "@/views/appDown/template/appDownDoc";
import sellTemplate from "@/views/appDown/template/sellTemplate";

import {
  getOssToken,
  preview,
  reportCode,
  reportCodeVerify,
  reportApp,
  getPCDBAdvertising,
  // vipDownInfo,
  downConsumptionFlow,
  softConformInstallIf,
  MobilecheckActCode,
  MobilecheckInviteCode,
  checkEquipmentNum,
  signatureUDID,
  checkStatus,
  verifyPersonUDIDPay,
} from "@/api/api";
import mixin from "@/mixin"; // 引入mixin文件

import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord, isEmpty, domainName } from "@/common/js/common.js";
import QRCode from "qrcodejs2";
export default {
  mixins: [mixin],
  components: {
    contractTopVue,
    appDownDoc,
    sellTemplate,
    MySliderCaptcha
  },
  props: {
    vip: {
      type: Boolean,
      default: false,
    },
    appInfo: {
      type: Object,
    },
    info: {
      type: Object,
    },
    shortUrl: {
      type: String,
      default: "",
    },
    UDID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scene: "report",
      checkData: null,
      checkSuccess: false,
      andriodClass: "iconfont icon-android-fill",
      appleClass: "iconfont icon-pingguo",
      sellFlag: false,
      languageToggle: 1,
      imageList: "",
      show: true,
      count: "",
      dialogVisible: false,
      dialogBanner: false,
      softId: "",
      src: BASE_URL,
      formData: {
        phone: "",
        code: "",
        report: 1,
        contentSupplement: "",
        softId: "",
        files: [],
      },
      form: {
        iconurl: "",
        softname: "",
        publishName: "",
        fileSize: "",
        modifyDate: "",
        softUrlSmall: "",
      },
      fileList: [],
      adkey: '',
      token: "",
      myHeaders: null,
      timer: null,
      status: 1,
      webClip: {},
      passwordObj: {},
      vipId: "",
      bannerList2: [],
      bannerFlag: false, // 轮播图状态
      superShow: false,
      isShowDoc: false,
      password: "",
      isShowInput: false,
      DowndialogVisible: false, //配置文件,校验中
      qqAndWxStatus: 0,
      sellFlag: false,
      isShow: true, //进度条是否显示
      stopCheckState: "", //停止轮询变量
      isUploadSuc: false,
      downFlag: 0,
      bannerList: [
        {
          id: 1,
          imgUrl: require("../../../assets/image/vip_1.png"),
          title1: "第一步",
          title2: "点击“允许”进行下一步操作。",
        },
        {
          id: 2,
          imgUrl: require("../../../assets/image/vip_2.png"),
          title1: "第二步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 3,
          imgUrl: require("../../../assets/image/vip_3.png"),
          title1: "第三步",
          title2: "点击“关闭”弹窗，然后进行下一步操作。",
        },
        {
          id: 4,
          imgUrl: require("../../../assets/image/vip_4.png"),
          title1: "第四步",
          title2: "点击右上角“安装”，然后进行下一步操作。",
        },
        {
          id: 5,
          imgUrl: require("../../../assets/image/vip_5.png"),
          title1: "第五步",
          title2: "点击“安装”即可。",
        },
      ],
    };
  },
  async created() {
    this.vipId = this.$route.query.vipId;
    console.log(this.info);
    if (this.vip) {
      this.form = this.info;
      // 修改域名
      const res1 = await domainName(this.form.userId, this.form.shortLink);
      this.form.shortLink = res1;
      console.log(this.form);
      if (this.form.automaticallyRefresh == 1) {
        this.superShow = true;
      }
      if (isEmpty(this.form.wx)) {
        this.form.wx = "";
      }
      if (isEmpty(this.form.infoqq)) {
        this.form.infoqq = "";
      }
      console.log(this.form);
      // console.log(this.shortUrl)
      // document.title = this.form.softname;
      this.getVipAppUrl();
    } else {
      this.form = this.info;
      // 修改域名
      const res = await domainName(this.form.userId, this.form.shortLink);
      this.form.shortLink = res;
      console.log(this.form);
      console.log(11111111111111111);
      if (isEmpty(this.form.wx)) {
        this.form.wx = "";
      }
      if (isEmpty(this.form.infoqq)) {
        this.form.infoqq = "";
      }
      console.log(this.form);
      console.log(domainName(this.info.userId, this.info.shortLink));
      this.getVipAppUrl();
      // this.form.shortLink = domainName(this.form.userId,this.form.shortLink);
      // console.log(this.form.shortLink)
      // this.getAppShow(); //单独调用数据显示form
    }
    if (this.form.openPassword != 0) {
      this.tips = "请输入密码";
      this.isShowInput = true;
    } else if (this.form.remarks4 == 1) {
      this.tips = "请输入邀请码";
      this.isShowInput = true;
    } else if (this.form.actcode == 1) {
      this.tips = "请输入激活码";
      this.isShowInput = true;
    } else {
      this.tips = "";
      this.isShowInput = false;
    }
    this.token = window.localStorage.getItem("token");
    this.myHeaders = { Authorization: this.token };
    this.getPCAdvertising();

    this.vipId = this.$route.query.vipId;
    if (this.vipId) {
      this.isShowInput = false;
      this.vipDownCheck();
    }
  },
  methods: {
    codeData(val) {
      this.checkData = val;
    },
    isSuccess(val) {
      this.checkSuccess = val;
    },
    // 苹果下载
    appleDown() {
      let softId = "";
      if (this.form.fittype == "IOS") {
        softId = this.form.softId;
        // 普通下载
        if (this.form.automaticallyRefresh == 0) {
          //下载消耗流量
          downConsumptionFlow({
            softId: softId,
            userId: this.form.userId,
            adkey: this.adkey
            // type: 1,
          }).then((res) => {
            console.log(res);
            if (res.code == 0) {
              // window.location.href = this.publishList[0].downUrl;
              window.location.href = res.data.downUrl;
            } else {
              this.$message.error(res.msg);
            }
          });
        }

        if (this.form.automaticallyRefresh == 1) {
          if (!this.getVipUserAgent()) {
            this.$message.error(
              "该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。"
            );
            return;
          }
          this.vipDown(softId);
        }
      } else {
        softId = this.form.cpSoftId;
        // vip下载
        if (this.form.cpautomaticallyRefresh == 0) {
          //下载消耗流量
          downConsumptionFlow({
            softId: softId,
            userId: this.form.userId,
            adkey: this.adkey
            // type: 1,
          }).then((res) => {
            console.log(res);
            if (res.code == 0) {
              // window.location.href = this.publishList[0].downUrl;
              window.location.href = res.data.downUrl;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
        if (this.form.cpautomaticallyRefresh == 1) {
          if (!this.getVipUserAgent()) {
            this.$message.error(
              "该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。"
            );
            return;
          }
          this.vipDown(softId);
        }
      }
    },
    // 安卓下载
    andriodDown() {
      let softId = "";
      if (this.form.fittype == "Android") {
        softId = this.form.softId;
      } else {
        softId = this.form.cpSoftId;
      }
      //下载消耗流量
      downConsumptionFlow({
        softId: softId,
        userId: this.form.userId,
        adkey: this.adkey
        // type: 1,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // window.location.href = this.publishList[0].downUrl;
          window.location.href = res.data.downUrl;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关闭轮播图
    closeBanner() {
      this.bannerFlag = false;
    },
    // 轮播图跳转
    bannerClick(val) {
      window.open(val);
    },
    vipDownCheck() {
      //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
      var udid = localStorage.getItem("udidcache");
      if (
        udid != "undefined" &&
        udid != null &&
        this.UDID != null &&
        this.UDID != ""
      ) {
        if (this.form.openPay == 1) {
          verifyPersonUDIDPay({
            userId: this.form.userId,
            udid: this.UDID,
          }).then((res) => {
            if (res.code != 0) {
              this.isShow = false;
              var ua = navigator.userAgent.toLowerCase();
              if (
                ua.match(/safari/i) == "safari" &&
                ua.match(/mqqbrowser/i) != "mqqbrowser"
              ) {
                this.downFlag = 3;
                this.$message.error("此应用已开启付费下载，请购买后安装");
              }
            } else {
              this.downFlag = 0;
              this.getCheckEquipmentNum();
            }
          });
        } else {
          this.getCheckEquipmentNum();
        }
      }
    },
    //校验设备
    getCheckEquipmentNum() {
      // if (this.form.remarks4 == 1) {
      //校验设备
      checkEquipmentNum({ softId: this.form.softId, UDID: this.UDID }).then(
        (res) => {
          if (res.code == 0) {
            //签名
            signatureUDID({
              softId: this.form.softId,
              UDID: this.UDID,
              softUrl: this.form.softUrlSmall,
            }).then((res) => {
              if (res.code != 0) {
                this.dialogBanner = false;
                this.$message.error(res.msg);
              } else {
                if (res.data.data.result == "downing") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.$message.error("有用户正在下载该应用，请稍后重试");
                } else if (res.data.data.result == "fail") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.DowndialogVisible = false;
                  this.$message.error(res.data.data.msg);
                } else {
                  this.dialogBanner = false;
                  this.DowndialogVisible = true;
                  // this.$message.success(res.msg);
                }
              }
            });
            this.stopCheckState = setInterval(() => {
              this.checkStatus();
            }, 3000); //轮询查看vip安装进度
            localStorage.setItem("udidcache", this.UDID); //缓存浏览器
          } else {
            this.DowndialogVisible = false;
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 继续安装
    nextInstall() {
      window.location.href = this.form.mobileconfigUrl;
      setTimeout(function () {
        window.location.href = "https://p.udid.store/embedded.mobileprovision";
      }, 2 * 1000); //延
    },
    // 获取轮播图
    getPCAdvertising() {
      getPCDBAdvertising().then((res) => {
        if (!isEmpty(res.data)) {
          this.bannerList2 = res.data.advertisings;
          this.bannerFlag = true;
        } else {
          this.bannerFlag = false;
        }
        if (res.code == 401) {
          // this.$message.error(res.msg)
          localStorage.clear();
          this.$router.push("/login");
        }
      });
    },
    save() {
      console.log(this.form);
      console.log(66666666666666666666666666);
      let type = "";
      if (this.form.openPassword == 1) {
        type = 0;
      } else if (this.form.remarks4 == 1) {
        type = 1;
      }
      MobilecheckInviteCode({
        softId: this.form.softId,
        inviteCode: this.password,
        type: type,
      }).then((res) => {
        if (res.code == 0) {
          this.adkey = res.msg
          this.isShowInput = false;
          if (type == 0) {
            this.$router.push({
              path: "/templateMerge",
              query: {
                softId: this.form.softId,
                softUrl: this.form.softUrlSmall,
                adkey: this.adkey,
              },
            });
          } else {
            this.$router.push({
              path: "/invitation/" + this.password,
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    actSave() {
      MobilecheckActCode({
        softId: this.form.softId,
        actCode: this.password,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.isShowInput = false;
          this.$router.push({
            path: "/active/" + this.password,
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    installClick() {
      this.isShowDoc = true;
    },
    downFile() {
      //下载消耗流量
      downConsumptionFlow({
        softId: this.form.softId,
        userId: this.form.userId,
        adkey: this.adkey
        // type: 1,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // window.location.href = this.publishList[0].downUrl;
          window.location.href = res.data.downUrl;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sellClick() {
      this.sellFlag = true;
    },
    clickFile() {
      // if(this.downFlag ==3) {
      //   this.$message.error("此应用已开启付费下载，请购买后安装");
      // }
      var ua = navigator.userAgent.toLowerCase();
      console.log(ua);
      if (this.form.automaticallyRefresh == 1) {
        if (!this.getVipUserAgent()) {
          this.$message.error(
            "该浏览器暂不支持安装App。请在Safari浏览器中打开即可安装。"
          );
          return;
        }
        this.vipDown(this.form.softId);
        // if (
        //   /Safari/.test(navigator.userAgent) &&
        //   !/Chrome/.test(navigator.userAgent)
        // ) {
        //   this.downFile();
        // } else {
        //   return this.$message.error("请在Safari浏览器中打开下载");
        // }
      } else {
        //校验应用是否符合下载标准
        softConformInstallIf({ softId: this.form.softId }).then((res) => {
          if (res.code != 0) {
            this.$message.error(res.msg);
            return;
          }
        });
        this.downFile();
      }
    },
    checkStatus() {
      //查看vip安装进度
      checkStatus({ softId: this.form.softId, UDID: this.UDID }).then((res) => {
        var status = res.data.result;
        if (status == "1") {
          this.$refs.a2.style.display = "none";
          this.$refs.a1.style.display = "block";
          this.$refs.b.style.display = "none";
          this.$refs.b2.style.display = "block";
        }
        if (status == "2") {
          this.$refs.b2.style.display = "none";
          this.$refs.b1.style.display = "block";
          this.$refs.c2.style.display = "block";
          this.$refs.c.style.display = "none";
        }
        if (status == "3") {
          this.$refs.c2.style.display = "none";
          //获取存放在隐藏域的itemservice链接
          var itemservice = res.data.itemservice;
          if (isEmpty(itemservice)) {
            return;
          }
          clearInterval(this.stopCheckState); //清除定时轮询
          this.DowndialogVisible = false;
          window.location.href = itemservice;
        }
      });
    },
    vipDown(softId) {
      //校验应用是否符合下载标准
      softConformInstallIf({ softId: softId }).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg);
          return;
        }
      });
      var ua = navigator.userAgent.toLowerCase();
      if (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      ) {
        if (ua.indexOf(" mac os x") > 0) {
          if (!navigator.cookieEnabled) {
            this.$message.error("你的浏览器已禁用cookie");
            return;
          }
        }
      } else {
        this.$message.error("请在Safari浏览器中打开本页");
        return;
      }

      //判断浏览器udid
      //获取udid之前 首先判断localStorage中是否有udid的缓存 如果有 直接通过udid和softId跳转到下载页
      var udid = localStorage.getItem("udidcache");
      if (
        udid != "undefined" &&
        udid != null &&
        this.UDID != null &&
        this.UDID != ""
      ) {
        //校验设备
        checkEquipmentNum({ softId: softId, UDID: this.UDID }).then((res) => {
          if (res.code == 0) {
            this.DowndialogVisible = true;
            //签名
            signatureUDID({
              softId: softId,
              UDID: this.UDID,
              softUrl: this.form.softUrlSmall,
            }).then((res) => {
              if (res.code != 0) {
                this.dialogBanner = false;
                this.$message.error(res.msg);
              } else {
                if (res.data.data.result == "downing") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.$message.error("有用户正在下载该应用，请稍后重试");
                } else if (res.data.data.result == "fail") {
                  clearInterval(this.stopCheckState); //清除定时轮询
                  this.DowndialogVisible = false;
                  this.$message.error(res.data.data.msg);
                } else {
                  this.dialogBanner = false;
                  // this.$message.success(res.msg);
                }
              }
            });
            this.stopCheckState = setInterval(() => {
              this.checkStatus();
            }, 3000); //轮询查看vip安装进度
            localStorage.setItem("udidcache", this.UDID); //缓存浏览器
          } else {
            this.DowndialogVisible = false;
            this.$message.error(res.msg);
          }
        });
        //查看状态
        // } else {
        // 	window.location.href = 'http://test.wkzx.store/vip?vipId='+this.UDID;
        // }
      } else {
        this.dialogBanner = true;
      }
    },
    // 选择中英文
    languageClick(val) {
      this.languageToggle = val;
    },
    getVipAppUrl() {
      console.log(this.form.shortLink);
      setTimeout(() => {
        // if (this.form.softUrlSmall.length > 4) {
        //   this.bindQRCode(this.form.softUrlSmall);
        // } else {
        //   this.bindQRCode(this.form.shortLink);
        // }
        this.bindQRCode(this.form.shortLink);
      }, 500);
    },
    // getVipAppUrl(shortUrl) {
    //   vipDownInfo({ vipId: this.vipId, computer: 1 }, shortUrl).then((res) => {
    //     this.form = res.data.info;
    //     this.form.softUrlSmall = res.data.info.shortLink;
    //     setTimeout(() => {
    //       this.bindQRCode(res.data.info.softUrlSmall);
    //     }, 500);
    //   });
    // },
    // 调用查询接口
    // getAppUrl(val) {
    // if (val) {
    //   axios.get(val).then((res) => {
    //     this.status = res.data.code;
    //     if (res.data.code == 7) {
    //       let obj = {};
    //       let url = res.data.data.forward;
    //       let urlList = url.split("?")[1].split("&");
    //       urlList.forEach((item) => {
    //         let arr = item.split("=");
    //         let key = arr[0];
    //         let val = arr[1];
    //         obj[key] = val;
    //       });
    //       this.appInfo = obj;
    //       this.formData.softId = this.softId;
    //       this.$router.push({});
    //       this.getAppShow();
    //     }
    //     if (res.data.code == 9) {
    //       this.webClip = res.data.data.webClip;
    //     }
    //     if (res.data.code == 101) {
    //       this.info = res.data.data.info;
    //     }
    //     if (res.data.code == 106 || res.data.code == 107) {
    //       this.info = res.data.data.info;
    //       this.imageList = res.data.data.imageList;
    //     }
    //     // 输入密码状态
    //     if (res.data.code == 8) {
    //       this.passwordObj = res.data.data;
    //     }
    //   });
    // }
    // },
    // 免责声明
    exemptionClick() {
      this.$router.push({
        path: "/contract",
        query: {
          status: 5,
        },
      });
    },
    // 保存
    saveData() {
      console.log(this.formData);
      if (this.formData.phone == "") {
        return this.$message.error("请输入手机号");
      }
      if (this.formData.code == "") {
        return this.$message.error("请输入验证码");
      }
      if (this.isUploadSuc) {
        return this.$message.error("请等待上传完成");
      }
      this.codeJiaoyan();
    },
    // 获取验证码
    getCode() {
      if (!this.formData.phone) return this.$message.warning("请输入手机号");
      if (this.checkSuccess) {
        reportCode({ phone: this.formData.phone,sessionId:this.checkData.sessionId, sig: this.checkData.sig, sessiontoken: this.checkData.token, scene: this.scene }).then((res) => {
          if (res.code == "1") {
            this.$message({
              type: "warning",
              duration: 2000,
              message: res.msg,
            });
          } else {
            this.timerChange();
          }
        });
      } else {
        this.$message.warning("请先通过滑块验证");
      }
    },
    codeJiaoyan() {
      if (this.formData.phone) {
        reportCodeVerify({
          phone: this.formData.phone,
          code: this.formData.code,
        }).then((res) => {
          if (res.code == 0) {
            // this.$message.success(res.msg);
            this.formData.softId = this.form.softId;
            reportApp(this.formData).then((res) => {
              if (res.code == 0) {
                this.$message.success("举报成功");
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            return this.$message.error(res.msg);
          }
        });
      } else {
        this.$message({
          type: "warning",
          duration: 2000,
          message: "请输入手机号",
        });
      }
    },
    timerChange() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 举报
    reportClick() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 165,
        height: 165,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    getAppShow() {
      preview(this.appInfo).then((res) => {
        if (res.code == 0) {
          console.log(res.data.info);
          this.form = res.data.info;
          this.form.shortLink = res.data.info.softUrl;
          if (isEmpty(this.form.wx)) {
            this.form.wx = "";
          }
          if (isEmpty(this.form.infoqq)) {
            this.form.infoqq = "";
          }
          // document.title = this.form.softname;
          setTimeout(() => {
            this.bindQRCode(res.data.info.softUrl);
          }, 500);
        }
      });
    },
    // 删除文件
    handleRemove(file) {
      console.log(file);
    },
    //上传限制数
    handleExceed(files) {
      this.$message.warning(
        `当前限制选择 2 个文件，本次选择了 ${files.length} 个文件`
      );
      //共选择了 ${files.length + fileList.length }个文件
    },
    //删除弹出框
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //上传文件调用
    beforeUpload(files) {
      this.isUploadSuc = true;
      console.log(files);
      //获取后缀名
      var fileName = files.name;
      var index = fileName.lastIndexOf(".");
      var suffix = fileName.substring(index, fileName.length); //后缀名
      fileName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(fileName, files)
            .then((res) => {
              console.log(res);
              if (res.url) {
                this.formData.files.push(res.url);
                this.isUploadSuc = false;
              } else {
                this.$message.error("文件上传失败");
                this.isUploadSuc = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error("上传失败");
          this.isUploadSuc = false;
        }
      });
    },
  },
  mounted() {
    if (this.form.chEn == 0) {
      if (
        (navigator.language || navigator.browserLanguage)
          .toLowerCase()
          .includes("zh-cn")
      ) {
        this.languageToggle = 1;
      } else {
        this.languageToggle = 2;
      }
    } else {
      this.languageToggle = this.form.chEn;
    }
  },
};
</script>

<style lang="scss" scoped>
.downClass {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.erwei {
  position: relative;
  height: 205px;
  margin-bottom: 20px;

  img {
    position: absolute;
    width: 24px;
    height: 25px;
    left: 50%;
    top: 50%;
    border: 2px solid #fff;
    background: #fff;
    border-radius: 2px;
    transform: translate(-50%, -50%);
  }
}

::v-deep .el-form-item__label {
  text-align: left;
}

.dialog_box {
  color: #888;
  padding: 25px;
}

.footerBox {
  // position: fixed;
  // bottom: 0;
  width: 100%;
}

.footer {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  background: rgba(229, 229, 229, 1);

  .w {
    display: flex;
    justify-content: space-between;

    span {
      color: #0098dd;
    }
  }
}

#qrCode {
  border: 10px solid #eee;
  padding: 10px;
  border-radius: 16px;
  margin-bottom: 20px;
}

.content {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  .logo {
    margin-top: 50px;

    img {
      width: 140px;
      height: 140px;
      border-radius: 16%;
    }
  }

  .title {
    font-size: 30px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: rgba(34, 34, 34, 1);
  }

  .verison {
    font-size: 15px;
    margin-top: 18px;
    margin-bottom: 16px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
  }

  .font_style {
    margin-bottom: 27px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }

  .ljInstall {
    width: 148px;
    height: 44px;
    background: #0092ff;
    border-radius: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;
    color: #fff;
    // margin-bottom: 22px;
    cursor: pointer;
  }
}

.icstyle {
  font-size: 30px;
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.closeBox {
  position: relative;
  overflow: hidden;

  .closeBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.cxt {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 152, 221, 1);
  margin-bottom: 10px;
  cursor: pointer;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.appDownDoc {
  width: 450px !important;
}

.modal-body {
  padding: 30px;

  .modal_item {
    display: flex;
    justify-content: space-between;

    .img_dialog {
      width: 20px;
      height: 20px;
    }
  }
}

::v-deep .el-carousel__container {
  height: 326px;
}
</style>
