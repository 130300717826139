<template>
  <div class="topBox" @click="openClick" v-show="!dialogVisible">
    <img
      src="../../assets/image/left.png"
      alt=""
      style="width: 8px; height: 48px"
    />

    <div class="rightBox">
      <img
        src="../../assets/image/contract.png"
        style="width: 16px; height: 16px"
        alt=""
      />
      <div>联</div>
      <div>系</div>
      <div>客</div>
      <div>服</div>
    </div>
  </div>
  <el-dialog
    :show-close="false"
    v-model="dialogVisible"
    width="80%"
  >
    <div style="background: #cce9ff; border-radius: 10px; padding-bottom: 20px">
      <div class="header">
        <div>联系客服</div>
        <img
          src="../../assets/image/close2.png"
          alt=""
          style="width: 16px; height: 16px"
          @click="closeDialog"
        />
      </div>
      <div class="contentBox">
        <div v-show="info.wx">
          <div class="title">微信</div>
          <div class="box" id="wx" :data-clipboard-text="info.wx" @click="wxCopy(info.wx)">{{info.wx}}</div>
          <div class="tips">点击复制，打开微信搜索</div>
        </div>
        <div v-show="info.infoqq">
          <div class="title">QQ群</div>
          <div class="box" id="qq" :data-clipboard-text="info.infoqq" @click="qqCopy(info.infoqq)">{{info.infoqq}}</div>
          <div class="tips">点击复制，打开QQ搜索</div>
        </div>
        <div v-show="!info.infoqq && !info.wx">
          <div class="title">暂无联系方式</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { ref } from "vue";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const dialogVisible = ref(false);
    // 关闭弹窗
    const closeDialog = () => {
      dialogVisible.value = false;
    };
    const openClick = () => {
      dialogVisible.value = true;
    };
    const flag = ref(false);

    const wxCopy = (val) => {
      copyFd(val)

    };

    const copyFd = (val) => {
      let copy = function (e) {
          e.preventDefault();
          console.log('copy');
          if (e.clipboardData) {
              e.clipboardData.setData('text/plain', val);
              alert('复制成功')
          } else if (window.clipboardData) {
              window.clipboardData.setData('Text', val);
              alert('复制成功')
          }
      }
      window.addEventListener('copy', copy);
      document.execCommand('copy');
      window.removeEventListener('copy', copy);
    }
    const qqCopy = (val) => {
      copyFd(val)
    };
    return {
      wxCopy,
      qqCopy,
      flag,
      openClick,
      dialogVisible,
      closeDialog,
    };
  },
};
</script>
<style lang="scss" scoped>
.contentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  width: 200px;
  height: 30px;
  background: #0092ff;
  border-radius: 16px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.tips {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */
  margin: 5px 0;
  text-align: center;

  color: #72c3ff;
}
.title {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 5px 0;
  /* identical to box height */

  text-align: center;

  color: #0092ff;
}
.header {
  position: relative;
  padding: 20px 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #0092ff;
  text-align: center;
  width: 100%;
  img {
    position: absolute;
    top: 22px;
    right: 20px;
  }
}
.topBox {
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  top: 20%;
  cursor: pointer;
}
.rightBox {
  width: 42px;
  height: 130px;
  background: #cce9ff;
  border-radius: 14px 0 0 14px;
  // 文字纵向展示
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #00a0e9;
}


::v-deep .el-dialog {
  border-radius: 10px!important;
  .pop-title{
    font-size: 3vh;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  .pop-context{
    text-align: center;
    display: block;
    height: auto;
  }
}
</style>