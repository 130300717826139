<template>
	<div class="content">
		<div>
			<img class="bgm" src="@/assets/image/scan_error_bg.png" alt="" />
			<div class="font_1">该应用已删除或已下架</div>
		</div>
		<div class="banner" v-if="bannerImg && !isPc()" v-show="bannerFlag">
			<img :src="bannerImg" style="width: 100%; height: 100%" @click="bannerClick" />
			<img class="btn" @click="closeBtn" src="@/assets/image/close-btn.png" style="width: 20px; height: 20px" />
		</div>
		<div class="footerBox">
			<div class="w closeBox" style="margin-bottom: 20px;" v-show="bannerFlag">  <!-- v-show="bannerFlag" -->
				<el-carousel indicator-position="none" height="100px" trigger="click" autoplay arrow="never">
					<el-carousel-item v-for="(item, index) in bannerList" :key="index">
					<img
						class="banner_img"
						:src="item.imgUrl"
						alt=""
						@click="bannerClick(item.link)"
					/>
					</el-carousel-item>
				</el-carousel>
				<img class="closeBtn" @click="closeBanner" src="@/assets/image/close-btn.png" style="width: 20px;height: 20px;" alt="">
			</div>
	  </div>
	</div>
</template>
<script>
import { getPCDBAdvertising } from '../../../api/api.js'
import { isEmpty } from "@/common/js/common.js";
export default {
	props: {
		bannerImg: {
			type: String,
			default: ''
		},
		link: {
			type: String,
			default: ''
		},
		softname: {
			type: String,
			default: ''
		}
	},
	created() {
	//   document.title = this.softname
		// console.log(this.isPc())
		if(this.isPc()) {
			this.getPCAdvertising()
		}
	},
	data() {
		return {
			bannerFlag: true,
			bannerList: [],
			isPcShow: false,
		};
	},
	methods: {
		// 判断pc端移动端
		isPc() {
			var userAgentInfo = navigator.userAgent;
			var Agents = ["Android", "iPhone",
				"SymbianOS", "Windows Phone",
				"iPad", "iPod"];
			var flag = true;
			for (var v = 0; v < Agents.length; v++) {
				if (userAgentInfo.indexOf(Agents[v]) > 0) {
					flag = false;
					break;
				}
			}
			return flag
		},
		closeBanner(){
			this.bannerFlag = false;
		},
		// 获取轮播图
		getPCAdvertising() {
			getPCDBAdvertising().then((res) => {
				if (!isEmpty(res.data)) {
				this.bannerList = res.data.advertisings;
				}
				if (res.code == 401) {
				// this.$message.error(res.msg)
				localStorage.clear();
				this.$router.push("/login");
				}
			});
		},
		bannerClick() {
			window.location.href = this.link;
		},
		// 关闭
		closeBtn() {
			this.bannerFlag = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.footerBox {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.w {
	width: 1200px auto;
}

.closeBox {
  position: relative;
  overflow: hidden;
  .closeBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.font_1 {
	font-family: PingFang SC;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	
	color: #666666;
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}
.bgm {
	margin-top: 149px;
	width: 241px;
	height: 241px;
}
.banner {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 90px;
	.btn {
		position: absolute;
		display: block;
		z-index: 999;
		top: 35px;
		right: 10px;
		cursor: pointer;
	}
}
</style>
