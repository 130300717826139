<template>
  <div>
    <div class="message_box" v-loading="loading">
      <div
        class="message_all"
        v-for="item in messageArray"
        :key="item.messageId"
      >
        <div class="content_item" v-if="item.messageType == 2">
          <div class="content_icon">·</div>
          <div class="content_body">
            <div style="font-size: 14px;">
              {{ item.messageContent }}
            </div>
            <div style="font-size: 12px;color: #999999;">
              {{ item.modifyDate ? item.modifyDate.substr(0, 10) : "" }}
            </div>
          </div>
        </div>
        <div class="content_item2" v-else>
          <div class="content_icon"></div>
          <div class="content_body">
            <div style="font-size: 14px;">
              {{ item.messageContent }}
            </div>
            <div style="font-size: 12px;color: #999999;">
              {{ item.modifyDate ? item.modifyDate.substr(0, 10) : "" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { messageList } from "@/api/api";
export default {
  name: "messageCore",
  data() {
    return {
      messageArray: [],
      loading: false
    };
  },
  created() {
    this.getMessageList();
  },
  methods: {
    // 获取消息队列
    getMessageList() {
      this.loading = true
      messageList().then((res) => {
        this.loading = false
        if (res.code == 0) {
          this.messageArray = res.data.messageList;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tips_icon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 205px;
  font-size: 12px;
  color: #666666;
  text-align: center;
  width: 92px;
  height: 25px;
  line-height: 25px;
  background: #f1f6f9;
  border-radius: 4px;
}
.message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message_box {
  overflow: auto;
  height: 280px;
}
.content_item2 {
   display: flex;
  height: 80px;
  padding: 15px 0;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid#f1f6f9;
}
.content_item {
  display: flex;
  height: 80px;
  padding: 15px 0;
  box-sizing: border-box;
  background: #f1f6f9;
  border-radius: 6px;
  }
  .content_icon {
    text-align: center;
    font-size: 50px;
    line-height: 0.2;
    color: #ff4a4a;
    width: 25px;
  }
  .content_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

.w2 {
  position: relative;
  width: 800px;
  margin: auto;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 20px 0;
  background: #fff;
  border-radius: 10px;
  img {
    width: 120px;
    height: 120px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 22px;
    color: #333333;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
</style>

