<template>
  <div>
    <div class="w">
      <div class="appeal_box">
        <div class="title">
          账号申诉
        </div>
        <div class="form_box" v-loading="loading">
          <el-form ref="form" :model="form" label-width="135px" :rules="rules">
            <el-form-item label="账号">
              <el-input v-model="form.userName"></el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="company">
              <el-input v-model="form.company"></el-input>
            </el-form-item>
            <el-form-item label="ICP备案号" prop="icp">
              <el-input
                v-model="form.icp"
                placeholder="输入ICP备案号"
              ></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码" prop="creditCode">
              <el-input
                v-model="form.creditCode"
                placeholder="输入统一社会信用代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="主营业务" prop="work">
              <el-input
                v-model="form.work"
                type="textarea"
                :rows="6"
                placeholder="输入主营业务"
              ></el-input>
            </el-form-item>
            <el-form-item label="申诉理由" prop="cause">
              <el-input
                v-model="form.cause"
                type="textarea"
                :rows="6"
                placeholder="输入申诉理由"
              ></el-input>
            </el-form-item>
            <el-form-item label="营业执照上传图片" prop="license">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :before-upload="beforeUploadIcon1"
                :accept="uploadType"
              >
                <img
                  v-if="form.license"
                  :src="form.license"
                  class="avatar upload-style"
                />
                <i v-else class="el-icon-plus upload-style"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="免责声明上传图片" prop="disclaimer">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :accept="uploadType"
                :before-upload="beforeUploadIcon2"
              >
                <img
                  v-if="form.disclaimer"
                  :src="form.disclaimer"
                  class="avatar upload-style"
                />
                <i v-else class="el-icon-plus upload-style"></i>
              </el-upload>
              <span class="down_class" @click="downTemplate"
                >下载免责声明模板</span
              >
            </el-form-item>
            <el-form-item label="其他资质上传图片">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :accept="uploadType"
                :show-file-list="false"
                :before-upload="beforeUploadIcon3"
              >
                <img
                  v-if="form.other"
                  :src="form.other"
                  class="avatar upload-style"
                />
                <i v-else class="el-icon-plus upload-style"></i>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="width: 148px;"
                round
                @click="save"
                >申诉</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOssToken, userAppeal, userInfo,getAuthenticationState } from "@/api/api";
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord } from "@/common/js/common.js";
export default {
  name: "appeal",
  data() {
    return {
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        icp: [{ required: true, message: "请输入ICP备案号", trigger: "blur" }],
        creditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        work: [{ required: true, message: "请输入主营业务", trigger: "blur" }],
        cause: [{ required: true, message: "请输入申诉理由", trigger: "blur" }],
        license: [
          { required: true, message: "请上传营业执照"},
        ],
        disclaimer: [
          { required: true, message: "请上传免责申明"},
        ],
      },
      loading: false,
      uploadType: ".jpg,.jpeg,.png,.JPG,.JPEG",
      form: {
        userName: "",
        company: "",
        icp: "",
        creditCode: "",
        work: "",
        cause: "",
        license: "",
        disclaimer: "",
        other: "",
      },
      userInfo: {},
    };
  },
  created() {
    this.getUserInfo();
    this.getTokenState()
  },
  methods: {
    // 获取封装状态值
    async getTokenState() {
      const res = await getAuthenticationState()
      if(res.data != null){
        this.form = res.data
      }
    },
    downTemplate() {
      window.location.href = "https://img.wkzx.store/disclaimer.docx";
    },
    // 获取个人信息
    getUserInfo() {
      userInfo().then((res) => {
        this.userInfo = res.data.info;
        this.form.userName = this.userInfo.userName;
        // this.form.company = this.userInfo.companyName;
      });
    },
    beforeUploadIcon1(file) {
      //获取后缀名
      var imageName = file.name;
      var index = imageName.lastIndexOf(".");
      var suffix = imageName.substring(index, imageName.length); //后缀名
      imageName = new Date().getTime() + randomWord(17) + suffix;
      this.loading = true;
      let _this = this;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(imageName, file)
            .then((res) => {
              console.log(res)
              this.loading = false;
              if (res.url) {
                console.log(111111111111111111111111111)
              console.log(_this.form)

                this.form.license = res.url;
              console.log(this.form)
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    beforeUploadIcon2(file) {
      //获取后缀名
      var imageName = file.name;
      var index = imageName.lastIndexOf(".");
      var suffix = imageName.substring(index, imageName.length); //后缀名
      imageName = new Date().getTime() + randomWord(17) + suffix;
      this.loading = true;
      getOssToken().then((res) => {
        this.loading = false;
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(imageName, file)
            .then((res) => {
              if (res.url) {
                this.form.disclaimer = res.url;
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    beforeUploadIcon3(file) {
      //获取后缀名
      var imageName = file.name;
      var index = imageName.lastIndexOf(".");
      var suffix = imageName.substring(index, imageName.length); //后缀名
      imageName = new Date().getTime() + randomWord(17) + suffix;
      this.loading = true;
      getOssToken().then((res) => {
        this.loading = false;
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(imageName, file)
            .then((res) => {
              if (res.url) {
                this.form.other = res.url;
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    save() {
      this.$refs.form.validate((valid) => {
      console.log(this.form)
        if (valid) {
          this.loading = true;
          userAppeal(this.form).then((res) => {
            this.loading = false;
            if (res.code == 0) {
              this.$router.push("/lock/examine");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  display: flex;
  align-items: center;
}
::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
}
.down_class {
  width: 200px;
  margin-left: 20px;
  font-size: 14px;
  color: #666666;
  text-decoration-line: underline;
  cursor: pointer;
}
.upload-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 180px;
  background: #f8f8f8;
  border: 1px dashed #acacac;
  box-sizing: border-box;
  border-radius: 10px;
}
.appeal_box {
  margin: 20px 0;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    margin: 50px 0;
    color: #333333;
    text-align: center;
  }
  .form_box {
    width: 584px;
    margin: 0 auto;
  }
}
::v-deep .el-form-item__label {
  text-align: left;
}
::v-deep .el-textarea__inner {
  resize: none;
}
</style>
