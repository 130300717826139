<template>
	<div class="mianban">
		<div class="neiye1">
			<div class="daohang">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/installDoc/center' }">文档说明</el-breadcrumb-item>
					<el-breadcrumb-item>购买的服务如何申请发票</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="shoubiaoti">购买的服务如何申请发票</div>
			<div class="biaoti"><img style="width: 100%;" src="../../assets/image/kaipiao.png"></div>
			<!-- <div class="dibu">
				<div>92阅读 更新时间 11-17 21:51</div>
				<div>73人觉得有帮助</div>
			</div> -->
			<el-divider></el-divider>
			<!-- <div>以上信息是否解决您的问题?</div>
			<div class="anniu">
				<div class="left">
					<el-button size="medium">是</el-button>
				</div>
				<div class="right">
					<el-button size="medium">否</el-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
</script>

<style>
	.mianban {
		/* width: 100%; */
		height: 100%;
		background-color: rgb(246, 246, 247);
		display: flex;
		justify-content: center;
	}

	.neiye1 {
		width: 50%;
		height: 100%;
		margin-left: 26rem;
		margin-right: 26rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
		padding: 40px;
		background-color: rgb(255, 255, 255);
	}

	.daohang {
		margin-bottom: 0.625rem;
	}

	.biaoti {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 1.5rem;
	}

	.shoubiaoti {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 4rem;
	}

	.neitest {
		font-size: 16px;
		margin-bottom: 3rem;
	}

	.neitest1 {
		font-size: 16px;
	}

	.dibu {
		display: flex;
		color: #b2b2b2;
		;
		justify-content: space-between;
	}

	.anniu {
		margin-top: 0.625rem;
		display: flex;
	}

	.right {
		margin-left: 0.625rem;
	}
</style>
