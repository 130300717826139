<template>
  <div>
    <el-dialog
      v-model="seeDialog"
      width="450px"
      center
      title="二维码"
      :close-on-click-modal="false"
      :before-close="seehandleClose"
    >
      <div>
        <div class="icon_erwei_box">
          <div class="icon_erwei_nBox">
            <img
              :src="erweiUrl"
              style="width: 100px;height: 100px;margin-top: 40px;border-radius: 20px;"
            />
            <div
              style="font-weight: bold;font-size: 18px;color: #333;margin: 10px 0 25px 0"
            >
              {{ form.label }}
            </div>
            <div class="icon_erwei_xiantiao">
              <img
                src="@/assets/image/border_1.png"
                alt=""
                style="height: 1px;width: 82px"
              />
              <img src="@/assets/image/border_2.png" alt="" />
              <img
                src="@/assets/image/border_1.png"
                alt=""
                style="height: 1px;width: 82px"
              />
            </div>
            <div style="font-size: 14px;color: #666;margin-bottom: 10px">
              扫码二维码下载
            </div>
            <div class="icon_erwei_border">
              <div id="qrCodeSee" ref="qrCodeDivSee"></div>
			  <!-- <img :src="form.iconUrl" alt=""> -->
            </div>
          </div>
        </div>
        <div class="icon_erwei_footer">
          <el-button type="primary" @click="downerwei">下载二维码</el-button>
        </div>
      </div>
    </el-dialog>
    <div class="w">
      <div class="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }"
            >悟空在线
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/webClip' }"
            >Web Clip</el-breadcrumb-item
          >
          <el-breadcrumb-item>更新Web Clip</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="content_left">
           <div class="left_header">
            <div class="left_header_left">
              <div>
                <img :src="form.iconUrl" />
              </div>
              <div
                style="display: flex;justify-content: center;align-items: center;margin-top: 10px;position: relative;"
              >
                <div class="icon_zhezao" @click="seeIconApp">
                  <img
                    src="@/assets/image/see.png"
                    alt=""
                    style="width: 20px;height: 20px"
                  />
                </div>
                <div
                  id="qrCodeIcon"
                  ref="qrCodeDivIcon"
                  style="width: 32px;height: 32px;"
                ></div>
              </div>
            </div>
            <div class="left_header_right">
              <div style="font-size: 18px;color:#333333;font-weight: 700">
                {{ form.label }}
              </div>
              <div class="box_content">
                <div class="box_content_left">
                  <span>短链接：{{ form.remarks1 }}</span
                  ><br />
                  <span>BundleID：{{ form.identifier }}</span>
                </div>
                <div class="box_content_right">
                  <span>版本信息：{{ form.version }}</span
                  ><br />
                  <!-- <span>消耗流量：{{ form.downNum }}点</span> -->
                </div>
              </div>
              <div class="left_header_btn">
                <el-button @click="computerClick">电脑预览</el-button>
                <el-button @click="telSeeClick">手机预览</el-button>
              </div>
              <div
                style="font-size: 22px;color: #333;font-weight: 700;margin-top: 30px"
              >
                最新版本
              </div>
            </div>
          </div>

          <div class="timerLine">
            <el-timeline>
              <el-timeline-item>
                <div class="timer_box">
                  <div class="timer_title">版本：{{ form.version }}</div>
                  <div class="timer_content">
                    <span>桌面名称：{{ form.label }}</span
                    ><br />
                    <span>更新时间：{{ form.modifyDate }}</span
                    ><br />
                    <span>访问地址：{{ form.targetUrl }}</span
                    ><br />
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <div class="content_right">
          <div class="right_btn">
            <div :class="editClass" @click="editClick">
              <img
                src="@/assets/image/manage2.png"
                alt=""
                style="width: 40px;height: 40px"
              />
              <div>基本信息</div>
            </div>
            <div :class="hebingClass" @click="hebingClick">
              <img
                src="@/assets/image/manage3.png"
                alt=""
                style="width: 40px;height: 40px"
              />
              <div>应用合并</div>
            </div>
          </div>
          <div class="tips_box" v-if="editShow">
            <div class="sanjiao"></div>
            <div class="right_content" v-loading="loading">
              <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="桌面图标">
                  <el-upload
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :before-upload="beforeUploadIcon"
                    :accept="uploadType"
                  >
                    <img
                      v-if="form.iconUrl"
                      :src="form.iconUrl"
                      class="avatar"
                      style="width:54px;height:54px;border-radius:10px;"
                    />
                    <img
                      v-else
                      src="../../assets/image/app_1.png"
                      class="avatar"
                      style="width:54px;height:54px;border-radius:10px;"
                    />
                  </el-upload>
                </el-form-item>
                <el-form-item label="桌面名称">
                  <el-input v-model="form.label"></el-input>
                </el-form-item>
                <el-form-item label="配置文件名称">
                  <el-input v-model="form.displayname"></el-input>
                </el-form-item>
                <el-form-item label="唯一标识">
                  <el-input v-model="form.identifier"></el-input>
                </el-form-item>
                <el-form-item label="版本">
                  <el-input v-model="form.version"></el-input>
                </el-form-item>
                <el-form-item label="访问地址">
                  <el-input v-model="form.targetUrl"></el-input>
                </el-form-item>
                <el-form-item label="下载时长">
                  <el-input v-model="form.timeLeft" disabled>
                    <template #append>
                      <el-button @click="sellClick"
                        ><span style="color:#0092FF">续费</span></el-button
                      >
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="全屏">
                  <el-select
                    v-model="form.fullScreen"
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="简介">
                  <el-input
                    v-model="form.description"
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submit">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="tips_box" v-if="hebingShow">
            <div class="sanjiao2"></div>
            <div class="right_content">
              <div class="hebing_header">
                <div class="hebing_header_box">
                  <div class="hebing_img">
                    <img
                      :src="form.iconUrl"
                      style="width: 80px; height: 80px;border-radius: 18px;"
                    />
                    <div
                      style="font-size: 12px;color:#666;margin-top: 10px;text-align: center"
                    >
                      {{ form.label }}
                    </div>
                  </div>
                  <div>
                    <img
                      style="width:30px;height: 30px;margin-top: 25px;"
                      src="../../assets/image/hebing1.png"
                      alt=""
                    />
                  </div>
                  <div class="hebing_img">
                    <div
                      style="width: 80px; height: 80px;border: 1px dashed #BCBCBC;border-radius: 18px;box-sizing: border-box;overflow:hidden"
                    >
                      <img
                        style="width: 100%;height: 100%"
                        v-if="mergeObj.iconUrl"
                        :src="mergeObj.iconUrl"
                      />
                    </div>
                    <div
                      style="font-size: 12px;color:#666;margin-top: 10px;text-align: center"
                    >
                      {{ mergeObj.softname }}
                    </div>
                  </div>
                </div>
                <div class="hebing_online">
                  <div class="online_item_left"></div>
                  <el-button :type="mergeType" @click="hbClick">{{
                    mergeTitle
                  }}</el-button>
                  <div class="online_item_right"></div>
                </div>
              </div>
              <div class="hebing_bottom">
                <div style="font-size: 14px;margin: 20px;text-align: center">
                  选择已有的应用进行合并
                </div>
                <div class="hebing_app">
                  <div
                    class="hebing_img"
                    style="overflow:hidden"
                    v-for="item in appMergeList"
                    :key="item.softId"
                  >
                    <img
                      @click="mergeClick(item)"
                      :src="item.iconurl"
                      style="width: 80px; height: 80px;border-radius: 18px;cursor: pointer;"
                    />
                    <div
                      style="font-size: 12px;color:#666;margin-top: 10px;text-align: center"
                    >
                      {{ item.softname }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        v-model="dialogVisible"
        width="450px"
        center
        :title="form.label"
        :before-close="handleClose"
      >
        <div>
          <div class="erwei">
            <div id="qrCode" ref="qrCodeDiv"></div>
			<img :src="form.iconUrl" alt="">
          </div>
          <div class="dialog_url">{{ form.remarks1 }}</div>
        </div>
      </el-dialog>
    </div>
    <sell-web-clip
      :dialogVisible="sellDialog"
      :webclipId = "id"
      @dialogChange="dialogChange"
    ></sell-web-clip>
  </div>
</template>
<script>
import { fileUpdateOSS } from "@/common/js/OssClient.js";
import { randomWord,domainName } from "@/common/js/common.js";
import sellWebClip from "@/views/webClip/common/sellWebClip";
import {
  updateWebclip,
  getWebClipInfo,
  webclipMerge,
  webClipMergeNo,
  webCliprelieveMerge,
  getOssToken,
} from "@/api/api";
import QRCode from "qrcodejs2";
// 截取html生成base64图片
import html2canvas from "html2canvas";
export default {
  name: "updateWebClip",
  components: {
    sellWebClip,
  },
  data() {
    return {
      uploadType:'.jpg,.jpeg,.png,.JPG,.JPEG',
      erweiUrl: '',
      seeDialog: false, // 二维码显示
      sellDialog: false,
      loading: false,
      options: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      form: {},
      editShow: true,
      hebingShow: false,
      editClass: "right_left_btn_active", // 修改按钮默认样式
      hebingClass: "right_right_btn", // 应用合并默认样式
      activities: [
        {
          content: "活动按期开始",
          title: "神都夜行录",
          uploadTime: "2021-07-08 09:33:43",
          urlPath: "https://wkzx.wang/webclip",
          timestamp: "2.7.3",
        },
        {
          content: "活动按期开始",
          title: "神都夜行录",
          uploadTime: "2021-07-08 09:33:43",
          urlPath: "https://wkzx.wang/webclip",
          timestamp: "2.7.3",
        },
      ],
      id: "", 
      userId: "",
      appMergeList: [],
      mergeTitle: "合并应用",
      mergeType: 'primary',
      mergeFlag: 0,
      // 合并应用对象
      mergeObj: {
        iconUrl: "",
        softname: "",
        softId: "",
      },
      dialogVisible: false, // 弹窗显示与隐藏
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.userId = this.$route.query.userId;
    this.getWebClipInfo();
  },
  methods: {
    // 第一个参数是图片的URL地址，第二个是转换成base64地址后要赋值给的img标签
    getBase64Image(url) {
      var that = this;
      var image = new Image();
      image.src = url + "?v=" + Math.random(); // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function() {
        var base64 = that.drawBase64Image(image);
        that.erweiUrl = base64;
		that.form.iconUrl = base64;
        // that.$refs[ref].src = base64;
      };
    },
    drawBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
    },
    /* 下载二维码开始 */
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    downloadFile(url, name) {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      a.setAttribute("target", "_blank");
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    downloadFileByBase64(base64, name) {
      var myBlob = this.dataURLtoBlob(base64);
      var myUrl = URL.createObjectURL(myBlob);
      this.downloadFile(myUrl, name);
    },
    // 下载二维码
    downerwei() {
      html2canvas(document.querySelector(".icon_erwei_box")).then(function(
        canvas
      ) {
        var url = canvas.toDataURL();
        // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          var bstr = atob(url.split(",")[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          var blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(
            blob,
            "chart-download" + "." + "png"
          );
        } else {
          // 这里就按照chrome等新版浏览器来处理
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "chart-download");
          a.click();
        }
      });
    },
    /* 下载二维码结束 */
    // 二维码关闭
    seehandleClose() {
      document.querySelector("#qrCodeSee").innerHTML = "";
      this.seeDialog = false;
    },
    seeIconApp() {
      this.seeDialog = true;
      setTimeout(() => {
        this.bindQRCode3(this.form.remarks1);
      }, 200);
    },
    bindQRCode3(val) {
      new QRCode(this.$refs.qrCodeDivSee, {
        text: val,
        width: 180,
        height: 180,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    beforeUploadIcon(file) {
      //获取后缀名
      var imageName = file.name;
      var index = imageName.lastIndexOf(".");
      var suffix = imageName.substring(index, imageName.length); //后缀名
      imageName = new Date().getTime() + randomWord(17) + suffix;
      getOssToken().then((res) => {
        if (res.code == 0) {
          let ossClient = fileUpdateOSS(res.data);
          ossClient
            .put(imageName, file)
            .then((res) => {
              if (res.url) {
                console.log(res.url + "===================");
                this.form.iconUrl = res.url;
                // this.form.iconImageName = imageName;
              } else {
                this.$message.error("文件上传失败");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    dialogChange(val) {
      this.sellDialog = val;
    },
    // 购买时长
    sellClick() {
      this.sellDialog = true;
    },
    // 合并应用方法
    hbClick() {
      if (this.mergeFlag == 0) {
        this.saveMerage();
      }
      if (this.mergeFlag == 1) {
        this.resetMerage();
      }
    },
    // 解除合并应用接口
    resetMerage() {
      webCliprelieveMerge({
        id: this.id,
        softId: this.mergeObj.softId,
      }).then((res) => {
        if (res.code == 0) {
          this.mergeObj.iconUrl = "";
          this.mergeObj.softname = "";
          this.mergeObj.softId = "";
          this.mergeTitle = "合并应用";
          this.mergeType = 'primary';
          this.$message.success("解除成功");
          // 调用应用列表更新应用
          this.getMergeAll();
        } else {
          this.$message.error("解除失败");
        }
      });
    },
    // 合并应用接口
    saveMerage() {
      webClipMergeNo({ id: this.id, softId: this.mergeObj.softId }).then(
        (res) => {
          if (res.code == 0) {
            this.mergeFlag = 1;
            this.mergeTitle = "解除合并";
            this.mergeType = 'warning'
            this.$message.success("合并成功");
            // 调用应用列表更新应用
            this.appMergeList = [];
          } else {
            this.$message.error("合并失败");
          }
        }
      );
    },
    // 获取合并应用
    getMergeAll() {
      webclipMerge({ id: this.id }).then((res) => {
        if (res.code == 101) {
          this.mergeFlag = 0;
          this.mergeTitle = "合并应用";
          this.mergeType = 'primary'
          this.appMergeList = res.data.infoList;
        }
        if (res.code == 102) {
          this.mergeFlag = 1;
          this.mergeTitle = "解除合并";
          this.mergeType = 'warning'
          this.mergeObj.iconUrl = res.data.software.iconUrl;
          this.mergeObj.softname = res.data.software.softName;
          this.mergeObj.softId = res.data.software.softId;
        }
      });
    },
    // 选择合并应用
    mergeClick(val) {
      this.mergeObj.iconUrl = val.iconurl;
      this.mergeObj.softname = val.softname;
      this.mergeObj.softId = val.softId;
      this.hbClick()
    },
    // webClip编辑
    async getWebClipInfo() {
      await getWebClipInfo({ id: this.id }).then((res) => {
        if (res.code == 0) {
          this.form = res.data.webclip;
          this.getBase64Image(this.form.iconUrl)
          setTimeout(() => {
            this.bindQRCode2(this.form.remarks1);
          }, 200);
        } else {
          this.$message.error(res.msg);
        }
      });
            // 修改域名
      const res2 = await domainName(this.form.userId,this.form.remarks1);
      this.form.remarks1 = res2;
      console.log(this.form)
    },
    bindQRCode2(val) {
      new QRCode(this.$refs.qrCodeDivIcon, {
        text: val,
        width: 32,
        height: 32,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    //生成二维码
    bindQRCode(val) {
      new QRCode(this.$refs.qrCodeDiv, {
        text: val,
        width: 350,
        height: 350,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    //手机预览
    telSeeClick() {
      if (!this.$refs.qrCodeDiv) {
        setTimeout(() => {
          this.bindQRCode(this.form.remarks1);
        }, 200);
      }

      this.dialogVisible = true;
    },
    //电脑预览
    computerClick() {
      let routeData = this.$router.resolve({
        path: "/webClipPreview",
        query: {
          id: this.id,
          userId: this.userId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 保存
    submit() {
      let fromData = {};
      fromData.id = this.form.id;
      fromData.label = this.form.label;
      fromData.displayname = this.form.displayname;
      fromData.identifier = this.form.identifier;
      fromData.organization = this.form.organization;
      fromData.version = this.form.version;
      fromData.targetUrl = this.form.targetUrl;
      fromData.timeLeft = this.form.timeLeft;
      fromData.fullScreen = this.form.fullScreen;
      fromData.description = this.form.description;
      fromData.iconUrl = this.form.iconUrl;

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          updateWebclip(fromData)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$router.push("/webClip");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error("请求失败");
              console.log(err);
            });
        }
      });
    },
    editClick() {
      this.editClass = "right_left_btn_active";
      this.hebingClass = "right_right_btn";
      this.editShow = true;
      this.hebingShow = false;
    },
    hebingClick() {
      this.hebingClass = "right_right_btn_active";
      this.editClass = "right_left_btn";
      this.editShow = false;
      this.hebingShow = true;
      this.getMergeAll();
    },
  },
};
</script>
<style lang="scss" scoped>
.icon_erwei_xiantiao {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 25px 0;
}
.icon_erwei_border {
  width: 200px;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    width: 30px;	
    height: 30px;
    left: 50%;
    top: 50%;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 1px;
    transform: translate(-50%, -50%);
  }
}
.erwei {
	position: relative;
	img {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 50%;
		top: 50%;
		background: #fff;
		border: 5px solid #fff;
		border-radius: 5px;
		transform: translate(-50%, -50%);
	}
}
::v-deep .left_header_btn .el-upload {
  float: left;
  margin-right: 10px;
}
::v-deep .el-textarea__inner {
  resize: none;
}
.avatar {
  width: 54px;
  height: 54px;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-form-item__label {
  text-align: left;
}
.tips_box {
  margin-top: 8px;
}
.sanjiao {
  width: 0;
  height: 0;
  margin-left: 45px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
}

.sanjiao2 {
  width: 0;
  height: 0;
  margin-left: 180px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
}
.right_content {
  height: 580px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 15px;
}
.right_btn {
  display: flex;
  color: #666;
}
.right_left_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  align-items: center;
  width: 110px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  margin-right: 25px;
}

.right_left_btn_active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  box-sizing: border-box;
  border: 2px solid #0092ff;
  align-items: center;
  width: 110px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
  margin-right: 25px;
}

.right_right_btn_active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border: 2px solid #0092ff;
  padding: 10px 0;
  align-items: center;
  width: 110px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
}
.right_right_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  align-items: center;
  width: 110px;
  height: 80px;
  background: #fff;
  border-radius: 10px;
}
.timerLine {
  margin-left: 40px;
}
.timer_box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 320px;
  height: 140px;
  background: #f1f6f9;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 15px;
  margin-left: 50px;
  .timer_title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }
  .timer_content {
    color: #666666;
  }
}
.content {
  display: flex;
  justify-content: space-between;
}
.box_content {
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  display: flex;
  color: #666666;
}
.content_left {
  width: 650px;
}
.left_header {
  display: flex;
  margin-bottom: 30px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 16%;
  }
  .left_header_right {
    margin-left: 20px;
  }
}
.header {
  padding: 20px 0;
  font-size: 12px;
  color: #666666;
}
.content {
  height: 777px;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 30px 20px;
  .content_right {
    width: 555px;
    height: 100%;
    background: #f1f6f9;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
  }
}

.hebing_header {
  width: 310px;
  margin: auto;
  .hebing_header_box {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .hebing_img {
      width: 80px;
    }
  }
}
.hebing_online {
  display: flex;
  justify-content: space-between;
  width: 230px;
  margin: auto;
  .online_item_left {
    width: 66px;
    height: 30px;
    border-radius: 0 0 0 10px;
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
  }
  .online_item_right {
    width: 66px;
    height: 30px;
    border-radius: 0 0 10px 0;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
  }
}
.hebing_app {
  width: 100%;
  .hebing_img {
    float: left;
    margin-right: 16px;
    margin-bottom: 20px;
    width: 80px;
    height: 110px;
  }
  .hebing_img:nth-child(5n) {
    float: left;
    margin-right: 0px;
    margin-bottom: 20px;
    width: 80px;
    height: 110px;
  }
}
.dialog_url {
  font-size: 18px;
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  color: rgba(136, 136, 136, 1);
  margin: 12px;
  text-align: center;
}
#qrCode {
  border: 30px solid rgba(229, 229, 229, 1);
  border-radius: 6px;
  img {
    width: 350px;
    height: 350px;
  }
}
.icon_erwei_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 340px;
  height: 511px;
  background: #f1f6f9;
  border-radius: 10px;
  .icon_erwei_nBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 316px;
    height: 487px;
    background: rgba(0, 146, 255, 0.04);
    border: 1px solid rgba(0, 146, 255, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
  }
}
.icon_erwei_footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_zhezao {
  position: absolute;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    display: none;
  }
}

.icon_zhezao:hover {
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
  }
}
::v-deep #qrCodeIcon:hover {
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}
</style>
