<template>
  <div>
    <div class="contract_right" v-if="!openFlag">
      <div class="header">
        <div class="title">发票管理</div>
        <el-button type="primary" @click="orderClick">申请开票</el-button>
      </div>
      <el-table v-loading="loading" :data="tableData" border max-height="300" :header-cell-style="{ background: '#E5F1FA' }">
        <el-table-column
          prop="createDate"
          label="申请时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="invoiceTitle"
          label="发票抬头"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total"
          label="发票总额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="nature"
          label="发票性质"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="发票状态"
          align="center"
        >
          <template #default="scope">
            <div v-if="scope.row.status == 2" style="color:#FFB340">开票中</div>
            <div v-if="scope.row.status == 1" style="color:#09C965">已开票</div>
          </template>
        </el-table-column>
      </el-table>
      <paging-template :currentPage="currentPage" :pageSize="pageSize" :total="total" @pageSizeChange="pageSizeChange" @currentChange="currentChange"></paging-template>
    </div>
    <order-list v-if="openFlag" @showAndNo="showAndNo"></order-list>
  </div>
</template>
<script>
import pagingTemplate from '@/components/common/pagingTemplate'
import { getInvoiceList } from "@/api/api";
import orderList from "@/components/personalData/controlOverInvoicesC/orderList";
export default {
  components: {
    orderList,
    pagingTemplate
  },
  data() {
    return {
      tableData: [],
      openFlag: false,
      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: null
    };
  },
  mounted() {
    this.getInovice()
  },
  methods: {
    pageSizeChange(val) {
      this.pageSize = val
    },
    currentChange(val) {
      this.currentPage = val
      this.getInovice()
    },
    showAndNo(val) {
      this.openFlag = val
    },
    getInovice() {
      this.loading = true
      getInvoiceList({pageNum: this.currentPage}).then((res) => {
        this.loading = false
        this.currentPage = res.data.pageInfo.pageNum
        this.pageSize = res.data.pageInfo.pageSize
        this.tableData = res.data.pageInfo.list;
        this.total = res.data.pageInfo.total
        console.log(res)
      });
    },
    orderClick() {
      this.openFlag = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  text-align: left;
}
.contract_right {
  width: 920px;
  height: 460px;
  border-radius: 10px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px 40px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .title {
      font-size: 22px;
      font-weight: 700;
    }
  }
}
</style>
