<template>
	<div>
		<el-dialog v-model="visible" title="绑定域名" width="700px" :close-on-click-modal="false"
			:before-close="handleClose" center>
			<div>
				<div class="step_box">
					<el-steps :active="getDomainStatus" finish-status="success">
						<el-step></el-step>
						<el-step></el-step>
						<el-step></el-step>
						<el-step></el-step>
						<el-step></el-step>
					</el-steps>
				</div>
				<div class="setep_1" v-if="getDomainStatus == 0">
					<div class="dialog_title">仅支持已备案域名绑定</div>
					<div class="dialog_checkbox">
						<el-radio v-model="form.cloudServiceType" label="0">阿里云</el-radio>
						<br /><br />
						<!-- <el-radio v-model="form.cloudServiceType" label="1">任意云服务商</el-radio> -->
					</div>
					<div class="dialog_tips">
						<span style="float: left">
							<i class="iconfont icon-tanhao" style="margin-right: 10px"></i>当前域名绑定，仅支持绑定已备案的域名
						</span>
						<span style="text-decoration: underline;float:right;font-weight: bold;cursor: pointer"
							@click="seeUrl">
							查看绑定域名教程
						</span>
					</div>
				</div>
				<div class="setep_2" v-if="getDomainStatus == 1">
					<div class="dialog_title">你的二级域名</div>
					<div class="dialog_checkbox">
						<el-input v-model="form.SLD" placeholder="请输入已备案的域名"></el-input>
					</div>
					<div class="dialog_tips">
						<i class="iconfont icon-tanhao" style="margin-right: 10px"></i>请输入二级域名，选择二级域名时，请确认 此二级域名无其他
						CNAME/A/MX
						记录配置 如: wkzx.store
					</div>
				</div>
				<div class="setep_3" v-if="getDomainStatus == 2">
					<div class="dialog_checkbox">
						<div class="tips">
							请联系管域名管理员，前往域名DNS管理后台添加如下记录
						</div>
						<el-table :data="tableData2" border  @cell-click="copyText">
							<el-table-column label="主机记录" align="center">{{form2.sub_domain}}</el-table-column>
							<!-- <el-table-column prop="record" label="记录记录" align="center" /> -->
							<el-table-column label="记录类型" align="center">{{form2.type}}</el-table-column>
							<el-table-column label="记录值" align="center">{{form2.record}}</el-table-column>
						</el-table>
					</div>
					<div class="dialog_tips">
						<i class="iconfont icon-tanhao" style="margin-right: 10px"></i>请在配置成功后，点击下一步按钮
					</div>
				</div>
				<div class="setep_3" v-if="getDomainStatus == 3">
					<div class="dialog_title">还差一步绑定成功</div>
					<div class="dialog_checkbox">
						<div class="tips">
							请联系管域名管理员，前往域名DNS管理后台添加如下CNAME记录
						</div>
						<el-table :data="tableData" border @cell-click="copyurea">
							<el-table-column prop="type" label="记录类型" align="center" />
							<!-- <el-table-column prop="record" label="记录记录" align="center" /> -->
							<el-table-column label="记录值" align="center">{{form.SLD}}</el-table-column>
							<el-table-column prop="value" label="记录值" align="center" fit="true" />
						</el-table>
					</div>
					<div class="dialog_tips">
						<i class="iconfont icon-tanhao" style="margin-right: 10px"></i>请在配置成功后，点击“完成”按钮
					</div>
				</div>
				<div class="setep_4" v-if="getDomainStatus == 4">
					<div class="dialog_title">绑定成功</div>
					<div class="dialog_title">记录值：testceshiwkzx.b0.aicdn.com</div>
					<div class="dialog_checkbox">
						<div>
							您的账户已绑定域名：【{{ SLDTitle }}】<span style="color: #FF4A4A;cursor: pointer;"
								@click="resetClick">解除绑定</span>
						</div>
					</div>
					<div class="dialog_tips">
						<i class="iconfont icon-tanhao" style="margin-right: 10px"></i>如需更换绑定，请点击解除绑定重新进行绑定操作
					</div>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="upClick" v-if="getDomainStatus != 0 && getDomainStatus != 4">上一步</el-button>
					<el-button type="primary" @click="setepClick" v-if="getDomainStatus != 4" v-loading="loading">下一步
					</el-button>
					<el-button type="primary" @click="confirmClick" v-if="getDomainStatus == 4">完成</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		bindOrUnbindDomain,
		getResolveCNAME,
		addYouPaiYunSLD,
		domainVerify,
		domainPRVerify
	} from "@/api/api";
	export default {
		props: {
			dialogVisible: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				checked1: false,
				copyUrl: "", //取件链接
				tableData: [{
					type: "CNAME",
					record: '',
					value: "testceshiwkzx.b0.aicdn.com",
				}, ],
				tableData2: [{
					type: "",
					record: '',
					value: "",
				}, ],
				SLDTitle: "",
				form: {
					domainState: "0",
					cloudServiceType: "0",
					SLD: "",
				},
				form2: {},
				loading: false,
				verified:false,
			};
		},
		computed: {
			...mapGetters(["getDomainStatus"]),
			visible() {
				return this.dialogVisible;
			},
		},

		methods: {
			seeUrl() {
				window.open('https://support.qq.com/products/349734/blog/509560')
			},
			// 解除绑定
			resetClick() {
				this.$confirm("解除绑定域名后，分发将出现中间页提示下载风险", "提示", {
						confirmButtonText: "解绑",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.form.domainState = 1;
						bindOrUnbindDomain(this.form).then((res) => {
							if (res.code == 0) {
								this.$store.commit("domainStatusChange", 0);
								this.$message.success("解除绑定成功");
							}else{
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消解绑",
						});
					});
			},
			confirmClick() {
				this.$emit("getDemain");
				this.$emit("dialogChange", false);
			},
			upClick() {
				if (this.getDomainStatus == 1) {
					this.$store.commit("domainStatusChange", 0);
					return;
				}
				if (this.getDomainStatus == 2) {
					this.$store.commit("domainStatusChange", 1);
					return;
				}
				if (this.getDomainStatus == 3) {
					this.$store.commit("domainStatusChange", 2);

					// if(this.verified == true) {
					// 	this.$store.commit("domainStatusChange", 1);
					// 	return;
					// }else{
					// 	this.$store.commit("domainStatusChange", 2);
					// 	return;
					// }
				}
			},
			copyText(row, column, cell, event){
				// 单击复制
				let save = function (e){
					e.clipboardData.setData('text/plain',event.target.innerText); 
					e.preventDefault();  //阻止默认行为
				}
				document.addEventListener('copy',save);//添加一个copy事件
				document.execCommand("copy");//执行copy方法
				this.$message({message: '复制成功', type:'success'})//提示
			},
			copyurea(row, column, cell, event) {
				// console.log("复制点击了");
				// this.$message.success("复制成功");
				// let oInput = document.createElement('input');
				// oInput.value = "testceshiwkzx.b0.aicdn.com";
				// document.body.appendChild(oInput);
				// oInput.select(); // 选择对象;
				// document.execCommand("Copy"); // 执行浏览器复制命令
				// oInput.remove()

				// 单击复制  新
				let save = function (e){
					e.clipboardData.setData('text/plain',event.target.innerText); 
					e.preventDefault();  //阻止默认行为
				}
				document.addEventListener('copy',save);//添加一个copy事件
				document.execCommand("copy");//执行copy方法
				this.$message({message: '复制成功', type:'success'})//提示
			},
			setepClick() {
				console.log(this.form.cloudServiceType)
				if (this.getDomainStatus == 0) {
					this.$store.commit("domainStatusChange", 1);
					return;
				}
				if (this.getDomainStatus == 1) {
					if (!this.form.SLD) {
						return this.$message.error('请输入你的二级域名');
					}
					this.loading = true;
					domainPRVerify({
						SLD: this.form.SLD
					}).then((res) => {
						console.log(res)
						if (res.code == 2) {
							this.loading = false;
							this.verified = res.data.verified;
							this.$store.commit("domainStatusChange", 2);
								this.form2 = res.data
							// if(res.data.verified==false) {
							// 	this.$store.commit("domainStatusChange", 2);
							// 	this.form2 = res.data
							// 	// console.log(this.form2.domain)
							// }else{
							// 	this.$store.commit("domainStatusChange", 2);
							// }
						} else {
							this.loading = false;
							return this.$message.error(res.msg);
						}

					});
				}
				if (this.getDomainStatus == 2) {
					//校验二级域名 及添加
					domainVerify({
						SLD: this.form.SLD
					}).then((res) => {
						if (res.code == 0) {
							this.loading = false;
							this.$store.commit("domainStatusChange", 3);
						} else {
							this.loading = false;
							return this.$message.error(res.msg);
						}

					});
					return;
				}
				if (this.getDomainStatus == 3) {
					this.loading = true;
					//解析CNAME
					getResolveCNAME({
						SLD: this.form.SLD
					}).then((res) => {
						if (res.code == 0) {
							bindOrUnbindDomain(this.form).then((res) => {
								if (res.code == 0) {
									this.loading = false;
									this.SLDTitle = this.form.SLD;
									this.$message.success(res.msg);
									this.$store.commit("domainStatusChange", 4);
								} else {
									this.loading = false;
									this.$message.error(res.msg);
								}
							});
						} else {
							this.$message.error(res.msg);
							this.loading = false;
						}
					});
					return;
				}
				if (this.getDomainStatus == 4) {
					this.$store.commit("domainStatusChange", 5);
					return;
				}
			},
			handleClose() {
				this.$emit("dialogChange", false);
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .el-dialog {
		border-radius: 20px;
	}

	.tips {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 17px;
		/* identical to box height */

		color: #999999;
		margin: 10px 0;
	}

	.dialog_checkbox {
		margin-bottom: 20px;
	}

	.step_box {
		width: 300px;
		margin: auto;
		padding: 50px 0 30px 0;
	}

	.dialog_tips {
		padding: 0 20px;
		overflow: hidden;
		line-height: 40px;
		background: rgba(0, 146, 255, 0.2);
		border-radius: 4px;
		margin-bottom: 50px;
		color: #0092ff;
	}

	.dialog_title {
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;

		color: #333333;
		margin-bottom: 30px;
	}
</style>
