<template>
  <div>
    <template-pc v-if="status == 7" :vip="true" :UDID="UDID" :appInfo="appInfo" :info="info" :userId="userId"
      :shortUrl="shortUrl"></template-pc>
    <template1 v-if="status == 101" :form="info" :vip="true" :UDID="UDID" :userId="userId" :bannerImg="bannerImg"
      :link="link" :closeAD="closeAD"></template1>
    <template2 v-if="status == 106" :form="info" :imageList="imageList" :vip="true" :UDID="UDID" :userId="userId"
      :bannerImg="bannerImg" link="link" :closeAD="closeAD"></template2>
    <template3 v-if="status == 107" :form="info" :imageList="imageList" :vip="true" :UDID="UDID" :userId="userId"
      :bannerImg="bannerImg" :link="link" :closeAD="closeAD"></template3>

    <password-info v-if="status == 8" :passwordObj="passwordObj" :vip="true" :UDID="UDID" :bannerImg="bannerImg"
      :link="link" :controlState="controlState"></password-info>
    <brower-template v-if="(qqAndWxStatus == 1 || qqAndWxStatus == 2) && flagStatus" :qqAndWxStatus="qqAndWxStatus"
      :icon="icon" :softname="softName" :downFlag="downFlag" :isIOS="isIOS" :isMerge="isMerge"></brower-template>
    <delete-page v-if="status == 3 || status == 4 || status == 2" :bannerImg="bannerImg" :link="link"
      :softname="softName">
    </delete-page>
  </div>
</template>

<script>
import { vipDownInfo, getPhoneAdvertising } from "@/api/api";
import { isEmpty } from "@/common/js/common.js";
import mixin from "@/mixin"; // 引入mixin文件
import templatePc from "@/views/appDown/template/templatePc";
import template1 from "@/views/appDown/template/template1";
import template2 from "@/views/appDown/template/template2";
import template3 from "@/views/appDown/template/template3";
import passwordInfo from "@/views/appDown/passwordInfo";
import BASE_URL from "@/utils/request.js";
import browerTemplate from "@/views/appDown/template/browerTemplate";
import deletePage from "@/views/appDown/template/deletePage";
export default {
  mixins: [mixin], // 判断是否安卓公共方法
  components: {
    template1,
    template2,
    template3,
    templatePc,
    passwordInfo,
    browerTemplate,
    deletePage,
  },
  data() {
    return {
      flagStatus: false,
      softName: "",
      bannerImg: "",
      link: "",
      languageToggle: 1,
      imageList: "",
      shortUrl: "",
      show: true,
      count: "",
      dialogVisible: false,
      softId: "",
      src: BASE_URL,
      formData: {
        phone: "",
        code: "",
        report: 1,
        contentSupplement: "",
        softId: "",
        files: [],
      },
      form: {
        iconurl: "",
        softname: "",
        publishName: "",
        fileSize: "",
        modifyDate: "",
        softUrlSmall: "",
      },
      fileList: [],
      token: "",
      myHeaders: null,
      timer: null,
      appInfo: {},
      status: 1,
      webClip: {},
      info: {},
      passwordObj: {},
      controlState: "", //0:密码 1:邀请码
      vipId: "",
      UDID: "",
      downFlag: false,
      qqAndWxStatus: 0,
      icon: "",
      isIOS: true,
      isMerge: false, //是否合并
      computer: "", //空是pc  非空是ipad
      closeAD: false, //是否不展示广告
      userId: "", //应用用户id
    };
  },
  created() {
    this.vipId = this.$route.query.vipId;
    localStorage.setItem("udidcache", this.vipId);
    if (window.location.href.includes("?")) {
      let short = window.location.href;
      short = short.split("?")[0];
      this.shortUrl = short.substr(short.lastIndexOf("/") + 1, short.length);
    } else {
      let short = window.location.href;
      this.shortUrl = short.substr(short.lastIndexOf("/") + 1, short.length);
      // this.shortUrl = window.location.href
      //   .split("?")[0]
      //   .substring(window.location.href.split("?")[0].length - 4);
      // this.shortUrl = window.location.href
      //   .split("?")[0].split('/')[window.location.href.split("?")[0].split('/').length - 1]
    }
    // this.getAppUrl(this.shortUrl);
    this.token = window.localStorage.getItem("token");
    this.myHeaders = { Authorization: this.token };
    // if (
    //   this.getBrower().iPad &&
    //   document.body.clientWidth <= 768 &&
    //   document.body.clientHeight <= 1024
    // ) {
    //   this.computer = 1;
    // }
    if (this.getBrower().mobile) {
      this.computer = 1;
    }
    // 判断是否安卓苹果
    this.andriodAndApple();
    this.downInfoNow(this.shortUrl);
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      this.qqAndWxStatus = 1;
    } else {
      if (
        ua.match(/iphone/i) == "iphone" &&
        ua.match(/qqtheme/i) == "qqtheme"
      ) {
        this.qqAndWxStatus = 2;
      }
      if (
        ua.match(/android/i) == "android" &&
        ua.match(/qqtheme/i) == "qqtheme"
      ) {
        this.qqAndWxStatus = 2;
      }
    }
  },
  methods: {
    // 获取广告栏数据
    getPhoneAdvertisingTl(val) {
      getPhoneAdvertising({ userId: val }).then((res) => {
        if (res.code == 0) {
          console.log(res)
          if (!isEmpty(res.data.advertising)) {
            this.bannerImg = res.data.advertising.imgUrl;
            this.link = res.data.advertising.link;
          } else {
            this.closeAD = true;
          }
        }
      });
    },
    // 判断系统所属安卓还是苹果
    andriodAndApple() {
      if (
        !(
          this.getBrower().ios ||
          this.getBrower().iPhone ||
          this.getBrower().iPad
        )
      ) {
        //非苹果端
        this.downFlag = false;
      } else {
        this.downFlag = true;
      }
    },
    async downInfoNow(shortUrl) {
      await vipDownInfo(
        { vipId: this.vipId, computer: this.computer },
        shortUrl
      ).then((res) => {
        this.status = res.code;
        if (this.status == 2 || this.status == 3 || this.status == 4) {
          this.flagStatus = false;
        }
        // alert(res.code) 
        // alert(JSON.stringify(res.data))
        console.log(this.status, 111111)
        if (res.data) {
          this.UDID = res.data.UDID
          this.userId = res.data.userId
          if (
            (res.data.cpSoftId == "0" &&
              res.data.remarks2 == "0" &&
              res.data.cpSoftId != "undefined" &&
              res.data.remarks2 != "undefined") ||
            (res.data.cpSoftId != undefined && res.data.remarks2 != undefined)
          ) {
            this.icon = res.data.iconUrl;
            this.softName = res.data.softName;
            //  document.title = this.softName;
            this.isIOS = res.data.IsIOS;
          } else {
            if (!this.downFlag) {
              this.icon = res.data.androidIconUrl;
              this.softName = res.data.androidSoftName;
            } else {
              this.icon = res.data.iOSIconUrl;
              this.softName = res.data.iOSSoftName;
            }

            if (this.softName) {
              document.title = this.softName;
            } else {
              document.title = "";
            }

            if (res.data.iOSIconUrl != null && res.data.androidIconUrl != null || res.data.thirdurl != null) {
              this.isMerge = true;
              if (res.data.thirdurl) {
                if (res.data.iOSIconUrl) {
                  this.icon = res.data.iOSIconUrl;
                  this.softName = res.data.iOSSoftName;
                } else {
                  this.icon = res.data.androidIconUrl;
                  this.softName = res.data.androidSoftName;
                }
              }
            } else {
              if (res.data.iOSIconUrl != null) {
                this.isIOS = true;
              } else {
                this.isIOS = false;
              }
            }
          }
        }




        if (res.code == 1 || res.code == 99) {
          this.$router.push({
            path: "/errorPage",
            query: {
              tips: "短链接不存在或已下架",
            },
          });
        }
        if (res.code == 11) {
          this.$router.push({
            path: "/errorPage",
            query: {
              tips: "黑名单中存在该应用",
            },
          });
        }
        if (res.code == 13) {
          this.$router.push({
            path: "/errorPage",
            query: {
              tips: res.msg,
            },
          });
        }
        if (res.code == 98) {
          this.$router.push({
            path: "/errorPage",
            query: {
              tips: "请实名认证后再请进下载",
            },
          });
        }
        if (res.code == 7) {
          let obj = {};
          let url = res.data.forward;
          let urlList = url.split("?")[1].split("&");
          urlList.forEach((item) => {
            let arr = item.split("=");
            let key = arr[0];
            let val = arr[1];
            obj[key] = val;
          });
          this.appInfo = obj;
          this.formData.softId = this.softId;
          this.info = res.data.info;
        }
        if (res.code == 9) {
          this.$router.push({
            path: "/errorPage",
            query: {
              tips: res.msg,
            },
          });
          this.webClip = res.data.webClip;
        }
        if (res.code == 101) {
          this.info = res.data.info;
          // if (res.data.info.thirdurl) {
          //   return (window.location.href = "http://" + res.data.info.thirdurl);
          // }
        }
        if (res.code == 101) {
          if (!this.getBrower().mobile) {
            this.status = 7;
          }
        }


        if (res.code == 106 || res.code == 107) {
          // if (res.data.info.thirdurl) {
          //   return (window.location.href = "http://" + res.data.info.thirdurl);
          // }
          this.info = res.data.info;
          this.imageList = res.data.imageList;
          this.userId = res.data.userId;
        }
        if (res.code == 106 || res.code == 107) {
          if (!this.getBrower().mobile) {
            this.status = 7;
          }
        }
        // 输入密码状态
        if (res.code == 8) {
          console.log(res)
          if (res.data.openPassword == 1) {
            this.controlState = 0;
          } else if (res.data.remarks4 == 1) {
            this.controlState = 1;
          }
          this.passwordObj = res.data;
        }
        if (this.downFlag && isEmpty(this.vipId)) {
          if (res.data.info) {
            if (res.data.info.fittype == "IOS") {
              if (res.code == 108 && (res.data.info.openPassword != 0 || res.data.info.actcode == 1 || res.data.info.remarks4 == 1)) {
                this.status = 8;
                if (res.data.info.openPassword == 1) {
                  this.controlState = 0;
                } else if (res.data.info.remarks4 == 1) {
                  this.controlState = 1;
                }
                this.passwordObj.iconUrl = res.data.info.iconurl;
                this.passwordObj.softName = res.data.info.softname;
                this.passwordObj.version = res.data.info.versionnum;
                this.passwordObj.actCode = res.data.info.actcode;
                this.passwordObj.softId = res.data.info.softId;
              }
            } else {
              if (res.code == 108 && (res.data.mergeInfo.openPassword != 0 || res.data.mergeInfo.actcode == 1 || res.data.mergeInfo.remarks4 == 1)) {
                this.status = 8;
                if (res.data.mergeInfo.openPassword == 1) {
                  this.controlState = 0;
                } else if (res.data.mergeInfo.remarks4 == 1) {
                  this.controlState = 1;
                }
                else if (res.data.mergeInfo.actcode == 1) {
                  this.controlState = 1;
                }
                console.log(this.controlState)
                this.passwordObj.iconUrl = res.data.mergeInfo.iconurl;
                this.passwordObj.softName = res.data.mergeInfo.softname;
                this.passwordObj.version = res.data.mergeInfo.versionnum;
                this.passwordObj.actCode = res.data.mergeInfo.actcode;
                let iosOran = '';
                if (this.downFlag) {
                  iosOran = res.data.mergeInfo.iosiconUrl
                } else {
                  iosOran = res.data.mergeInfo.androidiconUrl
                }
                let index = iosOran.indexOf('=');
                let softId = iosOran.substring(index + 1)
                this.passwordObj.softId = softId;
              }
            }
          }
        } else {
          if (res.data.info && isEmpty(this.vipId)) {
            if (res.data.info.fittype == "Android") {
              if (res.code == 108 && (res.data.info.openPassword != 0 || res.data.info.actcode == 1 || res.data.info.remarks4 == 1)) {
                this.status = 8;
                if (res.data.info.openPassword == 1) {
                  this.controlState = 0;
                } else if (res.data.info.actcode == 1) {
                  this.controlState = 1;
                }
                this.passwordObj.iconUrl = res.data.info.iconurl;
                this.passwordObj.softName = res.data.info.softname;
                this.passwordObj.version = res.data.info.versionnum;
                this.passwordObj.actCode = res.data.info.actcode;
                this.passwordObj.softId = res.data.info.softId;
              }
            } else {
              if (res.code == 108 && (res.data.mergeInfo.openPassword != 0 || res.data.mergeInfo.actcode == 1 || res.data.mergeInfo.remarks4 == 1)) {
                this.status = 8;
                if (res.data.mergeInfo.openPassword == 1) {
                  this.controlState = 0;
                } else if (res.data.mergeInfo.remarks4 == 1) {
                  this.controlState = 1;
                }
                console.log(this.controlState)
                this.passwordObj.iconUrl = res.data.mergeInfo.iconurl;
                this.passwordObj.softName = res.data.mergeInfo.softname;
                this.passwordObj.version = res.data.mergeInfo.versionnum;
                this.passwordObj.actCode = res.data.mergeInfo.actcode;
                let iosOran = '';
                if (this.downFlag) {
                  iosOran = res.data.mergeInfo.iosiconUrl
                } else {
                  iosOran = res.data.mergeInfo.androidiconUrl
                }
                let index = iosOran.indexOf('=');
                let softId = iosOran.substring(index + 1)
                this.passwordObj.softId = softId;
              }
            }
          }
        }
        if (res.code == 108) {
          if (!this.getBrower().mobile) {
            this.status = 7;
          }
        }
        // 合并跳转页面
        if (res.code == 108) {
          // if (res.data.info.thirdurl) {
          //   return (window.location.href = "http://" + res.data.info.thirdurl);
          // }
          if (!this.getBrower().mobile) {
            this.info = res.data.info;
            this.status = 7;
          } else {
            let url = "";
            if (this.downFlag) {
              //判断模板
              if (res.data.info.softTemplet == 6) {
                this.status = 106;
                this.imageList = res.data.imageList;
              } else if (res.data.info.softTemplet == 7) {
                this.status = 107;
              } else {
                this.status = 101;
              }
              this.info = res.data.info;
              // url = res.data.mergeInfo.iosiconUrl
            } else {
              url = res.data.mergeInfo.androidiconUrl;
              this.$router.push({
                path: "/templateMerge",
                query: {
                  softId: url.split("?")[1].split("=")[1],
                },
              });
            }
          }

        }
        this.flagStatus = true;
        this.getPhoneAdvertisingTl(res.data.userId);
      });
    },
    // 选择中英文
    languageClick(val) {
      this.languageToggle = val;
    },
    // 免责声明
    exemptionClick() {
      this.$router.push({
        path: "/contract",
        query: {
          status: 5,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
